import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  IAPIListOptions,
  useGetPerformersQuery,
} from '../../../../services/eventsApi';
import {useMemo} from 'react';
import {PerformerRow, PerformerRowProps} from '../PerformerList/PerformerRow';

export default function usePerformerList(options: IAPIListOptions = {}) {
  const intl = useIntl();
  const {
    data: performersResult,
    isLoading,
    isFetching,
  } = useGetPerformersQuery(options);

  const performers = useMemo(() => {
    if (!performersResult?.data) {
      return [];
    }
    return performersResult.data.map(performer => ({
      id: performer.id,
      name: {
        id: performer.id,
        title: performer.name,
        url: performer.landingPageUrl,
        image: performer.mainImage?.path,
        isManual: !performer.providers?.length,
        update: performer.providers?.[0]?.changes?.name,
      },
      createdAt: performer.createdAt,
    }));
  }, [performersResult?.data]);

  const HeaderCells = [
    {
      name: 'name',
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.performer'}),
      collapse: false,
      renderValue: (performerRowProps: Omit<PerformerRowProps, 'intl'>) => (
        <PerformerRow {...performerRowProps} intl={intl} />
      ),
      width: '40%',
    },
    {
      name: 'createdAt',
      label: intl.formatMessage({id: 'dashboard.performer.created_date'}),
      collapse: true,
      renderValue: (props: string) => {
        return (
          <Typography variant="body2">
            {moment(props).format('DD MMM YYYY')}
          </Typography>
        );
      },
      width: '60%',
    },
  ];

  return {
    HeaderCells,
    rows: performers,
    isLoading: isLoading || isFetching,
    total: performersResult?.meta.itemCount ?? 0,
    pageCount: performersResult?.meta.pageCount ?? 0,
    currentPage: options.page ?? performersResult?.meta.page ?? 1,
  };
}
