import * as yup from 'yup';
import {ObjectSchema} from 'yup';
import {IntlShape} from 'react-intl';
import {SeatmapFormFields} from './types';
import {ISeatmapBlock, ISeatmapSection} from '../../../services/eventsApi';

export type SeatmapFormSchema = ObjectSchema<
  Omit<SeatmapFormFields, 'seatmapFile' | 'imageUrl'>
>;

export function getSeatmapValidationSchema(intl: IntlShape): SeatmapFormSchema {
  // @ts-ignore
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        intl.formatMessage(
          {id: 'validation.error.required_field'},
          {fieldName: 'Seatmap name'}
        )
      ),
    venue: yup
      .object()
      .shape({
        id: yup
          .number()
          .required(
            intl.formatMessage(
              {id: 'validation.error.required_field'},
              {fieldName: 'Venue'}
            )
          ),
      })
      .required(
        intl.formatMessage(
          {id: 'validation.error.required_field'},
          {fieldName: 'Venue'}
        )
      ),
    sections: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required(
              intl.formatMessage(
                {
                  id: 'validation.error.required_field',
                },
                {
                  fieldName: 'Section name',
                }
              )
            ),
          isGeneralAdmission: yup.boolean().optional(),
          color: yup.string().optional(),
          customErrors: yup.object().shape({
            blockOrGeneralAdmissionError: yup.boolean().test(
              'blockOrGeneralAdmission',
              intl.formatMessage({
                id: 'validation.error.at_least_one_block',
              }),
              (value, context) => {
                const section = context.from?.at(1)?.value as ISeatmapSection;
                if (!section) return true;
                if (section.isGeneralAdmission || section.blocks.length)
                  return true;
                const path = context.path
                  .replaceAll('[', '.')
                  .replaceAll(']', '');
                return context.createError({
                  message: intl.formatMessage({
                    id: 'validation.error.at_least_one_block',
                  }),
                  path,
                });
              }
            ),
            sectionNameUniqueness: yup.boolean().test(
              'sectionNameUniqueness',
              intl.formatMessage({
                id: 'validation.error.at_least_one_block',
              }),
              (value, context) => {
                const section = context.from?.at(1)?.value as ISeatmapSection;
                const sections = context.from?.at(2)?.value
                  .sections as ISeatmapSection[];

                const sameNameDuplicatedIds = sections.reduce(
                  (acc, currentSection, index) => {
                    if (currentSection.name === section.name) {
                      acc.push(index);
                    }
                    return acc;
                  },
                  [] as number[]
                );

                if (sameNameDuplicatedIds.length === 1) return true;
                const path = context.path.replace('[', '.').replace(']', '');

                return context.createError({
                  message: intl.formatMessage({
                    id: 'validation.error.section_name_uniqueness',
                  }),
                  path,
                });
              }
            ),
          }),
          blocks: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  name: yup
                    .string()
                    .trim()
                    .required(
                      intl.formatMessage(
                        {id: 'validation.error.required_field'},
                        {fieldName: 'Block name'}
                      )
                    ),
                })
                .test(
                  'blockNameUniqueness',
                  intl.formatMessage({
                    id: 'validation.error.block_name_uniqueness',
                  }),
                  (block, context) => {
                    if (!block.name) return true;
                    if (!context.parent || context.parent.length < 2)
                      return true;
                    return (
                      context.parent.filter(
                        (otherBlock: ISeatmapBlock) =>
                          otherBlock.name.trim() === block.name.trim()
                      ).length === 1
                    );
                  }
                )
            )
            .optional(),
        })
        // .test(
        //   'blockOrGeneralAdmission',
        //   intl.formatMessage({id: 'validation.error.at_least_one_block'}),
        //   section => !!section?.blocks?.length || section?.isGeneralAdmission
        // )
        // .test(
        //   'sectionNameUniqueness',
        //   intl.formatMessage({
        //     id: 'validation.error.section_name_uniqueness',
        //   }),
        //   (section, context) => {
        //     if (!section.name) return true;
        //     if (!context.parent || context.parent.length < 2) return true;
        //     return (
        //       context.parent.filter(
        //         (otherSection: ISeatmapBlock) =>
        //           otherSection.name.trim() === section.name.trim()
        //       ).length === 1
        //     );
        //   }
        // )
      )
      .min(
        1,
        intl.formatMessage({id: 'validation.error.at_least_one_section'})
      ),
  });
}
