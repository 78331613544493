import {FormattedMessage, useIntl} from 'react-intl';
import {Prompt, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '../../Common/Button';
import {CategoryForm} from './CategoryForm';
import {useForm} from 'react-hook-form';
import {CategoryFormFields} from './CategoryForm/types';
import {
  categoryFormDefaultValues,
  isUniqueName,
  scrollToFirstError,
} from './CategoryForm/helpers';
import {yupResolver} from '@hookform/resolvers/yup';
import {getAddCategoryValidationSchema} from './CategoryForm/validations';
import {
  AddCategoryQueryParam,
  useAddCategoryMutation,
  useGetCategoriesQuery,
} from '../../../services/eventsApi';
import {useCallback, useEffect} from 'react';
import {
  EVENTS_CATEGORY_EDIT_PATH,
  EVENTS_CATEGORY_PATH,
} from '../../../constants/urls';
import {OverlaySpinner} from '../../Widgets/Spinner';
import {useUploadCategoryImage} from './hooks/useUploadCategoryImage';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import usePermission from '../../../Auth/usePermission';
import {CategoryPermissions} from '../../../constants/permissions';
import {useGetCategoryErrorElementsRef} from '../../../store/slices/eventsManagement';

const AddCategory = () => {
  const intl = useIntl();
  const history = useHistory();
  const [addCategory, {data: createdCategory}] = useAddCategoryMutation();
  const {upload: uploadCategoryImage, isLoading: isLoadingCategoryImage} =
    useUploadCategoryImage();
  const {hasPermission} = usePermission();
  const getErrorElementRefs = useGetCategoryErrorElementsRef();

  const {data: categories = []} = useGetCategoriesQuery(
    {
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const form = useForm<CategoryFormFields>({
    defaultValues: categoryFormDefaultValues,
    resolver: yupResolver(getAddCategoryValidationSchema(intl)) as never,
    mode: 'onBlur',
  });
  const updatedFields = form.watch('updatedFields');

  const getImageValue = async () => {
    const categoryImageResult = await uploadCategoryImage(
      form.getValues('categoryImage')
    );
    if (categoryImageResult) {
      return categoryImageResult;
    }

    const mediaLibraryItem = form.getValues('mediaLibraryItem');
    if (mediaLibraryItem) {
      return mediaLibraryItem.img;
    }
    form.setError('imageUrl', {
      type: 'required',
      message: intl.formatMessage({id: 'validation.error.required'}),
    });
    const errorElementRefs = getErrorElementRefs();
    scrollToFirstError(form.formState.errors, errorElementRefs);
    return null;
  };

  const checkNameUniqueness = () => {
    const isUnique = isUniqueName(categories, form.getValues('name'));
    if (!isUnique) {
      form.setError('name', {
        message: intl.formatMessage({id: 'validation.error.unique'}),
      });
      const errorElementRefs = getErrorElementRefs();
      scrollToFirstError(form.formState.errors, errorElementRefs);
      return false;
    }
    return true;
  };

  const submit = useCallback(async (data: CategoryFormFields) => {
    if (!checkNameUniqueness()) return;
    const imageUrl = await getImageValue();
    if (!imageUrl) return;

    const categoryData: AddCategoryQueryParam = {
      body: {
        name: data.name,
        imageUrl,
        domainIds: data.domainIds,
        parentId: data.parentId,
        landingPageUrl: data.landingPageUrl || undefined,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.CATEGORY_CREATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    await addCategory(categoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitIfValid = useCallback(async () => {
    const isValid = await form.trigger();

    if (isValid) {
      return submit(form.getValues());
    }

    const errorElementRefs = getErrorElementRefs();

    scrollToFirstError(form.formState.errors, errorElementRefs);
  }, [getErrorElementRefs, form, submit]);

  useEffect(() => {
    if (createdCategory) {
      hasPermission(CategoryPermissions.seeCategoryDetails)
        ? history.replace(
            EVENTS_CATEGORY_EDIT_PATH.replace(
              ':id',
              createdCategory.id.toString()
            ),
            {
              category: createdCategory,
            }
          )
        : history.replace(EVENTS_CATEGORY_PATH);
    }
  }, [createdCategory, hasPermission, history]);

  return (
    <div className="dashboard-wrapper">
      <OverlaySpinner isLoading={isLoadingCategoryImage} />
      <div className="container-fluid mx-lg-0">
        <div className="row align-items-center header-row pb-4 mb-1">
          <div className="col text-center text-lg-start">
            {/* Header */}
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.events.add.add_category" />
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*Cancel/Add buttons*/}
            <div className="row gx-3 align-items-center">
              <div className="col text-center">
                <Button
                  onClick={() => history.goBack()}
                  data-testid="cancel-add-category"
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                />
              </div>
              <div className="col text-center">
                <Button
                  primary
                  className="w-100"
                  onClick={submitIfValid}
                  data-testid="add-category-btn"
                  title={intl.formatMessage({id: 'actions.add'})}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-lg-0">
        <div className="dashboard-content">
          <CategoryForm form={form} />
          <div className="row gx-3 align-items-center pt-4 d-lg-none">
            <div className="col text-center">
              <Button
                onClick={() => history.goBack()}
                data-testid="cancel-add-category"
                link
                title={intl.formatMessage({id: 'actions.cancel'})}
              />
            </div>
            <div className="col text-center">
              <Button
                primary
                className="w-100"
                onClick={submitIfValid}
                title={intl.formatMessage({id: 'actions.add'})}
              />
            </div>
          </div>
        </div>
        <Prompt
          when={!!updatedFields.size && !createdCategory}
          message={intl.formatMessage({
            id: 'dashboard.confirm_not_saved_message',
          })}
        />
      </div>
    </div>
  );
};

export default AddCategory;
