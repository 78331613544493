import cx from 'clsx';
import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.divider,
    },
  },
  text: {
    position: 'relative',
    display: 'inline-flex',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0.5),
    color: theme.palette.text.secondary,
  },
}));

const TextLine = ({children, className}: any) => {
  const classes = useStyles();
  return (
    <Box className={cx(classes.root, className)}>
      <Typography variant="subtitle2" className={classes.text}>
        {children}
      </Typography>
    </Box>
  );
};

export default TextLine;
