import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../../types';

export function useCanAddSection(watch: UseFormWatch<SeatmapFormFields>) {
  const seatmapFile = watch('seatmapFile');
  const isSVGWithSeatmapFormat = watch('isSVGWithSeatmapFormat');

  return seatmapFile
    ? seatmapFile.file.name.split('.').pop() !== 'svg'
    : !isSVGWithSeatmapFormat;
}
