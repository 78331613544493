import {createStyles, makeStyles} from '@material-ui/styles';
import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '../../../../Common/Button';
import {IMG_BASE_URL} from '../../../../../constants/urls';
import {SeatmapImageView} from './SeatmapImageView';
import {DropzoneInputProps, DropzoneRootProps} from 'react-dropzone';
import React from 'react';
import clsx from 'clsx';
import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../types';
import {ITheme} from '../../../../../constants/theme';
import {SeatmapSvgFormSyncControls} from './SeatmapSvgFormSyncControls';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    btnsWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: 0,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      paddingTop: 10,
      paddingRight: 10,
      '&:hover': {
        background: theme.palette.action.disabled,
        '& .MuiButtonBase-root': {
          display: 'inline',
        },
      },
      '& .MuiButtonBase-root': {
        padding: 10,
        zIndex: 1,
        display: 'none',
      },
    },
    createMissingSectionsBtn: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      marginRight: '0.5vw',
      '&:hover': {
        background: theme.palette.background.additional,
        color: theme.palette.primary.main,
      },
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4.4),
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#282B3EB2',
        mixBlendMode: 'multiply',
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(3),
      },
      '& .MuiButtonBase-root': {
        position: 'absolute',
      },
    },
  })
);

interface SeatmapImageProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  createFormMissingSections: () => void;
  removeSvgUnrelatedSections: () => void;
  onDelete?: () => void;
  isNew?: boolean;
  watch: UseFormWatch<SeatmapFormFields>;
}

const SeatmapImage = ({
  getRootProps,
  getInputProps,
  createFormMissingSections,
  removeSvgUnrelatedSections,
  onDelete,
  isNew,
  watch,
}: SeatmapImageProps) => {
  const intl = useIntl();

  const classes = useStyles();
  const seatmapFile = watch('seatmapFile');
  const imageUrl = watch('imageUrl');

  const seatmapImagePlaceHolder = `${IMG_BASE_URL}seatmap-placeholder.svg`;
  const imageValue = seatmapFile?.url ?? imageUrl;
  const imageSrc = imageValue || seatmapImagePlaceHolder;

  return (
    <Box display="flex" flexDirection="column" gridGap={10} mt={0.5} mb={2.5}>
      {!imageValue && (
        <Typography variant="subtitle2" className="text-placeholder">
          <FormattedMessage id="dashboard.seatmap.details.image" />
        </Typography>
      )}
      {!imageValue && isNew && (
        <div
          data-testid="seatmap-upload"
          {...getRootProps({className: clsx('dropzone')})}
        >
          <div>
            <input {...getInputProps()} />
            <span className="add-file-link">
              + {intl.formatMessage({id: 'actions.add_file'})}
            </span>
          </div>
          <Typography className="text-muted font-italic" variant="subtitle2">
            {intl.formatMessage({id: 'actions.drop_upload'})}
          </Typography>
        </div>
      )}
      {!imageValue && !isNew && (
        <div {...getRootProps()}>
          <Box className={classes.wrapper}>
            <img className="img-fluid" src={imageSrc} key={imageSrc} alt="" />
            <input {...getInputProps()} />
            <Button
              primary
              title={intl.formatMessage({
                id: 'dashboard.seatmap.details.upload_seatmap',
              })}
            />
          </Box>
        </div>
      )}
      {imageValue && (
        <>
          <SeatmapSvgFormSyncControls
            createFormMissingSections={createFormMissingSections}
            removeSvgUnrelatedSections={removeSvgUnrelatedSections}
          />
          <SeatmapImageView
            src={imageSrc}
            onDelete={onDelete}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            watch={watch}
          />
        </>
      )}
    </Box>
  );
};

export default SeatmapImage;
