export enum FilterInputTypesEnum {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  DATE_SELECT = 'date_select',
  AUTOCOMPLETE = 'autocomplete',
  MULTISELECT_AUTOCOMPLETE = 'multiselect_autocomplete',
}

export enum DateSelectLastOptions {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export type AutocompleteOnFilterChangePayload = {
  value: string;
  type: FilterInputTypesEnum.AUTOCOMPLETE;
  name: string;
};

export type CheckboxOnFilterChangePayload = {
  value: boolean;
  type: FilterInputTypesEnum.CHECKBOX;
  name: string;
};

export type DateSelectOnFilterChangePayload = {
  type: FilterInputTypesEnum.DATE_SELECT;
  name: string;
  value: {
    from?: string | null;
    to?: string | null;
    selectingType?: string;
    lastOption?: DateSelectLastOptions;
    lastOptionValue?: string;
  };
};

export type MultiSelectAutocompleteOnFilterChangePayload = {
  value: Array<string | number>;
  type: FilterInputTypesEnum.MULTISELECT_AUTOCOMPLETE;
  name: string;
};

export type RadioGroupOnFilterChangePayload = {
  value: string | boolean | number;
  type: FilterInputTypesEnum.RADIO;
  name: string;
};

export type FilterChangePayload =
  | AutocompleteOnFilterChangePayload
  | CheckboxOnFilterChangePayload
  | DateSelectOnFilterChangePayload
  | MultiSelectAutocompleteOnFilterChangePayload
  | RadioGroupOnFilterChangePayload;

export type OnFilterChangeFunction = (payload: FilterChangePayload) => void;
export type OnFilterRemoveFunction = (name: string) => void;

export interface FilterItem {
  name: string;
  onFilterChange?: OnFilterChangeFunction;
  onFilterRemove?: OnFilterRemoveFunction;
  openedFilterName?: string;
  openFilter?: (name: string) => void;
}
