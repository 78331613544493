import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {urlPermissions} from '../../constants/permissions';
import usePermission from '../../Auth/usePermission';

interface PermissionContainerProps {
  permissions?: string[];
  url?: string;
  children: React.ReactNode;
}

const PermissionContainer = (props: PermissionContainerProps) => {
  const {permissions = [], url, children} = props;
  const urlPermission = urlPermissions.find(u => u.path === url);
  const {hasPermissions} = usePermission();

  const allPermissions = useMemo(
    () =>
      urlPermission
        ? [...permissions, ...urlPermission.permissions]
        : permissions,
    [permissions, urlPermission]
  );

  if (!hasPermissions(allPermissions)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default PermissionContainer;

PermissionContainer.propTypes = {
  children: PropTypes.any.isRequired,
  permissions: PropTypes.array,
  url: PropTypes.string,
};
