import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  EventManualIcon,
  ImportedEventIcon,
  UpdateIcon,
} from '../../../../constants/images';
import {
  IAPIListOptions,
  useGetVenuesQuery,
} from '../../../../services/eventsApi';
import {useMemo} from 'react';
import moment from 'moment/moment';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import EventMark from '../../components/EventMark';

export function useVenueList(options: IAPIListOptions = {}) {
  const intl = useIntl();
  const {
    data: venuesResult,
    isLoading,
    isFetching,
  } = useGetVenuesQuery({
    options,
    formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
  });

  const venues = useMemo(() => {
    if (venuesResult?.data) {
      return venuesResult.data.map(venue => ({
        id: venue.id,
        name: {
          id: venue.id,
          title: venue.name,
          isManual: venue.providers.length === 0,
          updateTitle: venue.providers.at(0)?.changes?.name,
        },
        city: `${venue.city}, ${venue.country}`,
        createdAt: moment(venue.createdAt).format('DD MMM YYYY'),
      }));
    }
    return [];
  }, [venuesResult]);

  const HeaderCells = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.venue',
      }),
      collapse: false,
      renderValue: ({
        id,
        title,
        isManual,
        updateTitle,
      }: {
        id: number;
        title: string;
        isManual: boolean;
        updateTitle?: string;
      }) => {
        const iconStyles = {style: {width: 11, height: 11}};
        return (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={5}
            className="ps-2 ps-lg-1"
            data-testid={`venue-${id}`}
          >
            <Box display="flex" alignItems="center" gridGap={5}>
              <EventMark
                icon={isManual ? <EventManualIcon /> : <ImportedEventIcon />}
                formattedMessageId={
                  'dashboard.events.list.' +
                  (isManual ? 'manual_input' : 'import')
                }
                iconSize="11"
              />
              <Typography
                variant="body2"
                className="text-truncate"
                data-testid="venue-title"
              >
                {title}
              </Typography>
            </Box>
            {updateTitle && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridGap={5}
              >
                <UpdateIcon {...iconStyles} />
                <Typography
                  variant="body2"
                  className="text-truncate font-bold-italic text-blue"
                >
                  {intl.formatMessage({
                    id: 'dashboard.events.list.venue_tab.venue_update',
                  })}
                  : {updateTitle}
                </Typography>
              </Box>
            )}
          </Box>
        );
      },
      width: '33%',
    },
    {
      name: 'city',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.venue_location',
      }),
      collapse: true,
      width: '25%',
    },
    {
      name: 'createdAt',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.created_date',
      }),
      collapse: true,
    },
  ];

  return {
    HeaderCells,
    rows: venues,
    isLoading: isLoading || isFetching,
    total: venuesResult?.meta.itemCount ?? 0,
    pageCount: venuesResult?.meta.pageCount ?? 0,
    currentPage: venuesResult?.meta.page ?? 1,
  };
}
