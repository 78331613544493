import {FileRejection} from 'react-dropzone';
import {IntlShape} from 'react-intl';

const FILE_INVALID_TYPE = 'file-invalid-type';
const FILE_TOO_LARGE = 'file-too-large';
const TOO_MANY_FILES = 'too-many-files';

export function showRejectedFilesMessages(
  rejectedFiles: FileRejection[],
  intl: IntlShape,
  showMessage: (
    message: string,
    options: {variant: 'error' | 'success'}
  ) => void
) {
  if (!rejectedFiles.length) return;

  rejectedFiles.forEach((errorFile, index) => {
    if (errorFile.errors.some(error => error.code === FILE_INVALID_TYPE)) {
      showMessage(
        intl.formatMessage(
          {
            id: 'dashboard.file_rejected',
          },
          {
            fileName: errorFile.file.name,
            acceptedFormats: '.svg, .png .jpg, and .jpeg',
          }
        ),
        {variant: 'error'}
      );
    }
    if (errorFile.errors.some(error => error.code === FILE_TOO_LARGE)) {
      showMessage(
        intl.formatMessage(
          {
            id: 'dashboard.file_too_large',
          },
          {
            fileName: errorFile.file.name,
          }
        ),
        {variant: 'error'}
      );
    }
    if (
      errorFile.errors.some(error => error.code === TOO_MANY_FILES) &&
      index === rejectedFiles.length - 1
    ) {
      showMessage(
        intl.formatMessage(
          {
            id: 'dashboard.too_many_files',
          },
          {
            max: 1,
            type: 'image',
          }
        ),
        {variant: 'error'}
      );
    }
  });
}
