import {EventStatus, IEvent} from '../../../../services/eventsApi';
import moment from 'moment/moment';
import {Item} from '../../../Widgets/ActiveFilters';
import {FilterItems, StaticFilters} from '../constants';
import {IntlShape} from 'react-intl';

export function getOptionsList<T, LK extends keyof T, VK extends keyof T>(
  labelKey: LK,
  valueKey: VK,
  list?: T[]
): Array<{label: T[LK]; value: T[VK]}> {
  if (!list) return [];
  return list.map((item: T) => ({
    label: item[labelKey],
    value: item[valueKey],
  }));
}

export function prepareFiltersPayload(values: Record<string, any>) {
  const statusFilter = [];
  if (values.activeStatus) {
    statusFilter.push('UPCOMING');
  }
  if (values.inactiveStatus) {
    statusFilter.push('PASSED');
  }
  const sourceFilter = [];
  if (values.fanpassEventSource) {
    sourceFilter.push('FANPASS');
  }
  if (values.tixstockEventSource) {
    sourceFilter.push('TIXSTOCK');
  }
  return {
    title: values.title,
    source: sourceFilter,
    status: statusFilter,
    performerId: values.performers,
    categoryId: values.categories,
    venue: values.venue,
    isMissingSeatmapImage: values.isMissingSeatmapImage,
    isPremium: values.isPremium,
    isDateConfirmed: values.isDateConfirmed,
    requiresUpdate: values.requiresUpdate,
    dateFrom: values.date?.from,
    dateTo: values.date?.to,
    creationDateFrom: values.creationDate?.from,
    creationDateTo: values.creationDate?.to,
  };
}

export function getFilterPayloadKey(filterKey: string): string[] | null {
  if (!filterKey.startsWith('filters_')) return null;
  const filterKeysMap: Record<string, string> = {
    filters_fanpassEventSource: 'Fanpass',
    filters_tixstockEventSource: 'Tixstock',
    filters_activeStatus: 'Upcoming',
    filters_inactiveStatus: 'Passed',
    filters_title: 'title',
    filters_performers: 'performerId',
    filters_categories: 'categoryId',
    filters_venue: 'venue',
    filters_isMissingSeatmapImage: 'isMissingSeatmapImage',
    filters_isPremium: 'isPremium',
    filters_isDateConfirmed: 'isDateConfirmed',
    filters_requiresUpdate: 'requiresUpdate',
    filters_date: 'date',
    filters_creationDate: 'creationDate',
  };

  const payloadKey = filterKeysMap[filterKey];
  if (payloadKey === 'date') {
    return ['dateFrom', 'dateTo'];
  }
  if (payloadKey === 'creationDate') {
    return ['creationDateFrom', 'creationDateTo'];
  }
  return payloadKey ? [payloadKey] : null;
}

export const getEventStatus = (
  event: Omit<IEvent, 'performers' | 'seatmap' | 'venue' | 'category'>
) => {
  if (!event.date || !event.isDateConfirmed) return EventStatus.UPCOMING;

  const nowMomentTme = moment();
  const eventDate = moment.utc(event.date);
  if (event.time) {
    const [hours, minutes] = event.time.split(':');
    eventDate.set({
      hours: Number(hours),
      minutes: Number(minutes),
    });
  }

  return nowMomentTme.isSameOrAfter(eventDate)
    ? EventStatus.PASSED
    : EventStatus.UPCOMING;
};

const getFormattedFilterDate = (value: {
  selectingType: string;
  from: string;
  to: string;
  lastOptionValue: string;
  lastOption: string;
}) => {
  if (value.selectingType === 'between') {
    return `From: ${moment(value.from).format('YYYY-MM-DD')} to ${moment(value.to).format('YYYY-MM-DD')}`;
  } else if (value.selectingType === 'last' && value.lastOptionValue) {
    return `Last ${value.lastOptionValue} ${value.lastOption}`;
  }
  return '';
};

export function getActiveFilters(
  filters: Record<string, any>,
  intl: IntlShape,
  performers: Array<{
    id: number;
    name: string;
  }> = [],
  categories: Array<{id: number; name: string}> = []
) {
  const items: Item[] = [];

  Object.keys(filters).forEach(filterKey => {
    let itemValues: string[] = [];
    const relativeItems: string[] = [];
    if (StaticFilters[filterKey]) {
      itemValues.push(StaticFilters[filterKey]);
    } else if (filterKey === 'title') {
      itemValues.push(filters[filterKey] ?? '');
    } else if (filterKey === 'performers') {
      itemValues =
        performers
          .filter(p => filters[filterKey].includes(p.id))
          .map(p => p.name) ?? [];
    } else if (filterKey === 'categories') {
      itemValues =
        categories
          .filter(c => filters[filterKey].includes(c.id))
          .map(c => c.name) ?? [];
    } else if (filterKey === 'venue') {
      itemValues.push(filters[filterKey] ?? '');
    } else if (filterKey === 'date' || filterKey === 'creationDate') {
      const itemValue = getFormattedFilterDate(filters[filterKey]);
      if (itemValue) {
        itemValues.push(itemValue);
        relativeItems.push(
          `filters_${filterKey}.from`,
          `filters_${filterKey}.to`,
          `filters_${filterKey}.selectingType`,
          `filters_${filterKey}.lastOptionValue`,
          `filters_${filterKey}.lastOption`
        );
      }
    } else if (filterKey in FilterItems) {
      itemValues.push(filters[filterKey] ? 'Yes' : 'No');
    } else if (filterKey === 'isDateConfirmed') {
      itemValues.push(filters['isDateConfirmed'] ? 'No' : 'Yes');
    }

    if (itemValues.length) {
      const filterItemKey =
        filterKey === 'isDateConfirmed' ? 'toBeConfirmed' : filterKey;
      const id =
        filterItemKey in FilterItems
          ? // @ts-ignore
            FilterItems[filterItemKey]
          : filterKey;

      items.push({
        id: `filters_${filterKey}`,
        title: intl.formatMessage({
          id,
        }),
        values: itemValues,
        relativeItems,
      });
    }
  });
  return items;
}
