export interface IDomain {
  id: number;
  value: string;
  name: string;
}

export enum ChangesStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export enum IFileEntityName {
  PERFORMER = 'performer',
}

export interface IFile {
  name: string;
  path: string;
  type: string;
  entityName: IFileEntityName;
  entityId: number;
  createdAt: Date;
  updatedAt: Date;
}

export type IChanges<T> = {
  [key in keyof T]: {
    value: T[key];
    status: ChangesStatus;
  };
};

export interface IProvider<
  T extends Omit<Record<string, any>, 'provider' | 'providers'>,
  Source = 'tixstock',
  Entity = undefined,
> {
  id: string | number;
  source: Source;
  entity: Entity;
  importDate: string | Date;
  changes: IChanges<T> | null;
}

export interface ITixstockCategory {
  id: string;
  name: string;
  upcomingEvents: number;
  parentId: string;
  fanpassId: string;
  useForFeeds: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ICategory {
  id: number;
  name: string;
  imageUrl?: string;
  landingPageUrl?: string;
  parentId: number | null;
  providers: IProvider<ICategory, 'tixstock', ITixstockCategory>[];
  domains: IDomain[];
  children: ICategory[];
  createdAt: string;
  updatedAt: string;
}

export interface IPicture {
  id: number;
  imageUrl: string;
  name: string;
}

export interface IPicturesResponse {
  categories: IPicture[];
  performers: IPicture[];
}

export interface PresignedUrlResult {
  uploadUrl: string;
  readUrl: string;
}

export interface ITixstockCategoryWithChildren extends ITixstockCategory {
  key?: string;
  children: ITixstockCategoryWithChildren[];
  totalChildren: number;
}

export interface IPermission {
  id: number;
  name: string;
  slug: string;
  permissionGroup: string;
  permission_group: string;
  visible: boolean;
  order: number;
}

export interface IRole {
  id: number;
  slug: string;
  visible: boolean;
  permissions: IPermission[];
}

export interface IVenue {
  id: number;
  name: string;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  providers: IProvider<IVenue>[];
  seatmaps?: ISeatmap[];
  createdAt: string;
  updatedAt: string;
}

export interface IPerformer {
  id: number;
  name: string;
  landingPageUrl: string | null;
  mainImageId: number | null;
  providers: IProvider<IPerformer>[] | null;
  images?: IFile[];
  mainImage?: IFile | null;
  updatedAt: string;
  createdAt: string;
}

export interface IPaginatedResponse<T> {
  data: T[];
  meta: {
    itemCount: number;
    pageCount: number;
    page: string;
    pageSize: string;
  };
}

export interface IPaginatedResponse<T> {
  data: T[];
  meta: {
    itemCount: number;
    pageCount: number;
    page: string;
    pageSize: string;
  };
}

export interface IAPIListOptions {
  page?: number;
  perPage?: number;
  rowsPerPage?: number;
  q?: string;
  sort?: string;
  direction?: 'asc' | 'desc';
}

export interface ISeatmap {
  id: number;
  name: string;
  venue: IVenue;
  imageUrl: string | null;
  isSVGWithSeatmapFormat: boolean;
  providers: IProvider<ISeatmap>[] | null;
  sections: ISeatmapSection[];
  updatedAt: string;
  createdAt: string;
}

export interface ISeatmapSection {
  id: number;
  name: string;
  slug: string;
  isGeneralAdmission: boolean;
  color: string;
  blocks: ISeatmapBlock[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ISeatmapBlock {
  id: number;
  name: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISeatmapParsedResponse {
  sections: ISeatmapParsedSection[];
}

export interface ISeatmapParsedSection {
  name: string;
  blocks: ISeatmapParsedBlock[];
}

export interface ISeatmapParsedBlock {
  name: string;
}

export enum EventStatus {
  UPCOMING = 'UPCOMING',
  PASSED = 'PASSED',
}

export interface IEvent {
  id: number;
  title: string;
  slug: string;
  imageUrl: string | null;
  numberOfSales: number;
  cheapestPrice: number | null;
  venueId: number;
  categoryId: number;
  seatmapId: number;
  useCategoryDomainSettings: boolean;
  isPostponed: boolean;
  isDateConfirmed: boolean;
  isTimeConfirmed: boolean;
  isLive: boolean;
  isPremium: boolean;
  premiumDaysBeforeEvent: number;
  date: Date;
  time: string;
  provider: IProvider<IEvent> | null;
  category?: ICategory | null;
  venue: IVenue;
  performers: IPerformer[];
  performersOrder: number[];
  seatmap: ISeatmap;
  domains?: IDomain[];
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
}
