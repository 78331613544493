import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';
import {IAPIListOptions, IPaginatedResponse, IVenue} from './types';
import {getPaginationAPIParams} from './utils';

export type GetVenuesQueryParam = {
  options: IAPIListOptions;
} & FeedbackParams;

export type GetVenueQueryParam = {
  id: number;
} & FeedbackParams;

export type AddVenueQueryParam = {
  body: Partial<IVenue>;
} & FeedbackParams;

export type UpdateVenueQueryParam = {
  id: number;
  body: Partial<IVenue>;
} & FeedbackParams;

export type DeleteVenueQueryParam = {
  id: number;
} & FeedbackParams;

export const venueApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    getVenues: builder.query<IPaginatedResponse<IVenue>, GetVenuesQueryParam>({
      query: ({options = {}}) => {
        const url = 'venues';
        const urlSearchParams = getPaginationAPIParams(options);

        if (urlSearchParams) {
          return `${url}?${urlSearchParams}`;
        }

        return url;
      },
    }),
    venueList: builder.query<IVenue[], {name?: string | null} | void>({
      query: ({name} = {}) => {
        const url = 'venues/list';

        if (name) {
          return `${url}?name=${name}`;
        }

        return url;
      },
    }),
    getVenue: builder.query<IVenue, GetVenueQueryParam>({
      query: ({id}) => {
        return `venues/${id}`;
      },
    }),
    addVenue: builder.mutation<IVenue, AddVenueQueryParam>({
      query: ({body}) => ({
        url: 'venues',
        method: 'POST',
        body,
      }),
    }),
    editVenue: builder.mutation<IVenue, UpdateVenueQueryParam>({
      query: ({body, id}) => ({
        url: `venues/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteVenue: builder.mutation<IVenue, DeleteVenueQueryParam>({
      query: ({id}) => ({
        url: `venues/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetVenuesQuery,
  useGetVenueQuery,
  useAddVenueMutation,
  useEditVenueMutation,
  useDeleteVenueMutation,
  useLazyVenueListQuery,
  useVenueListQuery,
} = venueApi;
