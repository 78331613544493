import {useIntl} from 'react-intl';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import SeatmapDetailsHeader from './Form/SeatmapDetailsHeader';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {
  EditSeatmapQueryParam,
  useEditSeatmapMutation,
  useGetSeatmapQuery,
} from '../../../services/eventsApi';
import {useForm} from 'react-hook-form';
import {CountSectionChangesParams, SeatmapFormFields} from './types';
import {getSeatmapValidationSchema} from './validations';
import {yupResolver} from '@hookform/resolvers/yup';
import SeatmapForm from './Form/SeatmapForm';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useUploadFileToS3} from '../../../services/hooks/useUploadFileToS3';
import {OverlaySpinner} from '../../Widgets/Spinner';
import {SeatmapFormDefaultValues} from './constants';
import useSeatmapImage from './hooks/useSeatmapImage';
import usePermission from '../../../Auth/usePermission';
import {SeatmapPermissions} from '../../../constants/permissions';
import ConfirmationModal from '../../Common/ConfirmationModal';
import {SeatmapSaveFeature} from './SeatmapSaveFeature';
import {getSeatmapDefaultValues, scrollToFirstError, slugify} from './helpers';
import {
  useGetFormSvgSyncProps,
  useGetSeatmapErrorElementsRef,
  useIsSeatmapProcessingAnAction,
  useSeatmapStoreReset,
  useSetSeatmapProcessingAction,
} from '../../../store/slices/eventsManagement';
import * as urls from '../../../constants/urls';

const SeatmapDetails = () => {
  const {id} = useParams<{id: string}>();
  const intl = useIntl();
  const history = useHistory();

  const getErrorElementRefs = useGetSeatmapErrorElementsRef();
  const getFormSvgSyncProps = useGetFormSvgSyncProps();
  const resetSeatmapStore = useSeatmapStoreReset();
  const isSeatmapProcessingAnAction = useIsSeatmapProcessingAnAction();
  const setSeatmapProcessingAction = useSetSeatmapProcessingAction();

  const {data: seatmap, isError: isFetchingSeatmapError} = useGetSeatmapQuery({
    id: Number(id),
    formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
    showProgressDialog: true,
  });
  if (isFetchingSeatmapError) {
    history.replace(urls.EVENTS_SEATMAP_PATH);
  }
  const {hasPermission} = usePermission();

  const {upload: uploadSeatmapImage, isLoading: isUploadingSeatmapFile} =
    useUploadFileToS3({
      entity: 'Seatmap',
    });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [pendingConfirmationFormData, setPendingConfirmationFormData] =
    useState<SeatmapFormFields>();

  const updatesSetRef = useRef(new Set<string>());
  const newlyAddedSectionsIndicesRef = useRef<Set<string | number>>(new Set());
  const newlyAddedBlocksIndicesRef = useRef<Set<string | number>>(new Set());

  const [
    editSeatmap,
    {data: editSeatmapResult, isLoading: isEditingSeatmap, isSuccess},
  ] = useEditSeatmapMutation();

  const form = useForm<SeatmapFormFields>({
    defaultValues: SeatmapFormDefaultValues,
    resolver: yupResolver(getSeatmapValidationSchema(intl)) as any,
    values: getSeatmapDefaultValues(seatmap),
    mode: 'onSubmit',
  });

  const {setValue, trigger, getValues, reset: resetForm} = form;

  const countSectionChanges = useCallback(
    (payload: CountSectionChangesParams) => {
      const {blockId, sectionId, value, sectionChangedProp} = payload;
      if (!seatmap) return;
      if (typeof blockId !== 'undefined') {
        const updatesBlockPath = `${sectionId}.blocks.${blockId}`;
        const oldBlock = seatmap.sections
          ?.find(section => String(section.id) === String(sectionId))
          ?.blocks?.find(block => String(block.id) === String(blockId));

        if (oldBlock?.name === value) {
          updatesSetRef.current.delete(updatesBlockPath);
        } else {
          updatesSetRef.current.add(updatesBlockPath);
        }
        return;
      }

      const oldSection = seatmap.sections.find(
        section => String(section.id) === String(sectionId)
      );
      const updatesSectionPath = `${sectionId}.${sectionChangedProp}`;
      if (!oldSection) {
        return;
      }
      if (!sectionChangedProp) return;
      if (oldSection[sectionChangedProp] === value) {
        updatesSetRef.current.delete(updatesSectionPath);
      } else {
        updatesSetRef.current.add(updatesSectionPath);
      }
    },
    [seatmap]
  );

  const countAllSectionChanges = useCallback(() => {
    if (!seatmap) return;
    seatmap.sections.forEach((section, sectionIndex) => {
      countSectionChanges({sectionId: String(section.id), value: section.name});
      section.blocks.forEach((block, blockIndex) => {
        countSectionChanges({
          sectionId: String(section.id),
          blockId: String(block.id),
          value: block.name,
        });
      });
    });

    const formSections = getValues('sections');
    formSections.forEach((section, sectionIndex) => {
      countSectionChanges({sectionId: String(section.id), value: section.name});
      section.blocks?.forEach((block, blockIndex) => {
        countSectionChanges({
          sectionId: String(section.id),
          blockId: String(block.id),
          value: block.name,
        });
      });
    });
  }, [countSectionChanges, getValues, seatmap]);

  const useSeatmapImageProps = useMemo(() => {
    return {
      setValue,
      trigger,
      getValues,
      countAllSectionChanges,
      onImageChange: () => {
        updatesSetRef.current.add('seatmapFile');
      },
    };
  }, [setValue, trigger, getValues, countAllSectionChanges]);

  const {
    isLoading: isImageLoading,
    getRootProps,
    getInputProps,
    createFormMissingSections,
    removeSvgUnrelatedSections,
    reset,
    deleteSvgMissingBlock,
    deleteSvgMissingSection,
  } = useSeatmapImage(useSeatmapImageProps);

  const getImageUrl = useCallback(async () => {
    const seatmapFile = getValues('seatmapFile');
    if (seatmapFile) {
      return uploadSeatmapImage(seatmapFile);
    }

    return form.getValues('imageUrl');
  }, [form, getValues, uploadSeatmapImage]);

  const updateSeatmap = useCallback(
    async (data?: SeatmapFormFields) => {
      setIsConfirmationOpen(false);
      const payloadData = data || pendingConfirmationFormData;
      if (!payloadData) return;

      const imageUrl = await getImageUrl();
      const payloadSections = payloadData.sections.map(section => {
        let sectionId = section.id;
        if (typeof sectionId === 'string') {
          sectionId = undefined;
        }

        return {
          ...slugify(section),
          name: section.name.trim(),
          id: sectionId,
          blocks: section.blocks?.map(block => {
            let blockId = block.id;
            if (typeof blockId === 'string') {
              blockId = undefined;
            }
            return {
              ...slugify(block),
              id: blockId,
            };
          }),
          isGeneralAdmission: !!section.isGeneralAdmission,
        };
      });

      const payload: EditSeatmapQueryParam = {
        body: {
          name: payloadData.name,
          sections: payloadSections,
          isSVGWithSeatmapFormat: payloadData.isSVGWithSeatmapFormat,
          venueId: payloadData.venue.id,
          imageUrl,
        },
        id: Number(id),
        showProgressDialog: false,
        formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
        formatSuccessMessage: () =>
          intl.formatMessage({
            id: 'messages.SEATMAP_UPDATED_SUCCESSFULLY',
          }),
      };
      editSeatmap(payload);
    },
    [editSeatmap, getImageUrl, id, intl, pendingConfirmationFormData]
  );

  const onSubmit = useCallback(
    async (data: SeatmapFormFields) => {
      setSeatmapProcessingAction(false);

      const colors = new Set(data.sections.map(section => section.color));
      if (colors.size === data.sections.length) {
        return updateSeatmap(data);
      } else {
        setPendingConfirmationFormData(data);
        setIsConfirmationOpen(true);
      }
    },
    [updateSeatmap]
  );

  const submitIfValid = useCallback(async () => {
    setSeatmapProcessingAction(true);
    // Adding set time out here with 0 ms to give the spinner a chance to appear in the UI!.
    setTimeout(async () => {
      const isValid = await form.trigger();
      const {
        formMissingSections,
        formMissingBlocks,
        svgMissingSections,
        svgMissingBlocks,
      } = getFormSvgSyncProps();

      const isListContainErrors =
        Object.keys(svgMissingBlocks).length > 0 ||
        svgMissingSections.length > 0;

      const isFormMissingElements =
        Object.keys(formMissingBlocks).length > 0 ||
        formMissingSections.length > 0;

      if (isValid && !isListContainErrors && !isFormMissingElements) {
        return onSubmit(form.getValues());
      }
      setSeatmapProcessingAction(false);

      const errorElementRefs = getErrorElementRefs();

      scrollToFirstError(
        form.formState.errors,
        errorElementRefs,
        isListContainErrors,
        isFormMissingElements
      );
    });
  }, [
    getFormSvgSyncProps,
    getErrorElementRefs,
    form,
    onSubmit,
    setSeatmapProcessingAction,
  ]);

  const onColorsConfirmed = useCallback(() => {
    updateSeatmap().catch();
  }, [updateSeatmap]);

  const onImageDelete = useCallback(() => {
    reset();
    setValue('seatmapFile', null);
    setValue('imageUrl', null);
    setValue('isSVGWithSeatmapFormat', false);
    if (seatmap?.imageUrl) {
      updatesSetRef.current.add('seatmapFile');
    }
  }, [reset, seatmap?.imageUrl, setValue]);

  const onSectionDelete = useCallback(
    (id: string | number, slug?: string) => {
      const isNewlyAddedSection = newlyAddedSectionsIndicesRef.current.has(id);
      deleteSvgMissingSection(String(id), slug);

      if (isNewlyAddedSection) {
        newlyAddedSectionsIndicesRef.current.delete(id);
        updatesSetRef.current.delete(`sections.${id}`);
        return;
      }

      updatesSetRef.current.add(`sections.${id}`);
    },
    [deleteSvgMissingSection]
  );

  const onSectionAdd = useCallback((id: string) => {
    newlyAddedSectionsIndicesRef.current.add(id);
    updatesSetRef.current.add(`sections.${id}`);
  }, []);

  const onBlockAddedOrRemoved = useCallback(
    (
      blockId: number | string,
      sectionId: number | string,
      action: 'add' | 'delete',
      blockSlug?: string,
      sectionSlug?: string
    ) => {
      const isNewlyAddedSection =
        newlyAddedSectionsIndicesRef.current.has(sectionId);

      if (isNewlyAddedSection) return;

      const updatesBlockPath = `${sectionId}.blocks.${blockId}`;

      if (action === 'add') {
        updatesSetRef.current.add(updatesBlockPath);
        newlyAddedBlocksIndicesRef.current.add(blockId);
        return;
      }

      if (newlyAddedBlocksIndicesRef.current.has(blockId)) {
        updatesSetRef.current.delete(updatesBlockPath);
        newlyAddedBlocksIndicesRef.current.delete(blockId);
      } else {
        updatesSetRef.current.add(updatesBlockPath);
        newlyAddedBlocksIndicesRef.current.add(blockId);
        updatesSetRef.current.add(updatesBlockPath);
      }

      if (sectionSlug && blockSlug) {
        deleteSvgMissingBlock(sectionSlug, blockSlug);
      }
    },
    [deleteSvgMissingBlock]
  );

  useEffect(() => {
    if (isSuccess) {
      updatesSetRef.current.clear();
      newlyAddedSectionsIndicesRef.current.clear();
      newlyAddedBlocksIndicesRef.current.clear();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && editSeatmapResult?.data) {
      resetForm(editSeatmapResult.data);
    }
  }, [editSeatmapResult, isSuccess, resetForm]);

  useEffect(() => {
    return () => {
      resetSeatmapStore();
    };
  }, [resetSeatmapStore]);

  const isLoading =
    isUploadingSeatmapFile ||
    isEditingSeatmap ||
    isImageLoading ||
    isSeatmapProcessingAnAction;

  const isFormDisabled = !hasPermission(SeatmapPermissions.editSeatmapDetails);

  return (
    <div className="details-page seatmap">
      <OverlaySpinner isLoading={isLoading} />
      {seatmap && <SeatmapDetailsHeader seatmap={seatmap} />}
      {seatmap && (
        <Container className="ms-0">
          <div className="content-block">
            <Row className="g-3">
              <Col lg={8}>
                <Card className="details-card mb-0">
                  <CardBody>
                    <SeatmapForm
                      form={form}
                      disabled={isFormDisabled}
                      seatmap={seatmap}
                      countSectionChanges={countSectionChanges}
                      onSectionDelete={onSectionDelete}
                      onSectionAdd={onSectionAdd}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      createFormMissingSections={createFormMissingSections}
                      removeSvgUnrelatedSections={removeSvgUnrelatedSections}
                      onImageDelete={onImageDelete}
                      onBlockAddedOrRemoved={onBlockAddedOrRemoved}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
      <Prompt
        when={!!updatesSetRef.current.size}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />
      <SeatmapSaveFeature
        onSave={submitIfValid}
        onClose={() => {
          reset();
          updatesSetRef.current.clear();
          newlyAddedSectionsIndicesRef.current.clear();
          newlyAddedBlocksIndicesRef.current.clear();
          resetForm(seatmap);
        }}
        updatesSet={updatesSetRef.current}
        isFormDisabled={isFormDisabled}
        watch={form.watch}
      />
      <ConfirmationModal
        opened={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onAccept={onColorsConfirmed}
        description={intl.formatMessage({
          id: 'dashboard.confirm_colors_duplication',
        })}
      />
    </div>
  );
};

export default SeatmapDetails;
