import React from 'react';
import Typography from '@material-ui/core/Typography';
import {ArrowForwardIcon} from '../../constants/images';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {ITheme} from '../../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  arrowLink: (props: {primary?: boolean; gray?: boolean; withUrl: boolean}) => {
    const color = props.primary
      ? theme.palette.green.main
      : props.gray
        ? theme.palette.gray.main
        : 'inherit';
    const hoverColor = props.primary
      ? theme.palette.green.main
      : props.gray
        ? theme.palette.gray.main
        : theme.palette.text.primary;

    return {
      display: 'flex',
      alignItems: 'center',
      color,
      textDecoration: 'none',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        textDecoration: props.withUrl ? 'underline' : 'none',
        color: hoverColor,
      },
      '& .MuiTypography-body2': {
        marginRight: 5,
      },
      '&.inline-link': {
        display: 'inline-flex',
        width: 'auto',
      },
    };
  },
}));

interface ArrowLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  primary?: boolean;
  gray?: boolean;
  title: string;
  url?: string;
  targetBlank?: boolean;
  titleClassName?: string;
}

/* This component renders a link with small arrow on the right */
const ArrowLink = ({
  primary,
  gray,
  title,
  url,
  className,
  targetBlank,
  titleClassName,
  ...other
}: ArrowLinkProps) => {
  const classes = useStyles({primary, gray, withUrl: !!url});

  return (
    <a
      className={clsx(classes.arrowLink, className)}
      {...other}
      onClick={e => {
        e.stopPropagation();
        if (targetBlank) {
          window.open(url, '_blank');
        } else {
          window.open(url, '_self');
        }
      }}
    >
      <Typography
        variant="body2"
        className={clsx('text-truncate', titleClassName)}
      >
        {title}
      </Typography>
      {url && (
        <ArrowForwardIcon
          style={{
            fontSize: 8,
          }}
        />
      )}
    </a>
  );
};

export default ArrowLink;
