import {useState} from 'react';
import cx from 'clsx';
import {Col, Form, Row} from 'reactstrap';
import {useTheme} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Modal from '../Modal';
import IconButton from '../../Common/IconButton';
import Pagination from '../../Common/Pagination';
import {usePagination} from '../../../hooks/gallery/usePagination';
import {SelectedItemIcon} from '../../../constants/images';
import SearchForm from '../../Form/NonReduxSearchForm';
import {useMediaLibraryStyles} from './styles';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {Box, Typography} from '@material-ui/core';

export const MediaLibrary = ({
  opened,
  handleClose,
  onSearchChange,
  search,
  onSearchSubmit,
  items,
  pageSize = 12,
  onChange,
  title,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useMediaLibraryStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selected, setSelected] = useState(null);
  const {
    items: paginatedItems,
    currentPage,
    numPages,
    setCurrentPage,
  } = usePagination(items, pageSize);

  const handleSelectImage = item => {
    setSelected(item);
    onChange(item);
  };

  return (
    <Modal
      width={565}
      opened={opened}
      headerClassName="py-0"
      contentClassName={classes.modalContent}
      handleClose={handleClose}
    >
      <div className="media-library pt-4 pb-3 h-100">
        <Row className="pb-3 gy-1">
          <Col>
            <Typography variant="h4">{title}</Typography>
          </Col>
          <Col lg="auto">
            <Typography color="primary" variant="h6">
              {intl.formatMessage({id: 'actions.selected'}, {count: 1})}
            </Typography>
          </Col>
        </Row>
        <Form>
          <div className="mb-4">
            <SearchForm
              search={search}
              onChange={text => onSearchChange(text)}
              onSubmit={onSearchSubmit}
              delay={180}
              SearchProps={{
                grayBackground: true,
              }}
            />
          </div>
          <div className={classes.root}>
            <ImageList
              component="div"
              className={classes.imageList}
              rowHeight={151}
              cols={isMobile ? 2 : 4}
              gap={isMobile ? 10 : 15}
            >
              {paginatedItems.map((item, index) => (
                <div
                  key={`${item.title}-${item.id}`}
                  className="d-flex flex-column"
                >
                  <ImageListItem
                    cols={1}
                    component="div"
                    className={classes.imageItem}
                  >
                    <img src={item.img} alt="" style={{height: 121}} />
                    <ImageListItemBar
                      position="top"
                      actionIcon={
                        selected?.id === item.id && (
                          <IconButton
                            aria-label={`selected-${index}`}
                            variant="filled"
                            size="xs"
                          >
                            <SelectedItemIcon />
                          </IconButton>
                        )
                      }
                      actionPosition="right"
                      className={cx(classes.titleBar, {
                        selected:
                          selected?.id === item.id &&
                          selected?.title === item.title,
                      })}
                      onClick={() => handleSelectImage(item)}
                      data-testid={`picture-${item.title}-${item.id}`}
                    />
                  </ImageListItem>
                  <Box mt={0.67}>
                    <Typography variant="body2" className="text-truncate">
                      {item.title}
                    </Typography>
                  </Box>
                </div>
              ))}
            </ImageList>
            <Row>
              <Col lg="auto" className="mt-4 ms-lg-auto">
                <Pagination
                  current={currentPage}
                  pages={numPages}
                  textPrev={intl.formatMessage({id: 'actions.prev'})}
                  textNext={intl.formatMessage({id: 'actions.next'})}
                  onChangePage={setCurrentPage}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

MediaLibrary.propTypes = {
  /**
   * Props that will be passed to the `search` field
   */
  search: PropTypes.string.isRequired,
  /**
   * Callback called when search values change
   */
  onSearchChange: PropTypes.func.isRequired,
  /**
   * Callback called when form user hit enter
   */
  onSearchSubmit: PropTypes.func,
  /**
   * Callback called when form user select and item
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Search is loading so term cannot be  changed
   */
  loading: PropTypes.bool,
  /**
   * number of items to display per page
   */
  pageSize: PropTypes.number,
  /**
   * items to display
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      img: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

MediaLibrary.defaultPrps = {
  pageSize: 12,
};
