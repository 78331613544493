import {ICategoryState} from './category.state';
import {useStore} from 'react-redux';
import {useCallback} from 'react';

export function useGetCategoryErrorElementsRef() {
  const store = useStore();
  return useCallback(() => {
    const {category} = store.getState() as {category: ICategoryState};
    return category.errorElementRefsMap;
  }, [store]);
}
