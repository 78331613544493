import {FC} from 'react';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import Chip from '../Common/Chip';
import Button from '../Common/Button';
import {getParamsFromUrl, getUrlSearchParams} from '../../utils/filters';
import {FILTERS_APPLIED_SEARCH_KEY, FILTERS_SEARCH_KEY} from './Filters';
import useSearchParams from '../../hooks/utils/useSearchParams';
import {ITheme} from '../../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: theme.spacing(0.5),
    rowGap: theme.spacing(1),
    margin: theme.spacing(2, 0, 2.5),
    '& > *': {
      margin: 0,
      maxWidth: '100%',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '60%',
      },
    },
    '& > h6': {
      color: theme.palette.success.main,
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 0),
    },
  },
  chip: {
    '& .MuiChip-label': {
      width: '99%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  clearButton: {
    minWidth: theme.spacing(5.9),
    marginLeft: theme.spacing(0.5),
    '& .MuiButton-label': {
      fontSize: 14,
      color: theme.palette.secondary.light,
    },
  },
}));

export interface Item {
  id: string;
  title: string;
  values: string[];
  relativeItems?: string[];
}

interface ActiveFiltersProps {
  items: Item[];
  onFilterRemoved?: (filterKey: string) => void;
  onClearAll?: () => void;
  clearAdditionalParams?: Record<string, string>;
}

const ActiveFilters: FC<ActiveFiltersProps> = ({
  items,
  onFilterRemoved,
  onClearAll: clearParentFilters,
  clearAdditionalParams,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const onDelete = (filterId: string, relativeItems: string[] = []) => {
    // Get current filter values from URL and remove the filterId
    const oldSearchParams = new URLSearchParams(searchParams.toString());

    const currentFilters = {...getParamsFromUrl(searchParams).filterValues};
    delete currentFilters[filterId];

    const currentSearchParams = getUrlSearchParams(searchParams);

    // Update URL Parameters
    if (filterId.startsWith('filters_')) {
      Array.from(searchParams.keys())
        .filter(key => key.startsWith('filters_'))
        .filter(f => f === filterId || relativeItems.includes(f))
        .map(fId => searchParams.delete(fId));
      setSearchParams(searchParams, oldSearchParams);
    } else {
      const p = getUrlSearchParams(searchParams);
      p.set(FILTERS_SEARCH_KEY, JSON.stringify(currentFilters));
      // @ts-ignore
      setSearchParams(p, currentSearchParams);
    }
    // Refresh results list
    onFilterRemoved && onFilterRemoved(filterId);
  };

  const onClearAll = () => {
    const _filters = Array.from(searchParams.keys()).filter(key =>
      key.startsWith('filters_')
    );

    const oldSearchParams = new URLSearchParams(searchParams.toString());
    Array.from(searchParams.keys())
      .filter(key => key.startsWith('filters_'))
      .forEach(key => searchParams.delete(key));

    const p = getUrlSearchParams(searchParams);
    // Remove filters from URL
    p.delete(FILTERS_SEARCH_KEY);
    // Remove applied filters flag from URL
    p.delete(FILTERS_APPLIED_SEARCH_KEY);

    // Clear additional params
    if (clearAdditionalParams) {
      Object.keys(clearAdditionalParams).forEach(k =>
        p.set(k, clearAdditionalParams[k])
      );
    }

    if (_filters.length) {
      setSearchParams(searchParams, oldSearchParams);
    } else {
      // @ts-ignore
      setSearchParams(p, null);
    }

    clearParentFilters?.();
  };

  // @ts-ignore
  return (
    <>
      {items && items.length > 0 && (
        <div className={classes.root}>
          <Typography variant="h6" className="font-weight-bold">
            <FormattedMessage id="actions.showing" />:
          </Typography>
          {items.map((item, index) => {
            const value = item.values.join(', ');
            return (
              <Chip
                key={index}
                label={`${item.title}: ${value}`}
                onDelete={() => onDelete(item.id, item.relativeItems)}
                className={classes.chip}
              />
            );
          })}
          <Button
            link
            title={intl.formatMessage({id: 'actions.clear_all'})}
            className={clsx(classes.clearButton, 'no-underlined')}
            onClick={onClearAll}
          />
        </div>
      )}
    </>
  );
};

export default ActiveFilters;
