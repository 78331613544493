import {useSelector, useStore} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {
  selectFormMissingBlocks,
  selectFormMissingSections,
  selectIsSeatmapProcessingAction,
  selectSvgMissingBlocks,
  selectSvgMissingSections,
} from './seatmap.selectors';
import {ISeatmapState} from './seatmap.state';

export function useGetSeatmapErrorElementsRef() {
  const store = useStore();
  return useCallback(() => {
    const {seatmap} = store.getState() as {seatmap: ISeatmapState};
    return seatmap.errorElementRefsMap;
  }, [store]);
}

export function useFormMissingBlocks() {
  const formMissingBlocks = useSelector(selectFormMissingBlocks);
  return useMemo(() => formMissingBlocks, [formMissingBlocks]);
}

export function useFormMissingSections() {
  const formMissingSections = useSelector(selectFormMissingSections);
  return useMemo(() => formMissingSections, [formMissingSections]);
}

export function useSvgMissingBlocks() {
  const svgMissingBlocks = useSelector(selectSvgMissingBlocks);
  return useMemo(() => svgMissingBlocks, [svgMissingBlocks]);
}

export function useSvgMissingSections() {
  const svgMissingSections = useSelector(selectSvgMissingSections);
  return useMemo(() => svgMissingSections, [svgMissingSections]);
}

export function useIsSeatmapProcessingAnAction() {
  const isProcessing = useSelector(selectIsSeatmapProcessingAction);
  return useMemo(() => isProcessing, [isProcessing]);
}

export function useIsSvgMissingSection(slug: string) {
  const svgMissingSections = useSvgMissingSections();

  return useMemo(
    () => svgMissingSections.some(section => section.slug === slug),
    [slug, svgMissingSections]
  );
}

export function useIsMissingBlockCheck(sectionSlug: string, blockName: string) {
  const svgMissingBlocks = useSvgMissingBlocks();
  const missingSectionBlocks = svgMissingBlocks[sectionSlug];
  if (!missingSectionBlocks) {
    return false;
  }

  return missingSectionBlocks.some(block => block.name === blockName);
}

export function useGetFormSvgSyncProps() {
  const store = useStore();
  return useCallback(() => {
    const {
      formMissingBlocks,
      formMissingSections,
      svgMissingBlocks,
      svgMissingSections,
    } = (
      store.getState() as {
        seatmap: ISeatmapState;
      }
    ).seatmap;
    return {
      formMissingBlocks,
      formMissingSections,
      svgMissingBlocks,
      svgMissingSections,
    };
  }, [store]);
}
