import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Dialog} from '@material-ui/core';
import React from 'react';

interface OverlaySpinnerProps {
  isLoading: boolean;
}

export function OverlaySpinner(props: Readonly<OverlaySpinnerProps>) {
  const {isLoading} = props;
  return (
    <Dialog
      disableEscapeKeyDown={true}
      //disableBackdropClick={true} // Deprecated
      fullScreen={false}
      open={isLoading}
      PaperProps={{
        elevation: 4,
      }}
    >
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}
