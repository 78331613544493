import {Col, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import Select from '../../../../Common/Select';
import React, {useEffect, useMemo} from 'react';
import moment from 'moment/moment';
import {EventFormFields} from '../../types';
import {usePremiumDaysBeforeEventConditions} from './usePremiumDaysBeforeEventConditions';
import {uniq} from 'lodash';

interface PremiumDaysBeforeEventProps {
  disabled?: boolean;
  isCustom: boolean;
  eventDate?: string | null;
  isEventDayTooSoon: boolean;
  isEventPassed: boolean;
  isDateToBeConfirmed: boolean;
}

export function PremiumDaysBeforeEvent(
  props: Readonly<PremiumDaysBeforeEventProps>
) {
  const {
    disabled,
    isCustom,
    eventDate,
    isEventDayTooSoon,
    isEventPassed,
    isDateToBeConfirmed,
  } = props;
  const {control, setValue} = useFormContext<EventFormFields>();
  const {
    isDaysBeforeEventDateDisabled,
    displayTooSoonError,
    displayDateNotProvidedError,
    isDateOrTimeChanged,
    isPremiumStateChanged,
  } = usePremiumDaysBeforeEventConditions({
    isEventDayTooSoon,
    disabled,
    isEventPassed,
    eventDate,
    isDateToBeConfirmed,
    isCustom,
  });

  const premiumDaysBeforeEvent = useWatch<
    EventFormFields,
    'premiumDaysBeforeEvent'
  >({
    control,
    name: 'premiumDaysBeforeEvent',
    exact: true,
  });

  const availablePremiumDaysBeforeEvent = useMemo(() => {
    if (!eventDate) return [];
    if (isEventPassed && premiumDaysBeforeEvent)
      return [premiumDaysBeforeEvent];

    const start = moment(eventDate);
    const end = moment();
    const diffDays = start.diff(end, 'days');
    if (diffDays <= 0) {
      return [premiumDaysBeforeEvent];
    }

    const result = Array.from(Array(diffDays > 30 ? 30 : diffDays).keys()).map(
      index => index + 1
    );

    if (!isDateOrTimeChanged && !isPremiumStateChanged) {
      return uniq(result.concat([premiumDaysBeforeEvent]));
    }

    return result;
  }, [
    eventDate,
    isDateOrTimeChanged,
    isEventPassed,
    isPremiumStateChanged,
    premiumDaysBeforeEvent,
  ]);

  const customDaysItems = useMemo(
    () =>
      availablePremiumDaysBeforeEvent.map(number => ({
        label: number.toString(),
        value: number.toString(),
      })),
    [availablePremiumDaysBeforeEvent]
  );

  useEffect(() => {
    setValue(
      'isCustomPremiumDateErrorExist',
      isCustom && (displayTooSoonError || displayDateNotProvidedError)
    );
  }, [displayTooSoonError, setValue, isCustom, displayDateNotProvidedError]);

  useEffect(() => {
    if (
      !availablePremiumDaysBeforeEvent.includes(
        Number(premiumDaysBeforeEvent)
      ) &&
      !isEventPassed
    ) {
      setValue('premiumDaysBeforeEvent', 0);
    }
  }, [
    eventDate,
    premiumDaysBeforeEvent,
    isCustom,
    isEventPassed,
    setValue,
    availablePremiumDaysBeforeEvent,
  ]);

  if (!isCustom) return null;

  return (
    <>
      {displayDateNotProvidedError && (
        <span className="error pt-1">
          <FormattedMessage id="dashboard.events.details.info_errors.no_date_provided" />
        </span>
      )}
      {displayTooSoonError && !displayDateNotProvidedError && (
        <span className="error px-2">
          <FormattedMessage id="dashboard.events.details.info_errors.date_too_soon" />
        </span>
      )}
      {!displayTooSoonError && !displayDateNotProvidedError && (
        <Row className="align-items-center gy-2 gx-1 gy-lg-0 mt-1">
          <Col className="align-self-start mt-2">
            <Typography variant="body2">
              <FormattedMessage id="dashboard.events.days_before_date" />
            </Typography>
          </Col>
          <Col lg={4}>
            <Controller
              control={control}
              name="premiumDaysBeforeEvent"
              render={({
                field: {onChange, value},
                formState: {
                  errors: {premiumDaysBeforeEvent: premiumDaysBeforeEventError},
                },
              }) => {
                const isValueExist = customDaysItems
                  .map(({label}) => Number(label))
                  .includes(Number(value));

                return (
                  <Select
                    disabled={isDaysBeforeEventDateDisabled}
                    name="premiumDaysBeforeEvent"
                    onChange={onChange}
                    value={isValueExist ? Number(value) : null}
                    outlined
                    extraChild={
                      <span className="error">
                        {premiumDaysBeforeEventError?.message}
                      </span>
                    }
                    error={!!premiumDaysBeforeEventError?.message}
                    items={customDaysItems}
                  />
                );
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
}
