import {ReactNode} from 'react';
import {Spinner} from './Spinner';

interface LoadableContentProps {
  children: ReactNode;
  isLoading: boolean;
  loaderClass?: string;
}

export function LoadableContent(props: Readonly<LoadableContentProps>) {
  return (
    <>
      <Spinner loading={props.isLoading} loaderClass={props.loaderClass} />
      {!props.isLoading && props.children}
    </>
  );
}
