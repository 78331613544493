import InputField from '../../../Form/Fields/InputField';
import {isUrl} from '../../../../utils/FormValidation';
import {Box} from '@material-ui/core';
import TixstockUpdater from '../../components/TixstockUpdater';
import IconButton from '../../../Common/IconButton';
import {ArrowForwardIcon} from '../../../../constants/images';
import React from 'react';
import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../types';
import {useIntl} from 'react-intl';
import {IChanges, ISeatmap} from '../../../../services/eventsApi';

interface ImageUrlDisplayProps {
  watch: UseFormWatch<SeatmapFormFields>;
  providerChanges?: IChanges<ISeatmap> | null;
}

export function ImageUrlDisplay(props: Readonly<ImageUrlDisplayProps>) {
  const {watch, providerChanges} = props;
  const intl = useIntl();

  const imageUrl = watch('imageUrl');

  if (!imageUrl) return null;
  return (
    <InputField
      data-testid="seatmap-image-link"
      outlined
      label={intl.formatMessage({
        id: 'dashboard.seatmap.details.image',
      })}
      externalLabel
      {...(isUrl(imageUrl) && {
        addon: (
          <Box display="flex" alignItems="center" gridGap={10} className="m-0">
            {providerChanges?.imageUrl && (
              <TixstockUpdater
                label={intl.formatMessage({
                  id: 'dashboard.seatmap.seat_map_update',
                })}
                isImage
                imgPath={providerChanges?.imageUrl.value ?? ''}
              />
            )}
            <IconButton
              data-testid="seatmap-image-link-button"
              color="default"
              size="small"
              variant="action"
              className="m-0 bg-transparent shadow-none"
              style={{height: '100%'}}
              onClick={() => {
                imageUrl && window.open(imageUrl);
              }}
            >
              <ArrowForwardIcon style={{width: 7, height: 'fit-content'}} />
            </IconButton>
          </Box>
        ),
      })}
      formGroupClass="form-group mb-3"
      inputClass="p-0 disabled"
      addonPosition="end"
      input={{
        value: imageUrl ?? '',
      }}
      disabled
    />
  );
}
