import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {seatmapSlice} from './seatmap.slice';

export function useAddSeatmapErrorElementRef() {
  const dispatch = useDispatch();
  return useCallback(
    (name, element) => {
      dispatch(seatmapSlice.actions.addErrorElementRef({name, element}));
    },
    [dispatch]
  );
}

export function useSeatmapStoreReset() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(seatmapSlice.actions.reset());
  }, [dispatch]);
}

export function useSetSeatmapProcessingAction() {
  const dispatch = useDispatch();
  return useCallback(
    (value?: boolean | void) => {
      dispatch(seatmapSlice.actions.setIsProcessingAction(!!value));
    },
    [dispatch]
  );
}

export function useOnSvgParsed() {
  const dispatch = useDispatch();
  return useCallback(
    (parsedSections, formSections) => {
      dispatch(
        seatmapSlice.actions.onSvgParsed({parsedSections, formSections})
      );
    },
    [dispatch]
  );
}

export function useFormMissingBlockActions() {
  const dispatch = useDispatch();
  const addFormMissingBlocks = useCallback(
    (slug, blocks) => {
      dispatch(seatmapSlice.actions.addFormMissingBlock({slug, blocks}));
    },
    [dispatch]
  );

  const removeFormMissingBlocks = useCallback(
    slug => {
      dispatch(seatmapSlice.actions.deleteFormMissingBlocks({slug}));
    },
    [dispatch]
  );

  const clearFormMissingBlocks = useCallback(() => {
    dispatch(seatmapSlice.actions.clearFormMissingBlock());
  }, [dispatch]);

  return {
    addFormMissingBlocks,
    removeFormMissingBlocks,
    clearFormMissingBlocks,
  };
}

export function useFormMissingSectionActions() {
  const dispatch = useDispatch();
  const addFormMissingSection = useCallback(
    section => {
      dispatch(seatmapSlice.actions.addFormMissingSection(section));
    },
    [dispatch]
  );

  const removeFormMissingSection = useCallback(
    sectionName => {
      dispatch(seatmapSlice.actions.deleteFormMissingSection({sectionName}));
    },
    [dispatch]
  );

  const clearFormMissingSections = useCallback(() => {
    dispatch(seatmapSlice.actions.clearFormMissingSection());
  }, [dispatch]);

  return {
    addFormMissingSection,
    removeFormMissingSection,
    clearFormMissingSections,
  };
}

export function useSvgMissingBlockActions() {
  const dispatch = useDispatch();
  const addSvgMissingBlocks = useCallback(
    (slug, blocks) => {
      dispatch(seatmapSlice.actions.addSvgMissingBlock({slug, blocks}));
    },
    [dispatch]
  );

  const removeSvgMissingBlocks = useCallback(
    slug => {
      dispatch(seatmapSlice.actions.deleteSvgMissingBlocks({slug}));
    },
    [dispatch]
  );

  const removeSvgMissingBlock = useCallback(
    (sectionSlug, blockSlug) => {
      dispatch(
        seatmapSlice.actions.deleteSvgMissingBlock({sectionSlug, blockSlug})
      );
    },
    [dispatch]
  );

  const clearSvgMissingBlocks = useCallback(() => {
    dispatch(seatmapSlice.actions.clearSvgMissingBlock());
  }, [dispatch]);

  return {
    addSvgMissingBlocks,
    removeSvgMissingBlocks,
    clearSvgMissingBlocks,
    removeSvgMissingBlock,
  };
}

export function useSvgMissingSectionActions() {
  const dispatch = useDispatch();
  const addSvgMissingSection = useCallback(
    section => {
      dispatch(seatmapSlice.actions.addSvgMissingSection(section));
    },
    [dispatch]
  );

  const removeSvgMissingSection = useCallback(
    sectionName => {
      dispatch(seatmapSlice.actions.deleteSvgMissingSection({sectionName}));
    },
    [dispatch]
  );

  const removeSvgMissingSectionById = useCallback(
    id => {
      dispatch(seatmapSlice.actions.deleteSvgMissingSectionById(id));
    },
    [dispatch]
  );

  const clearSvgMissingSections = useCallback(() => {
    dispatch(seatmapSlice.actions.clearSvgMissingSection());
  }, [dispatch]);

  return {
    addSvgMissingSection,
    removeSvgMissingSection,
    clearSvgMissingSections,
    removeSvgMissingSectionById,
  };
}

export function useSvgFormActions() {
  const formMissingBlockActions = useFormMissingBlockActions();
  const formMissingSectionActions = useFormMissingSectionActions();
  const svgMissingBlockActions = useSvgMissingBlockActions();
  const svgMissingSectionActions = useSvgMissingSectionActions();
  const onSvgParsed = useOnSvgParsed();

  const clearFormSvgSyncProps = useCallback(() => {
    formMissingBlockActions.clearFormMissingBlocks();
    formMissingSectionActions.clearFormMissingSections();
    svgMissingBlockActions.clearSvgMissingBlocks();
    svgMissingSectionActions.clearSvgMissingSections();
  }, [
    formMissingBlockActions,
    formMissingSectionActions,
    svgMissingBlockActions,
    svgMissingSectionActions,
  ]);

  return {
    ...formMissingBlockActions,
    ...formMissingSectionActions,
    ...svgMissingBlockActions,
    ...svgMissingSectionActions,
    clearFormSvgSyncProps,
    onSvgParsed,
  };
}
