import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IEventBusListener, InitialEventBusState} from './eventBus.state';

export const eventBusSlice = createSlice({
  name: 'eventBus',
  initialState: InitialEventBusState,
  reducers: {
    addListener: (
      state,
      action: PayloadAction<IEventBusListener & {event: string}>
    ) => {
      const {event, callback, id} = action.payload;
      if (!state.listeners[event]) {
        state.listeners[event] = [];
      }
      state.listeners[event].push({callback, id});
    },
    removeListener: (
      state,
      action: PayloadAction<{event: string; id: string}>
    ) => {
      const {event, id: removedCallbackId} = action.payload;
      if (!state.listeners[event]) return;

      state.listeners[event] = state.listeners[event].filter(
        ({id}) => id !== removedCallbackId
      );
    },

    emitEvent: (
      state,
      action: PayloadAction<{event: string; payload?: any | void}>
    ) => {
      const {event, payload} = action.payload;
      if (state.listeners[event]) {
        state.listeners[event].forEach(({callback}) => callback(payload));
      }
    },
  },
});
