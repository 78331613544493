import get from 'lodash.get';
import FormControlLabel from '@mui/material/FormControlLabel';
import clsx from 'clsx';
import Radio from '../../Common/Radio';
import {FormControlLabelProps} from '@mui/material/FormControlLabel/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import React, {useEffect, useState} from 'react';
import {RadioGroupProps} from '@mui/material/RadioGroup/RadioGroup';
import {RadioProps} from '@material-ui/core/Radio/Radio';
import {FilterInputTypesEnum, FilterItem} from './types';
import {makeStyles} from '@material-ui/core';
import {useModularFilterUrlValue} from './hooks';
import {
  eventBusEvents,
  useEventBusListener,
} from '../../../store/slices/eventBus';
import {nanoid} from 'nanoid';

const useStyles = makeStyles(theme => ({
  control: {
    marginRight: 26,
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },
  },
  label: {
    fontSize: '14px !important',
    minWidth: 50,
  },
}));

interface Item {
  label: string;
  value: string | number | boolean;
}

interface RadioGroupFilterProps extends FilterItem, RadioGroupProps {
  radioProps?: Omit<
    RadioProps,
    'onChange' | 'value' | 'onCopy' | 'onCopyCapture'
  >;
  name: string;
  items: Item[];
}

export function RadioGroupFilter(props: Readonly<RadioGroupFilterProps>) {
  const {
    items,
    name,
    radioProps,
    onFilterChange,
    onFilterRemove,
    ...radioGroupProps
  } = props;
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState<Item>();
  const urlValue = useModularFilterUrlValue(name);
  const {on, off} = useEventBusListener();

  const handleChange = (item: Item) => {
    setSelectedItem(item);
    onFilterChange?.({
      value: item.value,
      type: FilterInputTypesEnum.RADIO,
      name,
    });
  };

  useEffect(() => {
    if (urlValue === null) {
      onFilterRemove?.(name);
      setSelectedItem(undefined);
    } else if (items.length) {
      setSelectedItem(items.find(item => item.value === urlValue));
    }
  }, [urlValue, items, onFilterRemove, name]);

  useEffect(() => {
    const callbackId = nanoid(5);
    on(
      eventBusEvents.ModularFilterEvents.CLEAR_FILTER,
      callbackId,
      (clearedFilterNames: string[]) => {
        if (clearedFilterNames.includes(name)) {
          setSelectedItem(undefined);
        }
      }
    );
    return () => {
      off(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, callbackId);
    };
  }, [on, off, name]);

  return (
    <RadioGroup {...radioGroupProps} defaultValue={get(items, '[0].value', '')}>
      {items.map(({label, value}) => (
        <FormControlLabel
          key={`${label}_${value}`}
          classes={{
            root: clsx(classes.control, radioProps?.classes?.root),
            label: classes.label,
          }}
          control={
            (<Radio {...radioProps} />) as FormControlLabelProps['control']
          }
          label={label}
          name={name}
          checked={selectedItem?.label === label}
          onChange={() => handleChange({label, value})}
        />
      ))}
    </RadioGroup>
  );
}
