import {ITixstockCategoryWithChildren} from './types';
import {eventsManagementApi} from './eventsManagementApi';
import {FeedbackParams} from '../../store/queries/common';

export type ListTixstockCategoryWithResponse = {
  data: ITixstockCategoryWithChildren[];
};

export type UpdateTixstockCategory = {
  id: string;
  useForFeeds: boolean;
};

export type GetTixstockCategoriesQueryParam = {} & FeedbackParams;

export type UpdateTixstockCategoryRequest = {
  categories: UpdateTixstockCategory[];
} & FeedbackParams;

export type UpdateTixstockCategoryResponse = {
  data: ITixstockCategoryWithChildren[];
};

export const tixstockCategoryApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    getTixstockCategories: builder.query<
      ListTixstockCategoryWithResponse,
      GetTixstockCategoriesQueryParam
    >({
      query: () => 'tixstock/categories',
    }),
    updateTixstockCategories: builder.mutation<
      UpdateTixstockCategoryResponse,
      UpdateTixstockCategoryRequest
    >({
      query: ({categories}) => ({
        url: 'tixstock/categories',
        method: 'PATCH',
        body: {categories},
      }),
    }),
  }),
});

export const {
  useGetTixstockCategoriesQuery,
  useUpdateTixstockCategoriesMutation,
} = tixstockCategoryApi;
