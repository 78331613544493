import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InitialEventState} from './event.state';

export const eventSlice = createSlice({
  name: 'event',
  initialState: InitialEventState,
  reducers: {
    addErrorElementRef: (
      state,
      action: PayloadAction<{name: string; element: HTMLElement}>
    ) => {
      // @ts-ignore
      state.errorElementRefsMap[action.payload.name] = action.payload.element;
    },
  },
});
