import {WrappedFieldProps} from 'redux-form';
import VerificationInput from './VerificationInput';

export type VerificationFieldProps = {} & WrappedFieldProps;

const VerificationField = ({input, meta}: WrappedFieldProps) => (
  <VerificationInput
    value={input.value}
    onChange={input.onChange}
    error={(meta.touched || meta.dirty) && meta.error}
    onBlur={input.onBlur}
    onFocus={input.onFocus}
  />
);

export default VerificationField;
