import {IPermission, IRole} from './types';
import {eventsManagementApi} from './eventsManagementApi';

export type EventPermissionsResponse = {
  Event: IPermission[];
  Venue: IPermission[];
  'Seat map': IPermission[];
  Performer: IPermission[];
  Category: IPermission[];
};

export const eventPermissionsApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    getEventPermissions: builder.query<
      EventPermissionsResponse,
      string | undefined
    >({
      query: (q?: string) => {
        let url = 'permissions';
        if (q) {
          url += `?q=${q}`;
        }

        return url;
      },
    }),
    getEventRoles: builder.query<IRole[], string[] | void>({
      query: (slugs?: string[]) => {
        const query = slugs?.length
          ? '?' + slugs.map(slug => `slugs[]=${slug}`).join('&')
          : '';

        return 'roles' + query;
      },
    }),
    editEventRolePermissions: builder.mutation<
      IRole,
      {slug: string; permissions: number[]}
    >({
      query: ({slug, permissions}) => ({
        url: `roles/${slug}`,
        method: 'PUT',
        body: {permissions},
      }),
    }),
  }),
});

export const {
  useGetEventPermissionsQuery,
  useGetEventRolesQuery,
  useEditEventRolePermissionsMutation,
  useLazyGetEventRolesQuery,
} = eventPermissionsApi;
