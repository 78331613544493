import {CSSProperties, useState} from 'react';
import cx from 'clsx';
import {Collapse, Paper, Typography} from '@material-ui/core';
import IconButton from './IconButton';
import {MinusIcon, PlusIcon} from '../../constants/images';

export type CollapsableCardProps = {
  title: string;
  trailingElement?: React.ReactNode;
  leadingElement?: React.ReactNode;
  children: React.ReactNode;
  isCollapsable?: boolean;
  style?: CSSProperties;
};

const CollapsableCard = ({
  title,
  leadingElement = null,
  trailingElement = null,
  children,
  style,
  isCollapsable = true,
}: CollapsableCardProps) => {
  const [shown, setShown] = useState(false);
  return (
    <Paper elevation={0} className="collapsable-card rounded">
      <div className="px-3 overflow-hidden">
        <div
          role="button"
          tabIndex={0}
          className={cx(
            'header py-3 d-flex align-items-center justify-content-between',
            {expanded: shown}
          )}
          onClick={(event: any) => {
            event.stopPropagation();
            if (isCollapsable) {
              setShown(!shown);
            }
          }}
          data-testid={title}
          style={style}
        >
          {leadingElement && leadingElement}
          <Typography className="category-title">{title}</Typography>
          {trailingElement && (
            <div className="ms-auto me-3 pe-1">{trailingElement}</div>
          )}
          {isCollapsable && (
            <IconButton
              size="xs"
              variant="action"
              onClick={() => setShown(!shown)}
              numberLabel={undefined}
              className={undefined}
            >
              {shown ? (
                <MinusIcon style={{width: 15, height: 2}} />
              ) : (
                <PlusIcon style={{width: 15, height: 15}} />
              )}
            </IconButton>
          )}
        </div>
        <Collapse in={shown}>
          <div className="">{children}</div>
        </Collapse>
      </div>
    </Paper>
  );
};

export default CollapsableCard;
