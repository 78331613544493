import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {buildBaseQuery} from './functions';
import {API_BASE_URL, API_ROLES_URL} from '../constants/urls';
import {Role} from '../components/Settings/types';

export const roleApi = createApi({
  baseQuery: buildBaseQuery(`${API_BASE_URL}/api`),
  endpoints: builder => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: '/admin/roles',
      }),
      transformResponse: (res: {result: Role[]}): Role[] => res.result,
    }),
    editRolePermissions: builder.mutation<
      {data: Role; success: boolean},
      {id: number; payload: {permissions?: number[]; name?: string}}
    >({
      query: ({id, payload}) => ({
        url: `${API_ROLES_URL}/${id}`,
        body: payload,
        method: 'PUT',
      }),
      // transformResponse: (res: {result: Role[]}): Role[] => res.result,
    }),
  }),
});

export const {useGetRolesQuery, useEditRolePermissionsMutation} = roleApi;
