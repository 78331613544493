import {ChangeEvent, forwardRef, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Popover from '@material-ui/core/Popover';
import TextField from '../../Common/TextField';
import {CLOCK_ICON} from '../../../constants/images';
import {ReactComponent as ArrowUpIcon} from '../../../assets/images/arrowUp.svg';
import {ReactComponent as ArrowDownIcon} from '../../../assets/images/arrowDown.svg';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import {Control} from 'react-hook-form';

export type TimePickerFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'defaultValue'
> & {
  label: string;
  formGroupClass: string;
  excludeLabel?: boolean;
  input: {
    onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    name?: string;
  };
  canEdit: boolean;
  meta: {
    touched?: boolean;
    error?: string;
    form?: string;
  };
  setValue: (value: string) => void;
  endAdornment?: JSX.Element | null;
  confirmed?: JSX.Element | null;
  disabled?: boolean;
  control?: Control<any, any>;
  errorHelperText: boolean;
};

const TimePickerField = forwardRef<HTMLInputElement, TimePickerFieldProps>(
  (props: TimePickerFieldProps, ref) => {
    const {
      label,
      formGroupClass,
      excludeLabel,
      input: {onChange, onBlur, value, name},
      canEdit,
      meta,
      setValue,
      endAdornment = null,
      confirmed = null,
      disabled,
      ...custom
    } = props;
    const intl = useIntl();
    const {touched, error} = meta;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (canEdit) {
        setAnchorEl(event.currentTarget);
      }
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
      setAnchorEl(null);
      if (onBlur) {
        onBlur(event as unknown as React.FocusEvent<HTMLDivElement>);
      }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'timepicker-popover' : undefined;

    const format = 'hh:mm A';
    // const displayTime = value || '02:00 PM';
    const [displayTime, setDisplayTime] = useState(value || '02:00 PM');

    const [hours, setHours] = useState(
      moment(displayTime, 'hh:mm A').format('hh')
    );
    const [minutes, setMinutes] = useState(
      moment(displayTime, 'hh:mm A').format('mm')
    );

    useEffect(() => {
      if (value) {
        setDisplayTime(value);
        setHours(moment(value, format).format('hh'));
        setMinutes(moment(value, format).format('mm'));
      }
    }, [value]);

    const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newHour = e.target.value;
      if (
        newHour === '' ||
        (parseInt(newHour, 10) >= 0 && parseInt(newHour, 10) <= 12)
      ) {
        setHours(newHour);
        if (newHour.length === 2) {
          const currentMoment = moment(displayTime, format);
          const newTime = currentMoment
            .clone()
            .set('hour', parseInt(newHour, 10));

          // Preserve AM/PM
          if (currentMoment.format('A') !== newTime.format('A')) {
            newTime.add(12, 'hours');
          }
          setValue(newTime.format(format));
        }
      }
    };

    const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newMinute = e.target.value;
      if (
        newMinute === '' ||
        (parseInt(newMinute, 10) >= 0 && parseInt(newMinute, 10) <= 59)
      ) {
        setMinutes(newMinute);
        if (newMinute.length === 2) {
          const newTime = moment(displayTime, format).set(
            'minute',
            parseInt(newMinute, 10)
          );
          setValue(newTime.format(format));
        }
      }
    };

    const handleHourBlur = () => {
      if (hours === '' || parseInt(hours, 10) === 0) {
        setHours('12');
      } else if (hours.length === 1) {
        setHours(hours.padStart(2, '0'));
      }
      updateTimeValue();
    };

    const handleMinuteBlur = () => {
      if (minutes === '' || minutes.length === 1) {
        setMinutes(minutes.padStart(2, '0'));
      }
      updateTimeValue();
    };

    const updateTimeValue = () => {
      const newTime = moment(
        `${hours}:${minutes} ${displayTime.split(' ')[1]}`,
        format
      );
      setValue(newTime.format(format));
    };

    const setPeriod = () => {
      const newValue = moment(displayTime, format).add(12, 'hours');
      setValue(newValue.format(format));
    };

    const upHours = (current: string) => {
      const newValue = moment(current, format).add(1, 'hours');
      setValue(newValue.format(format));
      setHours(newValue.format('hh'));
    };

    const downHours = (current: string) => {
      const newValue = moment(current, format).subtract(1, 'hours');
      setValue(newValue.format(format));
      setHours(newValue.format('hh'));
    };

    const upMinutes = (current: string) => {
      const newValue = moment(current, format).add(1, 'minutes');
      setValue(newValue.format(format));
      setMinutes(newValue.format('mm'));
    };

    const downMinutes = (current: string) => {
      const newValue = moment(current, format).subtract(1, 'minutes');
      setValue(newValue.format(format));
      setMinutes(newValue.format('mm'));
    };

    return (
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className="fan-timepicker-popup"
        >
          <div className="popover-content">
            <div className="time-picker-wrapper">
              <div className="time-picker">
                <div className="time-unit">
                  <button
                    className="up"
                    onClick={() => upHours(displayTime)}
                    disabled={disabled}
                  >
                    <ArrowUpIcon />
                  </button>
                  <input
                    className="input"
                    type="text"
                    value={hours}
                    onChange={handleHourChange}
                    onBlur={handleHourBlur}
                    disabled={disabled}
                  />
                  <button
                    className="down"
                    onClick={() => downHours(displayTime)}
                    disabled={disabled}
                  >
                    <ArrowDownIcon />
                  </button>
                </div>
                <span className="colon">:</span>
                <div className="time-unit">
                  <button
                    className="up"
                    onClick={() => upMinutes(displayTime)}
                    disabled={disabled}
                  >
                    <ArrowUpIcon />
                  </button>
                  <input
                    className="input"
                    type="text"
                    value={minutes}
                    onChange={handleMinuteChange}
                    onBlur={handleMinuteBlur}
                    disabled={disabled}
                  />
                  <button
                    className="down"
                    onClick={() => downMinutes(displayTime)}
                    disabled={disabled}
                  >
                    <ArrowDownIcon />
                  </button>
                </div>
              </div>
              <div className="am-pm-buttons">
                <div className="button-group">
                  <button
                    className={clsx('button', {
                      active: displayTime.split(' ')[1] === 'AM' && !disabled,
                    })}
                    onClick={() => setPeriod()}
                    disabled={disabled}
                  >
                    {intl.formatMessage({id: 'actions.am'})}
                  </button>
                  <button
                    className={clsx('button', {
                      active: displayTime.split(' ')[1] === 'PM' && !disabled,
                    })}
                    onClick={() => setPeriod()}
                    disabled={disabled}
                  >
                    {intl.formatMessage({id: 'actions.pm'})}
                  </button>
                </div>
              </div>
            </div>
            <div className="confirmed-component">{confirmed}</div>
          </div>
        </Popover>
        <FormControl
          data-testid="time-picker-input"
          className={clsx(
            'd-flex fan-timepicker-input',
            formGroupClass ?? 'mb-3 mb-lg-fg'
          )}
        >
          {label && !excludeLabel && (
            <span className="small-label pb-2">{label}</span>
          )}
          <TextField
            autoComplete="off"
            helperText={touched ? error : null}
            error={touched && !!error}
            addon={
              <Box display="flex" alignItems="center" gridGap={10}>
                {endAdornment ?? null}
                {CLOCK_ICON}
              </Box>
            }
            addonPosition="end"
            onKeyDown={e => e.preventDefault()}
            {...custom}
            name={name}
            value={value}
            placeholder="HH:MM"
            onClick={handleClick}
            onAddonClick={handleClick}
            ref={ref}
            disabled={disabled}
          />
        </FormControl>
      </>
    );
  }
);
export default TimePickerField;
