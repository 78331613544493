import {UseFormReturn} from 'react-hook-form';
import {VenueFormFields} from '../types';
import {useEffect, useState} from 'react';
import {IVenue} from '../../../../services/eventsApi';

export function useVenueUpdatedFields(
  form: UseFormReturn<VenueFormFields>,
  venue?: Partial<IVenue>
) {
  const [updatedFields, setUpdatedFields] = useState<Set<string>>(new Set());
  const name = form.watch('name');
  const addressLine1 = form.watch('addressLine1');
  const addressLine2 = form.watch('addressLine2');
  const city = form.watch('city');
  const country = form.watch('country');
  const latitude = form.watch('latitude');
  const longitude = form.watch('longitude');

  useEffect(() => {
    if (!venue) return;
    const isBothFalsy = (a: any, b: any) => !a && !b;

    setUpdatedFields(() => {
      const newUpdatedFields = new Set<string>();
      if (
        !isBothFalsy(name, venue.name) &&
        name?.trim() !== venue.name?.trim()
      ) {
        newUpdatedFields.add('name');
      } else {
        newUpdatedFields.delete('name');
      }

      if (
        !isBothFalsy(addressLine2, venue.addressLine2) &&
        addressLine2?.trim() !== venue.addressLine2?.trim()
      ) {
        newUpdatedFields.add('addressLine2');
      } else {
        newUpdatedFields.delete('addressLine2');
      }

      const addressLine1Conditions = [
        !isBothFalsy(addressLine1, venue.addressLine1) &&
          addressLine1 !== venue.addressLine1,
        !isBothFalsy(city, venue.city) && city !== venue.city,
        !isBothFalsy(country, venue.country) && country !== venue.country,
        !isBothFalsy(latitude, venue.latitude) && latitude !== venue.latitude,
        !isBothFalsy(longitude, venue.longitude) &&
          longitude !== venue.longitude,
      ];

      if (addressLine1Conditions.includes(true)) {
        newUpdatedFields.add('addressLine1');
      } else {
        newUpdatedFields.delete('addressLine1');
      }

      return newUpdatedFields;
    });
  }, [
    venue,
    name,
    addressLine1,
    addressLine2,
    city,
    country,
    latitude,
    longitude,
  ]);

  return {updatedFields, setUpdatedFields};
}
