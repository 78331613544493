import {useIntl} from 'react-intl';
import {CategoryForm} from './CategoryForm';
import {useForm} from 'react-hook-form';
import {CategoryFormFields} from './CategoryForm/types';
import {
  categoryFormDefaultValues,
  getCategoryFormDefaultValues,
  isUniqueName,
  scrollToFirstError,
} from './CategoryForm/helpers';
import {yupResolver} from '@hookform/resolvers/yup';
import {getAddCategoryValidationSchema} from './CategoryForm/validations';
import {
  ICategory,
  UpdateCategoryQueryParam,
  useEditCategoryMutation,
  useGetCategoriesQuery,
  useGetCategoryQuery,
} from '../../../services/eventsApi';
import {Prompt, useHistory, useLocation, useParams} from 'react-router-dom';
import {SaveFeature} from '../../Common/SaveFeature';
import {useCallback, useEffect, useState} from 'react';
import {OverlaySpinner} from '../../Widgets/Spinner';
import CategoryHeader from './CategoryHeader';
import usePermission from '../../../Auth/usePermission';
import {CategoryPermissions} from '../../../constants/permissions';
import {useUploadCategoryImage} from './hooks/useUploadCategoryImage';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import * as urls from '../../../constants/urls';
import {useGetCategoryErrorElementsRef} from '../../../store/slices/eventsManagement/category';

const EditCategory = () => {
  const intl = useIntl();

  const location: {state: {category: ICategory}} = useLocation();
  const history = useHistory();
  const routeCategory = location.state?.category;
  const [editCategory, {data: updatedCategory, isSuccess}] =
    useEditCategoryMutation();
  const {upload: uploadCategoryImage, isLoading: isLoadingCategoryImage} =
    useUploadCategoryImage();
  const params = useParams();

  const getErrorElementRefs = useGetCategoryErrorElementsRef();

  const routeCategoryId = routeCategory?.id ?? params.id;

  const [category, setCategory] = useState<ICategory>();
  const {
    data: categoryData,
    isSuccess: isGetCategorySuccess,
    isError: isFetchingCategoryError,
  } = useGetCategoryQuery(
    {
      id: routeCategoryId,
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      skip: !routeCategoryId || isSuccess,
    }
  );

  const {hasPermission} = usePermission();
  if (isFetchingCategoryError) {
    history.replace(urls.EVENTS_CATEGORY_PATH);
  }
  useEffect(() => {
    if (isGetCategorySuccess && categoryData) {
      setCategory(categoryData);
    }
  }, [isGetCategorySuccess, categoryData]);

  useEffect(() => {
    if (isSuccess && updatedCategory) {
      setCategory(updatedCategory);
    }
  }, [isSuccess, updatedCategory]);

  const {data: categories = []} = useGetCategoriesQuery(
    {
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const form = useForm<CategoryFormFields>({
    defaultValues: categoryFormDefaultValues,
    values: getCategoryFormDefaultValues(category),
    resolver: yupResolver(getAddCategoryValidationSchema(intl)) as never,
    mode: 'onBlur',
  });

  const getImageValue = async () => {
    const categoryImageResult = await uploadCategoryImage(
      form.getValues('categoryImage')
    );
    if (categoryImageResult) {
      return categoryImageResult;
    }

    const mediaLibraryItem = form.getValues('mediaLibraryItem');
    if (mediaLibraryItem) {
      return mediaLibraryItem.img;
    }
    form.setError('imageUrl', {
      type: 'required',
      message: intl.formatMessage({id: 'validation.error.required'}),
    });
    const errorElementRefs = getErrorElementRefs();

    scrollToFirstError(form.formState.errors, errorElementRefs);
    return null;
  };

  const checkNameUniqueness = () => {
    const isUnique = isUniqueName(
      categories,
      form.getValues('name'),
      routeCategory.id
    );
    if (!isUnique) {
      form.setError('name', {
        message: intl.formatMessage({id: 'validation.error.unique'}),
      });
      const errorElementRefs = getErrorElementRefs();

      scrollToFirstError(form.formState.errors, errorElementRefs);
      return false;
    }
    return true;
  };

  const submit = useCallback(async (data: CategoryFormFields) => {
    if (!checkNameUniqueness()) return;
    const imageUrl = await getImageValue();
    if (!imageUrl) return;
    const categoryData: UpdateCategoryQueryParam = {
      id: routeCategory.id,
      body: {
        id: routeCategory.id,
        name: data.name,
        imageUrl,
        domainIds: data.domainIds,
        parentId: data.parentId,
        landingPageUrl: data.landingPageUrl || undefined,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.CATEGORY_UPDATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };

    await editCategory(categoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitIfValid = useCallback(async () => {
    const isValid = await form.trigger();

    if (isValid) {
      return submit(form.getValues());
    }

    const errorElementRefs = getErrorElementRefs();
    return scrollToFirstError(form.formState.errors, errorElementRefs);
  }, [getErrorElementRefs, form, submit]);

  const formOnClose = () => {
    form.reset();
  };

  const updatedFields = form.watch('updatedFields');

  const showOverlaySpinner = isLoadingCategoryImage;

  const disableCategoryEdit = !hasPermission(
    CategoryPermissions.editCategoryDetails
  );
  const disableDomainsEdit = !hasPermission(
    CategoryPermissions.updateDomainSetting
  );

  return (
    <div className="details-page category">
      <OverlaySpinner isLoading={showOverlaySpinner} />
      {category && (
        <>
          <CategoryHeader category={category} />
          <div className="container ms-lg-0">
            <div className="content-block">
              <CategoryForm
                form={form}
                category={category}
                disableCategoryEdit={disableCategoryEdit}
                disableDomainsEdit={disableDomainsEdit}
              />
            </div>
          </div>
        </>
      )}
      <Prompt
        when={!!updatedFields.size}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />
      <SaveFeature
        onSave={submitIfValid}
        num={updatedFields.size}
        onClose={formOnClose}
        open={!!updatedFields.size}
      />
    </div>
  );
};

export default EditCategory;
