import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {Mutex} from 'async-mutex';
import {EVENTS_SERVICE_BASE_URL} from '../../constants/urls';
import {RootState} from '../..';
import {chunk} from 'lodash';
import {updateUser} from '../../store/slices/authSlice';
import {IPermission} from './types';
import {Role} from '../../components/Settings/types';
import {authApi} from '../authApi';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: EVENTS_SERVICE_BASE_URL,
  prepareHeaders: (headers, {getState}) => {
    const state = getState() as {authReducer: {token?: string}};
    const token = state.authReducer.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      //get user roles from auth Reducer
      try {
        const user = (
          await api.dispatch(
            authApi.endpoints.getMe.initiate({
              headers: {
                authorization: (api.getState() as RootState).auth
                  .token as unknown as string,
              },
            })
          )
        ).data as any;
        if (user?.roles.length === 0) {
          return result;
        }
        const meRoleSlugs = chunk<string>(user.roles, 10);

        //get user permissions
        const results = await Promise.all(
          meRoleSlugs.map(slugs =>
            baseQuery(
              {
                url: 'roles?' + slugs.map(slug => `slugs[]=${slug}`).join('&'),
                headers: {
                  authorization: (api.getState() as RootState).auth
                    .token as unknown as string,
                },
              },
              api,
              extraOptions
            )
          )
        );
        if (results) {
          const meEventRolesPermissions = results
            .flatMap(result =>
              (result?.data as any[])?.flatMap((role: Role) => role.permissions)
            )
            .filter(Boolean) as IPermission[];

          api.dispatch(
            updateUser({
              user: {
                ...user,
                permissions: user.permissions.concat(
                  meEventRolesPermissions.map(permission => permission.slug)
                ),
              },
            })
          );
        }
        result = await baseQuery(args, api, extraOptions);
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default baseQueryWithReauth;
