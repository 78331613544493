import React, {useCallback, useMemo, useRef} from 'react';
import {useIntl} from 'react-intl';
import TopPaper from '../Common/TopPaper';
import Typography from '@material-ui/core/Typography';
import Button from '../Common/Button';
import AddIcon from '@material-ui/icons/Add';
import {EventList} from './Events';
import get from 'lodash.get';
import {useHistory, useRouteMatch} from 'react-router-dom';
import * as urls from '../../constants/urls';
import VenueList from './Venues/VenueList';
import CategoryList from './Categories/CategoryList';
import SeatmapList from './Seatmap/SeatmapList';
import {PerformerList} from './Performer/PerformerList';
import usePermission from '../../Auth/usePermission';
import PermissionContainer from '../Common/PermissionContainer';
import {
  CategoryPermissions,
  EventPermissions,
  PerformerPermissions,
  SeatmapPermissions,
  VenuePermissions,
} from '../../constants/permissions';
import {NavTabProps, NavTabs} from '../Common/NavTabs';

/* Events list */
const EventsManagement = () => {
  const intl = useIntl();

  const {hasPermission, hasAnyPermission} = usePermission();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (wrapperRef.current) {
      wrapperRef.current?.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
  }, []);

  //Router
  const history = useHistory();
  const match = useRouteMatch();
  const eventListPermission = hasPermission(EventPermissions.seeEventList);
  const venueListPermission = hasPermission(VenuePermissions.seeVenueList);
  const seatmapListPermission = hasPermission(
    SeatmapPermissions.seeSeatmapList
  );
  const performerListPermission = hasPermission(
    PerformerPermissions.seePerformerList
  );
  const categoryListPermission = hasAnyPermission([
    CategoryPermissions.seeCategoryList,
    CategoryPermissions.seeTixstockCategories,
  ]);

  const tabs = useMemo(
    () =>
      [
        eventListPermission && {
          label: intl.formatMessage({id: 'dashboard.events.list.tabs.event'}),
          value: 'event',
          href: urls.EVENTS_LIST_PATH,
          dataTestId: 'event-tab',
          isActive: (matchedPath?: string) => {
            return (
              matchedPath === 'event' || typeof matchedPath === 'undefined'
            );
          },
        },
        venueListPermission && {
          label: intl.formatMessage({id: 'dashboard.events.list.tabs.venue'}),
          value: 'venue',
          href: urls.EVENTS_VENUES_PATH,
          dataTestId: 'venue-tab',
          isActive: (matchedPath: string) => matchedPath === 'venue',
        },
        seatmapListPermission && {
          label: intl.formatMessage({
            id: 'dashboard.events.list.tabs.seatmap',
          }),
          value: 'seatmap',
          href: urls.EVENTS_SEATMAP_PATH,
          dataTestId: 'seatmap-tab',
          isActive: (matchedPath: string) => matchedPath === 'seatmap',
        },
        performerListPermission && {
          label: intl.formatMessage({
            id: 'dashboard.events.list.tabs.performer',
          }),
          value: 'performer',
          href: urls.EVENTS_PERFORMER_PATH,
          dataTestId: 'performer-tab',
          isActive: (matchedPath: string) => matchedPath === 'performer',
        },
        categoryListPermission && {
          label: intl.formatMessage({
            id: 'dashboard.events.list.tabs.categories',
          }),
          value: 'categories',
          href: urls.EVENTS_CATEGORY_PATH,
          dataTestId: 'categories-tab',
          isActive: (matchedPath: string) =>
            matchedPath === 'categories' ||
            matchedPath === 'categories/tixstock',
        },
      ].filter(Boolean) as Array<NavTabProps & {value: string}>,
    [
      categoryListPermission,
      eventListPermission,
      intl,
      performerListPermission,
      seatmapListPermission,
      venueListPermission,
    ]
  );

  //Add click action
  const onAddClick = () => {
    let route = '';
    const matchedTab = get(match, 'params[0]', tabs.at(0)?.value);
    switch (matchedTab) {
      case 'event':
        route = urls.EVENT_ADD_PATH;
        break;
      case 'venue':
        route = urls.VENUE_ADD_PATH;
        break;
      case 'performer':
        route = urls.PERFORMER_ADD_PATH;
        break;
      case 'categories':
        history.push(urls.EVENTS_CATEGORY_ADD_PATH);
        return;
      case 'seatmap':
        history.push(urls.SEATMAP_ADD_PATH);
        return;
      default:
        break;
    }

    if (route.length) {
      history.push(route.replace(':id', 'new'));
    }
  };

  const pathname = history.location.pathname;

  const activeTab = get(match, 'params[0]', tabs.at(0)?.value);

  return (
    <div ref={wrapperRef} className="dashboard-wrapper events-list p-0">
      <div className="container-fluid">
        <TopPaper>
          <div className="row header-row">
            <div className="col">
              <Typography
                variant="h3"
                className="font-weight-bolder text-center text-lg-start mb-lg-1"
              >
                {intl.formatMessage({
                  id: 'dashboard.events.list.tabs.' + (activeTab ?? 'event'),
                })}
              </Typography>
            </div>
            <div className="col-auto d-none d-lg-block">
              {activeTab === 'event' && (
                <PermissionContainer permissions={[EventPermissions.addEvent]}>
                  <Button
                    primary
                    icon={<AddIcon />}
                    title={intl.formatMessage({
                      id: 'dashboard.events.add_event',
                    })}
                    onClick={onAddClick}
                    data-testid="events-add-btn"
                  />
                </PermissionContainer>
              )}

              {activeTab === 'performer' && (
                <PermissionContainer
                  permissions={[PerformerPermissions.addPerformer]}
                >
                  <Button
                    primary
                    icon={<AddIcon />}
                    title={intl.formatMessage({
                      id: 'dashboard.performer.add_performer',
                    })}
                    onClick={onAddClick}
                    data-testid="performers-add-btn"
                  />
                </PermissionContainer>
              )}

              {activeTab === 'seatmap' && (
                <PermissionContainer
                  permissions={[SeatmapPermissions.addSeatmap]}
                >
                  <Button
                    primary
                    icon={<AddIcon />}
                    title={intl.formatMessage({
                      id: 'dashboard.seatmap.add_seat_map',
                    })}
                    onClick={onAddClick}
                    data-testid="seatmaps-add-btn"
                  />
                </PermissionContainer>
              )}

              {activeTab === 'categories' && !pathname.includes('tixstock') && (
                <PermissionContainer
                  permissions={[CategoryPermissions.addCategory]}
                >
                  <Button
                    primary
                    icon={<AddIcon />}
                    title={intl.formatMessage({
                      id: 'dashboard.events.add_category',
                    })}
                    onClick={onAddClick}
                    data-testid="categories-add-btn"
                  />
                </PermissionContainer>
              )}
              {activeTab === 'venue' && (
                <PermissionContainer permissions={[VenuePermissions.addVenue]}>
                  <Button
                    primary
                    icon={<AddIcon />}
                    title={intl.formatMessage({
                      id: 'dashboard.events.add_venue',
                    })}
                    onClick={onAddClick}
                    data-testid="venue-add-btn"
                  />
                </PermissionContainer>
              )}
            </div>
          </div>
          {tabs.length > 0 && (
            <div className="row">
              <div className="col">
                {/* Tabs */}
                <NavTabs
                  className="mb-0"
                  // value={activeTab}
                  tabs={tabs}
                />
              </div>
            </div>
          )}
        </TopPaper>
        {/*Tabs content*/}
        {activeTab === 'event' && (
          <EventList onAddClick={onAddClick} scrollToTop={scrollToTop} />
        )}
        {activeTab === 'venue' && (
          <VenueList onAddClick={onAddClick} scrollToTop={scrollToTop} />
        )}
        {activeTab === 'seatmap' && (
          <SeatmapList onAddClick={onAddClick} scrollToTop={scrollToTop} />
        )}
        {activeTab === 'performer' && (
          <PerformerList onAddClick={onAddClick} scrollToTop={scrollToTop} />
        )}
        {(activeTab === 'categories' ||
          activeTab === 'categories/tixstock') && <CategoryList />}
      </div>
    </div>
  );
};

export default EventsManagement;
