import Button from '../../../../Common/Button';
import {PlusIconDanger} from '../../../../../constants/images';
import {nanoid} from 'nanoid';
import {EmptySeatmapSection} from '../../constants';
import React from 'react';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {SeatmapFormFields} from '../../types';
import {useIntl} from 'react-intl';
import {useCanAddSection} from './hooks/useCanAddSection';

interface AddSectionButtonProps {
  disabled?: boolean;
  setValue: UseFormSetValue<SeatmapFormFields>;
  getValues: UseFormGetValues<SeatmapFormFields>;
  clearErrors: UseFormClearErrors<SeatmapFormFields>;
  watch: UseFormWatch<SeatmapFormFields>;
  onSectionAdd?: (id: string) => void;
}

export function AddSectionButton(props: AddSectionButtonProps) {
  const {disabled, setValue, watch, getValues, clearErrors, onSectionAdd} =
    props;

  const intl = useIntl();
  const canAddSection = useCanAddSection(watch);

  if (!canAddSection) return null;

  return (
    <div>
      <Button
        style={{fontWeight: '600'}}
        disabled={disabled}
        link
        small
        danger
        icon={<PlusIconDanger />}
        title={intl.formatMessage({
          id: 'dashboard.seatmap.details.new_section',
        })}
        onClick={() => {
          const newId = nanoid();
          setValue('sections', [
            ...getValues('sections'),
            {...EmptySeatmapSection, id: newId},
          ]);
          onSectionAdd?.(newId);
          // clearErrors('sections');
        }}
      />
    </div>
  );
}
