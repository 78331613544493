import {
  EventFormFields,
  EventRequestFields,
} from '../../components/EventsManagement/Events/types';
import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';
import {IAPIListOptions, IEvent, IPaginatedResponse} from './types';
import {getPaginationAPIParams} from './utils';

export interface DeleteEventQueryParam extends FeedbackParams {
  id: number;
}

export interface EventFilterValues {
  q?: string;
  title?: string;
  source?: string[];
  status?: string[];
  performerId?: number[];
  categoryId?: number[];
  venue?: string;
  isMissingSeatmapImage?: boolean;
  isPremium?: boolean;
  isDateConfirmed?: boolean;
  requiresUpdate?: boolean;
  dateFrom?: string;
  dateTo?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
}

export interface GetEventsQueryParam
  extends FeedbackParams,
    IAPIListOptions,
    EventFilterValues {}

export interface BulkUpdateEventsLiveStateMutationParams
  extends FeedbackParams {
  ids: number[];
  live: boolean;
}

export interface BulkUpdateEventsPremiumStateMutationParams
  extends FeedbackParams {
  ids: number[];
  isPremium: boolean;
  premiumDaysBeforeEvent: number;
}

export interface BulkUpdateEventsStateMutationResponse {
  message: string;
  updatedCount: number;
  ignoredEvents?: Partial<IEvent>[];
}

export type CreateEventQueryParam = {
  body: Omit<
    EventFormFields,
    | 'performers'
    | 'eventDate'
    | 'eventTime'
    | 'isConfirmed'
    | 'venue'
    | 'slug'
    | 'seatmap'
    | 'defaultPerformers'
    | 'isCustom'
  > &
    EventRequestFields;
} & FeedbackParams;

export interface GetEventsQueryParam extends FeedbackParams, IAPIListOptions {}

export type EditEventQueryParam = Partial<CreateEventQueryParam> & {
  id: number;
  slug: string;
} & FeedbackParams;

export const eventsApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    eventList: builder.query<IPaginatedResponse<IEvent>, GetEventsQueryParam>({
      query: (options = {}) => {
        const url = 'events';
        const paginationSearchParams = getPaginationAPIParams(options);
        const urlSearchParams = getEventFilterSearchParams(
          options,
          paginationSearchParams
        );

        if (urlSearchParams) {
          return `${url}?${urlSearchParams}`;
        }

        return url;
      },
    }),
    deleteEvent: builder.mutation<IEvent, DeleteEventQueryParam>({
      query: ({id}) => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
    }),
    bulkUpdateEventsLiveState: builder.mutation<
      BulkUpdateEventsStateMutationResponse,
      BulkUpdateEventsLiveStateMutationParams
    >({
      query: ({ids, live}) => ({
        url: 'events/bulk/live-events',
        method: 'PATCH',
        body: {ids, live},
      }),
    }),
    bulkUpdateEventsPremiumState: builder.mutation<
      BulkUpdateEventsStateMutationResponse,
      BulkUpdateEventsPremiumStateMutationParams
    >({
      query: ({ids, isPremium, premiumDaysBeforeEvent}) => ({
        url: 'events/bulk/premiums',
        method: 'PATCH',
        body: {ids, isPremium, premiumDaysBeforeEvent},
      }),
    }),
    createEvent: builder.mutation<IEvent, CreateEventQueryParam>({
      query: ({body}) => ({
        url: 'events',
        method: 'POST',
        body,
      }),
    }),
    getEvent: builder.query<IEvent, {slug: string} & FeedbackParams>({
      query: ({slug}) => `events/${slug}`,
    }),
    editEvent: builder.mutation<
      {data: IEvent; message: string},
      EditEventQueryParam
    >({
      query: ({body, id}) => ({
        url: `events/${id}`,
        method: 'PATCH',
        body,
      }),
      // async onQueryStarted(_args, {dispatch, queryFulfilled}) {
      //   try {
      //     const {data: updatedEvent} = await queryFulfilled;
      //     dispatch(
      //       eventsApi.util.updateQueryData(
      //         'getEvent',
      //         {slug: _args.slug, showProgressDialog: true},
      //         draft => {
      //           Object.assign(draft, updatedEvent.data);
      //         }
      //       )
      //     );
      //   } catch (error) {
      //     // Refetch the event to ensure consistency
      //   }
      // },
    }),
  }),
});

function getEventFilterSearchParams(
  values: EventFilterValues,
  initialSearchParams?: string
) {
  const searchParams = new URLSearchParams(initialSearchParams);
  const setParam = (
    key: string,
    value?: string | boolean | number | string[] | number[]
  ) => {
    if (typeof value === 'undefined' || (typeof value == 'string' && !value))
      return;
    if (Array.isArray(value)) {
      value.forEach(item => searchParams.append(key, item.toString()));
      return;
    }
    searchParams.set(key, value.toString());
  };

  setParam('q', values.q);
  setParam('title', values.title);
  setParam('source', values.source);
  setParam('status', values.status);
  setParam('performerId', values.performerId);
  setParam('categoryId', values.categoryId);
  setParam('venue', values.venue);
  setParam('isMissingSeatmapImage', values.isMissingSeatmapImage);
  setParam('isPremium', values.isPremium);
  setParam('isDateConfirmed', values.isDateConfirmed);
  setParam('requiresUpdate', values.requiresUpdate);
  setParam('dateFrom', values.dateFrom);
  setParam('dateTo', values.dateTo);
  setParam('creationDateFrom', values.creationDateFrom);
  setParam('creationDateTo', values.creationDateTo);

  return searchParams.toString();
}

export const {
  useDeleteEventMutation,
  useEventListQuery,
  useLazyEventListQuery,
  useBulkUpdateEventsLiveStateMutation,
  useBulkUpdateEventsPremiumStateMutation,
  useCreateEventMutation,
  useGetEventQuery,
  useEditEventMutation,
} = eventsApi;
