import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody} from 'reactstrap';
import SeatmapSelect from './SeatmapSelect';
import SectionsList from './SectionsList';
import {
  ISeatmap,
  IVenue,
  useGetSeatmapQuery,
  useGetVenueQuery,
} from '../../../../services/eventsApi';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import {FormHelperText} from '@material-ui/core';
import {FieldErrors} from 'react-hook-form';
import {EventFormFields} from '../types';
import {SeatmapSvgViewer} from '../../components/SeatmapSvgViewer';
import usePermission from '../../../../Auth/usePermission';
import {
  SeatmapPermissions,
  VenuePermissions,
} from '../../../../constants/permissions';
import {useEffect, useState} from 'react';

interface SeatmapDetailsProps {
  onSelectSeatmap: (seatmapId: number) => void;
  selectedVenue?: Partial<IVenue>;
  seatmapId?: number;
  errors?: FieldErrors<EventFormFields>;
  disabled?: boolean;
}

const SeatmapDetails = (props: SeatmapDetailsProps) => {
  const {selectedVenue, seatmapId, errors, onSelectSeatmap, disabled} = props;
  const intl = useIntl();
  const [selectedSeatmap, setSelectedSeatmap] = useState<ISeatmap>();

  const {hasPermission} = usePermission();
  const venueSeatmapListPermissionError = !hasPermission(
    VenuePermissions.seeVenueDetails
  );

  const seatmapPermissionError = !hasPermission(
    SeatmapPermissions.seeSeatmapDetails
  );

  const {data: fetchedSeatmap} = useGetSeatmapQuery(
    {id: Number(seatmapId), showProgressDialog: true},
    {skip: !seatmapId}
  );

  const {data: venue} = useGetVenueQuery(
    {
      id: Number(selectedVenue?.id),
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {skip: !Number(selectedVenue?.id)}
  );

  useEffect(() => {
    if (fetchedSeatmap) {
      setSelectedSeatmap(fetchedSeatmap);
    }
  }, [fetchedSeatmap]);

  useEffect(() => {
    if (!seatmapId) {
      setSelectedSeatmap(undefined);
    }
  }, [seatmapId]);

  return (
    <Card className="details-card">
      <CardBody>
        <Typography variant="h4" className="mb-0">
          <FormattedMessage id="dashboard.events.details.seatmap_details" />
        </Typography>
        {venue && (
          <SeatmapSelect
            disabled={disabled}
            label={intl.formatMessage({
              id: 'dashboard.events.details.seatmap',
            })}
            seatmaps={venue.seatmaps}
            venue={venue}
            onSelectSeatmap={onSelectSeatmap}
            selectedSeatmap={selectedSeatmap}
            isInvalid={!!errors?.seatmapId?.message}
          />
        )}
        <FormHelperText error>
          {seatmapPermissionError ? (
            <FormattedMessage id="dashboard.events.details.seatmap_permission_error" />
          ) : venueSeatmapListPermissionError ? (
            <FormattedMessage id="dashboard.events.details.venue_seatmap_list_permission_error" />
          ) : null}
        </FormHelperText>
        {selectedSeatmap?.imageUrl &&
          !selectedSeatmap.isSVGWithSeatmapFormat && (
            <div className="mb-2">
              <img
                className="img-fluid"
                src={selectedSeatmap.imageUrl}
                alt=""
              />
            </div>
          )}

        {selectedSeatmap?.imageUrl &&
          selectedSeatmap.isSVGWithSeatmapFormat && (
            <div className="mb-2">
              <SeatmapSvgViewer
                src={selectedSeatmap?.imageUrl}
                sections={selectedSeatmap.sections}
              />
            </div>
          )}
        {selectedSeatmap && <SectionsList seatmapId={selectedSeatmap.id} />}
        {venue?.seatmaps?.length === 0 && !errors?.seatmapId?.message && (
          <FormHelperText error>
            {intl.formatMessage({
              id: 'dashboard.events.details.info_errors.venue_has_no_seatmaps',
            })}
          </FormHelperText>
        )}
        <FormHelperText error>{errors?.seatmapId?.message} </FormHelperText>
      </CardBody>
    </Card>
  );
};

export default SeatmapDetails;
