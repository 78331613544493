import React, {cloneElement, useState} from 'react';
import {useIntl} from 'react-intl';
import cx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import {UpdateGrayIcon, UpdateIcon} from '../../../constants/images';
import UpdateSeatmapPopover from '../Seatmap/Form/UpdateSeatmapPopover';
import {ITheme} from '../../../constants/theme';

export type TixstockUpdaterProps = {
  isImage?: boolean;
  label: string;
  imgPath?: string;
  onChange?: (value: string) => void;
};

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette?.blue.light,
    borderRadius: theme.spacing(0.4),
    '& > p': {
      color: theme.palette?.blue.main,
    },

    '&.declined': {
      backgroundColor: theme.palette?.text.disabledBg,
      '& > p': {
        color: theme.palette?.text.disabled,
      },
    },
  },
  icon: {
    width: 11,
    height: 11,
    marginBottom: 2,
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
  paper: {
    borderRadius: theme.spacing(0.8),
  },
  menu: {
    padding: theme.spacing(0.5, 0),
    minWidth: 140,
    '& .MuiMenuItem-root': {
      padding: theme.spacing(0.5, 1),
    },
  },
}));

const TixstockUpdater = ({
  isImage,
  label,
  imgPath,
  onChange,
}: TixstockUpdaterProps) => {
  const intl = useIntl();
  const [declined, setDeclined] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();

  const options = [
    {
      label: intl.formatMessage({
        id: 'dashboard.events.tixstock.options.update',
      }),
      value: 'update',
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.tixstock.options.decline_update',
      }),
      value: 'decline',
    },
  ];

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleMenuClick = (v: string) => {
    if (onChange) {
      onChange(v);
    }
    setDeclined(v === 'decline');
    handlePopoverClose();
  };

  return (
    <>
      <Box
        className={cx(classes.root, 'tixstock-updater', {declined})}
        onClick={handlePopoverOpen}
      >
        {cloneElement(declined ? <UpdateGrayIcon /> : <UpdateIcon />, {
          className: classes.icon,
        })}
        <Typography variant="body2" className="font-bold-italic text-truncate">
          {label}
        </Typography>
      </Box>
      {isImage ? (
        <UpdateSeatmapPopover
          id="updatePopover"
          open={open}
          anchorEl={anchorEl}
          src={imgPath}
          handleClose={handlePopoverClose}
          onDecline={() => handleMenuClick('decline')}
          onUpdate={() => handleMenuClick('update')}
        />
      ) : (
        <Popover
          id="tixstock-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <MenuList className={classes.menu}>
            {options.map((item, index) =>
              item.value === 'decline' && declined ? null : (
                <MenuItem
                  key={index}
                  value={item.value}
                  onClick={() => handleMenuClick(item.value)}
                >
                  <Typography variant="subtitle2">{item.label}</Typography>
                </MenuItem>
              )
            )}
          </MenuList>
        </Popover>
      )}
    </>
  );
};

export default TixstockUpdater;
