import React, {Fragment} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import get from 'lodash.get';
import {
  HoldingTimeIcon,
  INSTANT_MARK_ICON,
  TicketStarIcon,
} from '../../../constants/images';
import ArrowLink from '../../Common/ArrowLink';
import PermissionContainer from '../../Common/PermissionContainer';
import {LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS} from '../../../constants/permissions';
import {LISTING_DETAILS_PATH} from '../../../constants/urls';
import clsx from 'clsx';
import SalesRow from './SalesRow';
import EventDate from '../../Common/EventDate';
import useTicketHelper from '../../../hooks/ticket/useTicketHelper';
import {getResourceId} from '../../../utils/functions';
import {Alert} from '@material-ui/lab';
import useDateHelper from '../../../hooks/utils/useDateHelper';

const SaleListingDetails = props => {
  const {saleDetails, custom} = props;
  const {
    sale,
    event,
    ticket,
    showTimeToConfirm,
    showConfirmedIn,
    showConfirmationMissed,
    showInstantConfirmation,
    confirmedIn,
    timeToConfirm,
  } = saleDetails;
  const ticketHelper = useTicketHelper();
  const intl = useIntl();
  const dateHelper = useDateHelper();

  return (
    <div className="card details-card sales-details mx-0">
      {/* display an alert if the ticket has been deleted */}
      {sale?.ticket?.deletedAt && (
        <Alert
          severity="error"
          style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
        >
          <FormattedMessage
            id="dashboard.sales.details.listing_deleted_at"
            values={{
              date: dateHelper.format(sale.ticket.deletedAt, 'DD/MM/YYYY'),
            }}
          />
        </Alert>
      )}
      <div className="card-header bg-light-green">
        <div className="row gx-2">
          <div className="col-auto">
            <span className="icon-wrapper green">
              <TicketStarIcon style={{fontSize: 19}} />
            </span>
          </div>
          <div className="col">
            <Typography variant="body2" component="b">
              {event?.name}
            </Typography>
            <Typography variant="body2">
              <span>{event?.venue?.entitled}</span>
              <Typography
                component="span"
                style={{color: '#D8D8D8'}}
                className="px-2"
              >
                |
              </Typography>
              <span>
                <EventDate event={event} dateFormat="DD/MM/YYYY HH:mm" />
              </span>
            </Typography>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className={clsx({'border-bottom': custom})}>
          <div className="row gx-2 align-items-center pb-2">
            <div className="col-lg-6">
              <div className="d-flex align-items-center justify-content-between">
                <Typography variant="body2">
                  <b>
                    {intl.formatMessage({
                      id: 'dashboard.sales.details.listing_details',
                    })}
                  </b>
                </Typography>
                {showTimeToConfirm && <HoldingTimeIcon />}
              </div>
            </div>
            {/*If the sales status is in confirmation pending then we display label "Time left to confirm" and <confirmation time>*/}
            {showTimeToConfirm && (
              <Fragment>
                <div className="col-auto col-lg-3">
                  <Typography variant="subtitle2" color="textSecondary">
                    {intl.formatMessage({
                      id: 'dashboard.sales.details.time_confirm',
                    })}
                  </Typography>
                </div>
                <div className="col-auto col-lg-3">
                  <Typography variant="subtitle2">
                    {timeToConfirm &&
                      intl.formatMessage(
                        {id: 'dashboard.sales.details.time_confirm_value'},
                        {
                          d: timeToConfirm.day,
                          h: timeToConfirm.hour,
                          m: timeToConfirm.min,
                        }
                      )}
                  </Typography>
                </div>
              </Fragment>
            )}
            {/*If the sales has been confirmed */}
            {showConfirmedIn && (
              <Fragment>
                <div className="col-auto col-lg-3">
                  <Typography variant="subtitle2" color="textSecondary">
                    {intl.formatMessage({
                      id: 'dashboard.sales.details.confirmed_in',
                    })}
                  </Typography>
                </div>
                <div className="col-auto col-lg-3">
                  <Typography variant="subtitle2">{confirmedIn}</Typography>
                </div>
              </Fragment>
            )}
            {showConfirmationMissed && (
              <div className="col-auto col-lg-3">
                <Typography variant="subtitle2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.sales.details.confirmation_missed',
                  })}
                </Typography>
              </div>
            )}
            {/*If the seller has instant confirmation ON*/}
            {showInstantConfirmation && (
              <div className="col-lg-5 text-lg-end">
                <div className="d-inline-flex align-items-center">
                  {INSTANT_MARK_ICON}
                  <Typography variant="body2" component="span" className="ps-2">
                    {intl.formatMessage({
                      id: 'dashboard.sales.details.instant_confirmation',
                    })}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row gx-2 row-cols-1 row-cols-lg-2 pt-2">
          <div className="col">
            <SalesRow
              label={intl.formatMessage({
                id: 'dashboard.sales.details.ticket_type',
              })}
              value={ticketHelper.formatTicketType(ticket)}
            />
            <SalesRow
              label={intl.formatMessage({
                id: 'dashboard.sales.details.section',
              })}
              value={get(sale.data, 'sectionName')}
            />
            {!!get(sale.data, 'rowInformation') && (
              <SalesRow
                label={intl.formatMessage({id: 'dashboard.sales.details.row'})}
                value={get(sale.data, 'rowInformation')}
              />
            )}
          </div>
          <div className="col">
            <SalesRow
              label={intl.formatMessage({
                id: 'dashboard.sales.details.tickets_sold',
              })}
              value={sale.units}
            />
            {get(sale.data, 'row') ? (
              <SalesRow
                label={intl.formatMessage({
                  id: 'dashboard.sales.details.block',
                })}
                value={get(sale.data, 'row')}
              />
            ) : (
              /* Block "Not Provided" */
              <SalesRow
                label={intl.formatMessage({
                  id: 'dashboard.sales.details.block',
                })}
                value={intl.formatMessage({
                  id: 'dashboard.sales.details.not_provided',
                })}
              />
            )}
          </div>
        </div>
        {get(sale.data, 'restrictionDetails') && (
          <div className="row gx-2 pt-2">
            <div className="col-6 col-lg-3">
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage({
                  id: 'dashboard.sales.details.restrictions',
                })}
              </Typography>
            </div>
            <div className="col-6 col-lg-8">
              <Typography variant="body2">
                {get(sale.data, 'restrictionDetails')}
              </Typography>
            </div>
          </div>
        )}
        <PermissionContainer
          permissions={LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS}
        >
          <div className="pt-3">
            <ArrowLink
              url={LISTING_DETAILS_PATH.replace(
                ':id',
                getResourceId(ticket) || 0
              )}
              title={intl.formatMessage({
                id: 'dashboard.sales.details.see_more_details',
              })}
              className="inline-link"
              primary
            />
          </div>
        </PermissionContainer>
      </div>
    </div>
  );
};

export default SaleListingDetails;
