import {useLocation} from 'react-router-dom';
import {fromStringToPrimitiveType} from './helpers';
import {useMemo} from 'react';

export function useModularFilterUrlValue(filterName: string) {
  const {search} = useLocation();
  return useMemo(() => {
    const filterKey = `filters_${filterName}`;
    const searchParams = new URLSearchParams(search);
    const values = Array.from(searchParams.entries()).filter(([key]) =>
      key.startsWith(filterKey)
    );

    if (!values.length) return null;
    // values.length === 1 means that the filter is not an object
    if (values.length === 1) {
      const filterValue = searchParams.get(filterKey);
      return filterValue ? fromStringToPrimitiveType(filterValue) : null;
    }

    // handling object filters example: filters_dateRange={from: '2021-01-01', to: '2021-01-31'}
    // in url would be filters_dateRange.from=2021-01-01&filters_dateRange.to=2021-01-31
    return values.reduce((acc, [key, value]) => {
      const keyName = key.replace(filterKey, '').replace('.', '');
      return {...acc, [keyName]: fromStringToPrimitiveType(value)};
    }, {});
  }, [filterName, search]);
}
