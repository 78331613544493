import {ReactNode} from 'react';
import cx from 'clsx';
import Box from '@material-ui/core/Box';
import {Col, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Switch from '../../../Common/Switch';
import {useDomainsListStyles} from '../styles';
import {useIntl} from 'react-intl';
import {LoadableContent} from '../../../Widgets/Spinner';
import categoriesStyle from '../categories.module.scss';
import {IDomain} from '../../../../services/eventsApi';

interface DomainsListProps {
  headerAction?: ReactNode;
  onChange: (domainIds: number[]) => void;
  existingCategoryDomainIds?: number[];
  disabled?: boolean;
  isLoading?: boolean;
  domains: IDomain[];
}

const DomainsList = (props: DomainsListProps) => {
  const {
    headerAction,
    onChange,
    existingCategoryDomainIds = [],
    disabled,
    isLoading,
    domains,
  } = props;
  const intl = useIntl();
  const classes = useDomainsListStyles();

  const onSwitchChange = (domainId: number) => {
    if (existingCategoryDomainIds.includes(domainId)) {
      onChange(existingCategoryDomainIds.filter(id => id !== domainId));
    } else {
      onChange([...existingCategoryDomainIds, domainId]);
    }
  };

  return (
    <div className={classes.root}>
      <Row
        className={cx('align-items-center gy-1 gx-2', {
          'mb-2': !headerAction,
        })}
      >
        <Col>
          <Typography variant="subtitle2" className="text-placeholder">
            {intl.formatMessage({
              id: 'dashboard.events.add.domain',
            })}
            *
          </Typography>
        </Col>
        {headerAction && <Col lg="auto">{headerAction}</Col>}
      </Row>
      <LoadableContent
        isLoading={!!isLoading}
        loaderClass={categoriesStyle.domainList}
      >
        <div className="d-flex flex-column gap-2">
          {domains?.map(domain => (
            <Box
              key={domain.value}
              className={cx(classes.item, {
                selected: existingCategoryDomainIds.includes(domain.id),
              })}
              data-testid={`${domain.value}_domain_wrapper`}
            >
              <Switch
                // @ts-ignore
                disabled={disabled}
                checked={existingCategoryDomainIds.includes(domain.id)}
                onChange={() => onSwitchChange(domain.id)}
              />
              <Typography variant="body2">{`${domain.name} (${domain.value})`}</Typography>
            </Box>
          ))}
        </div>
      </LoadableContent>
    </div>
  );
};

export default DomainsList;
