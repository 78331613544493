import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {categorySlice} from './category.slice';

export function useAddCategoryErrorElementRef() {
  const dispatch = useDispatch();
  return useCallback(
    (name, elementId) => {
      dispatch(categorySlice.actions.addErrorElementRef({name, elementId}));
    },
    [dispatch]
  );
}
