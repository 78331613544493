import {makeStyles} from '@material-ui/core/styles';

export const useDomainsListStyles = makeStyles(theme => ({
  root: {
    '& > h6': {
      color: theme.palette.secondary.light,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(0.7, 1),
    borderRadius: theme.spacing(0.4),
    backgroundColor: '#D8D8D81A',
    '& > p': {
      color: theme.palette.text.secondary,
    },
    '&.selected': {
      backgroundColor: theme.palette.success.light,
      '& > p': {
        color: theme.palette.success.main,
      },
    },
  },
}));
