import React, {cloneElement, MouseEvent, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../Common/IconButton';
import {Property} from 'csstype';
import {FormattedMessage} from 'react-intl';
import {Popover} from '@mui/material';
import {ITheme} from '../../../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    marginRight: 2,
    display: 'inline-flex',
  },
  button: {
    borderRadius: 4,
    display: 'inline-flex',
    backgroundColor: 'transparent',
    width: 'auto',
    height: 'auto',
    minWidth: 'unset',
    margin: 0,
    padding: 0,
    transition: 'none',
    boxShadow: 'none',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    position: 'relative',
    display: 'inline-flex',
    marginTop: 13,
    backgroundColor: `${theme.palette.text.primary} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    padding: theme.spacing(1),
    boxShadow: theme.shadows.at(4),
    overflow: 'visible',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: -8,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: 'transparent transparent #282B3E transparent',
    },
  },
}));

interface EventMarkProps {
  icon: React.ReactElement;
  iconSize?: Property.Width;
  formattedMessageId: string;
}

export const EventMark = (props: EventMarkProps) => {
  const {icon, iconSize, formattedMessageId} = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );

  const handlePopoverOpen = (event: MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget);
  };

  //Popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <IconButton
        variant="filled"
        size="small"
        onHover={handlePopoverOpen}
        onLeave={handlePopoverClose}
        className={classes.button}
      >
        {cloneElement(icon, {style: {width: iconSize, height: iconSize}})}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{paper: {className: classes.paper}}}
      >
        <div className="d-flex align-items-center">
          <div className="d-inline-flex">
            {cloneElement(icon, {style: {width: 18, height: 18}})}
          </div>
          <Typography variant="body2" className="ps-1">
            <FormattedMessage id={formattedMessageId} />
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default EventMark;
