import {Col, Row} from 'reactstrap';
import Autocomplete from '../../../Common/Autocomplete';
import {
  IEvent,
  IVenue,
  useLazyVenueListQuery,
} from '../../../../services/eventsApi';
import React, {useEffect, useState} from 'react';
import {FieldErrors, useFormContext} from 'react-hook-form';
import {FormHelperText} from '@material-ui/core';
import {EventFormFields} from '../types';
import {OverlaySpinner} from '../../../Widgets/Spinner';
import {FormattedMessage, useIntl} from 'react-intl';
import usePermission from '../../../../Auth/usePermission';
import {VenuePermissions} from '../../../../constants/permissions';
import {useAddEventsErrorElementRef} from '../../../../store/slices/eventsManagement';

interface VenueBlockProps {
  event?: IEvent;
  isNew?: boolean;
  errors: FieldErrors<EventFormFields>;
  setSelectedVenue: (venue: IVenue) => void;
  selectedVenue?: IVenue | null;
  disabled?: boolean;
}

const VenueBlock = ({
  event,
  isNew,
  setSelectedVenue,
  errors,
  selectedVenue,
  disabled,
}: VenueBlockProps) => {
  const [getVenues, {data: venues, isLoading, isFetching}] =
    useLazyVenueListQuery();
  const [venueSearch, setVenueSearch] = useState<string | null>(null);
  const intl = useIntl();
  const {clearErrors, trigger} = useFormContext();
  const {hasPermission} = usePermission();
  const venueListPermissionError = !hasPermission(
    VenuePermissions.seeVenueList
  );
  const addErrorElementRef = useAddEventsErrorElementRef();

  useEffect(() => {
    if (!isNew) return;
    const timeout = setTimeout(() => {
      let addressToRemove = '';
      if (selectedVenue) {
        addressToRemove = selectedVenue.city + ', ' + selectedVenue.country;
      }
      const searchName = venueSearch?.replace(addressToRemove, '').trim();
      getVenues({name: searchName ?? ''});
    }, 200);

    return () => clearTimeout(timeout);
  }, [venueSearch, getVenues, selectedVenue, isNew]);

  return (
    <div className="form-group mb-2 mb-lg-fg mb-0">
      <OverlaySpinner isLoading={isLoading} />
      <span className="small-label pb-2">
        {`${intl.formatMessage({
          id: 'dashboard.events.venue_details.venue_name',
        })}*`}
      </span>
      <Autocomplete
        disabled={disabled}
        data-testid="venue-picker-input"
        options={venues ?? []}
        value={selectedVenue ?? event?.venue}
        getOptionLabel={option =>
          option.name ? `${option.name}, ${option.city}, ${option.country}` : ''
        }
        getOptionSelected={(option, value) => option.id === value.id}
        loading={isLoading || isFetching}
        onBlur={() => {
          void trigger('venue');
        }}
        onInputChange={(event, value) => {
          setVenueSearch(value);
        }}
        onChange={(event, value) => {
          setSelectedVenue(value as IVenue);
          clearErrors('venue');
        }}
        renderOption={option => {
          return (
            <Col className="form-group mb-0 mt-0 row-label">
              <Row className="row-label">{option.name}</Row>
              <Row className="small-label row-label">
                {option.city}, {option.country}
              </Row>
            </Col>
          );
        }}
        isInvalid={!!errors.venue?.message || !!errors.venue?.id?.message}
      />
      {venueListPermissionError && isNew && (
        <FormHelperText error>
          <FormattedMessage id="dashboard.events.details.venue_list_permission_error" />
        </FormHelperText>
      )}
      <FormHelperText
        error
        ref={ref => {
          addErrorElementRef('venue', ref);
        }}
      >
        {errors.venue?.message ?? errors.venue?.id?.message}
      </FormHelperText>
    </div>
  );
};

export default React.memo(VenueBlock);
