import cx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as urls from '../../../constants/urls';
import ArrowLink from '../../Common/ArrowLink';
import {ISeatmap} from '../../../services/eventsApi';

// Updated styles
const useStyles = makeStyles((theme: Theme) => ({
  item: {
    cursor: 'pointer',
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    backgroundColor: '#9AA5B11A',
    borderRadius: theme.spacing(0.8),
  },
  img: {
    width: '50px',
    height: '50px',
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(0.5),
    flexShrink: 0, // Prevent image from shrinking
  },
  title: {
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  },
}));

interface SeatmapItemProps {
  item: ISeatmap;
  onClick: (id: number) => void;
}

const SeatmapItem = ({item, onClick}: SeatmapItemProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.item} onClick={() => onClick(item.id)}>
      <img
        className={classes.img}
        src={item.imageUrl ?? `${urls.IMG_BASE_URL}event-placeholder.jpg`}
        alt="Seatmap"
      />
      <ArrowLink
        title={item.name}
        url={urls.SEATMAP_DETAILS_PATH.replace(':id', item.id.toString())}
        targetBlank
        titleClassName={classes.title}
      />
    </Box>
  );
};

type SeatmapListProps = {
  className?: string;
  data: ISeatmap[];
};

const SeatmapList = ({className, data}: SeatmapListProps) => {
  const onItemClick = (id: number) => {
    const href = urls.SEATMAP_DETAILS_PATH.replace(':id', id.toString());
    window.open(href, '_blank');
  };

  return (
    <div className={cx(className)}>
      <Typography variant="subtitle2" className="text-placeholder mb-2">
        <FormattedMessage id="dashboard.events.venue_details.linked_seat_maps" />
      </Typography>
      <Box display="flex" flexDirection="column" gridGap={8}>
        {data.map(item => (
          <SeatmapItem key={item.id} item={item} onClick={onItemClick} />
        ))}
      </Box>
    </div>
  );
};

export default SeatmapList;
