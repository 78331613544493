import {makeStyles} from '@material-ui/styles';

export const useMediaLibraryStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  modalContent: {
    minHeight: '84vh',
  },
  title: {
    fontSize: 16,
  },
  imageItem: {
    '& .MuiImageListItem-item': {
      borderRadius: theme.spacing(0.4),
    },
  },
  titleBar: {
    cursor: 'pointer',
    height: 121,
    alignItems: 'flex-start',
    padding: theme.spacing(0.7),
    borderRadius: theme.spacing(0.4),
    backgroundColor: 'rgba(50,50,50, 0.4)',
    '&:not(:hover)': {
      background: 'none',
    },
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '& .MuiImageListItemBar-actionIcon': {
      display: 'inline-flex',
    },
  },
}));
