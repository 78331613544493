import React, {ChangeEvent, useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabsProps} from '@material-ui/core/Tabs/Tabs';
import {useIsWithUp} from '../../hooks/UI';
import {NavLink, useHistory, useRouteMatch} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: 23,
      marginLeft: -20,
      marginRight: 0,
      '& .MuiTabs-scroller': {
        overflowX: 'auto !important',
      },
    },
  },
  indicator: {
    height: 3,
  },
  tab: {
    textAlign: 'left',
    color: theme.palette.secondary.light,
    padding: '11px 0 13px 0',
    minWidth: 'auto',
    marginRight: 40,
    '&.Mui-selected': {
      outline: 'none',
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
    '&.default': {
      minWidth: 'auto',
      padding: '7px 0px 0 0px',
      marginRight: 20,
      [theme.breakpoints.down('lg')]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    '&.Mui-selected .MuiTab-wrapper': {
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold !important',
      alignItems: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0 16px',
      padding: '15px 0 9px 0',
      '&.Mui-selected .MuiTab-wrapper': {
        fontWeight: 'normal',
      },
      '&.default': {
        minWidth: 92,
      },
    },
  },
  wrapper: {
    fontWeight: 'normal',
  },
  labelIcon: {
    minHeight: 'unset',
    '& .MuiTab-wrapper': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .MuiChip-root': {
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
      marginBottom: '0 !important',
    },
  },
}));

export interface NavTabProps {
  label: string;
  href: string;
  dataTestId?: string;
  isActive: (path: string) => boolean;
}

type CustomTabsProps = Omit<TabsProps, 'className' | 'children'> & {
  className?: string;
  tabs: NavTabProps[];
};

export function NavTabs(props: CustomTabsProps) {
  const {className, tabs, ...others} = props;
  //current selected tab
  const [currentValue, setCurrentValue] = useState<number>();
  const isWidthUpLg = useIsWithUp('lg');
  const match = useRouteMatch();
  const history = useHistory();

  const classes = useStyles();

  //Tab change handler
  const handleChange = (e: ChangeEvent<{}>, newValue: number) => {
    setCurrentValue(newValue);
  };

  useEffect(() => {
    const activeTabIndex = tabs.findIndex(tab => tab.isActive(match.params[0]));
    if (currentValue === activeTabIndex) {
      return;
    }
    setCurrentValue(activeTabIndex > -1 ? activeTabIndex : 0);
    activeTabIndex === -1 && history.push(tabs[0].href);
  }, [match, tabs, currentValue, history]);

  if (typeof currentValue === 'undefined') return null;

  return (
    <Tabs
      className={className}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      variant={isWidthUpLg ? 'standard' : 'fullWidth'}
      value={currentValue}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}
      {...others}
    >
      {/* Render tab element */}
      {tabs.map(({label, dataTestId, href, isActive}, key) => (
        <Tab
          component={NavLink}
          to={href}
          classes={{
            root: clsx(classes.tab, 'default'),
            wrapper: classes.wrapper,
            labelIcon: classes.labelIcon,
          }}
          key={`${key}-${label}`}
          label={label}
          onClick={e => {
            e.preventDefault();
            if (isActive(match.params[0])) {
              return;
            }
            history.push(href);
          }}
          {...(dataTestId && {'data-testid': dataTestId})}
        />
      ))}
    </Tabs>
  );
}
