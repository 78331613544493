import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import cx from 'clsx';
import {Nav, NavItem, NavLink} from 'reactstrap';
import CollapsableBlock from '../../Common/CollapsableBlock';
import {IconButton, Typography} from '@material-ui/core';
import {
  EDIT_ICON_DEFAULT,
  EventManualIcon,
  ImportedEventIcon,
  PlusRoundedDangerIcon,
} from '../../../constants/images';
import {
  EVENTS_CATEGORY_ADD_PATH,
  EVENTS_CATEGORY_EDIT_PATH,
  EVENTS_CATEGORY_PATH,
  EVENTS_TIXSTOCK_CATEGORY_PATH,
  IMG_BASE_URL,
} from '../../../constants/urls';
import CollapsableCard from '../../Common/CollapsableCard';
import {ICategory, useGetCategoriesQuery} from '../../../services/eventsApi';
import Button from '../../Common/Button';
import {useHistory} from 'react-router-dom';
import {EventMark} from '../components';
import TixstockCategoriesList from '../TixstockCategories/TixstockCategoriesList';
import PermissionContainer from '../../Common/PermissionContainer';
import {CategoryPermissions} from '../../../constants/permissions';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import usePermission from '../../../Auth/usePermission';

const CategoryList = () => {
  const intl = useIntl();
  const history = useHistory();

  const {hasPermission} = usePermission();
  const {data: categories = []} = useGetCategoriesQuery(
    {
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !hasPermission(CategoryPermissions.seeCategoryList),
    }
  );

  const onEditCategory = (category: ICategory) => {
    history.push(
      EVENTS_CATEGORY_EDIT_PATH.replace(':id', category.id.toString()),
      {
        category,
      }
    );
  };

  //active tab
  const [activeTab, setActiveTab] = useState(
    hasPermission(CategoryPermissions.seeCategoryList) ? 'database' : 'tixstock'
  );

  //switch active tab
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === 'tixstock') {
      history.push(EVENTS_TIXSTOCK_CATEGORY_PATH);
    } else {
      history.push(EVENTS_CATEGORY_PATH);
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes('tixstock')) {
      setActiveTab('tixstock');
    }
  }, []);

  const canSeeDatabaseList = hasPermission(CategoryPermissions.seeCategoryList);
  const canViewCategory = hasPermission(CategoryPermissions.seeCategoryDetails);
  const canEdit =
    hasPermission(CategoryPermissions.editCategoryDetails) ||
    hasPermission(CategoryPermissions.updateDomainSetting);
  const canSeeTixstockList = hasPermission(
    CategoryPermissions.seeTixstockCategories
  );

  const canSeeOnlyOneList =
    (canSeeDatabaseList && !canSeeTixstockList) ||
    (canSeeTixstockList && !canSeeDatabaseList);

  return (
    <div className="dashboard-content event-list-categories">
      <div className="d-flex align-items-center pb-4 mb-2">
        {!canSeeOnlyOneList && (
          <Nav pills fill className="d-flex mb-0">
            <PermissionContainer
              // @ts-ignore
              permissions={[CategoryPermissions.seeCategoryList]}
            >
              <NavItem>
                <NavLink
                  active={activeTab === 'database'}
                  onClick={() => toggle('database')}
                >
                  <FormattedMessage id="dashboard.events.list.switcher.database" />
                </NavLink>
              </NavItem>
            </PermissionContainer>
            <PermissionContainer
              // @ts-ignore
              permissions={[CategoryPermissions.seeTixstockCategories]}
            >
              <NavItem>
                <NavLink
                  data-testid={'event-list-categories-tixstock'}
                  active={activeTab === 'tixstock'}
                  onClick={() => toggle('tixstock')}
                >
                  <FormattedMessage id="dashboard.events.list.switcher.tixstock" />
                </NavLink>
              </NavItem>
            </PermissionContainer>
          </Nav>
        )}
        <PermissionContainer permissions={[CategoryPermissions.addCategory]}>
          <div className="d-lg-none">
            <IconButton
              variant="filled"
              // @ts-ignore
              size="sm"
              className="py-0 pe-0"
              onClick={() => history.push(EVENTS_CATEGORY_ADD_PATH)}
            >
              <PlusRoundedDangerIcon />
            </IconButton>
          </div>
        </PermissionContainer>
      </div>
      <PermissionContainer permissions={[CategoryPermissions.seeCategoryList]}>
        {activeTab === 'database' && (
          <div className="categories-list">
            {categories.map(category => (
              <CollapsableCard
                key={category.id}
                title={`${category.name} (${category.children.length})`}
              >
                <CategoryItems
                  level0
                  items={category.children}
                  onEditClick={onEditCategory}
                  canEdit={canEdit}
                  canViewCategory={canViewCategory}
                />
              </CollapsableCard>
            ))}
          </div>
        )}
      </PermissionContainer>
      <PermissionContainer
        permissions={[CategoryPermissions.seeTixstockCategories]}
      >
        {activeTab === 'tixstock' && <TixstockCategoriesList />}
      </PermissionContainer>
    </div>
  );
};

function CategoryItems({
  items,
  onEditClick,
  level0 = false,
  canEdit,
  canViewCategory,
}: Readonly<{
  items?: ICategory[];
  onEditClick: (category: ICategory) => void;
  level0?: boolean;
  canEdit: boolean;
  canViewCategory: boolean;
}>) {
  if (!items) return null;

  return (
    <>
      {items.map((item, index) =>
        item.children.length ? (
          <CollapsableBlock
            key={item.id}
            header={(expanded: boolean) => (
              <CategoryHeader
                item={item}
                expanded={expanded}
                onEditClick={() => onEditClick(item)}
                canEdit={canEdit}
                canViewCategory={canViewCategory}
              />
            )}
            className={cx('event-category', {
              'border-0': index === 0,
              'mx-lg-4': level0,
            })}
            headerWrapperClass="header gx-0"
          >
            {!!item.children.length && (
              <div className={cx('inner px-lg-4')}>
                <CategoryItems
                  items={item.children}
                  onEditClick={onEditClick}
                  canEdit={canEdit}
                  canViewCategory={canViewCategory}
                />
              </div>
            )}
          </CollapsableBlock>
        ) : (
          <div
            key={item.id}
            className={cx('event-category', {
              'border-0': index === 0,
              'mx-lg-4': level0,
            })}
          >
            <CategoryHeader
              expanded
              item={item}
              canEdit={canEdit}
              canViewCategory={canViewCategory}
              onEditClick={() => {
                onEditClick(item);
              }}
            />
          </div>
        )
      )}
    </>
  );
}

function CategoryHeader({
  item,
  expanded,
  onEditClick,
  canEdit,
  canViewCategory,
}: Readonly<{
  item: ICategory;
  expanded?: boolean;
  canEdit: boolean;
  canViewCategory: boolean;
  onEditClick: () => void;
}>) {
  const {name, imageUrl, providers, children} = item;
  const intl = useIntl();
  const [isHovering, setIsHovering] = useState(false);

  const titleCountPart = children?.length > 0 ? ` (${children.length})` : '';
  const title = name + titleCountPart;
  const isImported = !!providers.length;

  return (
    <div
      className={cx('category-header')}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      data-testid={title}
    >
      <div className="me-1">
        <img
          src={imageUrl ?? `${IMG_BASE_URL}event-placeholder.jpg`}
          alt={name}
          style={{width: 35, height: 35}}
          className="img-fluid rounded-sm"
        />
      </div>
      <EventMark
        icon={
          isImported ? (
            <ImportedEventIcon style={{width: 11, height: 11}} />
          ) : (
            <EventManualIcon style={{width: 11, height: 11}} />
          )
        }
        iconSize="11"
        formattedMessageId={
          isImported
            ? 'dashboard.events.list.import'
            : 'dashboard.events.list.manual_input'
        }
      />
      <Typography variant="body2">{title}</Typography>
      {/* @ts-ignore */}
      {(expanded || isHovering) && (
        <div className="ms-auto me-3">
          {canEdit && (
            <Button
              link
              primary
              small
              title={intl.formatMessage({id: 'actions.edit'})}
              startIcon={EDIT_ICON_DEFAULT}
              onClick={onEditClick}
              onMouseLeave={() => setIsHovering(true)}
            />
          )}

          {!canEdit && canViewCategory && (
            <Button
              link
              primary
              small
              title={intl.formatMessage({id: 'actions.view'})}
              onClick={onEditClick}
              onMouseLeave={() => setIsHovering(true)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CategoryList;
