import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './spinner.module.scss';
import clsx from 'clsx';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';

interface SpinnerProps {
  loading: boolean;
  loaderClass?: string;
}

export function Spinner(props: Readonly<SpinnerProps>) {
  const {loading, loaderClass} = props;

  if (!loading) return null;

  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={animationVariants}
        transition={{ease: 'easeOut', duration: 3}}
      >
        <Paper
          className={clsx(classes.loadingWrapper, loaderClass)}
          elevation={0}
        >
          <CircularProgress disableShrink />
        </Paper>
      </motion.div>
    </AnimatePresence>
  );
}

const animationVariants = {
  visible: {opacity: 1},
  exit: {opacity: 0},
  hidden: {opacity: 0},
};
