import {SeatmapFormFields} from '../../components/EventsManagement/Seatmap/types';
import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';
import {
  IAPIListOptions,
  IPaginatedResponse,
  ISeatmap,
  ISeatmapParsedResponse,
} from './types';
import {getPaginationAPIParams} from './utils';

export interface SeatmapListQueryParams
  extends IAPIListOptions,
    FeedbackParams {}

export interface DeleteSeatmapQueryParam extends FeedbackParams {
  id: number;
}

export type CreateSeatmapQueryParam = {
  body: Omit<SeatmapFormFields, 'venue' | 'seatmapFile'> & {venueId: number};
} & FeedbackParams;

export type EditSeatmapQueryParam = Partial<CreateSeatmapQueryParam> & {
  id: number;
} & FeedbackParams;

export type ParseSeatmapQueryParam = {
  body: FormData;
} & FeedbackParams;

export const seatmapApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    getSeatmaps: builder.query<
      IPaginatedResponse<ISeatmap>,
      SeatmapListQueryParams
    >({
      query: (options: IAPIListOptions = {}) => {
        const url = 'seatmaps';
        const urlSearchParams = getPaginationAPIParams(options);

        if (urlSearchParams) {
          return `${url}?${urlSearchParams}`;
        }

        return url;
      },
    }),
    getSeatmap: builder.query<ISeatmap, {id: number} & FeedbackParams>({
      query: ({id}) => `seatmaps/${id}`,
    }),
    deleteSeatmap: builder.mutation<ISeatmap, DeleteSeatmapQueryParam>({
      query: ({id}) => ({
        url: `seatmaps/${id}`,
        method: 'DELETE',
      }),
    }),
    editSeatmap: builder.mutation<
      {data: ISeatmap; message: string},
      EditSeatmapQueryParam
    >({
      query: ({body, id}) => ({
        url: `seatmaps/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    createSeatmap: builder.mutation<ISeatmap, CreateSeatmapQueryParam>({
      query: ({body}) => ({
        url: 'seatmaps',
        method: 'POST',
        body,
      }),
    }),
    parseSeatmapSvg: builder.mutation<
      ISeatmapParsedResponse,
      ParseSeatmapQueryParam
    >({
      query: ({body}) => ({
        url: 'seatmaps/parse',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetSeatmapsQuery,
  useGetSeatmapQuery,
  useEditSeatmapMutation,
  useCreateSeatmapMutation,
  useParseSeatmapSvgMutation,
  useDeleteSeatmapMutation,
} = seatmapApi;
