import {makeStyles} from '@material-ui/core/styles';
import {ITheme} from '../../../../constants/theme';

export const useMultiLevelCategoryStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& h6': {
      paddingBottom: theme.spacing(1),
      color: theme.palette.secondary.light,
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.4, 1),
    border: `1px solid ${(theme.palette.text as any).border}`,
    borderRadius: theme.spacing(0.4),
    minHeight: theme.spacing(3.5),
    '&.disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    '&.invalid': {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
    },
  },
  categories: {
    cursor: 'default',
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
    '&.disabled': {
      color: theme.palette.text.disabled,
    },
  },
  categorySeparator: {
    margin: theme.spacing(0, 0.5),
  },
  categoryLink: {
    cursor: 'pointer',
    color: theme.palette.text.gray,
    fontSize: 14,
    lineHeight: 1.28,
    '&.disabled': {
      color: theme.palette.text.disabled,
    },
  },
  textPlaceholder: {
    color: theme.palette.secondary.light,
  },
  popper: {
    backgroundColor: (theme.palette as any).gray.light,
    padding: theme.spacing(1, 2, 3, 1),
    borderRadius: theme.spacing(0.4),
    zIndex: 3000,
    [theme.breakpoints.up('lg')]: {
      minWidth: '686px !important',
    },
  },
  paper: {
    margin: theme.spacing(1, 0, 0, 0),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.4),
    height: '100%',
  },
  list: {
    display: 'block',
    maxHeight: '50vh',
    overflowY: 'auto',
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  searchInput: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.4, 0.4, 0, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
    '& input': {
      padding: theme.spacing(0.5, 0),
      fontSize: 14,
    },
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

export const useParentCategoriesStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  item: {
    padding: theme.spacing(0),
    transition: 'unset',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItemText-root': {
      margin: 0,
      '& span': {
        fontSize: 14,
      },
      '&.selected': {
        '& span': {
          fontFamily: 'Averta Semibold',
        },
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
  nested: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    opacity: 1,
    margin: theme.spacing(0.5, -0.7, 0.5, 1),
  },
}));
