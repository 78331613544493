import cx from 'clsx';
import {withStyles} from '@material-ui/styles';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, {TreeItemProps} from '@material-ui/lab/TreeItem';
import Checkbox from '../../Common/Checkbox';
import {ITixstockCategoryWithChildren} from '../../../services/eventsApi';
import {ChangeEvent} from 'react';

type SubcategoriesTreeProps = {
  subSubcategory: ITixstockCategoryWithChildren[];
  isSelected: (id: string) => boolean;
  isIndeterminateSelection: (id: string) => boolean;
  updateSelection: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  disableSync?: boolean;
};

type SubcategoryItemsProps = {
  subSubcategory?: ITixstockCategoryWithChildren;
  subSubcategoryChildren?: ITixstockCategoryWithChildren[];
  isSelected: (id: string) => boolean;
  isIndeterminateSelection: (id: string) => boolean;
  updateSelection: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  disableSync?: boolean;
};

type printTitleElementsProps = {
  item: ITixstockCategoryWithChildren;
  checked: boolean;
  indeterminate: boolean;
  onClick: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
};

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 400,
    },
  })
);

const StyledTreeItem = withStyles(
  (theme: Theme) =>
    createStyles({
      content: {
        paddingBottom: theme.spacing(0.8),
        cursor: 'initial',
      },
      iconContainer: {
        width: 0,
      },
      selected: {
        '& .MuiTreeItem-content': {
          '& .MuiTreeItem-label': {
            backgroundColor: 'transparent !important',
          },
        },
      },
      label: {
        '&:hover, &:focus': {
          backgroundColor: 'transparent',
        },
      },
    })
  //@ts-ignore
)((props: TreeItemProps) => <TreeItem {...props} />);

const getExpandedIds = (
  subCategories: ITixstockCategoryWithChildren[]
): string[] => {
  return subCategories.reduce(
    (ids: string[], category: ITixstockCategoryWithChildren) => {
      if (category.children.length > 0) {
        return ids.concat(
          [category.id, ...category.children.map(c => c.id)],
          getExpandedIds(category.children)
        );
      }
      return ids.concat(category.id);
    },
    []
  );
};

const SubcategoryItems = ({
  subSubcategory,
  subSubcategoryChildren,
  isSelected,
  isIndeterminateSelection,
  updateSelection,
  disableSync,
}: SubcategoryItemsProps) => {
  const printTitleElements = ({
    item,
    checked,
    indeterminate,
    onClick,
  }: printTitleElementsProps) => (
    <Box display="flex" alignItems="center" gridGap={10}>
      <Box m={-1}>
        <Checkbox
          data-testid={`checkbox-${item.name}`}
          name={item.name}
          checked={checked}
          indeterminate={indeterminate}
          onClick={(event: any) => onClick(event, item.id)}
          disabled={disableSync}
        />
      </Box>
      <Typography
        data-testid={item.name}
        className={cx({'text-placeholder': !checked && !indeterminate})}
      >
        {item.name}
      </Typography>
    </Box>
  );
  return (
    <>
      {subSubcategory && (
        <StyledTreeItem
          key={subSubcategory.id}
          nodeId={subSubcategory.id}
          label={printTitleElements({
            item: subSubcategory,
            checked: isSelected(subSubcategory.id),
            indeterminate:
              isIndeterminateSelection(subSubcategory.id) &&
              !isSelected(subSubcategory.id),
            onClick: updateSelection,
          })}
        >
          {subSubcategory?.children.map(
            (item: ITixstockCategoryWithChildren) => (
              <StyledTreeItem
                key={item.id}
                nodeId={item.id}
                label={printTitleElements({
                  item: item,
                  checked: isSelected(item.id),
                  indeterminate:
                    isIndeterminateSelection(item.id) && !isSelected(item.id),
                  onClick: updateSelection,
                })}
              >
                {item.children?.length > 0 && (
                  <SubcategoryItems
                    subSubcategoryChildren={item.children}
                    isSelected={isSelected}
                    isIndeterminateSelection={isIndeterminateSelection}
                    updateSelection={updateSelection}
                  />
                )}
              </StyledTreeItem>
            )
          )}
        </StyledTreeItem>
      )}
      {subSubcategoryChildren?.map((item: ITixstockCategoryWithChildren) => (
        <StyledTreeItem
          key={item.id}
          nodeId={item.id}
          label={printTitleElements({
            item: item,
            checked: isSelected(item.id),
            indeterminate:
              isIndeterminateSelection(item.id) && !isSelected(item.id),
            onClick: updateSelection,
          })}
        >
          {item.children?.length > 0 && (
            <SubcategoryItems
              subSubcategoryChildren={item.children}
              isSelected={isSelected}
              isIndeterminateSelection={isIndeterminateSelection}
              updateSelection={updateSelection}
            />
          )}
        </StyledTreeItem>
      ))}
    </>
  );
};

const SubcategoriesTree = ({
  subSubcategory,
  isSelected,
  isIndeterminateSelection,
  updateSelection,
  disableSync,
}: SubcategoriesTreeProps) => {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      expanded={getExpandedIds(subSubcategory)}
    >
      {subSubcategory.map(subSubcategory => (
        <SubcategoryItems
          key={subSubcategory.id}
          subSubcategory={subSubcategory}
          isSelected={isSelected}
          isIndeterminateSelection={isIndeterminateSelection}
          updateSelection={updateSelection}
          disableSync={disableSync}
        />
      ))}
    </TreeView>
  );
};

export default SubcategoriesTree;
