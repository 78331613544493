export interface IEventBusListener {
  callback: Function;
  id: string;
}

export interface IEventBusState {
  listeners: Record<string, IEventBusListener[]>;
}

export const InitialEventBusState: IEventBusState = {
  listeners: {},
};
