import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';

export type DropDownQueryParam = {
  search?: string;
  fromDate?: string;
} & FeedbackParams;

export const dropdownsApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    eventsDropdown: builder.query<
      {events: Array<{id: number; title: string}>},
      DropDownQueryParam | void
    >({
      query: payload => {
        const url = 'drop-downs/event';

        const queryStrings = Object.entries(payload ?? {}).reduce(
          (acc, entry) => {
            acc.push(`${entry[0]}=${entry[1]}`);
            return acc;
          },
          [] as string[]
        );

        const queryString = queryStrings.join('&');

        if (queryString) {
          return `${url}?${queryString}`;
        }
        return url;
      },
    }),
    performersDropdown: builder.query<
      {performers: Array<{id: number; name: string}>},
      string | undefined | null
    >({
      query: search => {
        if (search) {
          return `drop-downs/performer?search=${search}`;
        }
        return 'drop-downs/performer';
      },
    }),
    categoriesDropdown: builder.query<
      {categories: Array<{id: number; name: string}>},
      DropDownQueryParam | void
    >({
      query: ({search} = {}) => {
        if (search) {
          return `drop-downs/category?search=${search}`;
        }
        return 'drop-downs/category';
      },
    }),
  }),
});

export const {
  useEventsDropdownQuery,
  usePerformersDropdownQuery,
  useCategoriesDropdownQuery,
} = dropdownsApi;
