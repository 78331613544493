import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import usePermission from '../../../Auth/usePermission';
import {
  useAddMemberMutation,
  useDeleteMemberMutation,
  useEditMemberMutation,
  useReset2FAMutation,
  useSuspendMemberMutation,
  useToggle2FAMutation,
  useUnsuspendMemberMutation,
  useUpdateRolesMutation,
} from '../../../services/teamMemberApi';
import {Role, TeamMember} from '../../../components/Settings/types';
import {TeamPermission} from '../../../constants/teamPermission';
import {
  getErrorMessage,
  getMemberName,
} from '../../../components/Settings/Team/functions';

const userIntlParams = (member: TeamMember) => ({
  user: getMemberName(member),
});

export const useTeamSettingsMutations = () => {
  const intl = useIntl();
  const {hasPermission} = usePermission();

  const [addMember, {isSuccess: isAddSuccess}] = useAddMemberMutation();
  const [editMember, {isSuccess: isEditSuccess}] = useEditMemberMutation();
  const [updateRoles, {isSuccess: isUpdateSuccess}] = useUpdateRolesMutation();
  const [deleteMember, {isSuccess: isDeleteSuccess}] =
    useDeleteMemberMutation();
  const [suspendMember, {isSuccess: isSuspendMemberSuccess}] =
    useSuspendMemberMutation();
  const [unsuspendMember, {isSuccess: isUnsuspendMemberSuccess}] =
    useUnsuspendMemberMutation();
  const [toggle2FA, {isSuccess: isToggle2FASuccess}] = useToggle2FAMutation();
  const [reset2FA, {isSuccess: isReset2FASuccess}] = useReset2FAMutation();

  const defaultErrorMessage = intl.formatMessage({
    id: 'dashboard.settings.team.error_message.default',
  });

  const onAddMember = useCallback(
    values => {
      addMember({
        ...values,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_added',
          },
          userIntlParams(values)
        ),
        showErrorMessage: true,
        formatErrorMessage: error => {
          return getErrorMessage(error, defaultErrorMessage);
        },
      });
    },
    [hasPermission, intl, addMember]
  );

  const onEditMember = useCallback(
    values => {
      editMember({
        ...values,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_updated',
          },
          userIntlParams(values)
        ),
        showErrorMessage: true,
        formatErrorMessage: error => {
          return getErrorMessage(error, defaultErrorMessage);
        },
      });
    },
    [hasPermission, intl, editMember]
  );

  const onDeleteMember = useCallback(
    (member: TeamMember) => {
      deleteMember({
        id: member.id,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_deleted',
          },
          userIntlParams(member)
        ),
        showErrorMessage: true,
      });
    },
    [hasPermission, intl, deleteMember]
  );

  const onMemberRolesChange = useCallback(
    (newRoles: Role[], member: TeamMember) => {
      if (!hasPermission(TeamPermission.Edit)) {
        console.warn('User does not have permission to edit team members');
        return;
      }

      updateRoles({
        id: member.id,
        // Include hidden roles otherwise, member loose some of his roles, e.g: ROLE_SUPER_ADMIN, ROLE_ADMIN, etc
        teamMemberRoles: newRoles.map(role => role.slug),
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_roles_updated',
          },
          userIntlParams(member)
        ),
        showErrorMessage: true,
      });
    },
    [hasPermission, intl, updateRoles]
  );

  const onMemberSuspend = useCallback(
    (member: TeamMember) => {
      if (!hasPermission(TeamPermission.Suspend)) {
        console.warn('User does not have permission to suspend team members');
        return;
      }
      suspendMember({
        id: member.id,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_suspended',
          },
          userIntlParams(member)
        ),
        showErrorMessage: true,
      });
    },
    [hasPermission, intl, suspendMember]
  );

  const onMemberUnsuspend = useCallback(
    (member: TeamMember) => {
      if (!hasPermission(TeamPermission.Suspend)) {
        console.warn('User does not have permission to unsuspend team members');
        return;
      }
      unsuspendMember({
        id: member.id,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {
            id: 'dashboard.settings.team.success_message.member_unsuspended',
          },
          userIntlParams(member)
        ),
        showErrorMessage: true,
      });
    },
    [hasPermission, intl, unsuspendMember]
  );

  const onToggle2FA = useCallback(
    (member: TeamMember) => {
      toggle2FA({
        id: member.id,
        enable2Fa: member.enable2Fa,
        showProgressDialog: true,
        successMessage: intl.formatMessage(
          {id: 'dashboard.settings.team.success_message.member_2fa_toggled'},
          {...userIntlParams(member), state: member.enable2Fa ? 'on' : 'off'}
        ),
        errorMessage: intl.formatMessage({
          id: 'dashboard.settings.team.error_message.member_2fa_toggled',
        }),
        showErrorMessage: false,
      });
    },
    [hasPermission, intl, toggle2FA]
  );

  const onReset2FA = useCallback(
    (member: TeamMember) => {
      reset2FA({
        id: member.id,
        showProgressDialog: true,
        successMessage: intl.formatMessage({
          id: 'dashboard.settings.team.success_message.member_2fa_reset',
        }),
        errorMessage: intl.formatMessage({
          id: 'dashboard.settings.team.error_message.member_2fa_reset',
        }),
        showErrorMessage: false,
      });
    },
    [hasPermission, intl, reset2FA]
  );

  return {
    isAddSuccess,
    isEditSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    isSuspendMemberSuccess,
    isUnsuspendMemberSuccess,
    isToggle2FASuccess,
    isReset2FASuccess,
    onAddMember,
    onEditMember,
    onDeleteMember,
    onMemberRolesChange,
    onMemberSuspend,
    onMemberUnsuspend,
    onToggle2FA,
    onReset2FA,
  };
};
