import {useFormContext, useWatch} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Box from '@material-ui/core/Box';
import {
  CountSectionChangesFunction,
  OnBlockAddedOrRemovedFunction,
  SeatmapBlockFormFields,
  SeatmapFormFields,
} from '../../../types';
import React, {ReactNode, useCallback, useEffect, useRef} from 'react';
import {BlockNameTitle} from './BlockNameTitle';
import {BlockItem} from './BlockItem';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {useCanAddBlock} from '../hooks/useCanAddBlock';
import {nanoid} from 'nanoid';
import {EmptySeatmapBlock} from '../../../constants';
import {AddBlock} from './AddBlock';

interface BlocksViewProps {
  sectionIndex: number;
  sectionId?: number | string;
  isSvgMissingSection: boolean;
  sectionSlug: string;
  countSectionChanges?: CountSectionChangesFunction;
  onBlockAddedOrRemoved?: OnBlockAddedOrRemovedFunction;
  deleteSectionButton: ReactNode;
  disabled?: boolean;
}

const emptyArray = [] as SeatmapBlockFormFields[];

export const BlocksView = React.memo((props: BlocksViewProps) => {
  const {
    deleteSectionButton,
    sectionIndex,
    sectionId,
    isSvgMissingSection,
    countSectionChanges,
    onBlockAddedOrRemoved,
    sectionSlug,
    disabled,
  } = props;

  const {control, trigger, watch, setValue, unregister} =
    useFormContext<SeatmapFormFields>();

  const lastBlockInputRef = useRef<HTMLInputElement | null>(null);
  const lastBlockInputFocusLock = useRef<boolean>(false);

  const blocks =
    useWatch<SeatmapFormFields, `sections.${number}.blocks`>({
      name: `sections.${sectionIndex}.blocks`,
      control,
    }) ?? emptyArray;

  const remove = useCallback(
    (index: number) => {
      unregister(`sections.${sectionIndex}.blocks.${index}`);
      const newBlocks = blocks.concat([]);
      newBlocks.splice(index, 1);
      setValue(`sections.${sectionIndex}.blocks`, newBlocks);
    },
    [blocks, sectionIndex, setValue, unregister]
  );

  const addBlock = useCallback(() => {
    lastBlockInputFocusLock.current = true;
    const id = nanoid();
    setValue(`sections.${sectionIndex}.blocks`, [
      ...blocks,
      {...EmptySeatmapBlock, id},
    ]);
    onBlockAddedOrRemoved?.(id, String(sectionId), 'add');
    if (blocks.length === 0) {
      void trigger(
        `sections.${sectionIndex}.customErrors.blockOrGeneralAdmissionError`,
        {shouldFocus: false}
      ).catch(() => {});
    }
  }, [
    blocks,
    onBlockAddedOrRemoved,
    sectionId,
    sectionIndex,
    setValue,
    trigger,
  ]);

  useEffect(() => {
    if (!lastBlockInputFocusLock.current || !lastBlockInputRef?.current) {
      return;
    }
    setTimeout(() => {
      lastBlockInputRef.current?.focus();
    }, 0);
    lastBlockInputFocusLock.current = false;
  }, [blocks.length]);

  const canAddBlock = useCanAddBlock(watch, sectionIndex, isSvgMissingSection);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={10}
        className={clsx({
          'mb-3': !!blocks.length,
        })}
      >
        <BlockNameTitle sectionIndex={sectionIndex} />

        {blocks.length === 0 && canAddBlock && (
          <Typography variant="body2" className="pb-2">
            <FormattedMessage id="dashboard.seatmap.details.no_block_message" />
          </Typography>
        )}

        {blocks.map((block, index) => (
          <BlockItem
            blockIndex={index}
            blockId={block.id}
            sectionSlug={sectionSlug}
            blockSlug={block.slug}
            sectionIndex={sectionIndex}
            sectionId={sectionId}
            remove={remove}
            isSvgMissingSection={isSvgMissingSection}
            countSectionChanges={countSectionChanges}
            onBlockAddedOrRemoved={onBlockAddedOrRemoved}
            key={`${block.id}-${index}`}
            disabled={disabled}
            ref={index === blocks.length - 1 ? lastBlockInputRef : undefined}
          />
        ))}
      </Box>
      <Box display="flex" alignItems="center" gridGap={20}>
        <AddBlock
          onAddBlock={addBlock}
          canAddBlock={canAddBlock}
          disabled={disabled}
        />
        {deleteSectionButton}
      </Box>
    </div>
  );
});
