import {useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CollapsableCard from '../../Common/CollapsableCard';
import Checkbox from '../../Common/Checkbox';
import ChildCategoriesList from './ChildCategoriesList';
import {
  ITixstockCategoryWithChildren,
  UpdateTixstockCategoryRequest,
  useGetTixstockCategoriesQuery,
  useUpdateTixstockCategoriesMutation,
} from '../../../services/eventsApi';
import {ChangeEvent, useEffect, useState} from 'react';
import {SaveFeature} from '../../Common/SaveFeature';
import useSnackBar from '../../../hooks/app/useSnackBar';
import {Prompt} from 'react-router-dom';
import usePermission from '../../../Auth/usePermission';
import {CategoryPermissions} from '../../../constants/permissions';
import {formatEventsApiErrorMessage} from '../../../utils/functions';

const flattenTree = (
  items: ITixstockCategoryWithChildren[]
): ITixstockCategoryWithChildren[] => {
  return items.reduce(
    (
      flattened: ITixstockCategoryWithChildren[],
      toFlatten: ITixstockCategoryWithChildren
    ) => {
      if (toFlatten.children.length > 0) {
        return flattened.concat(
          {...toFlatten, children: []},
          flattenTree(toFlatten.children)
        );
      }
      return flattened.concat(toFlatten);
    },
    []
  );
};

const TixstockCategoriesList = () => {
  const intl = useIntl();
  const [treeCategories, setTreeCategories] = useState<
    ITixstockCategoryWithChildren[]
  >([]);
  const [flattenCategories, setFlattenCategories] = useState<
    ITixstockCategoryWithChildren[]
  >([]);
  const {showMessage} = useSnackBar();
  const {hasPermission} = usePermission();

  const {
    data: categories,
    isSuccess,
    refetch,
  } = useGetTixstockCategoriesQuery(
    {
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateCategories, {error, isSuccess: isUpdateSuccess}] =
    useUpdateTixstockCategoriesMutation();

  useEffect(() => {
    if (categories?.data && isSuccess) {
      setTreeCategories(categories.data);
      setFlattenCategories(flattenTree(categories.data));
    }
  }, [categories, isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
    }
  }, [isUpdateSuccess, refetch]);

  const updateSelection = (
    event: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const checked = event.target.checked;
    const tempItems: ITixstockCategoryWithChildren[] = [];
    for (const item of flattenCategories) {
      if (item.id === id || item.key?.split('.').includes(id)) {
        tempItems.push({...item, useForFeeds: checked});
      } else {
        tempItems.push(item);
      }
    }
    setFlattenCategories(tempItems);
  };

  const isSelected = (id: string): boolean => {
    const category = flattenCategories.find(i => i.id === id);
    const isAllChildrenUnChecked = flattenCategories.some(
      i => i.id !== id && i.key?.split('.').includes(id) && !i.useForFeeds
    );
    if (category?.totalChildren && !isAllChildrenUnChecked) {
      return true;
    } else if (!category?.totalChildren) {
      return category?.useForFeeds ?? false;
    }
    return false;
  };

  const totalOrphanChildren = (id: string): number => {
    return flattenCategories.filter(
      item =>
        item.id !== id &&
        item.key?.split('.').includes(id) &&
        !item.totalChildren
    ).length;
  };

  const isIndeterminateSelection = (id: string): boolean => {
    return flattenCategories.some(
      item =>
        item.id !== id && item.key?.split('.').includes(id) && item.useForFeeds
    );
  };

  const countSynced = (id: string): number => {
    return flattenCategories.filter(
      item =>
        item.id !== id &&
        item.key?.split('.').includes(id) &&
        isSelected(item.id) &&
        !item.totalChildren
    ).length;
  };

  const updatedTixstockCategory = () => {
    const originalItems = flattenTree(treeCategories);
    return flattenCategories
      .filter(
        item =>
          originalItems.find(i => i.id === item.id)?.useForFeeds !==
            isSelected(item.id) && !item.totalChildren
      )
      .map(category => ({
        id: category.id,
        useForFeeds: isSelected(category.id),
      }));
  };

  const onSubmit = async () => {
    const categories = updatedTixstockCategory();
    const data: UpdateTixstockCategoryRequest = {
      categories,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.TIXSTOCK_CATEGORY_UPDATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    await updateCategories(data);
  };

  const onClear = async () => {
    const originalItems = flattenTree(treeCategories);
    setFlattenCategories(originalItems);
  };

  const disableSync = !hasPermission(
    CategoryPermissions.syncTixstockCategories
  );

  return (
    <>
      <div className="categories-list">
        {treeCategories.map(category => (
          <CollapsableCard
            key={category.id}
            title={`${category.name}`}
            isCollapsable={!!category.totalChildren}
            style={{cursor: !category.totalChildren ? 'default' : 'pointer'}}
            leadingElement={
              <Box
                ml={-1}
                my={-1}
                onClick={(event: any) => event.stopPropagation()}
              >
                <Checkbox
                  disabled={disableSync}
                  onChange={(event: any) => updateSelection(event, category.id)}
                  data-testid={`checkbox-${category.name}`}
                  checked={isSelected(category.id)}
                  indeterminate={
                    isIndeterminateSelection(category.id) &&
                    !isSelected(category.id)
                  }
                />
              </Box>
            }
            trailingElement={
              <Typography
                variant="body2"
                className="font-weight-bold text-gray-title"
              >
                {category.totalChildren
                  ? `${countSynced(category.id)}/${totalOrphanChildren(
                      category.id
                    )} ${intl.formatMessage({
                      id: 'dashboard.events.tixstock.synced',
                    })}`
                  : ''}
              </Typography>
            }
          >
            <div className="px-lg-3">
              <ChildCategoriesList
                childrenCategories={category.children}
                isSelected={isSelected}
                isIndeterminateSelection={isIndeterminateSelection}
                updateSelection={updateSelection}
                countSynced={countSynced}
                totalOrphanChildren={totalOrphanChildren}
                disableSync={disableSync}
              />
            </div>
          </CollapsableCard>
        ))}
      </div>
      <SaveFeature
        onSave={onSubmit}
        num={updatedTixstockCategory().length}
        onClose={onClear}
        open={!!updatedTixstockCategory().length}
      />
      <Prompt
        when={!!updatedTixstockCategory().length}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />
    </>
  );
};

export default TixstockCategoriesList;
