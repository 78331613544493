import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../../types';

export function useCanAddBlock(
  watch: UseFormWatch<SeatmapFormFields>,
  sectionIndex: number,
  isSvgMissingSection: boolean
) {
  const isGeneralAdmission = watch(
    `sections.${sectionIndex}.isGeneralAdmission`
  );
  const seatmapFile = watch('seatmapFile');
  const isSVGWithSeatmapFormat = watch('isSVGWithSeatmapFormat');

  const isSeatmapWithProperSVG = seatmapFile
    ? seatmapFile.file.name.split('.').pop() === 'svg'
    : isSVGWithSeatmapFormat;

  return !(isGeneralAdmission || isSeatmapWithProperSVG || isSvgMissingSection);
}
