import {upperFirst} from 'lodash';
import {ScrollOptions} from '../constants';
import {FieldErrors, FieldErrorsImpl} from 'react-hook-form';
import {SeatmapFormFields} from '../types';
import {ErrorElementRefs} from '../../../../store/slices/eventsManagement';
import {ISeatmap} from '../../../../services/eventsApi';

export function slugify<T extends {name: string; slug?: string}>(
  record: T
): T & {slug: string} {
  const slug =
    record.slug ?? record.name?.toLowerCase().trim().replace(/ /g, '-');
  return {
    ...record,
    slug,
    name: record.name
      ?.replaceAll('-', ' ')
      .replaceAll('_', ' ')
      .trim()
      .split(' ')
      .map(upperFirst)
      .join(' '),
  };
}

export function getSeatmapDefaultValues(
  seatmap?: ISeatmap
): ISeatmap | undefined {
  if (!seatmap) return;
  return {
    ...seatmap,
    sections: seatmap.sections.map(section => ({
      ...slugify(section),
      blocks: section.blocks?.map(block => ({
        ...slugify(block),
        name: block.name ?? '',
      })),
    })),
  };
}

export function scrollToFirstError(
  errors: FieldErrors<SeatmapFormFields>,
  errorElementRefs: ErrorElementRefs,
  isListContainErrors: boolean,
  isFormMissingElements: boolean
) {
  if (isFormMissingElements && errorElementRefs['svgFormSync']) {
    errorElementRefs['svgFormSync'].scrollIntoView(ScrollOptions);
    return;
  }

  if (isListContainErrors && errorElementRefs['sectionsSvgSync']) {
    errorElementRefs['sectionsSvgSync'].scrollIntoView(ScrollOptions);
    return;
  }

  const fixedPlaceElements = ['name', 'venue'];
  for (const key of fixedPlaceElements) {
    if (key in errors && key in errorElementRefs) {
      errorElementRefs[key].scrollIntoView(ScrollOptions);
      return;
    }
  }

  const sectionErrors = errors.sections;
  if (!sectionErrors) return;
  if (!Array.isArray(sectionErrors)) {
    errorElementRefs['sections']?.scrollIntoView(ScrollOptions);
    return;
  }

  const firstSectionErrorIndex = sectionErrors.findIndex(error => !!error);

  const firstSectionError = sectionErrors[
    firstSectionErrorIndex
  ] as FieldErrorsImpl<SeatmapFormFields['sections']>[number];
  if (!firstSectionError) return;

  if (
    firstSectionError.name ||
    // @ts-ignore
    firstSectionError.customErrors?.sectionNameUniqueness
  ) {
    errorElementRefs[`sections.${firstSectionErrorIndex}.name`]?.scrollIntoView(
      ScrollOptions
    );
    return;
  } else if (
    firstSectionError.message ||
    // @ts-ignore
    firstSectionError.customErrors?.blockOrGeneralAdmissionError
  ) {
    errorElementRefs[`sections.${firstSectionErrorIndex}`]?.scrollIntoView(
      ScrollOptions
    );
    return;
  }

  if (!firstSectionError.blocks || !Array.isArray(firstSectionError.blocks))
    return;

  const firstBlockErrorIndex = firstSectionError.blocks.findIndex(
    error => !!error
  );

  errorElementRefs[
    `sections.${firstSectionErrorIndex}.blocks.${firstBlockErrorIndex}.name`
  ]?.scrollIntoView(ScrollOptions);
}
