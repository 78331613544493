import {useIntl} from 'react-intl';
import StatusIndicator from '../../../../Common/StatusIndicator';
import {EventCell, EventHeaderCellProps} from './EventCell';
import {DateTimeCell, DateTimeCellProps} from './DateTimeCell';
import {VenueCell, VenueCellProps} from './VenueCell';
import {CategoryCell, CategoryCellProps} from './CategoryCell';

export function useHeaderCells() {
  const intl = useIntl();

  return [
    {
      name: 'title',
      disablePadding: true,
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.event_title',
      }),
      collapse: false,
      renderValue: (props: EventHeaderCellProps) => <EventCell {...props} />,
      width: '20%',
      numeric: false,
    },
    {
      name: 'date',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.date_time',
      }),
      collapse: true,
      renderValue: (props: DateTimeCellProps) => <DateTimeCell {...props} />,
    },
    {
      name: 'venue',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.venue',
      }),
      collapse: true,
      renderValue: (props: VenueCellProps) => <VenueCell {...props} />,
    },
    {
      name: 'category',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.category',
      }),
      collapse: true,
      renderValue: (props: CategoryCellProps) => <CategoryCell {...props} />,
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.status',
      }),
      collapse: false,
      renderValue: ({label, status}: {label: string; status: string}) => (
        <StatusIndicator label={label} status={status} className={undefined} />
      ),
      width: '13%',
    },
  ];
}
