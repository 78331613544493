import {Prompt, useHistory, useParams} from 'react-router-dom';
import VenueForm from './VenueForm';
import VenueHeader from './VenueHeader';
import {useForm} from 'react-hook-form';
import {VenueFormFields} from './types';
import {
  UpdateVenueQueryParam,
  useEditVenueMutation,
  useGetVenueQuery,
} from '../../../services/eventsApi';
import {useEffect, useState} from 'react';
import {SaveFeature} from '../../Common/SaveFeature';
import {
  getVenueValidationSchema,
  VenueFormDefaultValues,
} from './helpers/validations';
import usePermission from '../../../Auth/usePermission';
import {VenuePermissions} from '../../../constants/permissions';
import {useIntl} from 'react-intl';
import {useVenueUpdatedFields} from './hooks/useVenueUpdatedFields';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import {yupResolver} from '@hookform/resolvers/yup';
import * as urls from '../../../constants/urls';

const EditVenue = () => {
  const history = useHistory();
  const intl = useIntl();
  const {id} = useParams<{id: string}>();
  const {
    data: venue,
    isError: isFetchingVenueError,
    refetch,
  } = useGetVenueQuery({
    id: Number(id),
    formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
    showProgressDialog: true,
  });

  if (isFetchingVenueError) {
    history.replace(urls.EVENTS_VENUES_PATH);
  }
  const [editVenue, {data: editedVenue, isSuccess}] = useEditVenueMutation();

  const [defaultValues, setDefaultValues] = useState<
    {addressLine1?: string} | undefined
  >();

  const form = useForm<VenueFormFields>({
    defaultValues: VenueFormDefaultValues,
    values: editedVenue ?? venue,
    resolver: yupResolver(getVenueValidationSchema(intl)) as any,
    mode: 'onBlur',
  });

  const {hasPermission} = usePermission();

  const {updatedFields, setUpdatedFields} = useVenueUpdatedFields(
    form,
    editedVenue ?? venue
  );

  const onSubmit = async (data: VenueFormFields) => {
    const city = form.getValues('city');
    if (!city) {
      form.setError('addressLine1', {
        type: 'manual',
        message: intl.formatMessage({
          id: 'validation.error.select_from_suggestions',
        }),
      });
      return;
    }
    const venueData: UpdateVenueQueryParam = {
      id: Number(id),
      body: {
        ...data,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.VENUE_UPDATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    await editVenue(venueData);
  };

  useEffect(() => {
    setDefaultValues({addressLine1: venue?.addressLine1});
  }, [venue]);

  useEffect(() => {
    if (isSuccess && editedVenue) {
      refetch();
      setUpdatedFields(new Set());
    }
  }, [editedVenue, isSuccess, refetch, setUpdatedFields]);

  const isFormDisabled = !hasPermission(VenuePermissions.editVenueDetails);

  return (
    <div className="details-page venue">
      {venue && <VenueHeader venue={venue} />}
      {venue && (
        <div className="container ms-lg-0">
          <div className="content-block">
            <VenueForm
              form={form}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              isFormDisabled={isFormDisabled}
              seatmaps={venue?.seatmaps}
            />
          </div>
        </div>
      )}

      <Prompt
        when={!!updatedFields.size}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />

      {!isFormDisabled && (
        <SaveFeature
          onSave={form.handleSubmit(onSubmit)}
          num={updatedFields.size}
          onClose={() => {
            form.reset();
            setDefaultValues({addressLine1: venue?.addressLine1});
          }}
          open={!!updatedFields.size}
        />
      )}
    </div>
  );
};

export default EditVenue;
