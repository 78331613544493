import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../../types';
import {useCanAddSection} from './useCanAddSection';

export function useCanDeleteBlock(
  watch: UseFormWatch<SeatmapFormFields>,
  isSectionMissing: boolean
) {
  const canAddSection = useCanAddSection(watch);
  return isSectionMissing || canAddSection;
}
