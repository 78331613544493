import {useIsWithUp} from '../../../hooks/UI';
import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../../Common/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import {Close as CloseIcon} from '@material-ui/icons';
import {FILTER_ICON} from '../../../constants/images';
import {Drawer} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import {ITheme} from '../../../constants/theme';
import {makeStyles} from '@material-ui/core';
import IconButton from '../../Common/IconButton';
import {FilterItem} from './types';
import {useModularFilter} from './hooks';
import clsx from 'clsx';
import {useModularFilterUrlInitialValues} from './hooks/useModularFilterUrlInitialValues';

const useStyles = makeStyles((theme: ITheme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: `${theme.shadows[3]} !important`,
    overflow: 'hidden',

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '21.4%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 51,
      paddingBottom: 0,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
  closeWrapper: {
    padding: '0px 40px',
  },
  [theme.breakpoints.up('lg')]: {
    form: {
      overflow: 'auto',
    },
  },
}));

interface ModularFilterProps {
  openElement?: ReactElement;
  onApply: (values: Record<string, any>) => void;
  onClear: () => void;
  children: ReactElement<FilterItem> | ReactElement<FilterItem>[];
}

export function ModularFilter(props: Readonly<ModularFilterProps>) {
  const {openElement, onApply, children, onClear: clearParent} = props;

  const [isOpen, setIsOpen] = useState(false);
  const [openedFilterName, setOpenedFilterName] = useState('');
  const isInitialFiltersApplied = useRef(false);

  const isWidthUpLg = useIsWithUp('lg');
  const classes = useStyles();
  const intl = useIntl();

  const {
    onFilterChange,
    onClear: clearSearchParams,
    onFilterRemove,
    filterValues,
    activeFiltersCount,
    updateUrlSearch,
  } = useModularFilter();

  const urlInitialValues = useModularFilterUrlInitialValues();

  const openDrawer = () => {
    setIsOpen(true);
  };

  const onClear = () => {
    clearSearchParams();
    clearParent();
  };

  const openFilter = useCallback((name: string) => {
    setOpenedFilterName(name);
  }, []);

  const onApplyClick = () => {
    const payload = Object.values(filterValues).reduce((acc, currentValue) => {
      return {...acc, [currentValue.name]: currentValue.value};
    }, {});
    onApply(payload);
    setIsOpen(false);
    updateUrlSearch();
  };

  useEffect(() => {
    if (urlInitialValues && !isInitialFiltersApplied.current) {
      onApply(urlInitialValues);
      isInitialFiltersApplied.current = true;
    }
  }, [urlInitialValues, onApply]);

  const modifiedChildren = useMemo(
    () =>
      React.Children.map(
        Array.isArray(children) ? children : [children],
        child =>
          cloneElement(child, {
            onFilterChange,
            openedFilterName,
            openFilter,
            onFilterRemove,
          })
      ).filter(React.isValidElement),
    [children, onFilterChange, onFilterRemove, openFilter, openedFilterName]
  );

  const modifiedOpenElement =
    openElement &&
    cloneElement(openElement, {
      onClick: openDrawer,
      activeFiltersCount,
    });

  return (
    <>
      {modifiedOpenElement || (
        <IconButton
          className="m-0"
          onClick={openDrawer}
          {...(activeFiltersCount && {numberLabel: activeFiltersCount})}
        >
          {FILTER_ICON}
        </IconButton>
      )}
      <Drawer
        anchor={isWidthUpLg ? 'right' : 'bottom'}
        variant="persistent"
        elevation={0}
        classes={{
          paper: classes.paper,
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <form className="d-flex flex-column flex-grow-1">
          <div className="filter-content d-flex flex-column flex-fill">
            <div className="filter-header border-bottom">
              {/* Close action button */}
              <Button
                link
                light
                title={intl.formatMessage({id: 'actions.close'})}
                onClick={() => {
                  setIsOpen(false);
                }}
                icon={
                  <CloseIcon style={{color: '#989898', fontSize: '24px'}} />
                }
              />
              {/* Filter title */}
              <div className="filter-header-title d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {FILTER_ICON}
                  <Typography variant="h5">
                    <FormattedMessage id="actions.filter" />
                  </Typography>
                </div>
                <div
                  className={clsx('filter-counter-indicator text-center', {
                    'opacity-0': !activeFiltersCount,
                  })}
                >
                  {activeFiltersCount}
                </div>
              </div>
            </div>
            <div className="filter-items-container flex-fill">
              <>{modifiedChildren}</>
            </div>
            <div className="filter-bottom-actions-custom flex-shrink d-flex align-items-center justify-content-center mb-3">
              {/*Clear*/}
              <Button
                link
                title={intl.formatMessage({id: 'actions.clear'})}
                type="button"
                onClick={() => {
                  onClear();
                  setIsOpen(false);
                }}
              />
              {/*Apply*/}
              <Button
                primary
                title={intl.formatMessage({id: 'actions.apply'})}
                type="button"
                onClick={onApplyClick}
              />
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
}
