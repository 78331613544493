import {useFormContext} from 'react-hook-form';
import {
  CountSectionChangesFunction,
  OnBlockAddedOrRemovedFunction,
  SeatmapFormFields,
} from '../../types';
import {EmptySectionsView} from '../EmptySectionView';
import {SectionListItem} from './SectionListItem';
import Box from '@material-ui/core/Box';
import React, {useCallback, useEffect, useRef} from 'react';
import {
  useAddSeatmapErrorElementRef,
  useSetSeatmapProcessingAction,
} from '../../../../../store/slices/eventsManagement';
import {SectionsFormSyncError} from './SectionsFormSyncError';
import {AddSectionButton} from './AddSectionButton';

type SectionsViewProps = {
  countSectionChanges?: CountSectionChangesFunction;
  onSectionDelete?: (id: string | number, slug?: string) => void;
  onSectionAdd?: (id: string) => void;
  onBlockAddedOrRemoved?: OnBlockAddedOrRemovedFunction;
  disabled?: boolean;
};

const SectionsView = (props: SectionsViewProps) => {
  const {
    countSectionChanges,
    onSectionAdd,
    onSectionDelete,
    onBlockAddedOrRemoved,
    disabled,
  } = props;

  const lastSectionInputRef = useRef<HTMLInputElement | null>(null);
  const lastSectionInputFocusLock = useRef<boolean>(false);

  const addErrorElementRef = useAddSeatmapErrorElementRef();
  const setSeatmapProcessingAction = useSetSeatmapProcessingAction();
  const {
    clearErrors,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useFormContext<SeatmapFormFields>();

  const sections = watch('sections');

  const removeItem = useCallback(
    (sectionId: string) => {
      setSeatmapProcessingAction(true);

      // This set time out is used to defer removing a section to the next tick in the processor, it helps to display the loader
      // If there are a lot of sections the UI freeze, so displaying the loader helps to show the user that something is happening
      setTimeout(() => {
        const deletedSectionIndex = sections.findIndex(
          section => String(section.id) === sectionId
        );

        const deletedSection = sections[deletedSectionIndex];
        clearErrors(`sections.${deletedSectionIndex}`);

        setValue(
          'sections',
          sections.filter(section => String(section.id) !== sectionId)
        );
        onSectionDelete?.(sectionId, deletedSection?.slug);
        setSeatmapProcessingAction(false);
      }, 0);
    },
    [
      onSectionDelete,
      sections,
      setValue,
      setSeatmapProcessingAction,
      clearErrors,
    ]
  );

  const handleSectionAdd = useCallback(
    (id: string) => {
      lastSectionInputFocusLock.current = true;
      onSectionAdd?.(id);
    },
    [onSectionAdd]
  );

  useEffect(() => {
    if (!lastSectionInputFocusLock.current) {
      return;
    }
    if (lastSectionInputRef?.current) {
      lastSectionInputRef.current.focus();
      lastSectionInputFocusLock.current = false;
    }
  }, [lastSectionInputRef, sections.length]);

  return (
    <Box display="flex" flexDirection="column" gridGap={10}>
      {!sections.length && <EmptySectionsView />}
      <SectionsFormSyncError />
      {sections.map((item, index) => {
        return (
          <SectionListItem
            section={item}
            key={`${item.id}-${index}`}
            index={index}
            remove={removeItem}
            countSectionChanges={countSectionChanges}
            onBlockAddedOrRemoved={onBlockAddedOrRemoved}
            disabled={disabled}
            ref={
              index === sections.length - 1 ? lastSectionInputRef : undefined
            }
          />
        );
      })}
      <AddSectionButton
        onSectionAdd={handleSectionAdd}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        clearErrors={clearErrors}
        watch={watch}
      />
      <div
        ref={ref => {
          addErrorElementRef('sections', ref);
        }}
      >
        {errors.sections?.message && (
          <span className="error">{errors.sections?.message}</span>
        )}
      </div>
    </Box>
  );
};

export default SectionsView;
