import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../../Common/IconButton';
import Button from '../../../Common/Button';
import {ArrowForwardIcon} from '../../../../constants/images';
import ImageField from '../../../Form/Fields/ImageField';
import SelectMultiLevelCategory from '../../components/SelectMultiLevelCategory/SelectMultiLevelCategory';
import DomainList from './DomainList';
import {
  ICategory,
  useGetDomainsQuery,
  useGetPicturesQuery,
} from '../../../../services/eventsApi';
import {MediaLibrary, MediaLibraryItem} from '../../../Widgets/MediaLibrary';
import {CategoryFormFields, IImageField} from './types';
import {UseFormReturn} from 'react-hook-form';
import {get} from 'lodash';
import TextLine from '../../../Common/TextLine';
import {EnhancedInputField} from '../../../Form/Fields/EnhancedInputField/EnhancedInputField';
import {isArrayEquivalence} from '../../../../utils/functions';
import {isUrl} from '../../../../utils/FormValidation';
import {useAddCategoryErrorElementRef} from '../../../../store/slices/eventsManagement/category';

interface CategoryFormProps {
  category?: ICategory;
  form: UseFormReturn<CategoryFormFields>;
  disableCategoryEdit?: boolean;
  disableDomainsEdit?: boolean;
}

export const CategoryForm = (props: CategoryFormProps) => {
  const {category, form, disableCategoryEdit, disableDomainsEdit} = props;
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [imagesSearch, setImagesSearch] = useState('');
  const [mediaLibraryImages, setMediaLibraryImages] = useState<
    MediaLibraryItem[]
  >([]);
  const {data: domains = [], isLoading: isDomainsLoading} =
    useGetDomainsQuery();

  const addErrorElementRef = useAddCategoryErrorElementRef();

  const intl = useIntl();
  const {data: pictures} = useGetPicturesQuery();

  const {
    setValue,
    watch,
    formState: {errors},
    clearErrors,
    register,
    trigger,
  } = form;

  const mediaLibraryItem = watch('mediaLibraryItem');
  const categoryImage = watch('categoryImage');
  const landingPageUrl = watch('landingPageUrl');
  const parentId = watch('parentId');
  const domainIds = watch('domainIds');
  const defaultValues = form.formState?.defaultValues ?? {};

  const updateChangedField = (
    inputName: keyof Omit<CategoryFormFields, 'updatedFields'>
  ) => {
    setValue('updatedFields', form.getValues('updatedFields').add(inputName));
  };

  const updateNotChangedField = (
    inputName: keyof Omit<CategoryFormFields, 'updatedFields'>
  ) => {
    const updatedFields = form.getValues('updatedFields');
    updatedFields.delete(inputName);
    setValue('updatedFields', updatedFields);
  };

  useEffect(() => {
    let galleryPictures = [
      ...(pictures?.categories ?? []),
      ...(pictures?.performers ?? []),
    ].map(picture => ({
      id: picture.id,
      img: picture.imageUrl,
      title: picture.name,
    }));
    if (imagesSearch) {
      galleryPictures = galleryPictures.filter(picture =>
        picture.title.toLowerCase().includes(imagesSearch.toLowerCase())
      );
    }
    setMediaLibraryImages(galleryPictures);
  }, [pictures, imagesSearch]);

  useEffect(() => {
    if (category || !domains.length || !!domainIds?.length) return;
    setValue(
      'domainIds',
      domains.map(domain => domain.id)
    );
  }, [domains, category, setValue, domainIds?.length]);

  useEffect(() => {
    addErrorElementRef('parentId', 'parentId-field');
    addErrorElementRef('name', 'name-field');
    addErrorElementRef('landingPageUrl', 'landingPageUrl-field');
    addErrorElementRef('domainIds', 'domainIds-field');
    addErrorElementRef('imageUrl', 'imageUrl-field');
  }, [addErrorElementRef]);

  return (
    <Form autoComplete="off" data-testid="categoryForm">
      <Row className="gy-3 gx-3">
        <Col lg={7}>
          <Card className="details-card mb-0">
            <CardBody>
              <div className="d-flex flex-column gap-3 gap-lg-fg">
                {/* Parent category */}
                <div
                  id="parentId-field"
                  className="form-group mb-0"
                  data-testid="parentId-field"
                >
                  <SelectMultiLevelCategory
                    disabled={disableCategoryEdit}
                    onChange={parentId => {
                      clearErrors('parentId');
                      setValue('parentId', parentId);
                      parentId !== get(defaultValues, 'parentId')
                        ? updateChangedField('parentId')
                        : updateNotChangedField('parentId');
                    }}
                    existingCategoryId={parentId}
                    disableId={category?.id}
                    errors={errors}
                    onBlur={() => {
                      void trigger('parentId');
                    }}
                  />
                  <span
                    className="MuiFormHelperText-root Mui-error error"
                    data-testid="parentCategoryError"
                  >
                    {errors.parentId?.message}
                  </span>
                </div>

                {/* Category name */}
                <EnhancedInputField
                  id="name-field"
                  wrapperProps={{
                    className: 'form-group text-left mb-0',
                    'data-testid': 'category-name-input-wrapper',
                  }}
                  disabled={disableCategoryEdit}
                  label={`${intl.formatMessage({
                    id: 'dashboard.events.add.name',
                  })}*`}
                  placeholder={intl.formatMessage({
                    id: 'dashboard.events.add.name_placeholder',
                  })}
                  error={!!errors.name?.message}
                  errorMessage={errors.name?.message}
                  {...register('name')}
                  onChange={event => {
                    const {value} = event.target;
                    if (value === get(defaultValues, 'name')) {
                      updateNotChangedField('name');
                    } else {
                      updateChangedField('name');
                    }
                    clearErrors('name');
                    return register('name').onChange(event);
                  }}
                />
                {/* Page URL */}
                <EnhancedInputField
                  id={'landingPageUrl-field'}
                  wrapperProps={{
                    className: 'form-group text-left mb-0',
                  }}
                  disabled={disableCategoryEdit}
                  label={intl.formatMessage({
                    id: 'dashboard.events.add.page_url',
                  })}
                  {...(isUrl(landingPageUrl) && {
                    addon: (
                      <IconButton
                        color="default"
                        size="small"
                        variant="action"
                        className="m-0 bg-transparent shadow-none"
                        onClick={() => {
                          landingPageUrl && window.open(landingPageUrl);
                        }}
                      >
                        <ArrowForwardIcon style={{width: 7, height: 7}} />
                      </IconButton>
                    ),
                  })}
                  addonPosition="end"
                  error={!!errors.landingPageUrl?.message}
                  errorMessage={errors.landingPageUrl?.message}
                  {...register('landingPageUrl')}
                  onChange={event => {
                    const {value} = event.target;
                    if (value === get(defaultValues, 'landingPageUrl')) {
                      updateNotChangedField('landingPageUrl');
                    } else {
                      updateChangedField('landingPageUrl');
                    }
                    clearErrors('name');
                    return register('landingPageUrl').onChange(event);
                  }}
                />
                <div
                  id={'domainIds-field'}
                  data-testid="domainListWrapper"
                  className="form-group mb-0"
                >
                  <DomainList
                    onChange={ids => {
                      const defaultDomainIds = get(
                        form.formState?.defaultValues,
                        'domainIds'
                      );
                      setValue('domainIds', ids);
                      if (!isArrayEquivalence(defaultDomainIds, ids)) {
                        updateChangedField('domainIds');
                      } else {
                        updateNotChangedField('domainIds');
                      }
                      clearErrors('name');
                    }}
                    existingCategoryDomainIds={domainIds}
                    disabled={disableDomainsEdit}
                    domains={domains}
                    isLoading={isDomainsLoading}
                  />
                  <span className="MuiFormHelperText-root Mui-error error">
                    {errors.domainIds?.message}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={5} data-testid="mediaLibraryWrapper">
          <Card className="details-card mb-0">
            <CardBody>
              <div className="d-flex flex-column gap-2">
                <Typography variant="subtitle2" className="text-placeholder">
                  {intl.formatMessage({id: 'dashboard.events.add.image'})}*
                </Typography>
                <Button
                  secondary
                  small
                  title={
                    mediaLibraryItem?.title ??
                    intl.formatMessage({
                      id: 'dashboard.events.add.select_image',
                    })
                  }
                  onClick={() => setIsImagesModalOpen(true)}
                  data-testid="selectImageBtn"
                />
                <TextLine>
                  <FormattedMessage id="actions.or" />
                </TextLine>
                <MediaLibrary
                  opened={isImagesModalOpen}
                  handleClose={() => setIsImagesModalOpen(false)}
                  items={mediaLibraryImages}
                  onSearchChange={setImagesSearch}
                  search={imagesSearch}
                  title={intl.formatMessage({id: 'media_library.edit_image'})}
                  onChange={item => {
                    clearErrors('imageUrl');
                    setValue('mediaLibraryItem', item);
                    updateChangedField('mediaLibraryItem');
                  }}
                />
                <ImageField
                  input={{
                    name: 'categoryImage',
                    onChange: (image: IImageField) => {
                      clearErrors('imageUrl');
                      setValue('categoryImage', image);
                      updateChangedField('categoryImage');
                    },
                    value: categoryImage,
                  }}
                  onDelete={() => {
                    setValue('categoryImage', null);
                  }}
                  wrapperClassName="form-group"
                />
                <span
                  id="imageUrl-field"
                  className="MuiFormHelperText-root Mui-error error"
                >
                  {errors.imageUrl?.message}
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
