import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {
  addOrRemoveSelectedId,
  addOrRemoveSelectedIds,
  clearSelectedIds,
  selectSelectedIds,
} from '../../../../store/slices/tableSelectionSlice';
import {useCallback, useEffect, useState} from 'react';
import {
  useBulkUpdateEventsLiveStateMutation,
  useBulkUpdateEventsPremiumStateMutation,
  useLazyEventListQuery,
} from '../../../../services/eventsApi';
import {
  hideProgressDialog,
  showProgressDialog,
} from '../../../../store/slices/appSlice';
import {BulkActionType} from '../../../Widgets/DataTable/BulkActions';
import {EventsListOptions} from './EventList';
import moment from 'moment';

type EventListProps = {
  listCount: number;
  options: EventsListOptions;
};

export function useEventsBulkActions({listCount, options}: EventListProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedItems = useSelector(selectSelectedIds);

  const [successOperation, setSuccessOperation] = useState(false);

  const [successCount, setSuccessCount] = useState(0);
  const [ignoredEvents, setIgnoredEvents] = useState<
    {
      field1: string;
      field2: string;
      msg: string;
    }[]
  >([]);

  const [
    updatePremium,
    {isSuccess: isUpdatePremiumSuccess, data: updatePremiumData},
  ] = useBulkUpdateEventsPremiumStateMutation();

  const [updateLive, {isSuccess: isUpdateLiveSuccess, data: updateLiveData}] =
    useBulkUpdateEventsLiveStateMutation();

  const [getEventsList] = useLazyEventListQuery();

  const clearOperation = useCallback(() => {
    setSuccessCount(0);
    setIgnoredEvents([]);
  }, []);

  const addSelectedItemsIds = (id: number) => {
    dispatch(addOrRemoveSelectedId(id));
  };

  const setSelectedItemsAll = (ids: number[]) => {
    if (selectedItems.length === listCount) {
      dispatch(clearSelectedIds());
      return;
    }
    dispatch(addOrRemoveSelectedIds(ids));
  };

  const onSelectAllListItems = async () => {
    dispatch(showProgressDialog());
    const events = await getEventsList({
      ...options,
      perPage: listCount,
      page: 1,
    }).unwrap();
    const ids = events.data.map(event => event.id);
    dispatch(hideProgressDialog());
    dispatch(addOrRemoveSelectedIds(ids));
  };

  const onClearSelectedItems = useCallback(() => {
    dispatch(clearSelectedIds());
  }, [dispatch]);

  useEffect(() => {
    clearOperation();
    if (isUpdatePremiumSuccess && updatePremiumData) {
      setSuccessCount(updatePremiumData.updatedCount);
      setSuccessOperation(true);
      updatePremiumData.ignoredEvents?.length &&
        setIgnoredEvents(
          updatePremiumData.ignoredEvents.map(item => {
            const formattedValue = moment(item.date).format('DD MMM YYYY');
            return {
              field1: item.title!,
              field2: formattedValue,
              msg: intl.formatMessage({
                id: 'dashboard.events.list.bulkActionErrors.cannot_set_as_premium',
              }),
            };
          })
        );
    }
  }, [clearOperation, intl, isUpdatePremiumSuccess, updatePremiumData]);

  useEffect(() => {
    clearOperation();
    if (isUpdateLiveSuccess && updateLiveData) {
      setSuccessCount(updateLiveData.updatedCount);
      setSuccessOperation(true);
    }
  }, [clearOperation, isUpdateLiveSuccess, updateLiveData]);

  const bulkActions: BulkActionType[] = [
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.bulkActions.set_as_premium',
      }),
      value: 'set_as_premium',
      typesPlaceholder: intl.formatMessage({
        id: 'dashboard.events.list.bulkTypes.placeholder',
      }),
      types: [
        {
          label: intl.formatMessage({
            id: 'dashboard.events.list.bulkTypes.immediately',
          }),
          value: 'immediately',
          values: [],
          applyFun: () => {
            setSuccessOperation(false);
            updatePremium({
              ids: selectedItems,
              isPremium: true,
              premiumDaysBeforeEvent: 0,
              showProgressDialog: true,
            });
          },
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.events.list.bulkTypes.custom_date',
          }),
          value: 'custom',
          valueText: intl.formatMessage({
            id: 'dashboard.events.list.bulkValues.textAfter',
          }),
          values: Array.from({length: 15}, (_, i) => {
            const value = i + 1;
            return {
              label: value.toString(),
              value: value.toString(),
              applyFun: () => {
                setSuccessOperation(false);
                updatePremium({
                  ids: selectedItems,
                  isPremium: true,
                  premiumDaysBeforeEvent: Number(value),
                  showProgressDialog: true,
                });
              },
            };
          }),
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.bulkActions.remove_as_premium',
      }),
      value: 'remove_as_premium',
      types: [],
      applyFun: () => {
        setSuccessOperation(false);
        updatePremium({
          ids: selectedItems,
          isPremium: false,
          premiumDaysBeforeEvent: 0,
          showProgressDialog: true,
        });
      },
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.bulkActions.set_as_live',
      }),
      value: 'set_as_live',
      types: [],
      applyFun: () => {
        setSuccessOperation(false);
        updateLive({
          ids: selectedItems,
          live: true,
          showProgressDialog: true,
        });
      },
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.bulkActions.set_as_offline',
      }),
      value: 'set_as_offline',
      types: [],
      applyFun: () => {
        setSuccessOperation(false);
        updateLive({
          ids: selectedItems,
          live: false,
          showProgressDialog: true,
        });
      },
    },
  ];

  return {
    selectedItems,
    bulkActions,
    addSelectedItemsIds,
    setSelectedItemsAll,
    onSelectAllListItems,
    onClearSelectedItems,
    clearOperation,
    successCount,
    ignoredEvents,
    isBulkActionSuccess: successOperation,
  };
}
