import {useHistory} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import SearchForm from '../../Form/NonReduxSearchForm';
import DataTable from '../../Widgets/DataTable';
import useSeatmapList from './hooks/useSeatmapList';
import * as urls from '../../../constants/urls';
import {PlusRoundedDangerIcon} from '../../../constants/images';
import {IAPIListOptions} from '../../../services/eventsApi';
import {getPaginationURLParams} from '../../../utils/filters';
import {useEffect, useState} from 'react';
import usePermission from '../../../Auth/usePermission';
import {SeatmapPermissions} from '../../../constants/permissions';
import PermissionContainer from '../../Common/PermissionContainer';
import useTableSort from '../hooks/useTableSort';
import {useSearch} from '../../../hooks/common/useSearch';

interface SeatmapProps {
  onAddClick: () => void;
  scrollToTop: () => void;
}

const SeatmapList = ({onAddClick, scrollToTop}: SeatmapProps) => {
  const history = useHistory();
  const {searchText, setSearchText} = useSearch();
  const [loader, setLoader] = useState(false);

  const [seatmapListOptions, setSeatmapListOptions] = useState<IAPIListOptions>(
    getPaginationURLParams()
  );

  const {HeaderCells, rows, isLoading, total, currentPage} =
    useSeatmapList(seatmapListOptions);

  useEffect(() => {
    scrollToTop();
  }, [currentPage, scrollToTop]);

  const {hasPermission} = usePermission();
  const {handleSortChange} = useTableSort();

  const handleRowClick = (seatmapId: string) => {
    history.push(urls.SEATMAP_DETAILS_PATH.replace(':id', seatmapId));
  };

  const updateSeatmapListOptions = (payload: any) => {
    setLoader(true);
    setSeatmapListOptions(prevState => ({
      ...prevState,
      ...(payload.page && {page: payload.page}),
      ...(payload.rowsPerPage && {perPage: payload.rowsPerPage}),
      ...(payload.sort.by && {
        sort: payload.sort.by,
      }),
      ...(payload.sort.direction && {direction: payload.sort.direction}),
    }));
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const canAddSeatmap = hasPermission(SeatmapPermissions.addSeatmap);

  return (
    <div className="dashboard-content">
      <PermissionContainer permissions={[SeatmapPermissions.seeSeatmapList]}>
        <Row className="align-items-center justify-content-between search-row py-1">
          <Col className="col-xl-4 col-lg-8 d-flex">
            <SearchForm
              loading={isLoading}
              search={searchText}
              onChange={(value: string) => {
                setSearchText(value);
              }}
              onSubmit={() => {
                setSeatmapListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: searchText,
                }));
              }}
              onClear={() => {
                setSeatmapListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: '',
                }));
              }}
              SearchProps={{
                buttonProps: {
                  onClick: () => {},
                },
              }}
            />
          </Col>
        </Row>
        <DataTable
          className="seatmap-list"
          canSelect={false}
          empty={rows.length === 0}
          loading={isLoading || loader}
          clickableRow={hasPermission(SeatmapPermissions.seeSeatmapDetails)}
          onRowClick={
            hasPermission(SeatmapPermissions.seeSeatmapDetails)
              ? (seatmapId: string) => handleRowClick(seatmapId)
              : undefined
          }
          {...(canAddSeatmap && {
            actionButton: {
              variant: 'filled',
              size: 'sm',
              children: <PlusRoundedDangerIcon />,
              onClick: onAddClick,
            },
          })}
          headCells={HeaderCells.map(item => ({
            ...item,
            numeric: false,
            disablePadding: false,
          }))}
          rows={rows}
          onChange={(payload: any) => {
            updateSeatmapListOptions(payload);
          }}
          total={total}
          currentPage={Number(currentPage)}
          defaultRowPerPage={seatmapListOptions.rowsPerPage}
          handleParameterChange={handleSortChange}
          defaultOrder={seatmapListOptions.direction}
          defaultOrderBy={seatmapListOptions.sort}
        />
      </PermissionContainer>
    </div>
  );
};

export default SeatmapList;
