import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';
import {ICategory} from './types';

export type GetCategoriesQueryParam = {} & FeedbackParams;

export type GetCategoryQueryParam = {
  id: number;
} & FeedbackParams;

export type AddCategoryQueryParam = {
  body: Partial<ICategory> & {domainIds: number[]};
} & FeedbackParams;

export type UpdateCategoryQueryParam = {
  id: number;
  body: Partial<ICategory> & {id: number; domainIds: number[]};
} & FeedbackParams;

export type DeleteCategoryQueryParam = {
  id: number;
} & FeedbackParams;

export const categoryApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    getCategories: builder.query<ICategory[], GetCategoriesQueryParam>({
      query: () => 'categories',
    }),
    getCategory: builder.query<ICategory, GetCategoryQueryParam>({
      query: ({id}) => `categories/${id}`,
    }),
    addCategory: builder.mutation<ICategory, AddCategoryQueryParam>({
      query: ({body}) => ({
        url: 'categories',
        method: 'POST',
        body,
      }),
    }),
    editCategory: builder.mutation<ICategory, UpdateCategoryQueryParam>({
      query: ({id, body}) => ({
        url: `categories/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCategory: builder.mutation<ICategory, DeleteCategoryQueryParam>({
      query: ({id}) => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
