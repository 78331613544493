import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Box from '@material-ui/core/Box';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import DataTable from '../Widgets/DataTable';
import ArrowLink from '../Common/ArrowLink';
import SellerMarks from '../Sales/Details/SellerMark';
import StateIndicator from '../Common/StateIndicator';
import * as actionTypes from '../../constants/actionTypes';
import * as urls from '../../constants/urls';
import {
  formatCurrencyValue,
  getPaymentStatusAndType,
  getResourceId,
} from '../../utils/functions';
import useTicketHelper from '../../hooks/ticket/useTicketHelper';
import {CURRENCY_SYMBOL} from '../../constants/currencies';
import EventDate from '../Common/EventDate';
import {parseKeywordSearchValue, parseSort} from '../../utils/transactionUtil';
import clsx from 'clsx';
import IconButton from '../Common/IconButton';
import {FILTER_ICON, RefreshSearchResultsIcon} from '../../constants/images';
import usePaymentFilter from '../../hooks/transaction/usePaymentFilter';
import KeywordSearchForm, {KEYWORD_FIELD} from '../Form/KeywordSearchForm';
import useSearchParams from '../../hooks/utils/useSearchParams';
import {
  countActiveFilters,
  getParamsFromUrl,
  getUrlSearchParams,
} from '../../utils/filters';
import useDataTableState from '../../hooks/table/useDatatableState';
import Filters from '../Widgets/Filters';
import {get} from 'lodash';
import useFilters from '../../hooks/common/useFilters';
import ActiveFilters from '../Widgets/ActiveFilters';

const DEFAULT_TABLE_STATE = {
  orderDirection: 'DESC',
  orderBy: 'createdAt',
  page: 1,
  itemsPerPage: 25,
};

const defaultParamsValue = {
  ...DEFAULT_TABLE_STATE,
  orderDirection: DEFAULT_TABLE_STATE.orderDirection.toLocaleLowerCase(),
};

const useStyles = makeStyles(() => ({
  arrowLink: {
    width: 'fit-content !important',
  },
}));

const PaymentList = props => {
  const {items, totalItems, loading, loaded} = useSelector(
    state => state.transactionPaymentReducer
  );
  const bindLocation = true;
  const {filterItems: rawFilterItems} = usePaymentFilter({bindLocation});
  const filterItems = useMemo(() => {
    const res = rawFilterItems.map(item => ({
      ...item,
      ...(['eventName', 'eventDate', 'competitionName'].includes(item.name)
        ? {
            outputName: `sale.saleMetadata.${item.name}`,
          }
        : {}),
    }));

    const index = res.findIndex(item => item.name === 'saleHex');
    if (index > -1) {
      res[index].outputName = 'sale.saleMetadata.referenceHexa';
    }

    return res;
  }, [rawFilterItems]);
  const intl = useIntl();
  const {formatFilters} = useFilters('v2', {dateVersion: 'v2'});

  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);
  const {getSellerMarks} = useTicketHelper();
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const filterFormat = useCallback(
    filterValues => {
      const res = formatFilters(filterValues, filterItems);

      if (res.seller && Array.isArray(res.seller)) {
        for (const sellerOption of res.seller) {
          res[`sale.seller.${sellerOption}`] = true;
        }
        delete res.seller;
      }

      return res;
    },
    [filterItems, formatFilters]
  );

  const [rows, setRows] = useState([]);
  const [countItems, setCountItems] = useState(totalItems);

  /**
   * This functions should be used to arrange or create each row for the list
   * @param transaction
   * @returns {{amount: *, event_date: string, description: {line2: string, line1: string}, id: *, type: {type: *}, user: {user: {name: string, link: string}}, payment_date: string}}
   */
  const arrangeRow = useCallback(
    transaction => {
      /**
       * Types allowed
       *  From the specs we can see this
       *
       *  "Payment" - Show status as per - Sales
       *  "Refund" - Show status as per - Refund flow
       *  "Penalty" - Show status as per - Penalty flow
       *  "Seller payment" - Payment flow
       *
       *  Type is on of: Transaction, SalePenalty, SellerCharge, BuyerRefund, PlatformRefund
       **/

      let userDetails = {user: {}};
      const type = transaction['@type'];
      const sale = transaction.sale || {};
      const saleData = sale.data || {};
      //Currency default to GPB
      const saleCurrency = saleData.currency || 'GBP';
      const currencySymbol = CURRENCY_SYMBOL[saleCurrency];
      const amount = `${currencySymbol}${formatCurrencyValue(transaction['amount'])}`;
      const eventName = get(sale, 'saleMetadata.eventName');
      const eventDate = get(sale, 'saleMetadata.eventDate');
      const fanpassEvent = eventDate
        ? {
            date: eventDate,
            postponed: get(sale, 'saleMetadata.eventPostponed'),
          }
        : null;
      const buyer = sale.buyer;
      const seller = sale.seller;

      const getDescription = () => {
        let line1 = 'Payment for: Sales ID ';
        const line2 = eventName ? `Event: ${eventName}` : '';

        if (type === 'SellerCharge') {
          line1 = 'Seller payment for: Sales ID ';
        } else if (type === 'PlatformRefund' || type === 'BuyerRefund') {
          line1 = 'Refund for: Sales ID ';
        } else if (type === 'SalePenalty') {
          line1 = 'Penalty for: Sales ID ';
        }
        const refHexa = get(sale, 'saleMetadata.referenceHexa');
        line1 += refHexa ? `#${refHexa}` : 'N/A';

        return {
          line1,
          line2,
        };
      };

      if (
        type === 'Transaction' ||
        type === 'BuyerRefund' ||
        type === 'PlatformRefund'
      ) {
        userDetails = {
          user: {
            id: getResourceId(buyer),
            name: buyer ? `${buyer.firstname} ${buyer.lastname}` : '',
            link: buyer
              ? urls.USER_DETAILS_PATH.replace(':id', getResourceId(buyer))
              : '',
          },
        };
      } else {
        userDetails = {
          user: {
            id: getResourceId(seller),
            name: seller ? `${seller.firstname} ${seller.lastname}` : '',
            link: seller
              ? urls.USER_DETAILS_PATH.replace(':id', getResourceId(seller))
              : '',
          },
          options: getSellerMarks(seller || {}),
        };
      }

      return {
        id: transaction['@id'],
        userName: userDetails,
        transactionType: getPaymentStatusAndType(transaction, formatMessage),
        description: getDescription(),
        amount,
        eventDate: <EventDate event={fanpassEvent} />,
        createdAt: transaction.createdAt
          ? moment(transaction.createdAt).format('DD MMM YYYY')
          : '',
      };
    },
    [formatMessage, getSellerMarks]
  );

  // Used to create the rows to show into the table
  useEffect(() => {
    // If finish leading then show fill the tables with rows
    if (items.length === 0) {
      setRows([]);
      setCountItems(0);
    } else if (!loading) {
      const tempRow = items.map(transaction => arrangeRow(transaction));
      setCountItems(Number(totalItems));
      setRows(tempRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, totalItems, loading]);

  // Header cells items used in the list
  const headerCells = useMemo(
    () => [
      {
        name: 'userName',
        label: formatMessage({id: 'dashboard.transactions.user_name'}),
        renderValue: ({user, options}) => (
          <div className="ps-2">
            <Box mb={0.3}>
              <ArrowLink
                className={classes.arrowLink}
                title={user.name}
                url={user.link}
              />
            </Box>
            {options && <SellerMarks id={user.id} items={options} />}
          </div>
        ),
        collapse: false,
        width: '20%',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'transactionType',
        label: formatMessage({id: 'dashboard.transactions.type'}),
        renderValue: ({type, status}) => (
          <div className="d-flex flex-wrap align-items-end">
            <span style={{marginRight: 8}}>{type}</span>
            {status && (
              <StateIndicator {...status} style={{marginBottom: -1}} />
            )}
          </div>
        ),
        collapse: false,
        width: '15%',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'description',
        label: formatMessage({id: 'dashboard.transactions.description'}),
        renderValue: ({line1, line2}) => (
          <div className="d-flex flex-column">
            <span>{line1}</span>
            <span>{line2}</span>
          </div>
        ),
        collapse: true,
        width: '20%',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'amount',
        label: formatMessage({id: 'dashboard.transactions.amount'}),
        collapse: true,
        width: '70px',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'eventDate',
        label: formatMessage({id: 'dashboard.transactions.event_date'}),
        collapse: true,
        width: '80px',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'createdAt',
        label: formatMessage({id: 'dashboard.transactions.transaction_date'}),
        collapse: true,
        width: '85px',
        numeric: false,
        disablePadding: false,
      },
    ],
    []
  );

  const [searchParams] = useSearchParams();
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState([]);
  const [ready, setReady] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      const {filterValues} = getParamsFromUrl(searchParams);
      setActiveFiltersCount(
        countActiveFilters(
          filterItems.map(item => item.name),
          filterValues
        )
      );
      setReady(true);
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, filterItems]);

  const getResults = useCallback(
    p => {
      const {q, filterValues, page, rowsPerPage, sort} = getParamsFromUrl(
        p ?? searchParams,
        defaultParamsValue
      );
      dispatch({
        type: actionTypes.API_TRANSACTION_PAYMENT_LIST_REQUESTED,
        payload: {
          q: parseKeywordSearchValue(q),
          filters: filterFormat(filterValues),
          sort: parseSort(sort),
          page,
          rowsPerPage,
        },
      });
    },
    [dispatch, filterFormat, searchParams]
  );

  const tableState = useDataTableState({
    defaultValues: DEFAULT_TABLE_STATE,
    bindQueryParams: true,
    onChange: p => getResults(p),
  });

  const onFilter = useCallback(
    (filters, p) => {
      getResults(p);
      setFilterOpened(false);
    },
    [getResults]
  );

  const onSearchParamsChange = useCallback(
    (
      activeFiltersForDisplay: {id: string; title: any; values: any}[],
      applied: boolean,
      cleared: boolean
    ) => {
      // Set active filters to pass to ActiveFilters component
      // Do this when any change to searchParams is made,
      // so we're not only listening to the applyFilters action
      if (applied && !cleared) {
        // Set active filters that have been applied to the search query
        setActiveFilters(activeFiltersForDisplay);
      } else if (cleared) {
        // Clear active filters
        setActiveFilters([]);
      }
    },
    []
  );

  useEffect(() => {
    if (filterOpened) {
      document.body.classList.add('right-modal-open');
    } else {
      document.body.classList.remove('right-modal-open');
    }
  }, [filterOpened]);

  const onFilterClose = useCallback(() => {
    setFilterOpened(false);
  }, [setFilterOpened]);

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    getResults(getUrlSearchParams(searchParams));

    setTimeout(() => {
      if (!loading) {
        setIsRefreshing(false);
      }
    }, 500);
  }, [getResults]);

  useEffect(() => {
    if (ready) {
      getResults();
    }
  }, [ready]);

  return (
    <>
      <Filters
        form="PaymentListFilterForm"
        opened={filterOpened}
        translations={{
          filter: intl.formatMessage({id: 'actions.filter'}),
          close: intl.formatMessage({id: 'actions.close'}),
          clear: intl.formatMessage({id: 'actions.clear'}),
          apply: intl.formatMessage({id: 'actions.apply'}),
        }}
        items={filterItems}
        onClose={onFilterClose}
        onFilter={onFilter}
        onSearchParamsChange={onSearchParamsChange}
        onClear={onRefresh}
        filterAdditionalParams={{page: 1}}
        clearAdditionalParams={{[KEYWORD_FIELD]: '', page: 1}}
      />

      <div
        className={clsx(
          'row align-items-center justify-content-between search-row'
        )}
      >
        <div className="col-xl-4 col-lg-8 d-flex">
          {/* Search */}
          <KeywordSearchForm
            updateUrlOnChange
            additionalParams={{page: 1}}
            onSearch={(v, p) => getResults(p)}
          />
          {/* Refresh button */}
          <Box ml={1} mt={1}>
            <IconButton
              variant="filled"
              size="xs"
              onClick={onRefresh}
              style={{
                transform: isRefreshing ? 'rotate(360deg)' : 'none',
                transition: 'transform 0.5s',
              }}
            >
              <RefreshSearchResultsIcon style={{width: 18, height: 14}} />
            </IconButton>
          </Box>
          {/* Filter Button on mobile view only */}
          <Box ml={1} className="d-block d-lg-none">
            <IconButton
              className="m-0"
              onClick={() => setFilterOpened(true)}
              {...(activeFiltersCount ? {numberLabel: activeFiltersCount} : {})}
            >
              {FILTER_ICON}
            </IconButton>
          </Box>
        </div>
        {/* Button toolbar */}
        <div className="col-auto d-none d-lg-block">
          {/* Filter button */}
          <IconButton
            //{...(activeFilters ? {numberLabel: activeFilters} : {})}
            className="me-0"
            onClick={() => setFilterOpened(true)}
            {...(activeFiltersCount ? {numberLabel: activeFiltersCount} : {})}
          >
            {FILTER_ICON}
          </IconButton>
        </div>
      </div>
      <ActiveFilters items={activeFilters} onFilterRemoved={onRefresh} />
      {/* Data Table */}
      <DataTable
        className="transaction-list"
        canSelect={false}
        empty={loaded && (!items || items.length === 0)}
        loading={loading}
        headCells={headerCells}
        rows={rows}
        total={countItems}
        clickableRow={false}
        handleParameterChange={tableState.handleDataTableParamChange}
        defaultOrder={tableState.orderDirection.toLowerCase()}
        defaultOrderBy={tableState.orderBy}
        defaultRowPerPage={tableState.pageSize}
        currentPage={tableState.page}
      />
    </>
  );
};

PaymentList.propTypes = {
  filters: PropTypes.object,
};

export default PaymentList;
