export interface IGoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface IAutocompleteResult {
  address_components: IGoogleAddressComponent[];
  formatted_address: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  place_id: string;
  html_attributions: string[];
}

export function getAddress(result: IAutocompleteResult) {
  const addressTypesInOrder = [
    'geocode',
    'street_address',
    'route',
    'administrative_area_level_7',
    'administrative_area_level_6',
    'administrative_area_level_5',
    'administrative_area_level_4',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'administrative_area_level_1',
    'sublocality',
    'locality',
    'country',
  ];

  const addressComponents = result.address_components;
  return addressTypesInOrder
    .map(type => getAddressComponentTypeLongName(addressComponents, type))
    .filter(Boolean)
    .join(', ');
}

export function getLatLng(result: IAutocompleteResult) {
  return {
    lat: result.geometry.location.lat(),
    lng: result.geometry.location.lng(),
  };
}

export function getCounty(result: IAutocompleteResult) {
  const addressComponents = result.address_components;
  return getAddressComponentTypeLongName(addressComponents, 'country');
}

export function getCity(result: IAutocompleteResult) {
  const addressComponents = result.address_components;
  return (
    getAddressComponentTypeLongName(addressComponents, 'locality') ||
    getAddressComponentTypeLongName(addressComponents, 'postal_town') ||
    getAddressComponentTypeLongName(
      addressComponents,
      'administrative_area_level_3'
    ) ||
    getAddressComponentTypeLongName(
      addressComponents,
      'administrative_area_level_2'
    ) ||
    getAddressComponentTypeLongName(
      addressComponents,
      'administrative_area_level_1'
    ) ||
    getAddressComponentTypeLongName(addressComponents, 'sublocality') ||
    getAddressComponentTypeLongName(addressComponents, 'sublocality_level_1') ||
    getAddressComponentTypeLongName(addressComponents, 'sublocality_level_2') ||
    getAddressComponentTypeLongName(addressComponents, 'sublocality_level_3') ||
    getAddressComponentTypeLongName(addressComponents, 'sublocality_level_4')
  );
}

function getAddressComponentTypeLongName(
  addressComponents: IGoogleAddressComponent[],
  type: string
) {
  const component = addressComponents.find(component =>
    component.types.includes(type)
  );
  return component?.long_name;
}
