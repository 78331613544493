import axios from 'axios';
import {useCallback, useState} from 'react';

export function useUploadToPresignedUrlMutation() {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const upload = useCallback(async ({presignedUrl, file, contentType}) => {
    setIsUploading(true);
    return axios
      .put(presignedUrl, file, {
        transformRequest: (data, headers) => {
          if (contentType) {
            headers['Content-Type'] = contentType;
          }
          delete headers.common.Authorization;
          return data;
        },
        onUploadProgress: progressEvent => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, []);

  return {
    upload,
    progress,
    isUploading,
  };
}
