import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../Seatmap/types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ISeatmapSection} from '../../../services/eventsApi';
import {slugify} from '../Seatmap/helpers';

interface SvgViewerProps {
  src: string;
  watch?: UseFormWatch<SeatmapFormFields>;
  sections?: ISeatmapSection[];
}

export function SeatmapSvgViewer(props: Readonly<SvgViewerProps>) {
  const {src, watch, sections: seatmapSections} = props;

  const [svg, setSvg] = useState<SVGElement | null>(null);
  const svgWrapperRef = useRef<HTMLDivElement | null>(null);
  const srcCacheRef = useRef<string | null>(null);

  const watchedSections = watch?.('sections');

  const sections = useMemo(
    () => watchedSections ?? seatmapSections ?? [],
    [seatmapSections, watchedSections]
  );

  const colors = sections.map((section, i) => section.color);

  const fetchSvg = useCallback(async () => {
    const response = await fetch(src, {
      cache: 'no-cache',
      referrerPolicy: 'origin-when-cross-origin',
    });
    const text = await response.text();
    return new DOMParser().parseFromString(text, 'image/svg+xml');
  }, [src]);

  const styleBlockElement = useCallback(
    (blockElement: HTMLElement, style: Record<string, string>) => {
      Object.entries(style).forEach(([key, value]) => {
        blockElement.style.setProperty(key, value);
      });
      blockElement.querySelectorAll('*').forEach(element => {
        if (element.tagName === 'text') {
          (element as HTMLElement).style.setProperty('fill', 'currentColor');
          (element as HTMLElement).style.setProperty('stroke', 'none');
          return;
        }
        Object.entries(style).forEach(([key, value]) => {
          (element as HTMLElement).style.setProperty(key, value);
        });
      });
    },
    []
  );

  const traverseSVGSections = useCallback(
    callback => {
      if (!svg || !sections?.length) {
        return;
      }
      sections.forEach(section => {
        const slugifiedSection = section.slug ? section : slugify(section);
        const sectionElement = svg.querySelector(
          `[data-category="${slugifiedSection.slug}"]`
        );
        if (!sectionElement) return;
        callback(sectionElement, slugifiedSection);
      });
    },
    [sections, svg]
  );

  const traverseSVG = useCallback(
    callback => {
      traverseSVGSections(
        (sectionElement: HTMLElement, section: ISeatmapSection) => {
          callback({sectionElement, section});
        }
      );
    },
    [traverseSVGSections]
  );

  useEffect(() => {
    if (!src || (svg && srcCacheRef.current === src)) return;
    srcCacheRef.current = src;
    fetchSvg().then(result => {
      setSvg(result.documentElement as any as SVGElement);
    });
  }, [fetchSvg, src, svg]);

  useEffect(() => {
    if (!svg || !sections.length) return;
    traverseSVG(
      ({
        sectionElement,
        section,
      }: {
        sectionElement: HTMLElement;
        section: ISeatmapSection;
      }) => {
        styleBlockElement(sectionElement, {
          fill: section.color,
          stroke: '#000000',
        });
      }
    );
  }, [sections.length, styleBlockElement, svg, traverseSVG, colors]);

  useEffect(() => {
    if (!svg || !svgWrapperRef.current) return;
    svgWrapperRef.current.innerHTML = '';
    const isSvgAlreadyAppended = !!svgWrapperRef.current.querySelector('svg');
    if (!isSvgAlreadyAppended) {
      svgWrapperRef.current?.appendChild(svg);
    }
  }, [svg]);

  return <div ref={svgWrapperRef} />;
}
