import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type tableSelectStateType =
  | 'closed'
  | 'selected'
  | 'all'
  | 'ended'
  | 'triggered'
  | 'err_details';
export interface TableSelectionState {
  state: tableSelectStateType;
  selectedIds: number[];
}

const initialState: TableSelectionState = {
  selectedIds: [],
  state: 'closed',
};

export const tableSelectionSlice = createSlice({
  name: 'tableSelection',
  initialState,
  reducers: {
    setTableSelectionState: (
      state,
      action: PayloadAction<tableSelectStateType>
    ) => {
      state.state = action.payload;
    },
    setSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    addOrRemoveSelectedId: (state, action: PayloadAction<number>) => {
      if (state.selectedIds.includes(action.payload)) {
        state.selectedIds = state.selectedIds.filter(
          id => id !== action.payload
        );
      } else {
        state.selectedIds.push(action.payload);
      }
    },
    addOrRemoveSelectedIds: (state, action: PayloadAction<number[]>) => {
      if (action.payload.every(id => state.selectedIds.includes(id))) {
        state.selectedIds = state.selectedIds.filter(
          id => !action.payload.includes(id)
        );
      } else {
        state.selectedIds = Array.from(
          new Set([...state.selectedIds, ...action.payload])
        );
      }
    },
    clearSelectedIds: state => {
      state.selectedIds = [];
    },
  },
});

export const {
  setTableSelectionState,
  setSelectedIds,
  addOrRemoveSelectedId,
  addOrRemoveSelectedIds,
  clearSelectedIds,
} = tableSelectionSlice.actions;

export const selectSelectedIds = (state: {
  tableSelection: TableSelectionState;
}) => state.tableSelection.selectedIds;

export const selectTableSelectionState = (state: {
  tableSelection: TableSelectionState;
}) => state.tableSelection.state;

export default tableSelectionSlice.reducer;
