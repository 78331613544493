import React, {Fragment, MouseEvent} from 'react';
import {makeStyles} from '@material-ui/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import BaseInput from './BaseInput';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import {SelectProps} from '@material-ui/core/Select/Select';
import {ITheme} from '../../constants/theme'; /* Styles */

/* Styles */
const useStyles = makeStyles((theme: ITheme) => ({
  root: {},
  select: {
    '& .MuiSelect-root': {
      boxShadow: theme.shadows[0],
      borderRadius: 4,
      fontWeight: 'normal',
      fontSize: 14,
      paddingTop: 8,
      paddingBottom: 7,
      border: `1px solid ${theme.palette.text.border}`,
    },
    '& .MuiSelect-icon': {
      fontSize: 10,
      top: 13,
      right: 15,
    },
  },
  label: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    marginBottom: 0,
    zIndex: 10,
    left: 10,
    top: -14,
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
  },
  menu: {
    marginTop: 8,
    marginLeft: 0,
  },
  paper: {
    boxShadow: theme.shadows[1],
    maxHeight: 321,
    '& > ul': {
      padding: 0,
    },
    '&.cropped': {
      borderRadius: '0 0 8px 8px',
      maxHeight: 'calc(50vh - 50px)',
    },
  },
  menuItem: {
    fontSize: 14,
    padding: '12px 20px',
    borderTop: '1px solid #EDEDED',
    '&:first-child': {
      borderTop: 'none',
    },
  },
  chips: {
    overflow: 'hidden',
    '& .MuiChip-root + .MuiChip-root': {
      marginLeft: 5,
    },
  },
  chip: {
    color: theme.palette.green.main,
    backgroundColor: theme.palette.green.light,
    fontFamily: 'Averta Semibold',
    fontSize: 12,
    height: 20,
    padding: '3px 10px 3px 13px',
    overflow: 'hidden',
    '& .MuiChip-label': {
      padding: '1px 13px 0 0',
      whiteSpace: 'no-wrap',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xl')]: {
        padding: '1px 5px 0 0',
      },
    },
    '& .MuiChip-deleteIcon': {
      color: 'inherit',
      width: 14,
      height: 14,
      margin: 0,
    },
  },
  multiSelectItemsBelow: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingBottom: 10,
    margin: -4,
    '& .MuiChip-root': {
      margin: 4,
    },
  },
  placeholder: {
    color: theme.palette.secondary.light,
  },
}));

//Dropdown icon
const DropdownIcon = (props: {disabled?: boolean}) => (
  <SvgIcon
    viewBox="0 0 9 6"
    className={clsx('MuiSelect-icon', props.disabled && 'text-muted')}
  >
    <g
      id="Filter/Location"
      transform="translate(-191.000000, -246.000000)"
      fill="currentColor"
      stroke="currentColor"
    >
      <path
        d="M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407"
        id="Fill-1"
        transform="translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) "
      ></path>
    </g>
  </SvgIcon>
);

export interface MultiSelectProps extends Omit<SelectProps, 'onChange'> {
  values?: Array<string | number>;
  separated?: boolean;
  placeholder?: string;
  options: {label: string; value: string | number}[];
  iconDropdown?: boolean;
  reducedList?: boolean;
  onChange: (values: Array<string | number>) => void;
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    values = [],
    separated = false,
    placeholder = '',
    options,
    iconDropdown = false,
    reducedList = false,
    onChange,
    ...others
  } = props;

  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>
  ) => {
    const newValues = event.target.value as Array<string | number>;
    onChange(newValues);
  };

  const handleDelete = (remove: string | number) => {
    const newValues = values.filter(item => item !== remove);
    onChange(newValues);
  };

  return (
    <>
      <Select
        {...others}
        fullWidth
        displayEmpty
        variant="filled"
        className={classes.select}
        value={values}
        multiple
        // @ts-ignore
        input={<BaseInput />}
        // @ts-ignore
        IconComponent={() => (!iconDropdown ? null : <DropdownIcon />)}
        MenuProps={{
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          classes: {
            paper: classes.paper,
          },
        }}
        renderValue={
          !separated
            ? (selected: unknown) => (
                <div className={classes.chips}>
                  {Array.isArray(selected) &&
                    selected.map((value, index) => {
                      const item = options.find(item => item.value === value);
                      if (!item) return null;

                      return (
                        <>
                          {((reducedList && index === 0) || !reducedList) && (
                            <Chip
                              key={value}
                              label={item.label}
                              className={classes.chip}
                              onMouseDown={(e: MouseEvent) =>
                                e.stopPropagation()
                              }
                              // @ts-ignore
                              deleteIcon={<CloseIcon />}
                              onDelete={() => handleDelete(item.value)}
                            />
                          )}
                        </>
                      );
                    })}
                  {/*show number of rest*/}
                  {reducedList &&
                    Array.isArray(selected) &&
                    selected.length > 1 && (
                      <Chip
                        label={`+${selected.length - 1}`}
                        className={classes.chip}
                        onMouseDown={e => e.stopPropagation()}
                      />
                    )}
                </div>
              )
            : () => <span className={classes.placeholder}>{placeholder}</span>
        }
        onChange={handleChange}
      >
        {options.map(({label, value}, key) => (
          <MenuItem key={key} value={value} className={classes.menuItem}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {/*Show selected tags below*/}
      {separated && values && values.length > 0 && (
        <div className={classes.multiSelectItemsBelow}>
          {values.map(value => {
            const item = options.find(item => item.value === value);
            if (!item) return null;
            return (
              <Chip
                key={value}
                label={item.label}
                className={classes.chip}
                onMouseDown={(e: MouseEvent) => e.stopPropagation()}
                // @ts-ignore
                deleteIcon={<CloseIcon />}
                onDelete={() => handleDelete(item.value)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default MultiSelect;
