import {Box, Typography} from '@material-ui/core';
import React from 'react';
import CustomCheckbox from '../../../Common/Checkbox';
import {AutocompleteRenderOptionState} from '@mui/material/Autocomplete/Autocomplete';

export interface MultiSelectAutocompleteOptionProps
  extends Omit<React.HTMLAttributes<HTMLLIElement>, 'dangerouslySetInnerHTML'> {
  label: string;
  subLabel?: string;
  state: AutocompleteRenderOptionState;
}

export function MultiSelectAutocompleteOption(
  props: Readonly<MultiSelectAutocompleteOptionProps>
) {
  const {label, subLabel, state, ...rest} = props;

  return (
    <li className="cursor-pointer flex-row" {...rest}>
      <CustomCheckbox checked={state.selected} readOnly />
      <Box>
        <Typography variant="body2">{label}</Typography>
        {subLabel && (
          <Box mt={0.1}>
            <Typography variant="subtitle2" color="textSecondary">
              {subLabel}
            </Typography>
          </Box>
        )}
      </Box>
    </li>
  );
}
