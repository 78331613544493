import React, {forwardRef, MouseEventHandler} from 'react';
import TextField from '../../../Common/TextField';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import {Control} from 'react-hook-form';
import {FormHelperText} from '@material-ui/core';

type InputFieldProps = TextFieldProps & {
  errorMessage?: string;
  control?: Control<any, any>;
  defaultValue?: string;
  addonPosition?: 'start' | 'end';
  addon?: JSX.Element;
  onAddonClick?: MouseEventHandler;
  wrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    'data-testid'?: string;
  };
};

export const EnhancedInputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props: InputFieldProps, ref) => {
    const {label, wrapperProps, error, errorMessage, ...rest} = props;

    return (
      <div {...wrapperProps}>
        {typeof label === 'string' && (
          <span className="small-label pb-2">{label}</span>
        )}
        {label && typeof label !== 'string' && label}
        <TextField {...rest} error={error} variant="outlined" ref={ref} />

        {error && errorMessage && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </div>
    );
  }
);
