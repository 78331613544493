import {UpdateIcon} from '../../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {useTheme} from '@mui/material/styles';
import {ITheme} from '../../../../../constants/theme';
import moment from 'moment/moment';
import {useIntl} from 'react-intl';
import {fromUtcToUserTimezone} from '../../helpers';

export interface DateTimeCellProps {
  value: string | null;
  timeValue: string | null;
  dateUpdates?: string;
  isDateToBeConfirmed: boolean;
  isTimeToBeConfirmed: boolean;
}

export function DateTimeCell(props: Readonly<DateTimeCellProps>) {
  const {
    value,
    dateUpdates,
    timeValue,
    isDateToBeConfirmed,
    isTimeToBeConfirmed,
  } = props;
  const intl = useIntl();
  const theme: ITheme = useTheme();
  // Check if 'value' is a valid date
  const formattedValue = !isDateToBeConfirmed
    ? moment(value).format('DD MMM YYYY')
    : intl.formatMessage({
        id: 'dashboard.events.list.event_tab.tbc',
      });
  const formattedDateUpdates =
    dateUpdates && moment(dateUpdates).format('DD MMM YYYY | HH:mm');

  // Check if 'timeValue' is a valid time
  const formattedTimeValue = !isTimeToBeConfirmed
    ? moment(timeValue, 'HH:mm:ss').format('hh:mm A')
    : intl.formatMessage({
        id: 'dashboard.events.list.event_tab.tbc',
      });

  return (
    <div>
      {!(isDateToBeConfirmed && isTimeToBeConfirmed) ? (
        <div className="d-flex align-items-center">
          <Typography variant="body2" className="text-truncate mr-1">
            {formattedValue}
          </Typography>
          <span className="mx-1">|</span>
          <Typography variant="body2" className="text-truncate ml-1">
            {formattedTimeValue}
          </Typography>
        </div>
      ) : (
        <Typography variant="body2" className="text-truncate">
          {intl.formatMessage({
            id: 'dashboard.events.list.event_tab.date_and_time_tbc',
          })}
        </Typography>
      )}
      {formattedDateUpdates && (
        <div className="mt-1">
          <div className="d-inline-flex align-items-center w-100 mt-1">
            <div className="d-inline-flex">
              <UpdateIcon style={{width: 11, height: 11, marginBottom: 2}} />
            </div>
            <Typography
              style={{color: theme.palette?.blue?.main}}
              variant="body2"
              className="ps-1 font-bold-italic text-truncate"
            >
              {formattedDateUpdates}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}
