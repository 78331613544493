import {Typography} from '@mui/material';
import {Updates} from '../../components';
import React from 'react';
import {ICategory} from '../../../../../services/eventsApi';

export interface CategoryCellProps extends ICategory {
  categoryChange?: string;
}

export function CategoryCell(props: Readonly<CategoryCellProps>) {
  const {name, categoryChange} = props;
  return (
    <div>
      <Typography variant="body2" className="text-truncate">
        {name}
      </Typography>
      <Updates updatedValue={categoryChange} className="mt-1" />
    </div>
  );
}
