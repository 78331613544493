import {useRef, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as urls from '../../../../constants/urls';
import {IMG_BASE_URL} from '../../../../constants/urls';
import IconButton from '../../../Common/IconButton';
import BaseInput from '../../../Common/BaseInput';
import {ISeatmap, IVenue} from '../../../../services/eventsApi';
import ArrowLink from '../../../Common/ArrowLink';
import {useIntl} from 'react-intl';
import {ITheme} from '../../../../constants/theme';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme: ITheme) => ({
  select: (props: {disabled: boolean}) => ({
    width: '100%',
    backgroundColor: props.disabled
      ? theme.palette.background.additional
      : theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.border}`,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.background.additional,
    },

    '& li': {
      width: '95%',
      cursor: props.disabled ? 'initial' : 'pointer',
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },

    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      boxShadow: theme.shadows[0],
      padding: theme.spacing(0, 1),
      '&.MuiSelect-outlined': {
        backgroundColor: 'inherit',
        height: 55,
      },
    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
    },
    '&.invalid': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: theme.palette.error.light,
    },
  }),

  menu: {
    marginTop: theme.spacing(0.5),
  },
  menuItem: {
    padding: theme.spacing(1, 2),
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  paper: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 0),
    maxHeight: 321,
    '& > ul': {
      padding: '0 !important',
    },
  },
}));

export type SeatmapSelectProps = {
  label: string;
  formGroupClass?: string;
  selectClassName?: string;
  seatmaps?: ISeatmap[];
  venue: IVenue;
  onSelectSeatmap: (seatmapId: number) => void;
  selectedSeatmap?: ISeatmap;
  disabled?: boolean;
  isInvalid?: boolean;
};

type SeatmapOptionProps = {
  seatmap: ISeatmap;
  venue: IVenue;
  className: string;
  showArrowLink: boolean;
};

const SeatmapOption = ({
  seatmap,
  venue,
  showArrowLink,
  ...props
}: SeatmapOptionProps) => {
  const {imageUrl, name: seatmapName, id} = seatmap;
  const {city, country, name} = venue;
  return (
    <Box
      display="flex"
      alignItems="center"
      className="overflow-hidden ps-1 ps-lg-1"
      gridGap={15}
      width="100%"
    >
      <picture className="event-picture">
        <img
          className="img-fluid rounded-sm"
          src={imageUrl ?? `${IMG_BASE_URL}event-placeholder.jpg`}
          alt=""
        />
      </picture>
      <Box>
        <Typography variant="body2" className="mb-1">
          {seatmapName}
        </Typography>
        <Typography variant="body2" className="text-placeholder">
          {`${name} ${city}, ${country}`}
        </Typography>
      </Box>
      <Box ml="auto" mr={1}>
        {showArrowLink && (
          <IconButton
            color="default"
            size="medium"
            variant="action"
            style={{padding: '12px'}}
            className="m-0 bg-transparent shadow-none"
            onClick={e => {
              e.stopPropagation(); // Prevent the Select from closing
              window.open(
                urls.SEATMAP_DETAILS_PATH.replace(':id', id.toString()),
                '_blank'
              );
            }}
          >
            <ArrowLink
              title=""
              url={urls.SEATMAP_DETAILS_PATH.replace(':id', id.toString())}
              targetBlank
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const SeatmapSelect = ({
  label,
  seatmaps,
  formGroupClass,
  selectClassName,
  venue,
  selectedSeatmap,
  onSelectSeatmap,
  disabled,
  isInvalid,
}: SeatmapSelectProps) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const classes = useStyles({disabled: !!disabled});
  const toggleOpen = () => setOpen(!open);
  const intl = useIntl();
  const {trigger, clearErrors} = useFormContext();

  return (
    <div className={clsx('form-group', formGroupClass ?? 'mb-3')}>
      <Typography variant="subtitle2" className="text-placeholder mt-2 mb-2">
        {label}*
      </Typography>
      <Select
        disabled={disabled}
        data-testid="seatmap-select-input"
        id="seatmap-select-input"
        ref={ref}
        variant="outlined"
        open={open}
        onBlur={() => {
          void trigger('seatmapId');
        }}
        fullWidth
        placeholder={intl.formatMessage({
          id: 'dashboard.events.details.select_seatmap',
        })}
        value={selectedSeatmap?.id ? selectedSeatmap.id : null}
        className={clsx(classes.select, selectClassName, {
          invalid: isInvalid,
        })}
        //@ts-ignore
        input={<BaseInput />}
        MenuProps={{
          elevation: 0,
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          className: classes.menu,
          classes: {
            paper: classes.paper,
          },
          onBlur: () => {
            void trigger('seatmapId');
          },
          PaperProps: {
            style: {
              //@ts-ignore
              width: ref.current?.offsetWidth - 2,
            },
          },
        }}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        onChange={(e: any) => {
          e.stopPropagation();
          onSelectSeatmap(e.target.value);
          clearErrors('seatmapId');
        }}
        renderValue={(selectedId: unknown) => {
          const selected = seatmaps!.find(seatmap => seatmap.id === selectedId);
          return selected ? (
            <MenuItem
              value={selected.id}
              data-testid={`seatmap-select-input-${selected.id}`}
            >
              <SeatmapOption
                key={selected.id}
                venue={venue}
                seatmap={selected}
                className={classes.menuItem}
                showArrowLink={false}
              />
            </MenuItem>
          ) : null;
        }}
      >
        {seatmaps?.map(item => (
          <MenuItem
            value={item.id}
            data-testid={`seatmap-select-input-${item.id}`}
            key={item.id}
          >
            <SeatmapOption
              key={item.id}
              venue={venue}
              seatmap={item}
              className={classes.menuItem}
              showArrowLink={open}
            />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SeatmapSelect;
