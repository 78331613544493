import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

/**
 * Styled switch component
 */
const CustomSwitch = withStyles(theme => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    borderRadius: 20,
    '&.width48': {
      width: 48,
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translate(28px) !important',
      },
    },
    '&.MuiSwitch-sizeSmall': {
      width: 30,
      height: 16,
      borderRadius: 12,
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        width: 30,
        height: 16,
        borderRadius: 12,
      },
    },
  },
  switchBase: {
    padding: 2,
    color: `${theme.palette.common.white}!important`,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.green.main,
        border: 'none',
      },
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.green.main,
      border: '6px solid #fff',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&.small': {
      padding: 2,
      '&$checked': {
        transform: 'translateX(15px)',
      },
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(15px)',
        },
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
    '&.small': {
      width: 12,
      height: 12,
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        width: 12,
        height: 12,
      },
    },
  },
  track: {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.divider,
    transition: theme.transitions.create(['background-color', 'border']),
    opacity: 'unset!important',
  },
  checked: {
    '& + .MuiSwitch-track': {
      opacity: 'unset!important',
    },
  },
  disabled: {
    '&:not(.Mui-checked)': {
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.secondary.light}!important`,
        opacity: '0.25!important',
      },
    },
    '& + .MuiSwitch-track': {
      opacity: '0.4!important',
    },
  },
  focusVisible: {},
}))(({
  classes,
  disable = false,
  width48 = false,
  small = false,
  mobileSmall = false,
  handleOptionCheck,
  isChecked,
  option,
  ...props
}) => {
  // toggle switch state
  const changeValue = e => {
    handleOptionCheck(e.target.checked, option);
  };

  return (
    <Switch
      size={small ? 'small' : 'medium'}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: clsx(
          classes.root,
          width48 && 'width48',
          mobileSmall && 'mobile-small'
        ),
        switchBase: clsx(
          classes.switchBase,
          small && 'small',
          mobileSmall && 'mobile-small'
        ),
        thumb: clsx(
          classes.thumb,
          small && 'small',
          mobileSmall && 'mobile-small'
        ),
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      onChange={changeValue}
      checked={isChecked}
      disabled={disable}
      {...props}
    />
  );
});

CustomSwitch.propTypes = {
  /**
   * If true, the component is checked.
   */
  isChecked: PropTypes.bool,
  /**
   * If true the element is disabled
   */
  disabled: PropTypes.bool,
  /**
   * the width should be 48 instead of 40
   */
  width48: PropTypes.bool,
  /**
   * TRUE is size is small
   */
  small: PropTypes.bool,
  /**
   * Small on mobile
   */
  mobileSmall: PropTypes.bool,
  /**
   * Change checked state callback
   */
  onChange: PropTypes.func,
};

export default CustomSwitch;
