import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Container, Row} from 'reactstrap';
import Button from '../../../Common/Button';
import StatusIndicator from '../../../Common/StatusIndicator';
import {
  CalendarIcon,
  EventManualIcon,
  ImportedEventIcon,
  InfoPrimaryRedIcon,
  TicketIcon,
  UpdateIcon,
} from '../../../../constants/images';
import {
  DeleteEventQueryParam,
  EventStatus,
  IEvent,
  useDeleteEventMutation,
} from '../../../../services/eventsApi';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useSnackBar from '../../../../hooks/app/useSnackBar';
import ConfirmationModal from '../../../Common/ConfirmationModal';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import {EVENTS_LIST_PATH} from '../../../../constants/urls';
import moment from 'moment';
import {EventPermissions} from '../../../../constants/permissions';
import usePermission from '../../../../Auth/usePermission';
import {getEventStatus} from '../EventList/helpers';

export type EventDetailsHeaderProps = {
  event: IEvent;
};

const EventDetailsHeader = ({event}: EventDetailsHeaderProps) => {
  const intl = useIntl();
  const history = useHistory();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const {showMessage} = useSnackBar();

  const [deleteEvent, {isSuccess}] = useDeleteEventMutation();
  const {hasPermission} = usePermission();
  const isDeleteEventPermission = hasPermission(
    EventPermissions.deleteEventDetails
  );

  useEffect(() => {
    if (isSuccess) {
      history.replace(EVENTS_LIST_PATH);
    }
  }, [intl, isSuccess, history, showMessage]);

  const onDeleteConfirmed = useCallback(() => {
    if (!event) return;
    const eventData: DeleteEventQueryParam = {
      id: event.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.EVENT_DELETED_SUCCESSFULLY',
        });
      },
    };
    deleteEvent(eventData);
    setIsConfirmationOpen(false);
  }, [deleteEvent, intl, event]);

  const provider = event?.provider;
  const eventStatus = getEventStatus(event).toLowerCase();
  const isUpcomingEvent = getEventStatus(event) === EventStatus.UPCOMING;
  const elementClassName = 'd-inline-flex align-items-center gap-2';

  return (
    <Container fluid className="top-block bg-white">
      <div className="details-header event-details-header pt-1 pt-lg-fan-40">
        <Row className="title-block pb-4 pb-lg-3 gy-2">
          <Col>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start pb-2 pb-lg-fan-15">
              <Typography
                variant="h3"
                className="font-weight-bolder pe-lg-2"
                data-testid="header-event-name"
              >
                {event.title}
              </Typography>
              <StatusIndicator
                className=""
                status={isUpcomingEvent ? 'active' : 'inactive'}
                label={intl.formatMessage({
                  id: `dashboard.status.${eventStatus}`,
                })}
              />
            </div>
            <Row className="align-items-center justify-content-center justify-content-lg-start gx-3 gy-1 pb-2 pb-lg-2">
              {provider && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <ImportedEventIcon style={{width: 15, height: 15}} />
                    <Typography
                      variant="body2"
                      data-testid="header-event-source"
                    >
                      {intl.formatMessage({
                        id: 'dashboard.events.add.sourceImported',
                      })}
                    </Typography>
                  </div>
                </Col>
              )}
              {!provider && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <EventManualIcon style={{width: 15, height: 15}} />
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'dashboard.events.add.source_manual',
                      })}
                    </Typography>
                  </div>
                </Col>
              )}
              {provider?.changes && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <UpdateIcon
                      style={{width: 11, height: 11, marginBottom: 2}}
                    />
                    <Typography
                      variant="body2"
                      className="text-blue font-bold-italic"
                    >
                      <FormattedMessage id="dashboard.events.list.event_update" />
                    </Typography>
                  </div>
                </Col>
              )}
              {!event.seatmap.imageUrl && (
                <Col xs="auto">
                  <div className="d-flex align-items-center">
                    <InfoPrimaryRedIcon style={{width: 11, height: 11}} />
                    <Typography
                      color="primary"
                      className="ps-1 font-bold-italic text-truncate"
                      variant="body2"
                    >
                      <FormattedMessage id="dashboard.events.list.seat_map_missing" />
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="align-items-center justify-content-center justify-content-lg-start gx-3 gy-1">
              {provider && (
                <Col xs="auto" className="text-center">
                  <div className={elementClassName}>
                    <TicketIcon style={{width: 17, height: 15}} />
                    <Typography variant="body2" color="textSecondary">
                      {intl.formatMessage({
                        id: 'dashboard.events.add.source',
                      })}
                      :{' '}
                      {intl.formatMessage({
                        id: 'dashboard.events.list.switcher.tixstock',
                      })}{' '}
                      |{' '}
                      {intl.formatMessage({
                        id: 'dashboard.events.add.external_id',
                      })}
                      : {provider.id}
                    </Typography>
                  </div>
                </Col>
              )}
              {provider && (
                <Col lg="auto" className="text-center">
                  <div className={elementClassName}>
                    <CalendarIcon
                      style={{width: 15, height: 15, color: '#989898'}}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      data-testid="header-event-imported-date"
                    >
                      {intl.formatMessage({
                        id: 'dashboard.events.add.imported',
                      })}{' '}
                      {moment(provider.importDate).format('DD/MM/YYYY h:mm A')}
                    </Typography>
                  </div>
                </Col>
              )}
              {!provider && (
                <Col lg="auto" className="text-center">
                  <div className={elementClassName}>
                    <CalendarIcon
                      style={{width: 15, height: 15, color: '#989898'}}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {intl.formatMessage({
                        id: 'dashboard.events.add.created',
                      })}{' '}
                      {moment(event.createdAt).format('DD/MM/YYYY h:mm A')}
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          {isDeleteEventPermission && (
            <Col lg="auto" className="text-center">
              <Button
                link
                data-testid="header-event-delete-btn"
                title={intl.formatMessage({id: 'actions.delete'})}
                className="w-min-unset normal-font"
                onClick={() => {
                  setIsConfirmationOpen(true);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
      <ConfirmationModal
        opened={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onAccept={onDeleteConfirmed}
        description={intl.formatMessage({
          id: 'dashboard.confirm_continue_process',
        })}
      />
    </Container>
  );
};

export default EventDetailsHeader;
