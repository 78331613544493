import {
  FormControl,
  FormHelperText,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import useSnackBar from '../../../hooks/app/useSnackBar';
import {CUSTOM_ATTRIBUTE_IMAGE_MAX_SIZE} from '../../../constants/appConstants';
import ImageUpload from '../../Common/ImageUpload';
import clsx from 'clsx';
import {EditIconWhite, TrashIconWhite} from '../../../constants/images';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  btnsWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingRight: 10,
    '&:hover': {
      background: theme.palette.action.disabled,
      '& .MuiButtonBase-root': {
        display: 'inline',
      },
    },
    '& .MuiButtonBase-root': {
      padding: 10,
      zIndex: 1,
      display: 'none',
    },
  },
  add: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
}));

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'bmp', 'svg'];

const ImageField = props => {
  const {
    input: {name, value, onChange},
    label,
    uploadTitle,
    invalidSize,
    onDelete,
    width,
    invalidType,
    maxSize,
    meta,
    wrapperClassName,
    fieldClassName,
  } = props;
  const {touched, error} = meta;
  const classes = useStyles({width});
  const extensions = IMAGE_EXTENSIONS;
  const snackBar = useSnackBar();
  const [imageSrc, setImageSrc] = useState(null);
  const editInputRef = useRef(null);

  const handleEdit = () => {
    editInputRef?.current.click();
  };

  //Read and add file to list when uploading
  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = function (e) {
        // Add the file name to the data URL
        let dataURL = e.target.result;
        dataURL = dataURL.replace(';base64', ';name=' + file.name + ';base64');
        resolve({file: file, dataURL: dataURL});
      };

      reader.readAsDataURL(file);
    });
  };

  //Handle file upload
  const onInputChange = images => {
    readFile(images[0]).then(newFileData => {
      let errorMessage = null;
      const extensionsPattern = extensions
        .map(ext => ext.replace('.', ''))
        .join('|');
      const typeRegExp = new RegExp(`^[a-z]*/(${extensionsPattern})`, 'i');
      if (!typeRegExp.test(newFileData.file.type)) {
        errorMessage = invalidType;
      } else if (newFileData.file.size > maxSize) {
        errorMessage = invalidSize.replace(':maxSize', maxSize / (1024 * 1024));
      }
      if (!errorMessage) {
        onChange(newFileData);
      } else {
        snackBar.showMessage(errorMessage, {variant: 'error'});
      }
    });
  };

  const onEdit = event => {
    const files = event.target.files;
    if (files) {
      onInputChange(files);
    }
  };

  const handleDelete = () => {
    onDelete && onDelete();
    setImageSrc(null);
  };

  useEffect(() => {
    if (value === null || typeof value === 'object') {
      setImageSrc(value && value.dataURL);
    }
  }, [value]);

  return (
    <FormControl
      fullWidth
      className={clsx(classes.root, wrapperClassName)}
      error={!!(touched && error)}
      name={name}
    >
      {label && <span className="small-label">{label}</span>}
      <div className="position-relative">
        {imageSrc && (
          <>
            <img src={imageSrc} alt="alt" className={classes.img} />
            <div className={classes.btnsWrapper}>
              <IconButton onClick={handleEdit}>
                <EditIconWhite style={{fontSize: 20}} />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <TrashIconWhite style={{fontSize: 20}} />
              </IconButton>
            </div>
            <input
              type="file"
              style={{display: 'none'}}
              ref={editInputRef}
              onChange={onEdit}
            />
          </>
        )}
        {!imageSrc && (
          <ImageUpload
            className={fieldClassName}
            actionTitle={uploadTitle}
            onChange={onInputChange}
          />
        )}
      </div>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

ImageField.propTypes = {
  maxSize: PropTypes.number,
  invalidType: PropTypes.string,
  invalidSize: PropTypes.string,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  /**
   * Image and placeholder width/height
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  onDelete: PropTypes.func,
};

ImageField.defaultProps = {
  maxSize: CUSTOM_ATTRIBUTE_IMAGE_MAX_SIZE,
  invalidType: 'Only valid image formats are allowed (.jpg, .jpeg. .png .bmp)',
  invalidSize: 'Maximum image size :maxSizeM',
  meta: {},
};

export default ImageField;
