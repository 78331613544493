import {FieldErrors} from 'react-hook-form';
import {ICategory, PresignedUrlResult} from '../../../../services/eventsApi';
import {CategoryFormFields} from './types';

export const categoryFormDefaultValues = {
  name: '',
  landingPageUrl: '',
  domainIds: [],
  parentId: null,
  mediaLibraryItem: null,
  updatedFields: new Set<keyof Omit<CategoryFormFields, 'updatedFields'>>(),
};

export function getCategoryFormDefaultValues(category?: ICategory) {
  if (!category) {
    return categoryFormDefaultValues;
  }

  return {
    id: category.id,
    name: category.name,
    landingPageUrl: category.landingPageUrl ?? '',
    parentId: Number(category.parentId),
    domainIds: category.domains.map(domain => domain.id),
    updatedFields: new Set<keyof Omit<CategoryFormFields, 'updatedFields'>>(),
    mediaLibraryItem: category.imageUrl
      ? {
          id: category.id,
          title: category.name,
          img: category.imageUrl,
        }
      : null,
  };
}

export function buildCategoryParentsMap(
  categories: ICategory[],
  categoryParentsMap = new Map<number, number[]>()
) {
  categories.forEach(category => {
    const parentId = category.parentId;
    const grandParents =
      parentId !== null ? categoryParentsMap.get(parentId) : [];
    const parents = [...(grandParents ?? []), category.parentId].filter(
      (id): id is number => id !== null
    );
    categoryParentsMap.set(category.id, parents);
    if (category.children.length) {
      buildCategoryParentsMap(category.children, categoryParentsMap);
    }
  });
  return categoryParentsMap;
}

export function buildCategoryIdsMap(
  categories: ICategory[],
  categoryIdsMap = new Map()
): Map<number, ICategory> {
  categories.forEach(category => {
    categoryIdsMap.set(category.id, category);
    if (category.children.length) {
      buildCategoryIdsMap(category.children, categoryIdsMap);
    }
  });
  return categoryIdsMap;
}

export function isPresignedUrlResponse(
  result: unknown
): result is {data: PresignedUrlResult} {
  return typeof result === 'object' && result != null && 'data' in result;
}

export function isUniqueName(
  categories: ICategory[],
  name: string,
  id?: number
) {
  const flattedCategories = Array.from(
    buildCategoryIdsMap(categories).values()
  );
  const matchedCategory = flattedCategories.find(
    category => category.name === name
  );
  return !matchedCategory || matchedCategory.id === id;
}

export function scrollToFirstError(
  errors: FieldErrors<CategoryFormFields>,
  errorElementRefs: {[key: string]: string}
) {
  const sortedErrors = [
    'parentId',
    'name',
    'landingPageUrl',
    'domainIds',
    'imageUrl',
  ];

  for (const key of sortedErrors) {
    if (key in errors && key in errorElementRefs) {
      const id = errorElementRefs[key];
      const element = document.querySelector(`#${id}`);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
  }
}
