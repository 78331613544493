import {useState} from 'react';

export const usePagination = (items, pageSize) => {
  const [currentPage, setCurrentPage] = useState(1);

  function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  const pagedItems = [...chunks(items, pageSize)];
  const getCurrentPageItems = page => {
    return pagedItems[page - 1] ?? [];
  };

  return {
    items: getCurrentPageItems(currentPage),
    numPages: pagedItems.length,
    currentPage,
    setCurrentPage,
  };
};
