import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {eventBusSlice} from './eventBus.slice';

export function useEventBusListener() {
  const dispatch = useDispatch();
  const on = useCallback(
    (event: string, id: string, callback: Function) => {
      dispatch(eventBusSlice.actions.addListener({event, id, callback}));
    },
    [dispatch]
  );

  const off = useCallback(
    (event: string, id: string) => {
      dispatch(eventBusSlice.actions.removeListener({event, id}));
    },
    [dispatch]
  );

  return {on, off};
}

export function useEmitEvent() {
  const dispatch = useDispatch();
  return useCallback(
    (event: string, payload?: any | void) => {
      dispatch(eventBusSlice.actions.emitEvent({event, payload}));
    },
    [dispatch]
  );
}
