import React, {ReactNode, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import {FormControlProps} from '@material-ui/core/FormControl/FormControl';
import {SelectProps} from '@material-ui/core/Select/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import BaseInput from './BaseInput';
import IconButton from './IconButton';

/* Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  select: {
    lineHeight: 1.6,
    width: '100%',

    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      boxShadow: theme.shadows[0],
      borderRadius: 4,
      fontWeight: 'normal',
      fontSize: 14,
      paddingTop: 7.5,
      paddingBottom: 7.5,
      '&.MuiSelect-outlined': {
        backgroundColor: 'inherit',
      },
    },
    '& .MuiSelect-icon': {
      fontSize: 10,
      top: 13,
      right: 10,
    },

    '&.cropped': {
      width: 'auto',
      display: 'inline-flex',
      padding: theme.spacing(1),
      textAlign: 'center',
      '& .MuiSelect-root': {
        fontSize: 12,
        boxShadow: theme.shadows[4],
        borderRadius: 10,
        minHeight: theme.spacing(2.5),
      },

      '& .MuiSelect-icon': {
        width: 11,
        height: 9,
        right: 19,
        top: 26,
      },
    },
    '&.outlined': {
      border: `1px solid ${theme.palette.text.border}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
    },
  },
  label: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    marginBottom: 0,
    zIndex: 1,
    left: 10,
    top: -12,
    pointerEvents: 'none',
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
    '&.Mui-error': {
      color: theme.palette.secondary.light,
    },
  },
  menu: {
    marginTop: 8,
    marginLeft: 0,
  },
  paper: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0.5, 0),
    maxHeight: 321,
    '& > ul': {
      padding: 0,
    },
    '&.cropped': {
      borderRadius: '0 0 8px 8px',
      maxHeight: 'calc(50vh - 50px)',
    },
  },
  menuItem: {
    fontSize: 12,
    padding: '5px 10px',
  },
}));

//Dropdown icon
const DropdownIcon = (props: {disabled: boolean; className?: string}) => {
  const {className, ...rest} = props;
  return (
    <SvgIcon
      viewBox="0 0 9 6"
      className={clsx(rest.disabled && 'text-muted', className)}
      style={{width: 10, height: 8}}
      {...rest}
    >
      <g
        id="Filter/Location"
        transform="translate(-191.000000, -246.000000)"
        fill="currentColor"
        stroke="currentColor"
      >
        <path
          d="M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407"
          id="Fill-1"
          transform="translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) "
        ></path>
      </g>
    </SvgIcon>
  );
};

export interface CustomSelectProps
  extends Omit<SelectProps, 'variant' | 'onChange'> {
  value?: unknown;
  filled?: boolean;
  onChange?: (value?: string) => void;
  items: {label: string; value: string | ReadonlyArray<string> | number}[];
  placeholder?: string;
  variant?: 'cropped' | 'default';
  outlined?: boolean;
  extraChild?: ReactNode;
  rightAdornment?: ReactNode;
  formControlProps?: FormControlProps;
  selectClassName?: string;
  disabled?: boolean;
}

const CustomSelect = (props: CustomSelectProps) => {
  const {
    value = '',
    filled = true,
    onChange,
    items,
    placeholder,
    variant = 'default',
    outlined = false,
    extraChild,
    formControlProps,
    selectClassName = '',
    disabled,
    rightAdornment,
    ...others
  } = props;

  const classes = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  //selected value
  const [currentValue, setCurrentValue] = useState(value ?? null);

  //on select callback
  const onSelectChange = (
    e: React.ChangeEvent<{name?: string; value: unknown}>
  ) => {
    setCurrentValue(e.target.value);
    if (onChange) {
      onChange(e.target.value as string | undefined);
    }
  };

  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <FormControl className={classes.root} {...formControlProps}>
      {!currentValue && (
        <InputLabel
          htmlFor="grouped-select"
          shrink={false}
          className={classes.label}
        >
          {placeholder ?? 'Select'}
        </InputLabel>
      )}
      <Select
        ref={ref}
        open={open}
        disabled={disabled}
        variant={filled ? 'filled' : 'outlined'}
        className={clsx(
          clsx(classes.select, selectClassName),
          variant === 'cropped' && 'cropped',
          outlined && 'outlined'
        )}
        value={currentValue}
        // @ts-ignore
        input={<BaseInput value={currentValue} />}
        endAdornment={
          rightAdornment && (
            <Box
              display="flex"
              alignItems="center"
              position="relative"
              gridGap={5}
              paddingRight={0.5}
            >
              <InputAdornment position="end">{rightAdornment}</InputAdornment>
              <IconButton
                variant="action"
                size="sm"
                onClick={toggleOpen}
                numberLabel={undefined}
                className={undefined}
              >
                <DropdownIcon disabled={!!disabled} />
              </IconButton>
            </Box>
          )
        }
        {...(!rightAdornment && {
          IconComponent: () => (
            <DropdownIcon
              disabled={!!disabled}
              className="MuiSvgIcon-root MuiSelect-icon"
            />
          ),
        })}
        MenuProps={{
          elevation: 0,
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          className: clsx(classes.menu, variant === 'cropped' && 'cropped'),
          classes: {
            paper: classes.paper,
          },
          PaperProps: {
            style: {
              width: ref.current?.offsetWidth - 2,
            },
          },
        }}
        onChange={onSelectChange}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        {...others}
      >
        {items.map((item, key) => (
          <MenuItem
            key={key}
            value={`${item.value}`}
            className={classes.menuItem}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {extraChild}
    </FormControl>
  );
};

export default CustomSelect;
