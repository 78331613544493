import {isPresignedUrlResponse} from '../CategoryForm/helpers';
import {IImageField} from '../CategoryForm/types';
import {useUploadToPresignedUrlMutation} from '../../../../services/uploadApi';
import {useGeneratePresignedUrlMutation} from '../../../../services/eventsApi';
import {useCallback} from 'react';

export function useUploadCategoryImage() {
  const {upload, isUploading} = useUploadToPresignedUrlMutation();
  const [generatePresignedUrl, {isLoading: isGeneratingPresignedUrl}] =
    useGeneratePresignedUrlMutation();

  const uploadCategoryImage = useCallback(
    async (categoryImage?: IImageField | null) => {
      if (!categoryImage) {
        return;
      }
      const fileName = categoryImage.file.name;

      const result = await generatePresignedUrl({
        objectName: fileName,
      });

      if (!isPresignedUrlResponse(result)) {
        return;
      }
      const {uploadUrl, readUrl} = result.data;

      await upload({
        presignedUrl: uploadUrl,
        file: categoryImage.file,
        contentType: categoryImage.file.type,
      });
      return readUrl;
    },
    [upload, generatePresignedUrl]
  );

  return {
    upload: uploadCategoryImage,
    isLoading: isUploading || isGeneratingPresignedUrl,
  };
}
