import React, {Fragment, useCallback, useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Select from '../../Common/Select';
import IconButton from '../../Common/IconButton';
import ProgressBar from '../../Common/ProgressBar';
import Button from '../../Common/Button';
import useDataTableStyles from './useDataTableStyles';
import {FormHelperText} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectTableSelectionState,
  setTableSelectionState,
  tableSelectStateType,
} from '../../../store/slices/tableSelectionSlice';
import {useIsWithDown, useIsWithUp} from '../../../hooks/UI';
import {BREAKPOINTS} from '../../../constants/appConstants';

type BulkActionValuesType = {
  label: string;
  value: string;
  applyFun?: () => void;
};

type BulkActionTypesType = {
  label: string;
  value: string;
  valueText?: string;
  values: BulkActionValuesType[];
  applyFun?: () => void;
};

export type BulkActionType = {
  label: string;
  value: string;
  typesPlaceholder?: string;
  types: BulkActionTypesType[];
  applyFun?: () => void;
};

/**
 * Bulk actions block
 */
const BulkActions = ({
  entitiesName,
  actionList,
  actionValue,
  errorDetails,
  headCells,
  selected,
  total,
  selectAll,
  onSelectAllList,
  clearSelection,
  setOpacityState,
  successCount,
  ignoredCount,
}) => {
  const intl = useIntl();
  //styles
  const classes = useDataTableStyles();
  const dispatch = useDispatch();
  const isUpLg = useIsWithUp(BREAKPOINTS.LG);
  const isDownXl = useIsWithDown(BREAKPOINTS.XL);

  const shouldWrapApply = isUpLg && isDownXl;

  //Possible bulk actions states:
  // selected (some of items)
  // all - selected all items
  // triggered (action applied to selected)
  // ended
  // err_details Error details was shown
  // closed - Bulk actions block invisible
  // const [state, setState] = useState(
  //   selected.length === total ? 'all' : 'selected'
  // );
  const state = useSelector(selectTableSelectionState);
  const setState = useCallback(
    (newState: tableSelectStateType) => {
      dispatch(setTableSelectionState(newState));
    },
    [dispatch]
  );
  //Expanded/collapsed block state in mobile mode
  const [expandedMobile, setExpandedMobile] = useState(false);
  //state before triggering
  const [prevState, setPrevState] = useState<tableSelectStateType>();

  const [isError, setIsError] = useState<boolean>(false);

  const [selectedAction, setSelectedAction] = useState<
    BulkActionType | undefined
  >();
  const [selectedActionType, setSelectedActionType] = useState<
    BulkActionTypesType | undefined
  >();
  const [selectedValue, setSelectedValue] = useState<
    BulkActionValuesType | undefined
  >();

  useEffect(() => {
    if (state === 'triggered') {
      setOpacityState(true);
    } else {
      setOpacityState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state === 'ended' || state === 'err_details') {
      return;
    }
    if (selected.length === total) {
      setState('all');
    } else {
      setState('selected');
    }
  }, [selected, setOpacityState, setState, state, total]);

  //Toggle expanded/collapsed state
  const toggleExpanded = () => setExpandedMobile(!expandedMobile);
  const onActionSelectChange = useCallback(
    e => {
      setIsError(false);
      actionList.forEach(action => {
        if (action.value === e) {
          setSelectedAction(action);
          setSelectedActionType(undefined);
          setSelectedValue(undefined);
        }
      });
    },
    [actionList]
  );

  const onActionTypeSelectChange = useCallback(
    e => {
      setIsError(false);
      selectedAction?.types.forEach(action => {
        if (action.value === e) {
          setSelectedActionType(action);
          setSelectedValue(undefined);
        }
      });
    },
    [selectedAction?.types]
  );

  const onActionValueSelectChange = useCallback(
    e => {
      setIsError(false);
      selectedActionType?.values.forEach(action => {
        if (action.value === e) {
          setSelectedValue(action);
        }
      });
    },
    [selectedActionType?.values]
  );

  //Controls block
  const ControlsBlock = () => {
    return (
      <div className="elements d-flex align-items-lg-center flex-column flex-lg-row">
        {/*Bulk action list*/}
        {actionList && (
          <div className="control actions pt-2">
            <Select
              data-testid="data-table-bulk-action-select"
              outlined
              placeholder={intl.formatMessage({
                id: 'actions.bulk_actions.placeholder',
              })}
              onChange={onActionSelectChange}
              items={actionList.map(item => ({
                label: item.label,
                value: item.value,
              }))}
              value={selectedAction?.value}
            />
            <div
              className="error-message-container"
              style={{
                height: '10px',
                visibility: isError && !selectedAction ? 'visible' : 'hidden',
              }}
            >
              <FormHelperText error>
                {intl.formatMessage({id: 'validation.error.required'})}{' '}
              </FormHelperText>
            </div>
          </div>
        )}
        {/*Bulk action type*/}
        {selectedAction && selectedAction.types.length > 0 && (
          <div
            className="control actions pt-2"
            style={{
              maxWidth: selectedActionType?.values?.length ? 'auto' : '50%',
            }}
          >
            <Select
              outlined
              placeholder={selectedAction.typesPlaceholder}
              onChange={onActionTypeSelectChange}
              items={selectedAction.types.map(item => ({
                label: item.label,
                value: item.value,
              }))}
              value={selectedActionType?.value}
            />
            <div
              className="error-message-container"
              style={{
                height: '10px',
                visibility:
                  isError && !selectedActionType ? 'visible' : 'hidden',
              }}
            >
              <FormHelperText error>
                {intl.formatMessage({id: 'validation.error.required'})}{' '}
              </FormHelperText>
            </div>
          </div>
        )}
        {/*Bulk action value*/}
        {selectedActionType && selectedActionType.values?.length > 0 && (
          <div className="control d-flex align-items-center gap-2 pt-2">
            <div className="w-100">
              <Select
                outlined
                onChange={onActionValueSelectChange}
                items={selectedActionType.values.map(item => ({
                  label: item.label,
                  value: item.value,
                }))}
                value={selectedValue?.value}
              />
              <div
                className="error-message-container"
                style={{
                  height: '10px',
                  visibility: isError && !selectedValue ? 'visible' : 'hidden',
                }}
              >
                <FormHelperText error>
                  {intl.formatMessage({id: 'validation.error.required'})}{' '}
                </FormHelperText>
              </div>
            </div>
            {selectedActionType.valueText && (
              <Typography variant="body2" color="textSecondary">
                {selectedActionType.valueText}
              </Typography>
            )}
          </div>
        )}
      </div>
    );
  };

  //Close button on ended mode
  const ButtonClose = () => {
    return (
      <IconButton
        size="sm"
        variant="action"
        onClick={() => {
          clearSelection();
          setState('closed');
        }}
      >
        <SvgIcon viewBox="0 0 13 13" style={{fontSize: 15}}>
          <g transform="translate(-1249.000000, -258.000000)" fill="#B8B8B8">
            <g
              id="Path-3"
              transform="translate(1255.500000, 264.500000) rotate(-315.000000) translate(-1255.500000, -264.500000) translate(1248.000000, 257.000000)"
            >
              <polygon
                id="Path"
                points="8.43585237 6.56414763 8.43585237 0 6.56414763 0 6.56414763 6.56414763 0 6.56414763 0 8.43585237 6.56414763 8.43585237 6.56414763 15 8.43585237 15 8.43585237 8.43585237 15 8.43585237 15 6.56414763"
              />
            </g>
          </g>
        </SvgIcon>
      </IconButton>
    );
  };

  //Error details block
  const ErrorDetailsBlock = () => {
    return (
      <div className="pt-2 pt-lg-1">
        {errorDetails &&
          errorDetails.map(({field1, field2, msg}, key) => (
            <div className="row gx-4" key={key}>
              <div className="col-6 col-lg-auto">
                <Typography variant="body2" className="font-weight-bold">
                  {field1}
                </Typography>
                {field2 && (
                  <Typography
                    variant="subtitle1"
                    className="text-muted pb-2 d-lg-none"
                  >
                    {field2}
                  </Typography>
                )}
              </div>
              {field2 && (
                <div className="col-lg-auto d-none d-lg-block">
                  <Typography variant="body2" className="text-muted">
                    {field2}
                  </Typography>
                </div>
              )}
              <div className="col-6 col-lg-auto">
                <Typography variant="body2" className="text-danger text-center">
                  {msg}
                </Typography>
              </div>
            </div>
          ))}
      </div>
    );
  };

  //Select all click handler
  const doSelectAll = () => {
    setState('all');
    selectAll();
    onSelectAllList && onSelectAllList();
  };

  //Apply button click handler
  const doApply = () => {
    if (!selectedAction) {
      setIsError(true);
      return;
    }
    if (
      selectedAction &&
      selectedAction?.types.length > 0 &&
      !selectedActionType
    ) {
      setIsError(true);
      return;
    }
    if (
      selectedActionType &&
      selectedActionType?.values.length > 0 &&
      !selectedValue
    ) {
      setIsError(true);
      return;
    }
    setPrevState(state);
    setState('triggered');
    if (!handleAction()) {
      return;
    }
    setState('ended');
    clearActionSelection();
  };

  const clearActionSelection = () => {
    setSelectedValue(undefined);
    setSelectedActionType(undefined);
    setSelectedAction(undefined);
  };

  const handleAction = () => {
    if (!selectedAction) return false;

    if (selectedAction.types.length > 0) {
      return handleActionType();
    } else {
      applyFunction(selectedAction.applyFun);
    }
    return true;
  };

  const handleActionType = () => {
    if (!selectedActionType) return false;

    if (selectedActionType.values.length > 0) {
      return handleSelectedValue();
    } else {
      applyFunction(selectedActionType.applyFun);
    }
    return true;
  };

  const handleSelectedValue = () => {
    if (!selectedValue) return false;

    applyFunction(selectedValue.applyFun);
    return true;
  };

  const applyFunction = func => {
    func && func();
  };

  //Cancel button click handler
  const doCancel = () => {
    if (prevState) {
      setState(prevState);
    }
  };

  //Show error details
  const doShowErrorDetails = () => {
    setState('err_details');
  };

  //Hide error details
  const doHideErrorDetails = () => {
    setState('ended');
  };

  return (
    state !== 'closed' && (
      <TableBody className="bulk-actions-block">
        {(state === 'selected' || state === 'all') && (
          <TableRow className={classes.tableRow}>
            <TableCell
              className={clsx(classes.tableCell, 'bulk-actions')}
              colSpan={2}
            >
              {/*Items selected*/}
              {state === 'selected' && (
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {id: 'actions.bulk_actions.items_page_selected'},
                      {
                        number_items: `<b>${selected.length}</b>`,
                        entities: entitiesName,
                      }
                    ),
                  }}
                />
              )}
              {/*All items selected*/}
              {state === 'all' && (
                <Typography
                  variant="body2"
                  className="text-app-primary"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {id: 'actions.bulk_actions.items_page_selected'},
                      {number_items: `${total}`, entities: entitiesName}
                    ),
                  }}
                />
              )}
              {/*Select all items*/}
              {state === 'selected' && (
                <Typography
                  variant="body2"
                  className="text-app-primary cursor-pointer"
                  onClick={doSelectAll}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {id: 'actions.bulk_actions.select_all_items'},
                      {number_items: `${total}`, entities: entitiesName}
                    ),
                  }}
                />
              )}
              {/* Clear selection */}
              {state === 'all' && (
                <Typography
                  variant="body2"
                  color="primary"
                  className="cursor-pointer"
                  onClick={clearSelection}
                >
                  <FormattedMessage id="actions.bulk_actions.clear_selection" />
                </Typography>
              )}
            </TableCell>
            {/*Expand/Collapse for mobile view*/}
            <TableCell
              className={clsx(classes.tableCell, 'd-lg-none')}
              align="right"
              style={{paddingRight: 16}}
              colSpan={2}
            >
              <IconButton variant="action" size="sm" onClick={toggleExpanded}>
                {expandedMobile ? (
                  //minus
                  <SvgIcon viewBox="0 0 10 1" style={{width: 10, height: 1}}>
                    <g id="Page-1" stroke="none" fill="none">
                      <g
                        id="SW---UI-style-guideline"
                        transform="translate(-967.000000, -829.000000)"
                        fill="#282B3E"
                        fillRule="nonzero"
                      >
                        <polygon
                          id="Path"
                          points="967 829 967 830 977 830 977 829"
                        />
                      </g>
                    </g>
                  </SvgIcon>
                ) : (
                  //plus
                  <SvgIcon viewBox="0 0 10 10" style={{width: 10}}>
                    <g
                      transform="translate(-967.000000, -885.000000)"
                      fill="#282B3E"
                    >
                      <g
                        id="Path-3-Copy-3"
                        transform="translate(967.000000, 885.000000)"
                      >
                        <polygon
                          id="Path"
                          points="5.62390158 4.37609842 5.62390158 0 4.37609842 0 4.37609842 4.37609842 0 4.37609842 0 5.62390158 4.37609842 5.62390158 4.37609842 10 5.62390158 10 5.62390158 5.62390158 10 5.62390158 10 4.37609842"
                        />
                      </g>
                    </g>
                  </SvgIcon>
                )}
              </IconButton>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                'd-none d-lg-table-cell bulk-actions'
              )}
              colSpan={headCells.length - 2}
            >
              <ControlsBlock />
              {shouldWrapApply && (
                <Button
                  data-testid="data-table-bulk-action-apply"
                  primary
                  title={intl.formatMessage({id: 'actions.apply'})}
                  onClick={doApply}
                />
              )}
            </TableCell>
            {/*Apply*/}
            <TableCell
              className={clsx(
                classes.tableCell,
                'd-none d-lg-table-cell bulk-actions'
              )}
            >
              {!shouldWrapApply && (
                <div className="action-button">
                  <Button
                    data-testid="data-table-bulk-action-apply"
                    primary
                    title={intl.formatMessage({id: 'actions.apply'})}
                    onClick={doApply}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        )}
        {/* Triggered state */}
        {state === 'triggered' && (
          <Fragment>
            <TableRow className="triggered-row">
              <TableCell
                className={clsx(classes.tableCell, 'bulk-actions')}
                colSpan={headCells.length + 1}
              >
                <div className="triggered d-flex align-items-center">
                  {actionValue ? (
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          {id: 'actions.bulk_actions.description_value'},
                          {
                            entities: entitiesName,
                            action: selectedAction?.label ?? '', //put here an action (e.g. Increase)
                            action_type: selectedActionType?.label ?? '', //put here selected Action Type
                            value: selectedValue?.label ?? '', //put here value
                            number_items: `<b>${selected.length}</b>`,
                          }
                        ),
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          {id: 'actions.bulk_actions.description'},
                          {
                            entities: entitiesName,
                            action: selectedAction?.label ?? '', //put here an action (e.g. Set)
                            action_type: selectedActionType?.label ?? 'action', //put here selected Action Type
                            number_items: `<b>${selected.length}</b>`,
                          }
                        ),
                      }}
                    />
                  )}
                  {/*Cancel*/}
                  <div className="ms-auto ps-3">
                    <div className="action-button">
                      <Button
                        danger
                        title={intl.formatMessage({id: 'actions.cancel'})}
                        onClick={doCancel}
                      />
                    </div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
            {/*Progress bar*/}
            <TableRow className="triggered-row">
              <TableCell
                colSpan={headCells.length + 1}
                className={clsx(classes.progressBarWrapper)}
              >
                <ProgressBar value={50} />
              </TableCell>
            </TableRow>
          </Fragment>
        )}
        {(state === 'ended' || state === 'err_details') && (
          <TableRow>
            {(successCount > 0 || ignoredCount > 0) && (
              <TableCell
                className={clsx(classes.tableCell, 'bulk-actions')}
                colSpan={headCells.length + 1}
              >
                <div className="ended d-flex">
                  <div>
                    <div>
                      {ignoredCount > 0 ? (
                        <Typography
                          className="d-inline"
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage(
                              {id: 'actions.bulk_actions.bulk_action_result'},
                              {
                                entities: entitiesName,
                                number_success: `<b>${successCount}</b>`,
                                number_error: `<span class="font-weight-bold text-danger">${ignoredCount}</span>`,
                              }
                            ),
                          }}
                        />
                      ) : (
                        <Typography
                          className="d-inline"
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage(
                              {
                                id: 'actions.bulk_actions.bulk_action_result_no_error',
                              },
                              {
                                entities: entitiesName,
                                number_success: `<b>${successCount}</b>`,
                              }
                            ),
                          }}
                        />
                      )}
                      &nbsp;
                      {/*Show error details*/}
                      {state === 'ended' && errorDetails.length > 0 && (
                        <Typography
                          className="text-danger d-inline cursor-pointer"
                          variant="body2"
                          onClick={doShowErrorDetails}
                        >
                          <FormattedMessage id="actions.show" />
                        </Typography>
                      )}
                      {/*Hide error details*/}
                      {state === 'err_details' && (
                        <Typography
                          className="text-danger d-inline cursor-pointer"
                          variant="body2"
                          onClick={doHideErrorDetails}
                        >
                          <FormattedMessage id="actions.hide" />
                        </Typography>
                      )}
                    </div>
                    {state === 'err_details' && <ErrorDetailsBlock />}
                  </div>
                  <div className="ms-auto">
                    <ButtonClose />
                  </div>
                </div>
              </TableCell>
            )}
          </TableRow>
        )}
        {/*Mobile view collapsible part*/}
        <TableRow>
          <TableCell className={classes.collapsableTableCell} colspan={5}>
            {state !== 'triggered' && (
              <Collapse
                in={expandedMobile}
                className={clsx(classes.collapseWrapper, 'bulk d-lg-none')}
                unmountOnExit
              >
                {(state === 'selected' || state === 'all') && (
                  <div className="d-flex flex-fill flex-column">
                    <ControlsBlock />
                    <div className="action-button">
                      <Button
                        primary
                        title={intl.formatMessage({id: 'actions.apply'})}
                        onClick={doApply}
                      />
                    </div>
                  </div>
                )}
              </Collapse>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    )
  );
};

BulkActions.propTypes = {
  headCells: PropTypes.array,
  selected: PropTypes.bool,
  total: PropTypes.number,
  selectAll: PropTypes.func,
  clearSelection: PropTypes.func,
  entitiesName: PropTypes.string,
  actionList: PropTypes.arrayOf(PropTypes.string),
  actionTypes: PropTypes.arrayOf(PropTypes.string),
  errorDetails: PropTypes.array,
  setOpacityState: PropTypes.func,
};

export default BulkActions;
