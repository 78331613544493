import * as yup from 'yup';
import {AnyObject, ObjectSchema} from 'yup';
import {IntlShape} from 'react-intl';
import {CategoryFormFields} from './types';
import {getUrlSchema} from '../../../../utils/FormValidation';

type CategoryFormSchema = ObjectSchema<
  Omit<
    CategoryFormFields,
    'mediaLibraryItem' | 'categoryImage' | 'imageUrl' | 'id' | 'updatedFields'
  >,
  AnyObject
>;

const urlSchema = getUrlSchema();

export function getAddCategoryValidationSchema(
  intl: IntlShape
): CategoryFormSchema {
  return yup.object().shape({
    name: yup
      .string()
      .min(
        3,
        intl.formatMessage({id: 'validation.error.too_short'}, {length: 3})
      )
      .required(intl.formatMessage({id: 'validation.error.required'})),
    landingPageUrl: getUrlSchema(intl).nullable().optional(),
    domainIds: yup
      .array()
      .of(yup.number().required())
      .min(1, intl.formatMessage({id: 'validation.error.required'}))
      .required(intl.formatMessage({id: 'validation.error.required'})),
    parentId: yup
      .number()
      .required(intl.formatMessage({id: 'validation.error.required'})),
  });
}
