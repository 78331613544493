import React, {ChangeEvent, KeyboardEvent} from 'react';
import SearchTextField from './Fields/SearchTextField';
import {useIntl} from 'react-intl';

interface SearchFormProps {
  search: string;
  SearchProps: any;
  onChange: (value: string) => void;
  onSubmit?: () => void;
  loading?: boolean;
  onClear?: () => void;
}

/**
 * SearchForm that can be used to display a search field but not bound to redux-form
 */
export const SearchForm = (props: SearchFormProps) => {
  const {search, onChange, SearchProps, loading, onSubmit, onClear} = props;
  const intl = useIntl();

  const onSearchClear = () => {
    onChange('');
    onClear && onClear();
  };

  // Keyword search Submit only with ENTER KEY
  const onSearchKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmit && onSubmit();
    }
  };

  return (
    <SearchTextField
      value={search}
      clearable
      onChange={(v: ChangeEvent<HTMLInputElement>) => onChange(v.target.value)}
      placeholder={intl.formatMessage({id: 'actions.search'})}
      {...SearchProps}
      buttonProps={{
        onClick: onSubmit,
        onClear: onSearchClear,
        ...(SearchProps.buttonProps && SearchProps.buttonProps),
      }}
      readOnly={loading}
      onKeyUp={onSearchKeyUp}
    />
  );
};

export default SearchForm;
