export const SeatmapFormDefaultValues = {
  sections: [],
  seatmapFile: null,
  isSVGWithSeatmapFormat: false,
  formMissingBlocks: new Map(),
  formMissingSections: [],
  svgMissingSections: [],
  svgMissingBlocks: new Map(),
  imageUrl: null,
};

export const DEFAULT_SEATMAP_SECTION_COLOR = '#ef5777';

export const EmptySeatmapSection = {
  name: '',
  color: DEFAULT_SEATMAP_SECTION_COLOR,
  isGeneralAdmission: false,
  blocks: [],
};

export const EmptySeatmapBlock = {
  name: '',
};

export const ScrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
};
