import React from 'react';
import {useSelector} from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import {Dialog} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {selectShowProgressDialogCount} from '../../store/slices/appSlice';
import {useAppSelector} from '../../store/hooks';

/**
 * This component is responsible for render the loading spinner
 */
const ProgressDialog = () => {
  const show =
    useSelector(
      (store: {modalReducer: {progressModal: {show: boolean}}}) =>
        store.modalReducer.progressModal.show
    ) || false;
  const showProgressDialogCount = useAppSelector(selectShowProgressDialogCount);
  const showProgress = showProgressDialogCount > 0;

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={(event, reason) => {}}
      fullScreen={false}
      open={show || showProgress}
      classes={{paper: 'cco-react-progress-dialog'}}
      BackdropProps={{
        classes: {root: 'cco-react-progress-backdrop'},
      }}
      PaperProps={{
        elevation: 4,
      }}
    >
      <DialogContent className="progress-dialog-content">
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default ProgressDialog;
