import {SearchForm} from '../../Form/NonReduxSearchForm';
import DataTable from '../../Widgets/DataTable';
import {useHistory} from 'react-router-dom';
import * as urls from '../../../constants/urls';
import {PlusRoundedDangerIcon} from '../../../constants/images';
import {useEffect, useState} from 'react';
import {useVenueList} from './hooks/useVenueList';
import {useSearch} from '../../../hooks/common/useSearch';
import usePermission from '../../../Auth/usePermission';
import {VenuePermissions} from '../../../constants/permissions';
import {IAPIListOptions} from '../../../services/eventsApi';
import {getPaginationURLParams} from '../../../utils/filters';
import PermissionContainer from '../../Common/PermissionContainer';
import useTableSort from '../hooks/useTableSort';

interface ListVenueTabProps {
  onAddClick: () => void;
  scrollToTop: () => void;
}

const VenueList = ({onAddClick, scrollToTop}: ListVenueTabProps) => {
  const history = useHistory();
  const [venueListOptions, setVenueListOptions] = useState<IAPIListOptions>(
    getPaginationURLParams()
  );
  const [loader, setLoader] = useState(false);

  const {searchText, setSearchText} = useSearch();
  const {HeaderCells, rows, isLoading, currentPage, total} =
    useVenueList(venueListOptions);
  const {hasPermission} = usePermission();
  const {handleSortChange} = useTableSort();

  useEffect(() => {
    scrollToTop();
  }, [currentPage, scrollToTop]);

  const handleRowClick = (itemId: number) => {
    history.push(urls.VENUE_DETAILS_PATH.replace(':id', String(itemId)));
  };

  const canAddVenue = hasPermission(VenuePermissions.addVenue);

  return (
    <div className="dashboard-content">
      <PermissionContainer permissions={[VenuePermissions.seeVenueList]}>
        <div className="row align-items-center justify-content-between search-row py-1">
          <div className="col-xl-4 col-lg-8 d-flex">
            <SearchForm
              loading={isLoading}
              search={searchText}
              onChange={(value: string) => {
                setSearchText(value);
              }}
              onSubmit={() => {
                setVenueListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: searchText,
                }));
              }}
              onClear={() => {
                setVenueListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: '',
                }));
              }}
              SearchProps={{
                buttonProps: {
                  onClick: () => {},
                },
              }}
            />
          </div>
        </div>
        <DataTable
          className="events-table"
          canSelect={false}
          empty={rows.length === 0}
          loading={isLoading || loader}
          clickableRow={hasPermission(VenuePermissions.seeVenueDetails)}
          onRowClick={
            hasPermission(VenuePermissions.seeVenueDetails)
              ? (itemId: number) => handleRowClick(itemId)
              : undefined
          }
          {...(canAddVenue && {
            actionButton: {
              variant: 'filled',
              size: 'sm',
              children: <PlusRoundedDangerIcon />,
              onClick: onAddClick,
            },
          })}
          headCells={HeaderCells.map(item => ({
            ...item,
            numeric: false,
            disablePadding: false,
          }))}
          rows={rows}
          onChange={(payload: any) => {
            setLoader(true);
            setVenueListOptions(prevState => ({
              ...prevState,
              ...(payload.page && {page: payload.page}),
              ...(payload.rowsPerPage && {perPage: payload.rowsPerPage}),
              ...(payload.sort.by && {
                sort: payload.sort.by,
              }),
              ...(payload.sort.direction && {
                direction: payload.sort.direction,
              }),
            }));
            setTimeout(() => {
              setLoader(false);
            }, 500);
          }}
          total={total}
          currentPage={Number(venueListOptions.page)}
          defaultRowPerPage={venueListOptions.rowsPerPage}
          handleParameterChange={handleSortChange}
          defaultOrder={venueListOptions.direction}
          defaultOrderBy={venueListOptions.sort}
        />
      </PermissionContainer>
    </div>
  );
};

export default VenueList;
