import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Box, makeStyles, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AutocompleteProps} from '@material-ui/lab/Autocomplete/Autocomplete';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import {ITheme} from '../../constants/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';

const useAutocompleteStyles = makeStyles((theme: ITheme) => ({
  root: (props: {disabled?: boolean}) => ({
    '& .MuiOutlinedInput-root': {
      backgroundColor: props.disabled
        ? theme.palette.gray.light
        : theme.palette.background.paper,
      height: theme.spacing(3.5),
      fontSize: 14,
      padding: '2px 4px !important',
      '& .MuiAutocomplete-endAdornment': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  }),
  option: {
    fontSize: 14,
  },
  clearIndicator: {
    '& .MuiSvgIcon-root': {
      fontSize: 17,
    },
  },
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    // default border
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
      top: 0,
    },
    // hover border
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
    // focused border
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
  },
  invalid: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.error.light,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.main}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.dark}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.dark}`,
      },
    },
  },
}));

const useNoOptionStyles = makeStyles(theme => ({
  noOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    height: '2vh',
  },
  circularProgress: {
    color: '#21B59B',
    '& .MuiCircularProgress-circle': {
      strokeLinecap: 'round',
    },
    animationDuration: '600ms',
    position: 'absolute',
    left: 0,
  },
  circularProgressBack: {
    color: '#EDEDED',
  },
  text: {
    fontSize: 13,
  },
  noOptionText: {
    fontSize: 13,
    height: '2vh',
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface AppAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  label?: string;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  isInvalid?: boolean;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: Readonly<AppAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>>
) {
  const {
    label,
    placeholder,
    textFieldProps,
    disabled,
    loading,
    isInvalid,
    ...others
  } = props;
  const rootClasses = useAutocompleteStyles({disabled});
  const inputClasses = useInputStyles();
  const noOptionsClasses = useNoOptionStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <MuiAutocomplete
      {...others}
      classes={rootClasses}
      disabled={disabled}
      popupIcon={disabled ? null : <ExpandMoreIcon fontSize="small" />}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputCapture={() => setOpen(true)}
      noOptionsText={
        loading ? (
          <div className={noOptionsClasses.noOptionsWrapper}>
            <Box sx={{position: 'relative'}}>
              <CircularProgress
                variant="determinate"
                className={noOptionsClasses.circularProgressBack}
                size={20}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className={noOptionsClasses.circularProgress}
                size={20}
                thickness={4}
              />
            </Box>
            <Typography className={noOptionsClasses.text}>
              <FormattedMessage id="actions.autocomplete_loading" />
            </Typography>
          </div>
        ) : (
          <Typography className={noOptionsClasses.noOptionText}>
            <FormattedMessage id="actions.no_options" />
          </Typography>
        )
      }
      renderInput={params => (
        <TextField
          data-testid="autocomplete-text"
          {...params}
          {...textFieldProps}
          label={label}
          disabled={disabled}
          classes={{
            root: clsx(inputClasses.root, {
              [inputClasses.invalid]: isInvalid,
            }),
          }}
          size="small"
          placeholder={placeholder}
          variant="outlined"
          // override inputProps autoComplete prop to set autocomplete to "new-username"
          // autocomplete off doesn't always works
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
            autoComplete: 'new-username',
          }}
        />
      )}
    />
  );
}

export default Autocomplete;
