import Autocomplete, {AppAutocompleteProps} from '../../Common/Autocomplete';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FilterInputTypesEnum, FilterItem} from './types';
import {useModularFilterUrlValue} from './hooks';
import {
  eventBusEvents,
  useEventBusListener,
} from '../../../store/slices/eventBus';
import {nanoid} from 'nanoid';

export interface AutocompleteFilterProps<T>
  extends FilterItem,
    Omit<
      AppAutocompleteProps<T, false, false, true>,
      'value' | 'onChange' | 'multiple'
    > {
  error?: string;
  getOptionLabel: (option: T) => string;
  loading?: boolean;
}

export function AutocompleteFilter<T>(
  props: Readonly<AutocompleteFilterProps<T>>
) {
  const {
    label,
    error,
    textFieldProps,
    name,
    onFilterChange,
    onFilterRemove,
    options,
    ...autocompleteProps
  } = props;

  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const urlValue = useModularFilterUrlValue(name);
  const {on, off} = useEventBusListener();

  useEffect(() => {
    if (urlValue === null) {
      onFilterRemove?.(name);
      setTextFieldValue('');
    } else {
      setTextFieldValue(urlValue);
    }
  }, [urlValue, onFilterRemove, name]);

  useEffect(() => {
    if (textFieldValue) {
      onFilterChange?.({
        value: textFieldValue,
        type: FilterInputTypesEnum.AUTOCOMPLETE,
        name,
      });
    } else {
      onFilterRemove?.(name);
    }
  }, [onFilterRemove, onFilterChange, textFieldValue, name]);

  useEffect(() => {
    const callbackId = nanoid(5);
    on(
      eventBusEvents.ModularFilterEvents.CLEAR_FILTER,
      callbackId,
      (clearedFilterNames: string[]) => {
        if (clearedFilterNames.includes(name)) {
          setTextFieldValue('');
        }
      }
    );
    return () => {
      off(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, callbackId);
    };
  }, [on, off, name]);

  return (
    <div className="search-filter-row">
      {label && <span className="small-label">{label}</span>}
      <Autocomplete
        {...autocompleteProps}
        options={options}
        value={
          options.find(
            option =>
              autocompleteProps.getOptionLabel(option) === textFieldValue
          ) ?? null
        }
        onChange={(_, newValue) => {
          setTextFieldValue(
            newValue ? autocompleteProps.getOptionLabel(newValue as T) : ''
          );
        }}
        textFieldProps={{
          ...textFieldProps,
          error: !!error,
          helperText: error,
          inputProps: {
            value: textFieldValue,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setTextFieldValue(event.target.value);
              if (textFieldProps?.inputProps?.onChange) {
                textFieldProps.inputProps.onChange(event);
              }
            },
          },
        }}
      />
    </div>
  );
}
