import cx from 'clsx';
import {FormattedMessage} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Col, Row} from 'reactstrap';
import Checkbox from '../../Common/Checkbox';
import CollapsableBlock from '../../Common/CollapsableBlock';
import SubcategoriesTree from './SubcategoriesTree';
import {ITixstockCategoryWithChildren} from '../../../services/eventsApi';
import {ChangeEvent} from 'react';

const ChildCategoryHeader = ({
  checked,
  indeterminate,
  onClick,
  item,
  countSynced,
  totalOrphanChildren,
  disableSync,
}: any) => (
  <div
    className="category-header"
    style={{
      cursor: !item.totalChildren ? 'initial' : 'pointer',
    }}
  >
    <Row className="align-items-center w-100 gx-2">
      <Col xs="auto">
        <Box m={-1}>
          <Checkbox
            disabled={disableSync}
            data-testid={`checkbox-${item.name}`}
            checked={checked}
            indeterminate={indeterminate}
            onClick={(event: any) => onClick(event, item.id)}
          />
        </Box>
      </Col>
      <Col>
        <Typography
          data-testid={item.name}
          className={cx({'text-placeholder': !checked && !indeterminate})}
        >
          {item.name}
        </Typography>
      </Col>
      <Col xs="auto">
        {!!item.totalChildren && (
          <Typography
            variant="body2"
            className="font-weight-bold text-gray-title"
          >
            {countSynced(item.id)}/{totalOrphanChildren(item.id)}{' '}
            <FormattedMessage id="dashboard.events.tixstock.synced" />
          </Typography>
        )}
      </Col>
    </Row>
  </div>
);

type ChildCategoriesListProps = {
  childrenCategories: ITixstockCategoryWithChildren[];
  isSelected: (id: string) => boolean;
  isIndeterminateSelection: (id: string) => boolean;
  updateSelection: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  countSynced: (id: string) => number;
  totalOrphanChildren: (id: string) => number;
  disableSync?: boolean;
};

const ChildCategoriesList = (props: ChildCategoriesListProps) => {
  const {
    childrenCategories,
    isSelected,
    isIndeterminateSelection,
    updateSelection,
    countSynced,
    totalOrphanChildren,
    disableSync,
  } = props;
  return (
    <>
      {childrenCategories.map(item => (
        <CollapsableBlock
          key={item.id}
          header={(expanded: boolean) => (
            <ChildCategoryHeader
              checked={isSelected(item.id)}
              expanded={expanded}
              indeterminate={
                isIndeterminateSelection(item.id) && !isSelected(item.id)
              }
              item={item}
              onClick={updateSelection}
              countSynced={countSynced}
              totalOrphanChildren={totalOrphanChildren}
              disableSync={disableSync}
            />
          )}
          isCollapsable={!!item.totalChildren}
          className="child-category"
          headerWrapperClass="child-category-header-wrapper"
          restContent={undefined}
        >
          {!!item.children.length && (
            <div className="inner">
              <SubcategoriesTree
                subSubcategory={item.children}
                isSelected={isSelected}
                isIndeterminateSelection={isIndeterminateSelection}
                updateSelection={updateSelection}
                disableSync={disableSync}
              />
            </div>
          )}
        </CollapsableBlock>
      ))}
    </>
  );
};

export default ChildCategoriesList;
