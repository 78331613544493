import {useDispatch} from 'react-redux';
import {ENQUEUE_SNACKBAR} from '../../constants/actionTypes';
import {useCallback} from 'react';

export default function useSnackBar() {
  const dispatch = useDispatch();

  const showMessage = useCallback(
    (message, options: {variant?: 'success' | 'error' | 'warning'} = {}) => {
      dispatch({
        type: ENQUEUE_SNACKBAR,
        notification: {
          message: message,
          key: new Date().getTime() + Math.random(),
          options,
        },
      });
    },
    []
  );

  return {
    showMessage,
  };
}
