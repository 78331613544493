import {SaveFeature} from '../../Common/SaveFeature';
import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from './types';
import {useEffect, useState} from 'react';

interface SeatmapSaveFeatureProps {
  onSave: () => void;
  onClose: () => void;
  updatesSet: Set<string>;
  isFormDisabled: boolean;
  watch: UseFormWatch<SeatmapFormFields>;
}

export function SeatmapSaveFeature(props: SeatmapSaveFeatureProps) {
  const {onSave, onClose, watch, updatesSet, isFormDisabled} = props;

  const [isOpen, setIsOpen] = useState(false);

  const isSVGWithSeatmapFormat = watch('isSVGWithSeatmapFormat');
  const seatmapFile = watch('seatmapFile');
  const sections = watch('sections');

  useEffect(() => {
    setIsOpen(!isFormDisabled && updatesSet.size > 0);
  }, [
    isSVGWithSeatmapFormat,
    sections,
    seatmapFile,
    isFormDisabled,
    updatesSet.size,
  ]);

  return (
    <SaveFeature
      onSave={onSave}
      num={updatesSet.size}
      onClose={onClose}
      open={isOpen}
    />
  );
}
