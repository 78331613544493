import clsx from 'clsx';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {FormControl, FormHelperText} from '@material-ui/core';
import DatePicker from '../Inputs/DatePicker';
import moment from 'moment';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

export type DatePickerControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  excludeLabel?: boolean;
  formGroupClass?: string;
  displayFormat?: string;
  outlined?: boolean;
  endAdornment?: JSX.Element;
  disabled?: boolean;
  isInvalid?: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  clearErrorsOnChange?: () => void;
};

const DatePickerController = <T extends FieldValues>(
  props: Readonly<DatePickerControllerProps<T>>
) => {
  const {
    name,
    label,
    placeholder,
    excludeLabel,
    control,
    displayFormat,
    formGroupClass,
    outlined,
    endAdornment,
    disabled,
    shouldDisableDate,
    clearErrorsOnChange,
  } = props;
  // Format date from iFormat to fFormat
  const formatDate = (
    date: string,
    iFormat?: string,
    fFormat?: string,
    defaultValue = null
  ) => {
    const m = moment(date, iFormat);
    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  const extra = {label, inputVariant: '', size: ''};

  if (outlined) {
    extra.inputVariant = 'outlined';
    extra.size = 'small';
    delete extra.label;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        const touched = !!error;
        const handleChange = (val: string) => {
          const formatedDate = formatDate(val, displayFormat);
          onChange(formatedDate);
          if (clearErrorsOnChange) {
            clearErrorsOnChange();
          }
        };

        return (
          <FormControl
            data-testid="date-picker-input"
            className={clsx(
              'form-group d-flex',
              formGroupClass ?? 'mb-3 mb-lg-fg',
              {
                'form-group-invalid': touched && error,
              }
            )}
            error={!!error}
          >
            {label && !excludeLabel && (
              <span className="small-label pb-2">{label}</span>
            )}
            <DatePicker
              value={value}
              format={displayFormat}
              placeholder={placeholder}
              onChange={handleChange}
              fullWidth
              disabled={disabled}
              isInvalid={!!error}
              InputLabelProps={{shrink: true}}
              InputProps={{onBlur}}
              {...extra}
              outlined={outlined ? 'outlined' : ''}
              endAdornment={endAdornment}
              {...{helperText: '', error: false}}
              shouldDisableDate={shouldDisableDate}
            />
            {/* Show error here if errorHelperText is false */}
            {touched && error && (
              <FormHelperText error>{String(error.message)}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default DatePickerController;
