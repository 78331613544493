import {
  DateSelectOnFilterChangePayload,
  FilterChangePayload,
  FilterInputTypesEnum,
} from '../types';
import useSearchParams from '../../../../hooks/utils/useSearchParams';
import {useCallback, useRef, useState} from 'react';

export function useModularFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValuesRef = useRef<Record<string, FilterChangePayload>>({});
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const onDateSelectFilterChange = useCallback(
    (
      payload: DateSelectOnFilterChangePayload,
      urlSearchParams: URLSearchParams
    ) => {
      const {value, name} = payload;
      const fromKey = `filters_${name}.from`;
      const toKey = `filters_${name}.to`;
      const selectingTypeKey = `filters_${name}.selectingType`;
      const lastOptionKey = `filters_${name}.lastOption`;
      const lastOptionValueKey = `filters_${name}.lastOptionValue`;

      if (value.from) {
        urlSearchParams.set(fromKey, value.from);
      }
      if (value.to) {
        urlSearchParams.set(toKey, value.to);
      }
      if (value.selectingType) {
        urlSearchParams.set(selectingTypeKey, value.selectingType);
      }
      if (value.lastOption) {
        urlSearchParams.set(lastOptionKey, value.lastOption);
      }
      if (value.lastOptionValue) {
        urlSearchParams.set(lastOptionValueKey, value.lastOptionValue);
      }
    },
    []
  );

  const updateUrlSearch = useCallback(() => {
    if (!filterValuesRef.current) return;
    const oldSearchParams = new URLSearchParams(searchParams.toString());
    const newSearchParams = new URLSearchParams();
    Object.values(filterValuesRef.current).forEach(payload => {
      switch (payload.type) {
        case FilterInputTypesEnum.CHECKBOX:
          if (payload.value) {
            newSearchParams.set(`filters_${payload.name}`, 'true');
          }
          break;
        case FilterInputTypesEnum.DATE_SELECT:
          onDateSelectFilterChange(payload, newSearchParams);
          break;
        case FilterInputTypesEnum.MULTISELECT_AUTOCOMPLETE:
          if (payload.value.length) {
            newSearchParams.set(
              `filters_${payload.name}`,
              JSON.stringify(payload.value)
            );
          }
          break;
        case FilterInputTypesEnum.AUTOCOMPLETE:
          if (payload.value) {
            newSearchParams.set(`filters_${payload.name}`, payload.value);
          }
          break;
        case FilterInputTypesEnum.RADIO:
          newSearchParams.set(`filters_${payload.name}`, String(payload.value));
          break;
        default:
          break;
      }
    });

    const unrelatedFilters = Array.from(oldSearchParams.entries()).filter(
      ([key]) => !key.startsWith('filters_')
    );
    unrelatedFilters.forEach(([key, value]) => newSearchParams.set(key, value));

    setSearchParams(newSearchParams, oldSearchParams);
  }, [onDateSelectFilterChange, searchParams, setSearchParams]);

  const onClear = useCallback(() => {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const oldSearchParams = new URLSearchParams(searchParams.toString());
    Array.from(searchParams.keys())
      .filter(key => key.startsWith('filters_'))
      .forEach(key => searchParams.delete(key));

    setSearchParams(searchParams, oldSearchParams);
  }, [searchParams, setSearchParams]);

  const onFilterChange = useCallback((payload: FilterChangePayload) => {
    const name = payload.name;
    const shouldUpdateFiltersCount = !filterValuesRef.current[name];
    filterValuesRef.current[payload.name] = payload;
    if (shouldUpdateFiltersCount) {
      setActiveFiltersCount(prev => prev + 1);
    }
  }, []);

  const onFilterRemove = useCallback((name: string) => {
    if (name in filterValuesRef.current) {
      delete filterValuesRef.current[name];
    }
    const keysNumber = Object.keys(filterValuesRef.current).length;
    setActiveFiltersCount(prev => {
      if (prev !== keysNumber) {
        return keysNumber;
      }
      return prev;
    });
  }, []);

  return {
    onFilterChange,
    onClear,
    onFilterRemove,
    filterValues: filterValuesRef.current,
    activeFiltersCount,
    updateUrlSearch,
  };
}
