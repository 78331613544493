import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../../Common/Button';
import React from 'react';
import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../types';
import {useIntl} from 'react-intl';
import {useCanDeleteSection} from './hooks/useCanDeleteSection';

interface DeleteSectionButtonProps {
  disabled?: boolean;
  sectionId: string;
  remove: (sectionId: string) => void;
  watch: UseFormWatch<SeatmapFormFields>;
  isSectionMissing: boolean;
}

export function DeleteSectionButton(props: DeleteSectionButtonProps) {
  const {disabled, sectionId, remove, watch, isSectionMissing} = props;
  const intl = useIntl();

  const canDeleteSection = useCanDeleteSection(watch, isSectionMissing);

  if (!canDeleteSection) return null;

  return (
    <Button
      data-testid={'seatmap-section-delete-button'}
      disabled={disabled}
      link
      small
      secondary
      style={{fontWeight: '600'}}
      title={intl.formatMessage({
        id: 'dashboard.seatmap.details.delete_section',
      })}
      icon={<CloseIcon style={{width: 14}} />}
      onClick={() => {
        remove(sectionId);
      }}
    />
  );
}
