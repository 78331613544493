import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import Button from '../../../Common/Button';
import {
  CalendarIcon,
  EventManualIcon,
  ImportedEventIcon,
  InfoPrimaryRedIcon,
  TicketIcon,
  UpdateIcon,
} from '../../../../constants/images';
import {ISeatmap} from '../../../../services/eventsApi';
import moment from 'moment';
import ConfirmationModal from '../../../Common/ConfirmationModal';
import PermissionContainer from '../../../Common/PermissionContainer';
import {SeatmapPermissions} from '../../../../constants/permissions';
import {
  DeleteSeatmapQueryParam,
  useDeleteSeatmapMutation,
} from '../../../../services/eventsApi/seatmapApi';
import useSnackBar from '../../../../hooks/app/useSnackBar';
import {useCallback, useEffect, useState} from 'react';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import {useHistory} from 'react-router-dom';
import {EVENTS_SEATMAP_PATH} from '../../../../constants/urls';

export type SeatmapDetailsHeaderProps = {
  seatmap: ISeatmap;
};

const SeatmapDetailsHeader = ({seatmap}: SeatmapDetailsHeaderProps) => {
  const elementClassName = 'd-inline-flex align-items-center gap-2';
  const intl = useIntl();
  const history = useHistory();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const {showMessage} = useSnackBar();

  const [deleteSeatmap, {isSuccess}] = useDeleteSeatmapMutation();

  const provider = seatmap?.providers?.at(0);

  useEffect(() => {
    if (isSuccess) {
      showMessage(
        intl.formatMessage({
          id: 'messages.SEATMAP_DELETED_SUCCESSFULLY',
        }),
        {variant: 'success'}
      );
      history.replace(EVENTS_SEATMAP_PATH);
    }
  }, [intl, isSuccess, history, showMessage]);

  const onDeleteConfirmed = useCallback(() => {
    if (!seatmap) return;
    const seatmapData: DeleteSeatmapQueryParam = {
      id: seatmap.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
    };
    deleteSeatmap(seatmapData);
    setIsConfirmationOpen(false);
  }, [deleteSeatmap, intl, seatmap]);

  return (
    <Container fluid className="top-block bg-white">
      <div className="details-header sale-details-header pb-3">
        <Row>
          <Col>
            <Typography
              variant="h3"
              className="font-weight-bolder pb-2 text-center text-lg-start"
              data-testid="header-seatmap-name"
            >
              {seatmap.name}
            </Typography>
            <Row className="gx-3 py-1 pb-lg-0 align-items-center justify-content-center justify-content-lg-start">
              <Col xs="auto">
                {!provider ? (
                  <div
                    className={elementClassName}
                    data-testid="header-seatmap-source"
                  >
                    <EventManualIcon style={{width: 15, height: 15}} />
                    <span>
                      <FormattedMessage id="dashboard.seatmap.details.manually_added" />
                    </span>
                  </div>
                ) : (
                  <div
                    className={elementClassName}
                    data-testid="header-seatmap-source"
                  >
                    <ImportedEventIcon style={{width: 15, height: 15}} />
                    <span>
                      <FormattedMessage id="dashboard.seatmap.details.imported" />
                    </span>
                  </div>
                )}
              </Col>
              <Col xs="auto">
                {!seatmap.imageUrl && (
                  <div className="d-flex align-items-center gap-1">
                    <InfoPrimaryRedIcon
                      style={{width: 11, height: 11, marginBottom: 2}}
                    />
                    <Typography
                      color="primary"
                      className="font-bold-italic text-truncate"
                      variant="body2"
                    >
                      <FormattedMessage id="dashboard.events.list.seat_map_missing" />
                    </Typography>
                  </div>
                )}
                {provider?.changes && (
                  <div className={elementClassName}>
                    <UpdateIcon style={{width: 11, height: 11}} />
                    <Typography
                      variant="body2"
                      className="text-blue font-bold-italic"
                    >
                      <FormattedMessage id="dashboard.seatmap.seat_map_update" />
                    </Typography>
                  </div>
                )}
              </Col>
              {!provider && (
                <Col xs="auto">
                  <div
                    className={elementClassName}
                    data-testid="header-seatmap-creation-date"
                  >
                    <CalendarIcon
                      style={{width: 15, heigh: 15, color: '#989898'}}
                    />
                    <span className="text-muted">
                      <FormattedMessage id="dashboard.seatmap.details.created" />{' '}
                      {moment(seatmap.createdAt).format('DD/MM/YYYY h:mm A')}
                    </span>
                  </div>
                </Col>
              )}
              {provider && (
                <>
                  <Col xs="auto">
                    <div className={elementClassName}>
                      <TicketIcon style={{width: 17, heigh: 15}} />
                      <span className="text-muted">
                        <FormattedMessage id="dashboard.seatmap.details.source" />
                        :{provider.source}
                      </span>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <div
                      data-testid="header-seatmap-imported-date"
                      className={elementClassName}
                    >
                      <CalendarIcon
                        style={{width: 15, heigh: 15, color: '#989898'}}
                      />
                      <span className="text-muted">
                        <FormattedMessage id="dashboard.seatmap.details.imported" />{' '}
                        {moment(provider.importDate).format(
                          'DD/MM/YYYY h:mm A'
                        )}
                      </span>
                    </div>
                  </Col>
                </>
              )}
              <Col xs="auto">
                <div
                  className={elementClassName}
                  data-testid="header-seatmap-updated-date"
                >
                  <CalendarIcon
                    style={{width: 15, heigh: 15, color: '#989898'}}
                  />
                  <span className="text-muted">
                    <FormattedMessage id="dashboard.seatmap.details.last_updated" />{' '}
                    {moment(seatmap.updatedAt).format('DD/MM/YYYY h:mm A')}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="text-center">
            <PermissionContainer
              // @ts-ignore
              permissions={[SeatmapPermissions.deleteSeatmapDetails]}
            >
              <Button
                link
                className="w-min-unset normal-font"
                data-testid="header-seatmap-delete-btn"
                title={intl.formatMessage({id: 'actions.delete'})}
                onClick={() => {
                  setIsConfirmationOpen(true);
                }}
              />
            </PermissionContainer>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        opened={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onAccept={onDeleteConfirmed}
        description={intl.formatMessage({
          id: 'dashboard.confirm_continue_process',
        })}
      />
    </Container>
  );
};

export default SeatmapDetailsHeader;
