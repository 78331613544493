import {IAPIListOptions} from './types';

export function getPaginationAPIParams(options: IAPIListOptions): string {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const urlSearchParams = new URLSearchParams();
  if (options.page) {
    urlSearchParams.append('page', options.page.toString());
  }
  if (options.perPage) {
    urlSearchParams.append('pageSize', options.perPage.toString());
  }
  if (options.q) {
    urlSearchParams.append('q', options.q);
  }

  if (options.sort) {
    urlSearchParams.append('sort', options.sort);
  }

  if (options.direction) {
    urlSearchParams.append('direction', options.direction);
  }

  return urlSearchParams.toString();
}
