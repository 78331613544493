import {UpdateIcon} from '../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {ITheme} from '../../../../constants/theme';
import useTheme from '@material-ui/styles/useTheme';
import clsx from 'clsx';

interface UpdateTitleProps {
  updatedValue?: string | number | null;
  updatedValueLabel?: string;
  className?: string;
}

export function Updates(props: Readonly<UpdateTitleProps>) {
  const {updatedValue, className, updatedValueLabel} = props;
  const theme: ITheme = useTheme();

  if (!updatedValue) return null;

  return (
    <div
      className={clsx('d-inline-flex align-items-center w-100 mt-1', {
        className,
      })}
    >
      <div className="d-inline-flex">
        <UpdateIcon style={{width: 11, height: 11, marginBottom: 2}} />
      </div>
      <Typography
        style={{color: theme.palette?.blue?.main}}
        variant="body2"
        className="ps-1 font-bold-italic text-truncate"
      >
        {updatedValueLabel ?? ''} {updatedValue}
      </Typography>
    </div>
  );
}
