import * as yup from 'yup';
import {AnyObject, ObjectSchema} from 'yup';
import {IntlShape} from 'react-intl';
import {VenueFormFields} from '../types';

export const VenueFormDefaultValues: Readonly<VenueFormFields> = {
  name: '',
  addressLine1: '',
  addressLine2: null,
  city: '',
  country: '',
  latitude: null,
  longitude: null,
};

type VenueFormSchema = ObjectSchema<
  Omit<VenueFormFields, 'city' | 'country' | 'latitude' | 'longitude'>,
  AnyObject
>;

export function getVenueValidationSchema(intl: IntlShape): VenueFormSchema {
  return yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({id: 'validation.error.required'})),
    addressLine1: yup
      .string()
      .required(
        intl.formatMessage({id: 'validation.error.select_from_suggestions'})
      ),
    addressLine2: yup.string().optional().nullable(),
  });
}
