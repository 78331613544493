import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles, Theme} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
  EDIT_ICON_DEFAULT,
  MinusIcon,
  PlusIcon,
} from '../../../../constants/images';
import {Col, Row} from 'reactstrap';
import Button from '../../../Common/Button';
import {useState} from 'react';
import {
  ISeatmapSection,
  useGetSeatmapQuery,
} from '../../../../services/eventsApi';
import * as urls from '../../../../constants/urls';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    '&:before': {
      content: 'unset',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.border}`,
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(0, 1),
    minHeight: 'unset',
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(1, 0),
    },
  },
  details: {
    display: 'block',
    padding: 0,
  },
  item: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.text.border}`,
    },
  },
}));

export type SectionsListProps = {
  seatmapId: number;
};

type SectionItemProps = {
  section: ISeatmapSection;
};

const SectionItem = ({section}: SectionItemProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      key={section.id}
      className={classes.root}
      onChange={(e, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={
          !section?.blocks.length ? null : expanded ? (
            <MinusIcon />
          ) : (
            <PlusIcon />
          )
        }
      >
        <Typography variant="body2">{section.name}</Typography>
      </AccordionSummary>
      {!!section?.blocks.length && (
        <AccordionDetails className={classes.details}>
          {section.blocks.map(item => (
            <Box key={item.id} className={classes.item}>
              <Typography variant="body2">{item.name}</Typography>
            </Box>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const SectionsList = ({seatmapId}: SectionsListProps) => {
  const {data: seatmap} = useGetSeatmapQuery({
    id: Number(seatmapId),
    formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
    showProgressDialog: true,
  });
  const intl = useIntl();
  return (
    <div>
      {seatmap && (
        <>
          <Row className="align-items-center mb-2">
            <Col>
              <Typography variant="subtitle2" className="text-placeholder">
                <FormattedMessage id="dashboard.events.details.sections" />*
              </Typography>
            </Col>
            <Col xs="auto">
              <a
                href={urls.SEATMAP_DETAILS_PATH.replace(
                  ':id',
                  seatmap.id.toString()
                )}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  link
                  primary
                  small
                  title={intl.formatMessage({id: 'actions.edit'})}
                  startIcon={EDIT_ICON_DEFAULT}
                  onClick={undefined}
                />
              </a>
            </Col>
          </Row>
          <Box display="flex" flexDirection="column" gridGap={10}>
            {seatmap.sections.map(section => (
              <SectionItem key={section.id} section={section} />
            ))}
          </Box>
        </>
      )}
    </div>
  );
};

export default SectionsList;
