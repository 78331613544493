import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useTheme} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  ArrowDownIcon,
  ArrowRightSplitIcon,
  ArrowUpIcon,
  SearchIcon,
} from '../../../../constants/images';
import ParentCategories from './ParentCategories';
import Radio from '../../../Common/Radio';
import {
  Breadcrumbs,
  ClickAwayListener,
  FormHelperText,
  Link,
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import {useMultiLevelCategoryStyles} from './styles';
import {useIntl} from 'react-intl';
import clsx from 'clsx';
import {buildCategoryParentsMap} from '../../Categories/CategoryForm/helpers';
import {ICategory, useGetCategoriesQuery} from '../../../../services/eventsApi';
import Theme from '../../../../constants/theme';
import {FieldErrors} from 'react-hook-form';

interface SelectMultiLevelCategoryProps {
  label?: string;
  placeholder?: string;
  className?: string;
  onChange: (id: number | null, hasSportPatent?: boolean) => void;
  onBlur?: () => void;
  existingCategory?: ICategory | null;
  existingCategoryId?: number | null;
  disableId?: number;
  formCategoryId?: number | null;
  disabled?: boolean;
  showFullPath?: boolean;
  errors?: FieldErrors<any>;
}

const SelectMultiLevelCategory = (props: SelectMultiLevelCategoryProps) => {
  const theme = useTheme<typeof Theme>();
  const {
    label,
    className,
    placeholder,
    onChange,
    onBlur,
    disabled,
    existingCategory,
    existingCategoryId,
    disableId,
    showFullPath,
    errors,
    formCategoryId,
  } = props;

  const {data: categories = []} = useGetCategoriesQuery({});
  const classes = useMultiLevelCategoryStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedCategoriesTree, setSelectedCategoriesTree] = useState<
    ICategory[]
  >([]);
  const [displayCategoriesTree, setDisplayCategoriesTree] = useState<
    ICategory[]
  >([]);

  const [selectedCategory, setSelectedCategory] = useState<
    ICategory | undefined | null
  >(existingCategory);

  const [searchText, setSearchText] = useState('');
  const [categoryParentsMap, setCategoryParentsMap] = useState(
    buildCategoryParentsMap(categories)
  );
  const openButtonRef = useRef<HTMLButtonElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'multi-level-select' : undefined;
  const selectedCategoryIdsTree = selectedCategoriesTree.map(
    category => category.id
  );
  const displayCategoryIdsTree = displayCategoriesTree.map(
    category => category.id
  );

  const handleClick: MouseEventHandler<HTMLElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const flatCategories = useCallback((array: ICategory[]) => {
    let result: ICategory[] = [];
    array.forEach((a: ICategory) => {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flatCategories(a.children));
      }
    });
    return result;
  }, []);

  const findCategoryById = useCallback(
    (id: string | number) => {
      const category = categories.find(c => c.id === Number(id));
      if (category) {
        return category;
      }
      return flatCategories(categories).find(c => c.id === Number(id));
    },
    [categories, flatCategories]
  );

  const onSelectDisplay = useCallback(
    (category: ICategory) => {
      if (category.parentId) {
        let categoryParents = categoryParentsMap.get(category.id);
        const tempCategories = [];
        while (categoryParents?.length) {
          const parentCategory = findCategoryById(categoryParents[0]);
          if (parentCategory) {
            tempCategories.unshift(parentCategory);
            categoryParents = parentCategory.parentId
              ? categoryParentsMap.get(parentCategory.id)
              : undefined;
          }
        }
        setDisplayCategoriesTree([...tempCategories, category]);
      } else {
        setDisplayCategoriesTree([category]);
      }
    },
    [categoryParentsMap, findCategoryById]
  );

  const onSelect = useCallback(
    (category: ICategory) => {
      onSelectDisplay(category);
      if (!category.parentId) {
        setSelectedCategoriesTree([category]);
        return;
      }
      findCategoryById(category.parentId);
      let categoryParents = categoryParentsMap.get(category.id);
      const tempCategories = [];
      while (categoryParents?.length) {
        const parentCategory = findCategoryById(categoryParents[0]);
        if (!parentCategory) {
          continue;
        }
        tempCategories.unshift({...parentCategory});
        categoryParents = parentCategory.parentId
          ? categoryParentsMap.get(parentCategory.id)
          : undefined;
      }
      setSelectedCategoriesTree([...tempCategories, category]);
    },
    [categoryParentsMap, findCategoryById, onSelectDisplay]
  );

  const applySearch = (searchCategories: ICategory[]) => {
    if (!searchText) {
      return searchCategories;
    }
    const results = [];

    function searchChildren(
      category: ICategory
    ): ICategory & {hasMatch: boolean} {
      let hasMatch = false;
      const filteredChildren = [];

      if (category.name.toLowerCase().includes(searchText.toLowerCase())) {
        hasMatch = true;
      }

      for (const child of category.children) {
        const childResult = searchChildren(child);
        if (childResult.hasMatch) {
          hasMatch = true;
          filteredChildren.push(childResult);
        }
      }

      return {...category, children: filteredChildren, hasMatch};
    }

    for (const child of searchCategories) {
      const result = searchChildren(child);
      if (result.hasMatch) {
        results.push(result);
      }
    }

    return results;
  };

  const onCategoryClick = (category: ICategory) => {
    setSelectedCategory(category);
    let hasSportParent = false;
    let categoryParents = categoryParentsMap.get(category.id);
    while (categoryParents?.length) {
      if (hasSportParent) {
        break;
      }
      const parentCategory = findCategoryById(categoryParents[0]);
      if (!parentCategory) {
        continue;
      }
      categoryParents = parentCategory.parentId
        ? categoryParentsMap.get(parentCategory.id)
        : undefined;

      hasSportParent = parentCategory.name.toLowerCase().includes('sport');
    }

    onChange(category.id, hasSportParent);
    if (!open) {
      openButtonRef.current?.click();
    }
  };

  useEffect(() => {
    if (categories.length) {
      setCategoryParentsMap(buildCategoryParentsMap(categories));
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategory) {
      const category = findCategoryById(selectedCategory.id);
      if (category) {
        onSelect(category);
      }
    }
  }, [selectedCategory, onSelect, findCategoryById, open]);

  useEffect(() => {
    if (formCategoryId && formCategoryId !== selectedCategory?.id) {
      const category = findCategoryById(formCategoryId);
      if (category) {
        onSelect(category);
      }
    }
  }, [formCategoryId, selectedCategory, findCategoryById, onSelect]);

  useEffect(() => {
    if (existingCategoryId) {
      const category = findCategoryById(existingCategoryId);
      if (category) {
        onSelect(category);
      }
    }
  }, [existingCategoryId, findCategoryById, onSelect]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div data-testid="category-input">
        <div className={clsx(classes.root, className)}>
          <Typography variant="subtitle2">
            {label ??
              `${intl.formatMessage({
                id: 'dashboard.events.add.parent_category',
              })}*`}
          </Typography>
          <ButtonBase
            disableRipple
            className={clsx(classes.button, {
              disabled,
              invalid: errors?.categoryId || errors?.parentId,
            })}
            onClick={open ? handleClose : handleClick}
            ref={openButtonRef}
            disabled={disabled}
            onBlur={() => onBlur && setTimeout(onBlur, 100)}
          >
            {selectedCategoriesTree.length === 0 ? (
              <Typography
                variant="body2"
                className={clsx('text-truncate', classes.textPlaceholder)}
              >
                {placeholder ??
                  intl.formatMessage({
                    id: 'dashboard.multi_level_category.select_category',
                  })}
              </Typography>
            ) : (
              <Breadcrumbs
                classes={{
                  root: classes.categories,
                  separator: classes.categorySeparator,
                }}
                className={clsx({disabled})}
                separator={
                  <ArrowRightSplitIcon
                    style={{
                      width: 15,
                      height: 15,
                      color: !disabled
                        ? theme?.palette?.primary.main
                        : theme?.palette?.text.disabled,
                    }}
                  />
                }
                aria-label="select-category-tree"
                maxItems={4}
                onClick={e => e.stopPropagation()}
              >
                {selectedCategoriesTree
                  .filter(c =>
                    !showFullPath ? c.id !== existingCategory?.id : true
                  )
                  .map((category, index, {length}) => {
                    return index === length - 1 ? (
                      <Typography
                        data-testid="category-bread-crumbs"
                        key={category.id}
                        variant="body2"
                        className={clsx('text-truncate')}
                        style={{cursor: 'pointer'}}
                        onClick={() => onCategoryClick(category)}
                      >
                        {category.name}
                      </Typography>
                    ) : (
                      <Link
                        data-testid="category-bread-crumbs"
                        key={category.id}
                        className={clsx(classes.categoryLink, {disabled})}
                        onClick={() => onCategoryClick(category)}
                      >
                        {category.name}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            )}
            {!disabled &&
              (open ? (
                <ArrowUpIcon style={{width: 9, height: 7}} />
              ) : (
                <ArrowDownIcon style={{width: 9, height: 7}} />
              ))}
          </ButtonBase>
        </div>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className={classes.popper}
          disablePortal
          popperOptions={{
            modifiers: {
              offset: {
                offset: '0,5',
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: true,
                boundariesElement: 'viewport',
              },
            },
          }}
          style={{minWidth: anchorEl?.clientWidth}}
        >
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <ParentCategories
                categories={categories}
                selectedCategoryIdsTree={displayCategoryIdsTree}
                onSelect={onCategoryClick}
                disabledId={disableId}
              />
            </Grid>
            <Grid item xs={7}>
              {displayCategoriesTree.length > 1 &&
                !!displayCategoriesTree.at(1)?.children.length && (
                  <Box className={classes.paper}>
                    <InputBase
                      autoFocus
                      className={classes.searchInput}
                      placeholder={intl.formatMessage({
                        id: 'dashboard.events.add.search',
                      })}
                      value={searchText}
                      onChange={e => {
                        setSearchText(e.target.value);
                      }}
                      endAdornment={
                        <SearchIcon style={{width: 10, height: 10}} />
                      }
                    />
                    <>
                      {displayCategoriesTree.at(1)?.children.length ? (
                        <RadioGroup
                          className={clsx(classes.list, 'px-2 px-lg-3 py-2')}
                          name={displayCategoriesTree.at(1)?.name}
                        >
                          {applySearch(
                            displayCategoriesTree.at(1)!.children
                          ).map(child => (
                            <CategoriesRadioTree
                              category={child}
                              level={2}
                              selectedCategoryIdsTree={selectedCategoryIdsTree}
                              onSelect={onCategoryClick}
                              key={child.id}
                              disableId={disableId}
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        <div className="px-3 py-2">
                          <Typography>(empty)</Typography>
                        </div>
                      )}
                    </>
                  </Box>
                )}
            </Grid>
          </Grid>
        </Popper>
        {errors && (
          <FormHelperText error>{errors.categoryId?.message}</FormHelperText>
        )}
      </div>
    </ClickAwayListener>
  );
};

interface CategoriesRadioTreeProps {
  category: ICategory;
  level: number;
  disableId?: number;
  selectedCategoryIdsTree: number[];
  onSelect: (category: ICategory) => void;
}

function CategoriesRadioTree(props: Readonly<CategoriesRadioTreeProps>) {
  const {category, level, selectedCategoryIdsTree, onSelect, disableId} = props;
  const classes = useMultiLevelCategoryStyles();
  const disabled = category.id === disableId;
  const ignoreClick =
    selectedCategoryIdsTree.at(-1) === category.id || disabled;

  return (
    <div>
      <FormControlLabel
        className={classes.label}
        value={category.id}
        disabled={disabled}
        onClick={() => {
          if (!ignoreClick) {
            onSelect(category);
          }
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={
          <Radio
            color="primary"
            disabled={disabled}
            checked={selectedCategoryIdsTree.includes(category.id)}
          />
        }
        label={category.name}
      />
      <RadioGroup className={'ms-3'}>
        {/*
              Setting every child to a disabled item to disabled by sending
              the child id as disabled in the current item is disabled,
              this would prevent assigning a parent category to it's child
             */}
        {category.children.map(child => (
          <CategoriesRadioTree
            key={child.id}
            category={child}
            level={level + 1}
            selectedCategoryIdsTree={selectedCategoryIdsTree}
            onSelect={onSelect}
            disableId={disabled ? child.id : disableId}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

export default SelectMultiLevelCategory;
