import {FeedbackParams} from '../../store/queries/common';
import {eventsManagementApi} from './eventsManagementApi';
import {IAPIListOptions, IPaginatedResponse, IPerformer} from './types';
import {getPaginationAPIParams} from './utils';

export type AddPerformerQueryParam = {
  body: Partial<IPerformer>;
} & FeedbackParams;

export interface PerformerListQueryParams
  extends IAPIListOptions,
    FeedbackParams {}

export interface DeletePerformerQueryParam extends FeedbackParams {
  id: number;
}

export const performerApi = eventsManagementApi.injectEndpoints({
  endpoints: builder => ({
    checkPerformerName: builder.query<void, {name: string} & FeedbackParams>({
      query: ({name}) => ({
        url: `performers/names/${name}`,
        method: 'HEAD',
      }),
    }),
    getPerformers: builder.query<
      IPaginatedResponse<IPerformer>,
      PerformerListQueryParams
    >({
      query: (options: IAPIListOptions = {}) => {
        const url = 'performers';
        const urlSearchParams = getPaginationAPIParams(options);

        if (urlSearchParams) {
          return `${url}?${urlSearchParams}`;
        }

        return url;
      },
    }),
    getPerformer: builder.query<IPerformer, {id: number} & FeedbackParams>({
      query: ({id}) => `performers/${id}`,
    }),
    addPerformer: builder.mutation<IPerformer, AddPerformerQueryParam>({
      query: ({body}) => ({
        url: 'performers',
        method: 'POST',
        body,
      }),
    }),
    editPerformer: builder.mutation<
      {data: IPerformer; message: string},
      AddPerformerQueryParam & {id: string}
    >({
      query: ({body, id}) => ({
        url: `performers/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deletePerformer: builder.mutation<IPerformer, DeletePerformerQueryParam>({
      query: ({id}) => ({
        url: `performers/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPerformersQuery,
  useAddPerformerMutation,
  useGetPerformerQuery,
  useLazyCheckPerformerNameQuery,
  useEditPerformerMutation,
  useDeletePerformerMutation,
} = performerApi;
