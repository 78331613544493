import {useCallback} from 'react';
import {getUrlSearchParams} from '../../../utils/filters';
import useSearchParams from '../../../hooks/utils/useSearchParams';

export const useTableSort = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleParameterChange = useCallback(
    (type, value) => {
      if (type === 'order') {
        const currentParams = getUrlSearchParams(searchParams);
        const params = getUrlSearchParams(searchParams);
        params.set('sort', value.orderBy);
        params.set('sortDirection', value.order.toLowerCase());
        setSearchParams(params, currentParams);
      }
    },
    [searchParams, setSearchParams]
  );

  return {
    handleSortChange: handleParameterChange,
  };
};

export default useTableSort;
