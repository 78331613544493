import {useHistory} from 'react-router-dom';
import {useSearch} from '../../../../hooks/common/useSearch';
import {useEffect, useState} from 'react';
import {IAPIListOptions} from '../../../../services/eventsApi';
import {getPaginationURLParams} from '../../../../utils/filters';
import usePerformerList from '../hooks/usePerformerList';
import usePermission from '../../../../Auth/usePermission';
import useTableSort from '../../hooks/useTableSort';
import * as urls from '../../../../constants/urls';
import PermissionContainer from '../../../Common/PermissionContainer';
import {PerformerPermissions} from '../../../../constants/permissions';
import SearchForm from '../../../Form/NonReduxSearchForm';
import DataTable from '../../../Widgets/DataTable';
import {PlusRoundedDangerIcon} from '../../../../constants/images';

interface ListPerformerTabProps {
  onAddClick: () => void;
  scrollToTop: () => void;
}

export function PerformerList(props: Readonly<ListPerformerTabProps>) {
  const {onAddClick, scrollToTop} = props;
  const history = useHistory();
  const {searchText, setSearchText} = useSearch();
  const [loader, setLoader] = useState(false);

  const [performerListOptions, setPerformerListOptions] =
    useState<IAPIListOptions>(getPaginationURLParams());
  const {HeaderCells, rows, isLoading, total, currentPage} =
    usePerformerList(performerListOptions);

  const {hasPermission} = usePermission();
  const {handleSortChange} = useTableSort();

  useEffect(() => {
    scrollToTop();
  }, [currentPage, scrollToTop]);

  const handleRowClick = (performerId: string) => {
    history.push(urls.PERFORMER_EDIT_PATH.replace(':id', performerId));
  };

  const canAddPerformer = hasPermission(PerformerPermissions.addPerformer);

  return (
    <div className="dashboard-content">
      <PermissionContainer
        permissions={[PerformerPermissions.seePerformerList]}
      >
        <div className="row align-items-center justify-content-between search-row py-1">
          <div className="col-xl-4 col-lg-8 d-flex">
            {/* Search */}
            <SearchForm
              loading={isLoading}
              search={searchText}
              onChange={(value: string) => {
                setSearchText(value);
              }}
              onSubmit={() => {
                setPerformerListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: searchText,
                }));
              }}
              onClear={() => {
                setPerformerListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: '',
                }));
              }}
              SearchProps={{
                buttonProps: {
                  onClick: () => {},
                },
              }}
            />
          </div>
        </div>
        <DataTable
          className="performer-table"
          canSelect={false}
          empty={rows.length === 0}
          loading={isLoading || loader}
          clickableRow={hasPermission(PerformerPermissions.seePerformerDetails)}
          onRowClick={
            hasPermission(PerformerPermissions.seePerformerDetails)
              ? (performerId: string) => handleRowClick(performerId)
              : undefined
          }
          {...(canAddPerformer && {
            actionButton: {
              variant: 'filled',
              size: 'sm',
              children: <PlusRoundedDangerIcon />,
              onClick: onAddClick,
            },
          })}
          headCells={HeaderCells.map(item => ({
            ...item,
            numeric: false,
            disablePadding: false,
          }))}
          rows={rows}
          onChange={(
            payload: Partial<IAPIListOptions> & {
              sort?: {by?: string; direction?: 'asc' | 'desc'};
            }
          ) => {
            setLoader(true);
            setPerformerListOptions(prevState => ({
              ...prevState,
              ...(payload.page && {page: payload.page}),
              ...(payload.rowsPerPage && {perPage: payload.rowsPerPage}),
              ...(payload.sort?.by && {
                sort: payload.sort.by,
              }),
              ...(payload.sort?.direction && {
                direction: payload.sort.direction,
              }),
            }));
            setTimeout(() => {
              setLoader(false);
            }, 500);
          }}
          total={total}
          currentPage={Number(currentPage)}
          defaultRowPerPage={performerListOptions.rowsPerPage}
          handleParameterChange={handleSortChange}
          defaultOrder={performerListOptions.direction}
          defaultOrderBy={performerListOptions.sort}
        />
      </PermissionContainer>
    </div>
  );
}
