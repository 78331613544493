import {get, pickBy} from 'lodash';
import queryString from 'query-string';
import {FILTERS_SEARCH_KEY} from '../components/Widgets/FilterForm';
import {getStates} from '../hooks/table/useDatatableState';

export function getQueryStringFromObject<T extends Record<string, any>>(
  obj: T
) {
  // Remove falsy values
  const filtersWithoutEmptyValues = pickBy(obj);
  return filtersWithoutEmptyValues
    ? queryString.stringify(filtersWithoutEmptyValues, {
        arrayFormat: 'bracket',
        skipNull: true,
      })
    : '';
}

export function setFiltersOnUrl<
  T extends Record<string, number | string | boolean>,
>(filters: T): void {
  const params = getQueryStringFromObject(filters);
  window.history.pushState({}, '', `?${params}`);
}

export function getFiltersFromUrl(filterKeys: string[]): Record<string, any> {
  const urlWithOnlyMyFilters = queryString.pick(
    window.location.search,
    filterKeys
  );
  return queryString.parse(urlWithOnlyMyFilters, {arrayFormat: 'bracket'});
}

export const getUrlSearchParams = searchParams => {
  try {
    const windowSearchParams = new URLSearchParams(window.location.search);

    if (
      !searchParams ||
      searchParams instanceof URLSearchParams ||
      searchParams.toString() !== windowSearchParams.toString()
    ) {
      return windowSearchParams;
    }

    return searchParams;
  } catch (e) {
    return new URLSearchParams(
      searchParams && searchParams instanceof URLSearchParams
        ? searchParams.toString()
        : window.location.search
    );
  }
};

export const parseFilterSearchParams = (
  searchParams,
  key = FILTERS_SEARCH_KEY
) => {
  if (!searchParams) {
    return undefined;
  }

  try {
    return JSON.parse(searchParams.get(key) ?? '{}');
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return undefined;
};

export const getParamsFromUrl = (searchParams, defaultValue = {}) => {
  const search = getUrlSearchParams(searchParams);
  const filterValues = parseFilterSearchParams(search);
  const q = search.get('q') ?? '';
  const {
    page = defaultValue?.page,
    itemsPerPage: rowsPerPage = defaultValue?.itemsPerPage,
    orderBy: by = defaultValue?.orderBy,
    orderDirection: direction = defaultValue?.orderDirection,
  } = getStates({searchParams, defaultValues: {}, bindQueryParams: true});

  return {
    q,
    filterValues,
    page,
    rowsPerPage,
    sort: {by, direction},
  };
};

/**
 * Count active filters from `vals`
 * Empty arrays are considered inactive
 *
 * @param {Object} vals    Key/value object where key if the filter name
 * @return {number}        Number of active filters
 */
export const countActiveFilters = (fields, vals = {}) => {
  const activeFilter = key => {
    const val = get(vals, key);
    if (Array.isArray(val)) {
      return val.length > 0;
    } else if (typeof val === 'object') {
      return Object.keys(val).length > 0;
    }

    return !!val;
  };

  return vals && fields ? fields.filter(activeFilter).length : 0;
};

export function getPaginationURLParams() {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const url = new URL(window.location.href);
  const page = url.searchParams.get('page') ?? 1;
  const perPage = url.searchParams.get('perPage') ?? 25;
  const rowsPerPage = url.searchParams.get('rowsPerPage');
  const q = url.searchParams.get('q');
  const sort = url.searchParams.get('sort');
  const direction = (url.searchParams.get('sortDirection') ?? 'asc') as
    | 'asc'
    | 'desc'
    | null;
  return {
    ...(page && {page: Number(page)}),
    ...(perPage && {perPage: Number(perPage)}),
    ...(rowsPerPage && {rowsPerPage: Number(rowsPerPage)}),
    ...(q && {q}),
    ...(sort && {sort}),
    ...(direction && {direction}),
  };
}
