import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody, Col, Row} from 'reactstrap';
import InputField from '../../../Form/Fields/InputField';
import TimePickerField from '../../../Form/Fields/TimePickerField';
import SeatmapDetails from './SeatmapDetails';
import {EventSummary} from './EventSummary';
import SelectMultiLevelCategory from '../../components/SelectMultiLevelCategory/SelectMultiLevelCategory';
import PerformersBlock from './PerformersBlock';
import DomainList from '../../Categories/CategoryForm/DomainList';
import Checkbox from '../../../Common/Checkbox';
import IconButton from '../../../Common/IconButton';
import {ArrowForwardIcon} from '../../../../constants/images';
import TixstockUpdater from '../../components/TixstockUpdater';
import {
  ChangesStatus,
  IChanges,
  IEvent,
  IVenue,
  useGetCategoryQuery,
  useGetDomainsQuery,
} from '../../../../services/eventsApi';
import moment from 'moment';
import {Controller, FormProvider, UseFormReturn} from 'react-hook-form';
import {EventFormFields} from '../types';
import DatePickerController from '../../../Form/HookForm/DatePickerController';
import {useCallback, useEffect, useMemo} from 'react';
import VenueBlock from './VenueBlock';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@material-ui/core';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {get} from 'lodash';
import {useAddEventsErrorElementRef} from '../../../../store/slices/eventsManagement';
import usePermission from '../../../../Auth/usePermission';
import {CategoryPermissions} from '../../../../constants/permissions';
import {EnhancedInputField} from '../../../Form/Fields/EnhancedInputField/EnhancedInputField';

interface EventDetailsFormProps {
  isNew?: boolean;
  event?: IEvent;
  form: UseFormReturn<EventFormFields>;
  disabled?: boolean;
  domainDisabled?: boolean;
}

const EventDetailsForm = (props: EventDetailsFormProps) => {
  const {isNew, domainDisabled, event, form, disabled} = props;
  const {
    setValue,
    getValues,
    watch,
    control,
    formState: {errors, dirtyFields},
    clearErrors,
    resetField,
    trigger,
    register,
  } = form;

  const addErrorElementRef = useAddEventsErrorElementRef();

  const disableDomainUpdate = !isNew && domainDisabled;

  const categoryId = watch('categoryId');
  const isTimeToBeConfirmed = watch('isTimeConfirmed');
  const isDateToBeConfirmed = watch('isDateConfirmed');
  const seatmapId = watch('seatmapId');
  const venue = watch('venue');
  const selectedDomainIds = watch('domainIds');
  const useCategoryDomainSettings = watch('useCategoryDomainSettings');
  const {hasPermission} = usePermission();
  const categoryListPermissionError = !hasPermission(
    CategoryPermissions.seeCategoryList
  );
  const categoryPermissionError = !hasPermission(
    CategoryPermissions.seeCategoryDetails
  );

  const intl = useIntl();
  const {data: domains = [], isLoading: isDomainsLoading} =
    useGetDomainsQuery();

  const {data: selectedCategory, isLoading: isSelectedCategoryLoading} =
    useGetCategoryQuery(
      {
        id: categoryId,
      },
      {skip: !categoryId}
    );

  const setEventTime = (time: string) => {
    setValue('eventTime', time, {shouldDirty: true});
    clearErrors('eventTime');
  };

  const onSelectSeatmap = (seatmapId: number) => {
    setValue('seatmapId', seatmapId, {shouldDirty: true});
    clearErrors('seatmapId');
  };

  useEffect(() => {
    if (isNew && useCategoryDomainSettings) {
      setValue('domainIds', []);
    }
  }, [isNew, useCategoryDomainSettings, setValue]);

  useEffect(() => {
    if (selectedCategory && useCategoryDomainSettings) {
      setValue(
        'domainIds',
        selectedCategory?.domains.map(domain => domain.id) || []
      );
    }
  }, [selectedCategory, useCategoryDomainSettings, setValue]);

  const dateChanges = get(
    event,
    'provider.changes.date',
    {} as IChanges<IEvent>['date']
  );
  const dateValueChanges =
    dateChanges.status === ChangesStatus.PENDING ? dateChanges.value : null;
  const timeChanges = get(
    event,
    'provider.changes.time',
    {} as IChanges<IEvent>['time']
  );
  const timeValueChanges =
    timeChanges.status === ChangesStatus.PENDING ? timeChanges.value : null;

  const titleChanges = get(
    event,
    'provider.changes.title',
    {} as IChanges<IEvent>['title']
  );
  const titleValueChanges =
    titleChanges.status === ChangesStatus.PENDING ? titleChanges.value : null;

  const setSelectedVenue = (selectedVenue: IVenue) => {
    if (selectedVenue) {
      setValue('venue', selectedVenue, {shouldDirty: true});
      const isVenueSeatmap = selectedVenue.seatmaps?.find(
        s => s.id !== seatmapId
      );
      setValue('seatmapId', isVenueSeatmap?.id ?? 0);
    } else {
      setValue('venue', null as never, {shouldDirty: true});
      resetField('venue');
      resetField('seatmapId');
      clearErrors('seatmapId');
    }
  };

  const disablePreviousDates = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return false;
      return date.isBefore(moment(), 'D') && !date.isSame(event?.date, 'D');
    },
    [event?.date]
  );

  const onUseCategorySettingsClick = useCallback(() => {
    const newUseCategorySettingsValue = !useCategoryDomainSettings;

    if (newUseCategorySettingsValue) {
      setValue(
        'domainIds',
        selectedCategory?.domains.map(domain => domain.id) || [],
        {shouldDirty: true}
      );
    } else {
      setValue(
        'domainIds',
        domains.map(domain => domain.id),
        {shouldDirty: true}
      );
    }

    setValue('useCategoryDomainSettings', newUseCategorySettingsValue, {
      shouldDirty: true,
    });
  }, [domains, selectedCategory?.domains, setValue, useCategoryDomainSettings]);

  const existingCategoryDomainIds = useMemo(() => {
    const allDomainIds = domains.map(domain => domain.id);
    if (useCategoryDomainSettings) {
      return selectedCategory?.domains.map(domain => domain.id) || allDomainIds;
    }

    if (isNew && dirtyFields.domainIds) {
      return selectedDomainIds;
    }

    return selectedDomainIds;
  }, [
    dirtyFields.domainIds,
    domains,
    isNew,
    selectedCategory?.domains,
    selectedDomainIds,
    useCategoryDomainSettings,
  ]);

  const registerTitleResult = useMemo(() => {
    return register('title');
  }, [register]);

  return (
    <FormProvider {...form}>
      <div className="row flex-column flex-column-reverse flex-lg-row gy-2 gx-3">
        <div className="col-lg-8">
          <Card className="details-card mb-2 mb-lg-3">
            <CardBody>
              <Typography variant="h4" className="mb-3">
                <FormattedMessage id="dashboard.events.details.event_details" />
              </Typography>

              <div
                ref={ref => {
                  addErrorElementRef('categoryId', ref);
                }}
              />
              <Box display="flex" flexDirection="column" gridGap={10}>
                <SelectMultiLevelCategory
                  label={`${intl.formatMessage({
                    id: 'dashboard.events.details.category',
                  })}*`}
                  placeholder={intl.formatMessage({
                    id: 'dashboard.events.details.select_category',
                  })}
                  onChange={(categoryId, hasSportPatent) => {
                    clearErrors('categoryId');
                    if (categoryId && getValues('categoryId') !== categoryId) {
                      setValue('categoryId', categoryId, {shouldDirty: true});
                      if (hasSportPatent) {
                        setValue('defaultPerformers', 2);
                      } else {
                        setValue('defaultPerformers', 1);
                      }
                    }
                  }}
                  disabled={disabled}
                  errors={errors}
                  showFullPath={true}
                  existingCategory={event?.category}
                  formCategoryId={categoryId}
                  onBlur={() => {
                    void trigger('categoryId');
                  }}
                />
                {categoryListPermissionError && (
                  <FormHelperText error>
                    <FormattedMessage id="dashboard.events.details.category_list_permission_error" />
                  </FormHelperText>
                )}
                {!!categoryId && (
                  <PerformersBlock errors={errors} disabled={disabled} />
                )}
                <Row className="gy-2 gx-fan-25 align-items-start">
                  <Col lg={isNew ? 12 : 6}>
                    <EnhancedInputField
                      wrapperProps={{
                        className: 'form-group mb-0',
                      }}
                      disabled={disabled}
                      data-testid="event-title-input"
                      label={`${intl.formatMessage({
                        id: 'dashboard.events.event_title',
                      })}*`}
                      error={!!errors.title}
                      errorMessage={errors.title?.message}
                      addonPosition="end"
                      addon={
                        <>
                          {!isNew && titleValueChanges && !disabled && (
                            <TixstockUpdater label={titleValueChanges} />
                          )}
                        </>
                      }
                      {...registerTitleResult}
                      ref={element => {
                        registerTitleResult.ref(element);
                        addErrorElementRef('title', element);
                      }}
                      onChange={e => {
                        void register('title').onChange(e);
                        clearErrors('title');
                      }}
                    />
                  </Col>
                  {!isNew && (
                    <Col lg={6}>
                      <Controller
                        control={control}
                        name="publicUrl"
                        render={({field: {value}}) => (
                          <InputField
                            data-testid="event-public-url-input"
                            control={control}
                            disabled
                            label={intl.formatMessage({
                              id: 'dashboard.events.public_url',
                            })}
                            outlined
                            externalLabel
                            name="publicUrl"
                            input={{
                              value,
                            }}
                            meta={{
                              touched: true,
                            }}
                            addonPosition="end"
                            addon={
                              <IconButton
                                color="default"
                                size="small"
                                variant="action"
                                className="m-0 bg-transparent shadow-none"
                                onClick={() => {
                                  value && window.open(value);
                                }}
                              >
                                <ArrowForwardIcon
                                  style={{width: 7, height: 7}}
                                />
                              </IconButton>
                            }
                          />
                        )}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="gy-2 gx-fan-25 align-items-start">
                  <Col lg={6}>
                    <div
                      ref={ref => {
                        addErrorElementRef('eventDate', ref);
                      }}
                    />
                    <DatePickerController
                      disabled={isDateToBeConfirmed || disabled}
                      name="eventDate"
                      control={control}
                      label={`${intl.formatMessage({
                        id: 'dashboard.events.details.event_date',
                      })}*`}
                      outlined
                      displayFormat="DD/MM/YYYY"
                      formGroupClass="mb-3"
                      clearErrorsOnChange={() => {
                        clearErrors('eventDate');
                      }}
                      endAdornment={
                        <>
                          {!isNew &&
                            dateValueChanges &&
                            !disabled &&
                            !isDateToBeConfirmed && (
                              <TixstockUpdater
                                label={moment(dateValueChanges).format(
                                  'DD/MM/YYYY'
                                )}
                              />
                            )}
                        </>
                      }
                      shouldDisableDate={disablePreviousDates}
                    />
                  </Col>
                  <Col lg={6}>
                    <div
                      ref={ref => {
                        addErrorElementRef('eventTime', ref);
                      }}
                    />
                    <Controller
                      control={control}
                      name="eventTime"
                      render={({
                        field: {onChange, value, onBlur},
                        formState: {
                          errors: {eventTime: eventTimeError},
                        },
                      }) => (
                        <TimePickerField
                          disabled={isTimeToBeConfirmed || disabled}
                          control={control}
                          canEdit={!disabled}
                          label={`${intl.formatMessage({
                            id: 'dashboard.events.details.event_time',
                          })}*`}
                          setValue={setEventTime}
                          input={{
                            onBlur,
                            onChange: (...payload) => {
                              onChange(...payload);
                              clearErrors('eventTime');
                            },
                            value,
                            name: 'eventTime',
                          }}
                          formGroupClass="mb-0"
                          errorHelperText={!!eventTimeError?.message}
                          helperText={eventTimeError?.message}
                          meta={{
                            error: eventTimeError?.message,
                            touched: true,
                          }}
                          endAdornment={
                            <>
                              {!isNew &&
                                timeValueChanges &&
                                !disabled &&
                                !isTimeToBeConfirmed && (
                                  <TixstockUpdater
                                    label={moment(timeValueChanges).format(
                                      'h:mm A'
                                    )}
                                  />
                                )}
                            </>
                          }
                          confirmed={
                            <Row className="align-items-center gx-0">
                              <Col xs="auto">
                                <Checkbox
                                  checked={isTimeToBeConfirmed}
                                  onClick={(e: any) => {
                                    setValue(
                                      'isTimeConfirmed',
                                      e.target.checked,
                                      {shouldDirty: true}
                                    );
                                    trigger('eventTime');
                                  }}
                                />
                              </Col>
                              <Col>
                                <Typography variant="body2">
                                  {intl.formatMessage({
                                    id: 'actions.to_be_confirmed',
                                  })}
                                </Typography>
                              </Col>
                            </Row>
                          }
                          excludeLabel={false}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <div data-testid="domainListWrapper">
                  {/* XYZ */}
                  <DomainList
                    headerAction={
                      <FormControlLabel
                        control={
                          (
                            <Checkbox
                              size="default"
                              disabled={disableDomainUpdate}
                              checked={useCategoryDomainSettings}
                              onClick={onUseCategorySettingsClick}
                            />
                          ) as FormControlLabelProps['control']
                        }
                        label={
                          <Typography variant="body2">
                            <FormattedMessage id="dashboard.events.details.use_category_settings" />
                          </Typography>
                        }
                        className="me-0"
                      />
                    }
                    existingCategoryDomainIds={existingCategoryDomainIds}
                    isLoading={isDomainsLoading || isSelectedCategoryLoading}
                    disabled={useCategoryDomainSettings || disableDomainUpdate}
                    onChange={ids => {
                      setValue('domainIds', ids, {shouldDirty: true});
                      clearErrors('domainIds');
                    }}
                    domains={domains}
                  />

                  <span
                    className="error"
                    ref={ref => {
                      addErrorElementRef('domainIds', ref);
                    }}
                  >
                    {errors.domainIds?.message}
                  </span>
                  {categoryPermissionError && useCategoryDomainSettings && (
                    <FormHelperText error>
                      <FormattedMessage id="dashboard.events.details.category_permission_error" />
                    </FormHelperText>
                  )}
                </div>
              </Box>
            </CardBody>
          </Card>
          <Card className="details-card mb-2 mb-lg-3">
            <CardBody>
              <Typography variant="h4" className="mb-3">
                <FormattedMessage id="dashboard.events.details.venue_details" />
              </Typography>
              <VenueBlock
                disabled={disabled || !isNew}
                errors={errors}
                isNew={isNew}
                event={event}
                setSelectedVenue={setSelectedVenue}
                selectedVenue={venue as IVenue}
              />
            </CardBody>
          </Card>
          {venue && (
            <>
              <SeatmapDetails
                disabled={disabled}
                selectedVenue={venue as IVenue}
                seatmapId={seatmapId}
                onSelectSeatmap={onSelectSeatmap}
                errors={errors}
              />
              <div
                ref={ref => {
                  addErrorElementRef('seatmapId', ref);
                }}
              />
            </>
          )}
        </div>
        <div className="col-lg-4">
          <EventSummary disabled={disabled} isNew={isNew} />
        </div>
      </div>
    </FormProvider>
  );
};

export default EventDetailsForm;
