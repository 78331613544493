import {Col, Row} from 'reactstrap';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import Checkbox from '../../../../Common/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {CountSectionChangesFunction, SeatmapFormFields} from '../../types';

interface GeneralAdmissionCheckboxProps {
  sectionIndex: number;
  sectionId: number | string;
  countSectionChanges?: CountSectionChangesFunction;
  disabled?: boolean;
}

export function GeneralAdmissionCheckbox(
  props: Readonly<GeneralAdmissionCheckboxProps>
) {
  const {sectionIndex, sectionId, countSectionChanges, disabled} = props;
  const {control, trigger} = useFormContext<SeatmapFormFields>();

  const isSVGWithSeatmapFormat = useWatch<SeatmapFormFields>({
    control,
    name: 'isSVGWithSeatmapFormat',
    exact: true,
  });

  const blocksCount = useWatch<SeatmapFormFields, `sections.${number}.blocks`>({
    control,
    name: `sections.${sectionIndex}.blocks`,
    exact: true,
  })?.length;

  if (blocksCount) return null;

  return (
    <Box my={-10}>
      <Row className="align-items-center gx-0">
        <Col xs="auto">
          <Controller
            control={control}
            name={`sections.${sectionIndex}.isGeneralAdmission`}
            render={({field: {onChange, value}}) => (
              <Checkbox
                data-testid={`seatmap-section-isGeneralAdmission-${sectionIndex}`}
                disabled={disabled || !!isSVGWithSeatmapFormat}
                name={`sections.${sectionIndex}.isGeneralAdmission`}
                onChange={(...args) => {
                  onChange(...args);
                  const checkboxValue = args[1];
                  countSectionChanges?.({
                    value: checkboxValue,
                    sectionId,
                    sectionChangedProp: 'isGeneralAdmission',
                  });
                  trigger(
                    `sections.${sectionIndex}.customErrors.blockOrGeneralAdmissionError`
                  ).catch();
                }}
                checked={value}
              />
            )}
          />
        </Col>
        <Col>
          <Typography variant="body2">
            <FormattedMessage id="dashboard.seatmap.details.is_general_admission" />
          </Typography>
        </Col>
      </Row>
    </Box>
  );
}
