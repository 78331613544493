import React from 'react';
import {useSelector} from 'react-redux';
import {Field, FieldArray} from 'redux-form';
import PropTypes from 'prop-types';
import SearchTextField from './SearchTextField';
import CheckboxField from './CheckboxField';
import CheckboxGroupField from './CheckboxGroupField';
import DateBetweenField from './DateBetweenField';
import DateSelectField from './DateSelectField';
import RadioGroupField from './RadioGroupField';
import SelectField from './SelectField';
import InputField from './InputField';
import {Box} from '@material-ui/core';
import DatePickerField from './DatePickerField';
import ImageField from './ImageField';
import DateTimePickerField from './DateTimePickerField';
import TimePickerFIeld from './TimePickerField';
import CustomObjectFieldGroup from '../CustomObjectFieldGroup';
import CaCollectionFieldArray from './CaCollectionFieldArray';
import {inputTypes} from '../../../constants/enums';
import TextAreaField from './TextAreaField';
import AdvancedNumberField from './AdvancedNumberField';
import AutocompleteField from './AutocompleteField';
import AutocompletePopupField from './AutocompletePopupField';

/**
 * Render a redux-form `Field` dynamically depending on `inputType`
 *
 * @param {Object} props
 */
const DynamicField = props => {
  const {
    inputType,
    name,
    label,
    items,
    classes,
    userCustomObject,
    depth,
    maxDepth,
    unsupportedTypes,
    DynamicFieldProps,
    builderParams,
    builderConfig,
    ...others
  } = props;
  let fieldProps = {};
  let wrapperProps = null;

  const stateOptions = useSelector(
    props.getOptionsSelector ? props.getOptionsSelector : state => null
  );
  if (props.useOptionsSelector && !props.getOptionsSelector) {
    throw new Error(
      'You must provide getOptionsSelector to use useOptionsSelector'
    );
  }

  if (unsupportedTypes.indexOf(inputType) > -1) {
    console.warn(
      `unsupported inputType ${inputType} from unsupportTypes props`,
      props
    );
    return null;
  } else if (inputType === inputTypes.CHECKBOXES) {
    fieldProps = {
      ...fieldProps,
      component: CheckboxGroupField,
      options: items,
      formControlLabelClass: classes && classes.checkboxRow,
      groupClassName: classes && classes.groupClassName,
    };
  } else if (inputType === inputTypes.TEXT) {
    fieldProps['component'] = InputField;
  } else if (inputType === inputTypes.SELECT) {
    fieldProps = {
      ...fieldProps,
      items,
      component: SelectField,
    };
  } else if (inputType === inputTypes.DATE_BETWEEN) {
    fieldProps['component'] = DateBetweenField;
  } else if (inputType === inputTypes.ADVANCED_NUMBER) {
    fieldProps['component'] = AdvancedNumberField;
  } else if (inputType === inputTypes.DATE_SELECT) {
    fieldProps = {
      ...fieldProps,
      displayFormat: 'YYYY-MM-DD',
      component: DateSelectField,
    };
  } else if (inputType === inputTypes.DATEPICKER) {
    fieldProps['component'] = DatePickerField;
  } else if (inputType === inputTypes.SEARCH) {
    // fieldProps['component'] = SearchTextField;
    fieldProps = {
      ...fieldProps,
      component: SearchTextField,
      sharpedSmall: true,
    };
    wrapperProps = {className: classes && classes.fieldWrapper};
  } else if (inputType === inputTypes.RADIO) {
    fieldProps['component'] = RadioGroupField;
    fieldProps['items'] = items;
    wrapperProps = {className: classes && classes.fieldWrapper};
  } else if (inputType === inputTypes.CHECKBOX) {
    fieldProps['component'] = CheckboxField;
    wrapperProps = {className: classes && classes.fieldWrapper};
  } else if (inputType === inputTypes.IMAGE) {
    fieldProps['component'] = ImageField;
    wrapperProps = {className: classes && classes.fieldWrapper};
  } else if (inputType === inputTypes.DATE_TIME) {
    fieldProps['component'] = DateTimePickerField;
  } else if (inputType === inputTypes.TIME) {
    fieldProps['component'] = TimePickerFIeld;
  } else if (inputType === inputTypes.CUSTOM_OBJECT) {
    if (depth < maxDepth) {
      return (
        <CustomObjectFieldGroup
          {...props}
          DynamicFieldProps={{
            depth: depth + 1,
            maxDepth, // maxDepth must be the same as parent's
          }}
          customObject={userCustomObject}
        />
      );
    } else {
      console.warn(
        `DynamicField max depth of ${maxDepth} reached, custom object not rendered`,
        userCustomObject
      );
      return null;
    }
  } else if (inputType === inputTypes.COLLECTION) {
    if (depth < maxDepth) {
      return (
        <FieldArray
          name={name}
          label={label}
          component={CaCollectionFieldArray}
          customObject={userCustomObject}
          DynamicFieldProps={{
            depth: depth + 1,
            maxDepth,
          }}
        />
      );
    } else {
      console.warn(
        `DynamicField max depth of ${maxDepth} reached, collection custom object not rendered`,
        userCustomObject
      );
      return null;
    }
  } else if (inputType === inputTypes.TEXTAREA) {
    fieldProps['component'] = TextAreaField;
  } else if (inputType === inputTypes.AUTOCOMPLETE) {
    fieldProps['component'] = AutocompleteField;
    if (props.useOptionsSelector) {
      fieldProps['options'] = stateOptions;
    }
  } else if (inputType === inputTypes.AUTOCOMPLETE2) {
    fieldProps['component'] = AutocompletePopupField;
    if (props.useOptionsSelector) {
      fieldProps['options'] = stateOptions;
    }
  } else {
    console.warn(`unsupported inputType ${inputType}`, props);
    return null;
  }
  // Remove props unneeded for Field
  const {getOptionsSelector, useOptionsSelector, ...otherProps} = others;
  fieldProps = {...fieldProps, ...otherProps};
  const field = <Field name={name} label={label} {...fieldProps} />;

  return wrapperProps ? <Box {...wrapperProps}>{field}</Box> : field;
};

DynamicField.propTypes = {
  /** Field `name` */
  name: PropTypes.string.isRequired,
  /** Field `label` */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Field items, depending on the field type
   * Required for select, checkboxes and radio
   */
  items: PropTypes.array,
  /**
   * Field input type
   */
  inputType: PropTypes.oneOf([
    /** Render CheckboxField */
    inputTypes.CHECKBOX,
    /** Render CheckboxGroupField */
    inputTypes.CHECKBOXES,
    /** Render SelectField */
    inputTypes.SELECT,
    /** Render DateBetweenField */
    inputTypes.DATE_BETWEEN,
    /** Render SearchTextField */
    inputTypes.SEARCH,
    /** Render DateSelectField */
    inputTypes.DATE_SELECT,
    /** Render RadioGroupField */
    inputTypes.RADIO,
    /** Render TextField */
    inputTypes.TEXT,
    /** Render DatePickerField */
    inputTypes.DATEPICKER,
    /** Render ImageField */
    inputTypes.IMAGE,
    /** Render CaCollectionFieldArray */
    inputTypes.COLLECTION,
    /** Render DateTimePickerField */
    inputTypes.DATE_TIME,
    /** Render a TimePickerField */
    inputTypes.TIME,
    /** Render a CustomObjectField */
    inputTypes.CUSTOM_OBJECT,
    /** Render a TextareField */
    inputTypes.TEXTAREA,
    /** Render an AdvancedNumberField */
    inputTypes.ADVANCED_NUMBER,
    /** Render an AutocompleteField */
    inputTypes.AUTOCOMPLETE,
    inputTypes.AUTOCOMPLETE2,
  ]).isRequired,
  /**
   * Classes used for wrapper and other elements
   */
  classes: PropTypes.shape({
    /**
     * Used for `checkboxes` type, for `formControlLabelClass` prop
     */
    checkboxRow: PropTypes.string,
    /**
     * Field wrapper className in case there is a wrapper
     */
    fieldWrapper: PropTypes.string,
    /**
     * Used for `checkboxes` type, for checkboxes container class
     */
    groupClassName: PropTypes.string,
  }),
  /**
   * Current field nesting depth, default 1
   * Used to limit Custom Object and Collection's Custom Object nesting
   * to prevent CO/Collection to be rendered inside CO/Collection
   */
  depth: PropTypes.number,
  /**
   * Field nesting max depth, default 1
   */
  maxDepth: PropTypes.number,
  /**
   * Contains unsupported Custom Field types
   * Used mainly to prevent images to be rendered inside CO/Collection
   * since the backend's endpoint does not support that
   */
  unsupportedTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * Used only for custom object and collection
   */
  tooltip: PropTypes.string,
  useOptionsSelector: PropTypes.bool,
};

DynamicField.defaultProps = {
  depth: 1,
  maxDepth: 2, // prevent customObject and collection to be rendered
  unsupportedTypes: [],
  getOptionsSelector: null,
  useOptionsSelector: false,
};

export default DynamicField;
