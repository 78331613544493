import Typography from '@material-ui/core/Typography';
import {Updates} from '../../components';
import React from 'react';
import {IVenue} from '../../../../../services/eventsApi';

export type VenueCellProps = IVenue;

export function VenueCell(props: Readonly<VenueCellProps>) {
  const {name, country, providers} = props;

  const venueProviderChanges = providers?.at(0)?.changes;
  const updates =
    venueProviderChanges?.name.value ??
    venueProviderChanges?.country.value ??
    venueProviderChanges?.city.value ??
    venueProviderChanges?.addressLine1.value ??
    venueProviderChanges?.addressLine2.value ??
    venueProviderChanges?.latitude.value ??
    venueProviderChanges?.longitude.value;

  return (
    <div>
      <Typography variant="body2" className="text-truncate">
        {name}, {country}
      </Typography>
      <Updates updatedValue={updates} className="mt-1" />
    </div>
  );
}
