import {FormattedMessage} from 'react-intl';
import {Form} from 'reactstrap';
import InputField from '../../../Form/Fields/InputField';
import Typography from '@material-ui/core/Typography';
import {Controller, FormProvider, UseFormReturn} from 'react-hook-form';
import {
  CountSectionChangesFunction,
  OnBlockAddedOrRemovedFunction,
  SeatmapFormFields,
} from '../types';
import {
  ISeatmap,
  IVenue,
  useLazyVenueListQuery,
} from '../../../../services/eventsApi';
import React, {useEffect, useState} from 'react';
import SeatmapImage from './SeatmapImage/SeatmapImage';
import {Box, FormHelperText} from '@material-ui/core';
import SectionsView from './Sections/SectionsView';
import Autocomplete from '../../../Common/Autocomplete';
import {DropzoneInputProps, DropzoneRootProps} from 'react-dropzone';
import {ImageUrlDisplay} from './ImageUrlDisplay';
import {useAddSeatmapErrorElementRef} from '../../../../store/slices/eventsManagement';

interface SeatmapFormProps {
  seatmap?: ISeatmap;
  form: UseFormReturn<SeatmapFormFields>;
  disabled?: boolean;
  isNew?: boolean;
  countSectionChanges?: CountSectionChangesFunction;
  onBlockAddedOrRemoved?: OnBlockAddedOrRemovedFunction;
  onSectionDelete?: (id: string | number, slug?: string) => void;
  onSectionAdd?: (id: string) => void;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  createFormMissingSections: () => void;
  removeSvgUnrelatedSections: () => void;
  onImageDelete?: () => void;
}

const SeatmapForm = (props: SeatmapFormProps) => {
  const {
    countSectionChanges,
    onSectionDelete,
    onSectionAdd,
    getRootProps,
    getInputProps,
    createFormMissingSections,
    removeSvgUnrelatedSections,
    onImageDelete,
    seatmap,
    form,
    isNew,
    disabled,
    onBlockAddedOrRemoved,
  } = props;

  const {
    setValue,
    resetField,
    clearErrors,
    watch,
    formState: {errors},
    control,
    trigger,
  } = form;

  const [venueSearch, setVenueSearch] = useState<string | null>(null);
  const [getVenues, {data: venues, isLoading, isFetching}] =
    useLazyVenueListQuery();
  const [selectedVenue, setSelectedVenue] = useState<IVenue | null>(
    seatmap?.venue ?? null
  );

  const addErrorElementRef = useAddSeatmapErrorElementRef();

  useEffect(() => {
    if (!isNew) return;
    const timeout = setTimeout(() => {
      let addressToRemove = '';
      if (selectedVenue) {
        addressToRemove = selectedVenue.city + ', ' + selectedVenue.country;
      }
      const searchName = venueSearch?.replace(addressToRemove, '').trim();
      getVenues({name: searchName ?? null});
    }, 200);

    return () => clearTimeout(timeout);
  }, [venueSearch, getVenues, selectedVenue, isNew]);

  useEffect(() => {
    if (selectedVenue) {
      setValue('venue', selectedVenue);
    } else {
      resetField('venue');
    }
  }, [selectedVenue, setValue, resetField]);

  const providerChanges = seatmap?.providers?.at(0)?.changes;

  return (
    <FormProvider {...form}>
      <Form autoComplete="off" form={form}>
        {!isNew && (
          <Typography variant="h4" className="mb-3">
            <FormattedMessage id="dashboard.seatmap.details.seat_map_details" />
          </Typography>
        )}
        {isNew && (
          <div
            className="mb-2 mb-lg-fg mb-0"
            ref={ref => {
              addErrorElementRef('name', ref);
            }}
          >
            <Typography
              variant="subtitle2"
              className="text-placeholder me-auto mb-2"
            >
              <FormattedMessage id="dashboard.seatmap.add.name" />*
            </Typography>
            <Controller
              control={control}
              name="name"
              render={({
                field: {onChange, value, onBlur},
                formState: {
                  errors: {name: nameError},
                },
              }) => (
                <InputField
                  data-testid="seatmap-name"
                  control={control}
                  outlined
                  externalLabel
                  name="name"
                  input={{
                    onChange: e => {
                      clearErrors('name');
                      onChange(e);
                    },
                    value,
                    onBlur: () => {
                      void trigger('name');
                      onBlur();
                    },
                  }}
                  meta={{
                    error: nameError?.message,
                    touched: true,
                  }}
                  errorHelperText={!!nameError?.message}
                  helperText={nameError?.message}
                />
              )}
            />
          </div>
        )}

        <div className="form-group mb-2 mb-lg-fg mb-0">
          <Typography
            variant="subtitle2"
            className="text-placeholder me-auto mb-2"
          >
            <FormattedMessage id="dashboard.seatmap.details.venue" />*
          </Typography>
          <Autocomplete
            data-testid="seatmap-venue-name"
            options={venues ?? []}
            value={selectedVenue}
            disabled={!isNew}
            isInvalid={!!errors.venue}
            onBlur={() => {
              trigger('venue');
            }}
            getOptionLabel={option =>
              `${option.name}, ${option.city}, ${option.country}`
            }
            getOptionSelected={(option, value) => option.id === value.id}
            loading={isLoading || isFetching}
            onInputChange={(event, value) => {
              setVenueSearch(value);
            }}
            onChange={(event, value) => {
              setSelectedVenue(value as IVenue);
              clearErrors('venue');
            }}
            renderOption={option => (
              <Box>
                <Typography variant="body2">{option.name}</Typography>
                <Box mt={0.1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {`${option.city}, ${option.country}`}
                  </Typography>
                </Box>
              </Box>
            )}
          />
          <FormHelperText
            error
            ref={ref => {
              addErrorElementRef('venue', ref);
            }}
          >
            {errors.venue?.message ?? errors.venue?.id?.message}{' '}
          </FormHelperText>
        </div>
        {!isNew && (
          <ImageUrlDisplay watch={watch} providerChanges={providerChanges} />
        )}
        <SeatmapImage
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          createFormMissingSections={createFormMissingSections}
          removeSvgUnrelatedSections={removeSvgUnrelatedSections}
          onDelete={onImageDelete}
          isNew={isNew}
          watch={watch}
        />
        <Typography
          variant="subtitle2"
          className="text-placeholder me-auto mb-2"
        >
          <FormattedMessage id="dashboard.seatmap.details.sections" />*
        </Typography>
        <SectionsView
          countSectionChanges={countSectionChanges}
          onSectionAdd={onSectionAdd}
          onSectionDelete={onSectionDelete}
          onBlockAddedOrRemoved={onBlockAddedOrRemoved}
          disabled={disabled}
        />
      </Form>
    </FormProvider>
  );
};

export default SeatmapForm;
