import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InitialCategoryState} from './category.state';

export const categorySlice = createSlice({
  name: 'category',
  initialState: InitialCategoryState,
  reducers: {
    addErrorElementRef: (
      state,
      action: PayloadAction<{name: string; elementId: string}>
    ) => {
      const {name, elementId} = action.payload;
      state.errorElementRefsMap[name] = elementId;
    },
  },
});
