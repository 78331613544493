import {makeStyles} from '@material-ui/core/styles';

export const useMultiSelectAutocompleteFilterStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  selectedOptionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    width: '18vw',
    border: `solid .1px ${theme.palette.divider}`,
    borderRadius: '4px',
    marginBottom: '5px',
    overflowY: 'scroll',
    gap: 4,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    // Add these properties for Firefox
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
    // Add these properties for Webkit browsers (Chrome, Safari, etc.)
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
    },
  },
  empty: {
    color: `${theme.palette.secondary.light} !important`,
  },
  popper: {
    width: '100%',
    borderRadius: theme.spacing(0.4, 0.4, 0, 0),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0.8),
    zIndex: theme.zIndex.tooltip,
    boxShadow: theme.shadows[5],
  },
  inputBase: {
    padding: theme.spacing(0.9, 1.5),
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    '& input': {
      padding: theme.spacing(0.5),
      fontSize: 14,
    },
  },
  paper: {
    margin: 0,
    borderRadius: theme.spacing(0, 0, 0.4, 0.4),
    padding: 0,
    boxShadow: theme.shadows[5],
  },
  listBox: {
    padding: theme.spacing(1, 0),
    maxHeight: '25vh !important',
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    '&[data-focus="true"]': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  chip: {
    color: theme.palette.error.main,
    background: theme.palette.error.light,
    margin: 0,
    '& svg': {
      color: `${theme.palette.error.main} !important`,
    },
    '&:hover': {
      color: `${theme.palette.error.main}`,
      background: `${theme.palette.error.light} !important`,
    },
  },
  noOptionsWrapper: {
    display: 'flex',
    gap: theme.spacing(1),
    height: '2vh',
  },
  circularProgress: {
    color: '#21B59B',
    '& .MuiCircularProgress-circle': {
      strokeLinecap: 'round',
    },
    animationDuration: '600ms',
    position: 'absolute',
    left: 0,
  },
  circularProgressBack: {
    color: '#EDEDED',
  },
  loadingText: {
    fontSize: 13,
  },
  noOptionText: {
    fontSize: 13,
    height: '2vh',
  },
}));
