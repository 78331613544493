import PerformerForm from './Details/PerformerForm';
import PerformerHeader from './Details/PerformerHeader';
import {
  AddPerformerQueryParam,
  useEditPerformerMutation,
  useGetPerformerQuery,
} from '../../../services/eventsApi';
import {useEffect, useState} from 'react';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import {useForm} from 'react-hook-form';
import {PerformerFormFields} from './types';
import {
  getPerformerValidationSchema,
  PerformerFormDefaultValues,
} from './validations';
import {yupResolver} from '@hookform/resolvers/yup';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {SaveFeature} from '../../Common/SaveFeature';
import usePermission from '../../../Auth/usePermission';
import {PerformerPermissions} from '../../../constants/permissions';
import * as urls from '../../../constants/urls';

const EditPerformer = () => {
  const {id} = useParams<{id: string}>();
  const intl = useIntl();
  const history = useHistory();
  const [updatedFields, setUpdatedFields] = useState(new Set<string>());

  const {
    data: performer,
    isError: isFetchingPerformerError,
    refetch,
  } = useGetPerformerQuery({
    id: Number(id),
    formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
    showProgressDialog: true,
  });

  if (isFetchingPerformerError) {
    history.replace(urls.EVENTS_PERFORMER_PATH);
  }

  const [editPerformer, {data: editPerformerResult, isSuccess}] =
    useEditPerformerMutation();

  const {hasPermission} = usePermission();

  const form = useForm<PerformerFormFields>({
    defaultValues: PerformerFormDefaultValues,
    resolver: yupResolver(getPerformerValidationSchema(intl)) as never,
    values: editPerformerResult?.data ?? performer,
    mode: 'onBlur',
  });

  const name = form.watch('name');
  const landingPageUrl = form.watch('landingPageUrl');

  useEffect(() => {
    if (editPerformerResult && isSuccess) {
      if (editPerformerResult.data) {
        refetch();
        setUpdatedFields(new Set<string>());
      }
    }
  }, [editPerformerResult, isSuccess, refetch]);
  useEffect(() => {
    if (!performer) return;
    const isBothFalsy = (a: any, b: any) => !a && !b;

    setUpdatedFields(() => {
      const newUpdatedFields = new Set<string>();
      if (
        !isBothFalsy(name, performer.name) &&
        name?.trim() !== performer.name?.trim()
      ) {
        newUpdatedFields.add('name');
      } else {
        newUpdatedFields.delete('name');
      }

      if (
        !isBothFalsy(landingPageUrl, performer.landingPageUrl) &&
        landingPageUrl?.trim() !== performer.landingPageUrl?.trim()
      ) {
        newUpdatedFields.add('landingPageUrl');
      } else {
        newUpdatedFields.delete('landingPageUrl');
      }

      return newUpdatedFields;
    });
  }, [name, landingPageUrl, performer]);

  const onSubmit = (data: PerformerFormFields) => {
    if (!id) return;
    const reqData = {
      name: data.name,
      landingPageUrl: data.landingPageUrl ? data.landingPageUrl : null,
    };
    const payload: AddPerformerQueryParam & {id: string} = {
      body: {
        ...reqData,
      },
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      formatSuccessMessage: () =>
        intl.formatMessage({
          id: 'messages.PERFORMER_UPDATED_SUCCESSFULLY',
        }),
    };
    editPerformer(payload);
  };

  const isFormDisabled = !hasPermission(
    PerformerPermissions.editPerformerDetails
  );

  return (
    <div className="details-page performer">
      {performer && <PerformerHeader performer={performer} />}
      {performer && (
        <div className="container ms-lg-0">
          <div className="content-block">
            <PerformerForm form={form} disabled={isFormDisabled} />
          </div>
        </div>
      )}

      <Prompt
        when={!!updatedFields.size}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />

      {!isFormDisabled && (
        <SaveFeature
          onSave={form.handleSubmit(onSubmit)}
          num={updatedFields.size}
          onClose={() => {
            form.reset();
          }}
          open={!!updatedFields.size}
        />
      )}
    </div>
  );
};

export default EditPerformer;
