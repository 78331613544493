import cx from 'clsx';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import Button from '../Common/Button';

export type NewActionsToolbarProps = {
  isMobile?: boolean;
  onAddClick?: () => void;
};

const NewActionsToolbar = ({
  isMobile = false,
  onAddClick = () => null,
}: NewActionsToolbarProps) => {
  const history = useHistory();
  const intl = useIntl();
  return (
    <Row
      className={cx(
        'gx-3 align-items-center',
        isMobile ? 'd-lg-none' : 'd-none d-lg-flex'
      )}
    >
      <Col className="text-center">
        <Button
          onClick={() => history.goBack()}
          data-testid="cancel-form-add"
          link
          title={intl.formatMessage({id: 'actions.cancel'})}
        />
      </Col>
      <Col className="text-center">
        <Button
          primary
          className="w-100"
          data-testid={!isMobile ? 'add-form-btn' : 'add-form-btn-isMobile'}
          title={intl.formatMessage({id: 'actions.add'})}
          onClick={onAddClick}
        />
      </Col>
    </Row>
  );
};

export default NewActionsToolbar;
