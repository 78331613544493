import {FormattedMessage, useIntl} from 'react-intl';
import {Prompt, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '../../Common/Button';
import EventDetailsForm from './Forms/EventDetailsForm';
import * as urls from '../../../constants/urls';
import {yupResolver} from '@hookform/resolvers/yup';
import {EventFormFields} from './types';
import {useForm} from 'react-hook-form';
import {EventFormDefaultValues} from './constants';
import {getEventValidationSchema} from './validations';
import {
  CreateEventQueryParam,
  useCreateEventMutation,
} from '../../../services/eventsApi';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import {OverlaySpinner} from '../../Widgets/Spinner';
import {useCallback, useEffect} from 'react';
import usePermission from '../../../Auth/usePermission';
import {EventPermissions} from '../../../constants/permissions';
import {getDateTimeInEventFormat, scrollToFirstError} from './helpers';
import {useGetEventErrorElementsRef} from '../../../store/slices/eventsManagement';

const AddEvent = () => {
  const intl = useIntl();
  const history = useHistory();
  const getErrorElementRefs = useGetEventErrorElementsRef();
  const [createEvent, {data: createdEvent, isLoading: isCreatingEvent}] =
    useCreateEventMutation();

  const form = useForm<EventFormFields>({
    defaultValues: EventFormDefaultValues,
    resolver: yupResolver(getEventValidationSchema(intl)) as any,
    mode: 'all',
  });

  const {hasPermission} = usePermission();
  const isAddEventPermission = hasPermission(EventPermissions.addEvent);
  const formChanges = form.formState.isDirty;

  const submit = useCallback(
    async (data: EventFormFields) => {
      const {date: formDate, time: formTime} = getDateTimeInEventFormat(
        data.eventDate,
        data.eventTime
      );

      const premiumDaysBeforeEventNumber = Number(data.premiumDaysBeforeEvent);
      const premiumDaysBeforeEvent = !Number.isNaN(premiumDaysBeforeEventNumber)
        ? premiumDaysBeforeEventNumber
        : 0;

      let isPremium = data.isPremium;
      if (data.isPremium && premiumDaysBeforeEvent > 0) {
        isPremium = false;
      }

      const payload: CreateEventQueryParam = {
        body: {
          title: data.title,
          imageUrl: null,
          premiumDaysBeforeEvent,
          isPremium,
          isLive: data.isLive,
          isDateConfirmed: !data.isDateConfirmed,
          isTimeConfirmed: !data.isTimeConfirmed,
          date: formDate,
          time: formTime,
          categoryId: data.categoryId,
          venueId: data.venue.id,
          performerIds: data.performers.map(p => p.id!),
          seatmapId: data.seatmapId,
          domainIds: data.domainIds,
          isPostponed: data.isPostponed,
          useCategoryDomainSettings: data.useCategoryDomainSettings,
        },
        showProgressDialog: false,
        formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
        formatSuccessMessage: () =>
          intl.formatMessage({
            id: 'messages.EVENT_CREATED_SUCCESSFULLY',
          }),
      };
      createEvent(payload);
    },
    [createEvent, intl]
  );

  const submitIfValid = useCallback(async () => {
    const isValid = await form.trigger();
    const payload = form.getValues();
    if (!isValid || payload.isCustomPremiumDateErrorExist) {
      const errorElementRefs = getErrorElementRefs();

      scrollToFirstError(
        form.formState.errors,
        errorElementRefs,
        payload.isCustomPremiumDateErrorExist
      );
      return;
    }

    return submit(form.getValues());
  }, [getErrorElementRefs, form, submit]);

  useEffect(() => {
    if (createdEvent) {
      history.replace(
        urls.EVENT_DETAILS_PATH.replace(':slug', createdEvent.slug),
        {
          event: createdEvent,
        }
      );
    }
  }, [createdEvent, history]);

  return (
    <div className="dashboard-wrapper">
      <OverlaySpinner isLoading={isCreatingEvent} />
      <div className="container-fluid">
        <div className="row align-items-center header-row pb-4 mb-1">
          <div className="col text-center text-lg-start">
            {/* Header */}
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.events.add_event" />
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*Cancel/Add buttons*/}
            <div className="row gx-3 align-items-center">
              <div className="col text-center">
                <Button
                  data-testid="cancel-form-btn"
                  onClick={() => history.push(urls.EVENTS_LIST_PATH)}
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                />
              </div>
              {isAddEventPermission && (
                <div className="col text-center">
                  <Button
                    data-testid="add-form-btn"
                    primary
                    className="w-100"
                    title={intl.formatMessage({id: 'actions.add'})}
                    onClick={submitIfValid}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-lg-0">
        <div className="dashboard-content">
          <EventDetailsForm isNew form={form} />
        </div>
        {/*Cancel/Add buttons*/}
        <div className="row gx-3 align-items-center d-lg-none">
          <div className="col text-center">
            <Button
              onClick={() => history.push(urls.EVENTS_LIST_PATH)}
              link
              title={intl.formatMessage({id: 'actions.cancel'})}
            />
          </div>
          <div className="col text-center">
            <Button
              primary
              className="w-100"
              title={intl.formatMessage({id: 'actions.add'})}
              onClick={submitIfValid}
            />
          </div>
        </div>
      </div>
      <Prompt
        when={!!formChanges && !createdEvent}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />
    </div>
  );
};

export default AddEvent;
