import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../../types';

export function useCanDeleteSection(
  watch: UseFormWatch<SeatmapFormFields>,
  isSectionMissing: boolean
) {
  const seatmapFile = watch('seatmapFile');
  const isSVGWithSeatmapFormat = watch('isSVGWithSeatmapFormat');

  return (
    isSectionMissing ||
    (seatmapFile
      ? seatmapFile.file.name.split('.').pop() !== 'svg'
      : !isSVGWithSeatmapFormat)
  );
}
