import React, {useEffect} from 'react';
import {Redirect, Switch, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as urls from '../../../constants/urls';
import * as permissions from '../../../constants/permissions';
import SaleDetails from '../../Sales/Details/SalesDetails';
import PermissionSettings from '../../Settings/Permission/PermissionSettings';
import MarketplaceFeeSettings from '../../Settings/MarketplaceFeeSettings';
import TeamSettings from '../../Settings/TeamSettings';
import ListingsList from '../../Listings/ListingsList';
import ListingDetails from '../../Listings/ListingDetails';
import SettingsList from '../../Settings/SettingsList';
import Route from '../Route';
import HomeRedirect from '../../Common/HomeRedirect';
import TransactionsContainer from '../../Transactions/TransactionsContainer';
import UserList from '../../Users/UserList';
import UserDetails from '../../Users/UserDetails';
import EventsManagement from '../../EventsManagement/EventsManagement';
import SaleList from '../../Sales/List/SaleList';
import MobileDrawer from '../../Navbar/MobileDrawer';
import MenuLeft from '../../Navbar/MenuLeft';
import {
  selectToken,
  selectUser,
  updateTargetPath,
} from '../../../store/slices/authSlice';
import AddCategory from '../../EventsManagement/Categories/AddCategory';
import EditCategory from '../../EventsManagement/Categories/EditCategory';
import SeatmapDetails from '../../EventsManagement/Seatmap/SeatmapDetails';
import {AddSeatmap} from '../../EventsManagement/Seatmap/AddSeatmap';
import EditEvent from '../../EventsManagement/Events/EditEvent';
import AddEvent from '../../EventsManagement/Events/AddEvent';
import AddVenue from '../../EventsManagement/Venues/AddVenue';
import EditVenue from '../../EventsManagement/Venues/EditVenue';
import AddPerformer from '../../EventsManagement/Performer/AddPerformer';
import EditPerformer from '../../EventsManagement/Performer/EditPerformer';

const AppRoutes = () => {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const {pathname, search = ''} = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      dispatch(updateTargetPath({targetPath: `${pathname}${search}`}));
    }
    // IMPORTANT: Do not add dependencies here
    // This effect should only be executed when this component is initialized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If there is not auth token, redirect to login page
  if (!token) {
    return <Redirect to={'/login'} />;
  }

  // If the user is not loaded yet, display a loading screen
  if (!user) {
    return null;
  }

  return (
    <section className="react-container d-flex flex-column flex-lg-row">
      <MobileDrawer />
      <MenuLeft />
      <Switch>
        {/* Home */}
        <Route exact path={urls.DASHBOARD_ROOT_PATH} component={HomeRedirect} />
        {/*Sales list*/}
        <Route
          exact
          path={urls.SALES_LIST_PATH}
          component={SaleList}
          permissions={permissions.SALES_LIST_PERMISSIONS}
        />
        {/*Sales details*/}
        <Route
          path={urls.SALES_DETAILS_PATH}
          component={SaleDetails}
          permissions={permissions.SALES_DETAILS_PERMISSIONS}
        />

        {/*Listings*/}
        <Route exact path={urls.LISTINGS_PATH} component={ListingsList} />
        <Route path={urls.LISTING_DETAILS_PATH} component={ListingDetails} />

        {/*Events*/}
        <Route
          exact
          path={
            urls.EVENTS_LIST_PATH +
            '/(event|venue|seatmap|performer|categories|categories/tixstock)?'
          }
          component={EventsManagement}
          permissions={[
            permissions.EventPermissions.seeEventList,
            permissions.VenuePermissions.seeVenueList,
            permissions.SeatmapPermissions.seeSeatmapList,
            permissions.PerformerPermissions.seePerformerList,
            permissions.CategoryPermissions.seeCategoryList,
            permissions.CategoryPermissions.seeTixstockCategories,
          ]}
        />
        <Route
          exact
          path={urls.EVENT_ADD_PATH}
          component={AddEvent}
          permissions={[permissions.EventPermissions.addEvent]}
        />
        <Route
          path={urls.EVENT_DETAILS_PATH}
          component={EditEvent}
          permissions={[permissions.EventPermissions.seeEventDetails]}
        />
        <Route
          exact
          path={urls.VENUE_ADD_PATH}
          component={AddVenue}
          permissions={[permissions.VenuePermissions.addVenue]}
        />
        <Route
          path={urls.VENUE_DETAILS_PATH}
          component={EditVenue}
          permissions={[permissions.VenuePermissions.seeVenueList]}
        />
        <Route
          path={urls.EVENTS_CATEGORY_ADD_PATH}
          component={AddCategory}
          permissions={[permissions.CategoryPermissions.addCategory]}
        />
        <Route
          path={urls.EVENTS_CATEGORY_EDIT_PATH}
          component={EditCategory}
          permissions={[permissions.CategoryPermissions.seeCategoryDetails]}
        />
        <Route
          path={urls.SEATMAP_ADD_PATH}
          component={AddSeatmap}
          permissions={[permissions.SeatmapPermissions.addSeatmap]}
        />
        <Route
          path={urls.SEATMAP_DETAILS_PATH}
          component={SeatmapDetails}
          permissions={[permissions.SeatmapPermissions.seeSeatmapDetails]}
        />
        <Route
          exact
          path={urls.PERFORMER_ADD_PATH}
          component={AddPerformer}
          permissions={[permissions.PerformerPermissions.addPerformer]}
        />
        <Route
          path={urls.PERFORMER_EDIT_PATH}
          component={EditPerformer}
          permissions={[permissions.PerformerPermissions.seePerformerDetails]}
        />

        {/*Users*/}
        <Route
          exact
          path={urls.USERS_LIST_PATH + '/(seller|buyer)?'}
          component={UserList}
        />
        <Route path={urls.USER_DETAILS_PATH} component={UserDetails} />

        {/*Transactions*/}
        <Route
          path={urls.TRANSACTIONS_PATH}
          component={TransactionsContainer}
          permissions={permissions.TRANSACTIONS_LIST_PERMISSIONS}
        />
        {/*Transactions*/}
        <Route
          path={urls.TRANSACTIONS_PATH}
          component={TransactionsContainer}
          permissions={permissions.TRANSACTIONS_LIST_PERMISSIONS}
        />

        {/*** Settings ***/}
        <Route
          exact
          path={urls.SETTINGS_PATH}
          component={SettingsList}
          permissions={permissions.SETTINGS_PERMISSIONS}
        />
        {/*Permissions*/}
        <Route
          path={urls.PERMISSION_SETTINGS}
          component={PermissionSettings}
          permissions={permissions.PERMISSION_PERMISSIONS}
        />
        {/*Marketplace fee*/}
        <Route
          path={urls.MARKETPLACE_FEE_SETTINGS}
          component={
            MarketplaceFeeSettings
          } /*permissions={permissions.MARKETPLACE_FEE_PERMISSIONS}*/
        />
        {/*Team*/}
        <Route
          path={urls.TEAM_MEMBERS_LIST}
          component={
            TeamSettings
          } /*permissions={permissions.TEAM_MEMBERS_PERMISSIONS}*/
        />
        <Redirect from="*" to={urls.DASHBOARD_ROOT_PATH} />
      </Switch>
    </section>
  );
};

export default AppRoutes;
