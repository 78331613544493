import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../../types';

interface BlockNameTitleProps {
  sectionIndex: number;
}

export function BlockNameTitle(props: Readonly<BlockNameTitleProps>) {
  const {sectionIndex} = props;
  const {control} = useFormContext<SeatmapFormFields>();
  const isGeneralAdmission = useWatch<
    SeatmapFormFields,
    `sections.${number}.isGeneralAdmission`
  >({
    control,
    name: `sections.${sectionIndex}.isGeneralAdmission`,
    exact: true,
  });

  if (isGeneralAdmission) return null;

  return (
    <Typography variant="subtitle2" className="text-placeholder me-auto">
      <FormattedMessage id="dashboard.events.venue_details.section_details.block_name" />
    </Typography>
  );
}
