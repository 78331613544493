import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Button from '../../../Common/Button';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
    },
    paper: {
      padding: theme.spacing(1.3, 1.5, 1.5),
      boxShadow: theme.shadows[8],
    },
  })
);

const UpdateSeatmapPopover = ({
  id,
  src,
  open,
  anchorEl,
  handleClose,
  onDecline,
  onUpdate,
}: any) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{root: classes.root, paper: classes.paper}}
    >
      <>
        <div className="mb-4">
          <img className="img-fluid" src={src} style={{width: 400}} />
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="end"
          gridGap={30}
        >
          <Button
            link
            title={intl.formatMessage({
              id: 'dashboard.seatmap.details.decline_update',
            })}
            onClick={onDecline}
          />
          <Button
            primary
            title={intl.formatMessage({id: 'actions.upgrade'})}
            onClick={onUpdate}
          />
        </Box>
      </>
    </Popover>
  );
};

export default UpdateSeatmapPopover;
