import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage, injectIntl} from 'react-intl';
import Drawer from '../../Common/StyledDrawer';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import InputField from '../../Form/Fields/InputField';
import Button from '../../Common/Button';
import {tooLong, required, isAdminWord} from '../../../utils/FormValidation';
import * as PropTypes from 'prop-types';

const validate = (values, {intl}) => {
  const errors = {};
  const requiredError = intl.formatMessage({id: 'validation.error.required'});

  errors.name =
    required(values.name, requiredError) ||
    tooLong(60)(
      values.name,
      intl.formatMessage({id: 'validation.error.too_long'}, {length: 60})
    ) ||
    isAdminWord(
      values.name,
      intl.formatMessage({id: 'validation.error.admin_role_not_allowed'})
    );

  return errors;
};

/* New Role Popup */
let NewRolePopup = props => {
  const {intl, opened, onClose, handleSubmit, saveRole, reset} = props;
  const submit = values => {
    saveRole(values);
  };

  useEffect(() => {
    if (opened) {
      document.body.classList.add('right-modal-open');
    } else {
      document.body.classList.remove('right-modal-open');
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Drawer className="new-role-popup" open={opened} onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="fields">
          <Typography variant="h5" className="mb-3">
            <FormattedMessage id="dashboard.settings.permissions.new_role" />
          </Typography>
          <Field
            name="name"
            component={InputField}
            outlined
            externalLabel
            label={intl.formatMessage({
              id: 'dashboard.settings.permissions.role_name',
            })}
          />
        </div>
        <div className="buttons">
          <div className="action-buttons">
            <Button
              link
              title={intl.formatMessage({id: 'actions.close'})}
              onClick={onClose}
              type="button"
            />
            <Button
              primary
              title={intl.formatMessage({id: 'actions.add'})}
              type="submit"
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
};

NewRolePopup.propTypes = {
  saveRole: PropTypes.func,
};

NewRolePopup = reduxForm({
  form: 'NewRolePopupForm',
  enableReinitialize: true,
  validate,
})(NewRolePopup);

const mapStateToProps = state => {
  return {
    initialValues: {},
  };
};

export default connect(mapStateToProps)(injectIntl(NewRolePopup));
