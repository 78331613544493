import {IEventState} from './event.state';
import {useStore} from 'react-redux';
import {useCallback} from 'react';

export function useGetEventErrorElementsRef() {
  const store = useStore();
  return useCallback(() => {
    const {event} = store.getState() as {event: IEventState};
    return event.errorElementRefsMap;
  }, [store]);
}
