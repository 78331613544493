export const EventFormDefaultValues = {
  performers: [],
  title: '',
  slug: '',
  eventTime: '',
  eventDate: '',
  isDateConfirmed: false,
  isTimeConfirmed: false,
  domainIds: [],
  seatmapId: 0,
  isLive: true,
  isPostponed: false,
  isPremium: false,
  premiumDaysBeforeEvent: 0,
  defaultPerformers: 0,
  isCustom: false,
  useCategoryDomainSettings: true,
};

export const EmptyPerformer = {
  name: '',
  id: 0,
};

export const FilterItems = {
  title: 'dashboard.events.list.filters.event_name',
  source: 'dashboard.events.list.filters.event_source',
  status: 'dashboard.events.list.filters.event_status',
  performers: 'dashboard.events.list.filters.performer',
  categories: 'dashboard.events.list.filters.category',
  venue: 'dashboard.events.list.filters.venue',
  isMissingSeatmapImage: 'dashboard.events.list.filters.seatmap_is_missing',
  isPremium: 'dashboard.events.list.filters.event_is_premium',
  toBeConfirmed: 'dashboard.events.list.filters.to_be_confirmed',
  requiresUpdate: 'dashboard.events.list.filters.requires_update',
  date: 'dashboard.events.list.filters.event_date',
  creationDate: 'dashboard.events.list.filters.event_creation_date',
  fanpassEventSource: 'dashboard.events.list.filters.event_source',
  tixstockEventSource: 'dashboard.events.list.filters.event_source',
  activeStatus: 'dashboard.events.list.filters.event_status',
  inactiveStatus: 'dashboard.events.list.filters.event_status',
};

export const StaticFilters: Record<string, string> = {
  fanpassEventSource: 'Fanpass',
  tixstockEventSource: 'Tixstock',
  activeStatus: 'Upcoming',
  inactiveStatus: 'Passed',
};

export const DataTableSortKeysOverrides = {
  event: 'title',
  status: 'isLive',
};
