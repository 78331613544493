import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {eventSlice} from './event.slice';

export function useAddEventsErrorElementRef() {
  const dispatch = useDispatch();
  return useCallback(
    (name, element) => {
      dispatch(eventSlice.actions.addErrorElementRef({name, element}));
    },
    [dispatch]
  );
}
