import {Col, Row} from 'reactstrap';
import Autocomplete from '../../../Common/Autocomplete';
import {usePerformersDropdownQuery} from '../../../../services/eventsApi';
import React, {useState} from 'react';
import {Controller, UseFieldArrayRemove, useFormContext} from 'react-hook-form';
import {FormHelperText, IconButton} from '@material-ui/core';
import {ColumnDelete} from '../../../../constants/images';
import {useIsWithDown} from '../../../../hooks/UI';
import {BREAKPOINTS} from '../../../../constants/appConstants';

interface PerformerListItemProps {
  remove: UseFieldArrayRemove;
  index: number;
  disabled?: boolean;
}

const PerformerListItem = ({
  remove,
  index,
  disabled,
}: PerformerListItemProps) => {
  const [performerSearch, setPerformerSearch] = useState<string | null>(null);
  const {data, isLoading, isFetching} =
    usePerformersDropdownQuery(performerSearch);
  const isWidthSmall = useIsWithDown(BREAKPOINTS.MD);

  const {control, watch, setValue, clearErrors} = useFormContext();
  const performer = watch(`performers.${index}`);

  return (
    <Controller
      control={control}
      name={`performers.${index}.name`}
      render={({
        field: {onBlur},
        formState: {
          errors: {performers: performerErrors},
        },
      }) => {
        const error =
          (Array.isArray(performerErrors) &&
            performerErrors?.at(index)?.name?.message) ??
          (Array.isArray(performerErrors) &&
            performerErrors?.at(index)?.message);

        return (
          <Row
            style={{
              alignItems: 'start',
            }}
          >
            <Col
              style={{paddingRight: 1, flexBasis: isWidthSmall ? '90%' : '93%'}}
            >
              <Autocomplete
                disableClearable
                disabled={disabled}
                data-testid={`event-performer-name-${index}`}
                options={data?.performers ?? []}
                value={performer}
                getOptionLabel={option => option.name ?? ''}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoading || isFetching}
                onBlur={onBlur}
                isInvalid={!!error}
                onInputChange={(event, value) => {
                  setPerformerSearch(value);
                }}
                onChange={(event, value) => {
                  setValue(`performers.${index}`, value, {
                    shouldDirty: true,
                  });
                  clearErrors('performers');
                }}
                renderOption={option => {
                  return (
                    <Col className="form-group mb-0 mt-0">
                      <Row className="row-label">{option.name}</Row>
                    </Col>
                  );
                }}
              />
              <FormHelperText error>{error}</FormHelperText>
            </Col>
            <Col
              xs="1"
              style={{
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                flexBasis: isWidthSmall ? '10%' : '7%',
              }}
            >
              <IconButton
                size="small"
                style={{transform: 'translate(-15%, 15%)'}}
                onClick={() => {
                  remove(index);
                }}
                disabled={disabled}
              >
                <ColumnDelete style={{fontSize: 20}} />
              </IconButton>
            </Col>
          </Row>
        );
      }}
    />
  );
};

export default React.memo(PerformerListItem);
