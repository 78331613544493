import {useIntl} from 'react-intl';
import React, {ChangeEvent, useEffect, useState} from 'react';
import Select from '../../Common/Select';
import DatePicker from '../../Common/DatePicker';
import BaseInput from '../../Common/BaseInput';
import {
  DateSelectLastOptions,
  DateSelectOnFilterChangePayload,
  FilterInputTypesEnum,
  FilterItem,
} from './types';
import {useModularFilterUrlValue} from './hooks';
import {
  eventBusEvents,
  useEventBusListener,
} from '../../../store/slices/eventBus';
import {nanoid} from 'nanoid';

export type DateSelectOnFilterChangePayloadValue =
  DateSelectOnFilterChangePayload['value'];

const initialValues = {
  from: new Date().toISOString(),
  to: new Date().toISOString(),
  lastOption: DateSelectLastOptions.DAYS,
  selectingType: undefined,
  lastOptionValue: undefined,
};

export function DateSelectFilter(props: Readonly<FilterItem>) {
  const {name, onFilterChange, onFilterRemove} = props;
  const intl = useIntl();
  const urlValues = useModularFilterUrlValue(name);
  const {on, off} = useEventBusListener();

  const [values, setValues] =
    useState<DateSelectOnFilterChangePayloadValue>(initialValues);

  const {
    from: fromDate = new Date().toISOString(),
    to: toDate = new Date().toISOString(),
    selectingType: option,
    lastOption: optionLast = DateSelectLastOptions.DAYS,
    lastOptionValue,
  } = values ?? {};

  const onOptionLastInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value) {
      setValues(prev => ({
        ...prev,
        lastOptionValue: undefined,
      }));
      return;
    }
    if (Number.isNaN(value)) return;
    const numberValue = Number(value ?? 0);
    if (numberValue > 0) {
      const date = new Date();
      switch (optionLast) {
        case DateSelectLastOptions.DAYS:
          date.setDate(date.getDate() - numberValue);
          break;
        case DateSelectLastOptions.WEEKS:
          date.setDate(date.getDate() - numberValue * 7);
          break;
        case DateSelectLastOptions.MONTHS:
          date.setMonth(date.getMonth() - numberValue);
          break;
      }

      const changePayload = {
        from: date.toISOString(),
        to: new Date().toISOString(),
        selectingType: option,
        lastOption: optionLast,
        lastOptionValue: String(numberValue),
      };
      setValues(changePayload);
    }
  };

  const onOptionLastSelectChange = (value: DateSelectLastOptions) => {
    const lastOptionValueNumber = Number(lastOptionValue);
    if (Number.isNaN(lastOptionValueNumber)) {
      setValues(prev => ({...prev, lastOption: value}));
      return;
    }

    if (lastOptionValueNumber > 0) {
      const date = new Date();
      switch (value) {
        case DateSelectLastOptions.DAYS:
          date.setDate(date.getDate() - lastOptionValueNumber);
          break;
        case DateSelectLastOptions.WEEKS:
          date.setDate(date.getDate() - lastOptionValueNumber * 7);
          break;
        case DateSelectLastOptions.MONTHS:
          date.setMonth(date.getMonth() - lastOptionValueNumber);
          break;
      }

      const changePayload = {
        from: date.toISOString(),
        to: new Date().toISOString(),
        selectingType: option,
        lastOption: value,
        lastOptionValue,
      };
      setValues(changePayload);
    }
  };

  useEffect(() => {
    if (urlValues) {
      setValues(urlValues);
    } else {
      onFilterRemove?.(name);
      setValues(initialValues);
    }
  }, [urlValues, onFilterRemove, name]);

  useEffect(() => {
    if (values && values.selectingType) {
      onFilterChange?.({
        value: values,
        type: FilterInputTypesEnum.DATE_SELECT,
        name,
      });
    }
  }, [onFilterChange, values, name]);

  useEffect(() => {
    const callbackId = nanoid(5);
    on(
      eventBusEvents.ModularFilterEvents.CLEAR_FILTER,
      callbackId,
      (clearedFilterNames: string[]) => {
        if (clearedFilterNames.includes(name)) {
          setValues(initialValues);
        }
      }
    );
    return () => {
      off(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, callbackId);
    };
  }, [on, off, name]);

  return (
    <div className="date-selects-wrapper d-flex flex-column">
      <Select
        value={option || ''}
        placeholder={intl.formatMessage({id: 'dashboard.filters.date_range'})}
        items={[
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_between'}),
            value: 'between',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_last'}),
            value: 'last',
          },
        ]}
        onChange={value => {
          const changePayload = {
            from: fromDate,
            to: toDate,
            selectingType: value,
            lastOption: optionLast,
            lastOptionValue,
          };
          setValues(changePayload);
        }}
      />
      {/* In between variant' inputs */}
      {option === 'between' && (
        <div className="in-between-inputs">
          {/* Date From */}
          <DatePicker
            placeholder={intl.formatMessage({id: 'actions.from'})}
            onChange={value => {
              const changePayload = {
                from: value?.toISOString(),
                to: toDate,
                selectingType: option,
                lastOption: optionLast,
                lastOptionValue,
              };
              setValues(changePayload);
            }}
            defaultValue={fromDate ? new Date(fromDate) : new Date()}
            value={fromDate ? new Date(fromDate) : new Date()}
          />
          {/* Date To */}
          <DatePicker
            placeholder={intl.formatMessage({id: 'actions.to'})}
            onChange={value => {
              const changePayload = {
                from: fromDate ?? new Date().toISOString(),
                to: value?.toISOString(),
                selectingType: option,
                lastOption: optionLast,
                lastOptionValue,
              };
              setValues(changePayload);
            }}
            defaultValue={toDate ? new Date(toDate) : new Date()}
            value={toDate ? new Date(toDate) : new Date()}
          />
        </div>
      )}
      {/* in the last variant' inputs */}
      {option === 'last' && (
        <div className="last-days-inputs d-flex align-items-center">
          <BaseInput
            className="days-input"
            type="text"
            onChange={onOptionLastInputChange}
            value={lastOptionValue}
          />
          <div className="flex-grow-1">
            <Select
              placeholder={intl.formatMessage({id: 'dashboard.filters.days'})}
              items={[
                {
                  label: intl.formatMessage({id: 'dashboard.filters.days'}),
                  value: DateSelectLastOptions.DAYS,
                },
                {
                  label: intl.formatMessage({id: 'dashboard.filters.weeks'}),
                  value: DateSelectLastOptions.WEEKS,
                },
                {
                  label: intl.formatMessage({id: 'dashboard.filters.months'}),
                  value: DateSelectLastOptions.MONTHS,
                },
              ]}
              defaultValue={DateSelectLastOptions.DAYS}
              value={optionLast}
              onChange={value => {
                if (value) {
                  onOptionLastSelectChange(value as DateSelectLastOptions);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
