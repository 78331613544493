import cx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {CollapseIcon, ExpandIcon} from '../../../Common/CollapsableBlock';
import {ArrowRightIcon} from '../../../../constants/images';
import Radio from '../../../Common/Radio';
import {Fragment} from 'react';
import {useParentCategoriesStyles} from './styles';
import {ICategory} from '../../../../services/eventsApi';

export interface ParentCategoriesProps {
  categories: ICategory[];
  selectedCategoryIdsTree: number[];
  disabledId?: number;
  onSelect: (category: ICategory) => void;
}

const ParentCategories = ({
  categories,
  selectedCategoryIdsTree,
  onSelect,
  disabledId,
}: ParentCategoriesProps) => {
  const classes = useParentCategoriesStyles();
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-categories"
      disablePadding
    >
      {categories.map(category => {
        const isOpened = selectedCategoryIdsTree.includes(category.id);

        return (
          <Fragment key={category.id}>
            <ListItem
              key={category.id}
              button
              className={classes.item}
              onClick={() => {
                onSelect(category);
              }}
            >
              <Radio
                color="primary"
                checked={selectedCategoryIdsTree.includes(category.id)}
              />
              <ListItemText
                primary={category.name}
                className={cx({selected: isOpened})}
              />
              <ListItemIcon>
                {isOpened ? <CollapseIcon /> : <ExpandIcon />}
              </ListItemIcon>
            </ListItem>
            {isOpened && <Divider className={classes.divider} />}
            <Collapse in={isOpened} timeout="auto" unmountOnExit>
              <>
                <List component="div" disablePadding>
                  {category.children.map((child, index) => {
                    const disabled = disabledId === child.id;
                    const ignoreClick =
                      selectedCategoryIdsTree.at(-1) === child.id || disabled;
                    return (
                      <ListItem
                        key={child.id}
                        className={cx(classes.item, classes.nested)}
                        button
                        disabled={disabled}
                        onClick={() => {
                          if (!ignoreClick) {
                            onSelect(child);
                          }
                        }}
                      >
                        <Radio
                          color="primary"
                          checked={selectedCategoryIdsTree.includes(child.id)}
                          disabled={disabled}
                        />
                        <ListItemText>{child.name}</ListItemText>
                        {child.children.length > 0 && (
                          <ListItemIcon>
                            <ArrowRightIcon style={{width: 8, height: 11}} />
                          </ListItemIcon>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
                <Divider className={classes.divider} />
              </>
            </Collapse>
          </Fragment>
        );
      })}
    </List>
  );
};
export default ParentCategories;
