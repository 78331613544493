import {FormattedMessage, useIntl} from 'react-intl';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import IconButton from '../../../Common/IconButton';
import {ArrowForwardIcon} from '../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import {UseFormReturn} from 'react-hook-form';
import {PerformerFormFields} from '../types';
import {IPerformer} from '../../../../services/eventsApi';
import {isUrl} from '../../../../utils/FormValidation';
import {EnhancedInputField} from '../../../Form/Fields/EnhancedInputField/EnhancedInputField';

interface PerformerFormProps {
  performer?: IPerformer;
  form: UseFormReturn<PerformerFormFields>;
  disabled?: boolean;
  isNew?: boolean;
}

const PerformerForm = ({isNew = false, form, disabled}: PerformerFormProps) => {
  const intl = useIntl();

  const {
    watch,
    register,
    clearErrors,
    formState: {errors},
  } = form;

  const landingPageUrl = watch('landingPageUrl');

  return (
    <Form autoComplete="off" form={form} data-testid="performer-form">
      <Row className="g-3">
        <Col lg={8}>
          <Card className="details-card mb-0">
            <CardBody>
              {!isNew && (
                <Typography variant="h4" className="mb-3">
                  <FormattedMessage id="dashboard.performer.performer_details" />
                </Typography>
              )}
              <EnhancedInputField
                label={`${intl.formatMessage({
                  id: 'dashboard.performer.name',
                })}*`}
                wrapperProps={{
                  className: 'form-group mb-2 mb-lg-fg',
                  'data-testid': 'performer-name-input-wrapper',
                }}
                error={!!errors.name?.message}
                errorMessage={errors.name?.message}
                disabled={disabled}
                {...register('name')}
                onChange={e => {
                  void register('name').onChange(e);
                  clearErrors('name');
                }}
              />
              <EnhancedInputField
                disabled={disabled}
                label={intl.formatMessage({
                  id: 'dashboard.events.add.page_url',
                })}
                {...(isUrl(landingPageUrl) && {
                  addon: (
                    <IconButton
                      color="default"
                      size="small"
                      variant="action"
                      className="m-0 bg-transparent shadow-none"
                      onClick={() => {
                        landingPageUrl && window.open(landingPageUrl);
                      }}
                    >
                      <ArrowForwardIcon
                        style={{
                          width: 7,
                          height: 'fit-content',
                        }}
                      />
                    </IconButton>
                  ),
                })}
                addonPosition="end"
                error={!!errors.landingPageUrl?.message}
                errorMessage={errors.landingPageUrl?.message}
                wrapperProps={{
                  className: 'form-group mb-0 mb-lg-fg',
                  'data-testid': 'landingPageUrl-name-input-wrapper',
                }}
                {...register('landingPageUrl')}
                onChange={e => {
                  void register('landingPageUrl').onChange(e);
                  clearErrors('landingPageUrl');
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default PerformerForm;
