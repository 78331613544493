import {debounce} from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export function useSearch(delayQueryUpdate?: number) {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const location = useLocation();

  const updateSearchText = useMemo(
    () => debounce(history.replace, delayQueryUpdate ?? 0),
    [delayQueryUpdate, history.replace]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get('q');
    if (q) {
      setSearchText(q);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (searchText) {
      params.set('q', searchText);
      const newUrl = `${location.pathname}?${params.toString()}`;
      updateSearchText(newUrl);
    } else {
      params.delete('q');
      const newUrl = `${location.pathname}?${params.toString()}`;
      updateSearchText(newUrl);
    }
  }, [
    searchText,
    history,
    location.search,
    location.pathname,
    updateSearchText,
  ]);

  return {searchText, setSearchText};
}
