import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import {Col, Row} from 'reactstrap';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import ColorSelect from '../../../../Form/Inputs/ColorSelect';
import {BlocksView} from './Blocks/BlocksView';
import {
  CountSectionChangesFunction,
  OnBlockAddedOrRemovedFunction,
  SeatmapFormFields,
  SeatmapSectionFormFields,
} from '../../types';
import clsx from 'clsx';
import React, {forwardRef} from 'react';
import {slugify} from '../../helpers';
import {GeneralAdmissionCheckbox} from './GeneralAdmissionCheckbox';
import {EnhancedInputField} from '../../../../Form/Fields/EnhancedInputField/EnhancedInputField';
import {
  useAddSeatmapErrorElementRef,
  useIsSvgMissingSection,
} from '../../../../../store/slices/eventsManagement';
import {DeleteSectionButton} from './DeleteSectionButton';

interface SectionListItemProps {
  remove: (sectionId: string) => void;
  index: number;
  section: SeatmapSectionFormFields;
  countSectionChanges?: CountSectionChangesFunction;
  onBlockAddedOrRemoved?: OnBlockAddedOrRemovedFunction;
  disabled?: boolean;
}

export const SectionListItem = forwardRef<
  HTMLInputElement,
  SectionListItemProps
>((props: SectionListItemProps, ref) => {
  const {
    section,
    index,
    remove,
    countSectionChanges,
    onBlockAddedOrRemoved,
    disabled,
  } = props;

  const {name, slug} = slugify(section);

  const addErrorElementRef = useAddSeatmapErrorElementRef();

  const {
    control,
    watch,
    clearErrors,
    formState: {errors},
    trigger,
  } = useFormContext<SeatmapFormFields>();

  const isSVGWithSeatmapFormat = useWatch<
    SeatmapFormFields,
    'isSVGWithSeatmapFormat'
  >({
    control,
    name: 'isSVGWithSeatmapFormat',
    exact: true,
  });

  const isSvgMissingSection =
    useIsSvgMissingSection(slug) && isSVGWithSeatmapFormat;

  const blockOrGeneralAdmissionError = Array.isArray(errors.sections)
    ? errors.sections.at(index)?.customErrors?.blockOrGeneralAdmissionError ??
      {}
    : {};

  const sectionNameUniquenessError = Array.isArray(errors.sections)
    ? errors.sections.at(index)?.customErrors?.sectionNameUniqueness ?? {}
    : {};

  return (
    <Box display="flex" flexDirection="column" gridGap={10}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={10}
        bgcolor="background.default"
        py={1.5}
        px={1}
        borderRadius={8}
      >
        <Box display="flex" alignItems="center" gridGap={10}>
          <Typography variant="subtitle2" className="text-placeholder me-auto">
            <FormattedMessage id="dashboard.events.venue_details.section_details.section_name" />
          </Typography>
          <GeneralAdmissionCheckbox
            sectionId={String(section.id)}
            sectionIndex={index}
            countSectionChanges={countSectionChanges}
            disabled={disabled}
          />
        </Box>
        <Row className="g-2">
          <Col lg={8}>
            <Controller
              control={control}
              name={`sections.${index}.name`}
              render={({
                field: {onChange, value, onBlur},
                fieldState: {error},
              }) => {
                return (
                  <>
                    {/* the following div is used for scroll to error behaviour*/}
                    <div
                      ref={ref => {
                        addErrorElementRef(`sections.${index}.name`, ref);
                      }}
                    />
                    <EnhancedInputField
                      ref={ref}
                      data-testid={`seatmap-section-name-${name.toLowerCase().replace(' ', '-')}`}
                      className={clsx({
                        'bg-white': !error?.message && !isSvgMissingSection,
                      })}
                      disabled={disabled}
                      onBlur={() => {
                        onBlur();
                        trigger([
                          `sections.${index}.customErrors.sectionNameUniqueness`,
                          `sections.${index}.name`,
                        ]).catch();
                      }}
                      onChange={event => {
                        onChange(event);
                        const value = event.target.value;
                        countSectionChanges?.({
                          sectionId: String(section.id),
                          value,
                          sectionChangedProp: 'name',
                        });
                        clearErrors(`sections.${index}.name`);
                        clearErrors(
                          `sections.${index}.customErrors.sectionNameUniqueness`
                        );
                      }}
                      value={value}
                      name={`sections.${index}.name`}
                      error={
                        isSvgMissingSection ||
                        !!error?.message ||
                        !!sectionNameUniquenessError.message
                      }
                      errorMessage={
                        error?.message ?? sectionNameUniquenessError.message
                      }
                    />
                  </>
                );
              }}
            />
          </Col>
          <Col lg={4}>
            <Controller
              control={control}
              name={`sections.${index}.color`}
              render={({field: {onChange, value}}) => (
                <ColorSelect
                  data-testid={`seatmap-section-color-${name.toLowerCase().replace(' ', '-')}`}
                  disabled={disabled}
                  onChange={(...args) => {
                    onChange(...args);
                    const value = args[0].target.value as string;
                    countSectionChanges?.({
                      value,
                      sectionId: String(section.id),
                      sectionChangedProp: 'color',
                    });
                  }}
                  name={`sections.${index}.color`}
                  defaultValue={value}
                  value={value}
                />
              )}
            />
          </Col>
        </Row>
        <BlocksView
          sectionIndex={index}
          sectionSlug={slug}
          isSvgMissingSection={isSvgMissingSection}
          countSectionChanges={countSectionChanges}
          onBlockAddedOrRemoved={onBlockAddedOrRemoved}
          disabled={disabled}
          deleteSectionButton={
            <DeleteSectionButton
              sectionId={`${section.id}`}
              isSectionMissing={isSvgMissingSection}
              watch={watch}
              remove={remove}
              disabled={disabled}
            />
          }
          sectionId={section.id}
        />
        <span
          className="error"
          style={{
            display: blockOrGeneralAdmissionError.message ? 'block' : 'none',
          }}
          ref={ref => {
            addErrorElementRef(`sections.${index}`, ref);
          }}
        >
          {blockOrGeneralAdmissionError.message}
        </span>
      </Box>
    </Box>
  );
});
