import * as yup from 'yup';
import {ObjectSchema} from 'yup';
import {IntlShape} from 'react-intl';
import {PerformerFormFields} from './types';
import {getUrlSchema} from '../../../utils/FormValidation';

export const PerformerFormDefaultValues: Readonly<PerformerFormFields> = {
  name: '',
  landingPageUrl: '',
};

type PerformerFormSchema = ObjectSchema<PerformerFormFields>;

export function getPerformerValidationSchema(
  intl: IntlShape
): PerformerFormSchema {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(intl.formatMessage({id: 'validation.error.required'}))
      .max(
        255,
        intl.formatMessage({id: 'validation.error.too_long'}, {length: 255})
      ),
    landingPageUrl: getUrlSchema(intl).nullable().optional(),
  });
}
