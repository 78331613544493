import moment from 'moment';
import {EventFormFields} from './types';
import {FieldErrors} from 'react-hook-form';
import {ErrorElementRefs} from '../../../store/slices/eventsManagement';

function convertTimeToUTC(timeString: string) {
  const [time, period] = timeString.split(' ');
  let [hours] = time.split(':').map(Number);
  const minutes = Number(time.split(':')[1]);

  if (period.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  const localDate = new Date();
  localDate.setHours(hours, minutes, 0, 0);

  const utcHours = localDate.getUTCHours();
  const utcMinutes = localDate.getUTCMinutes();

  return `${utcHours.toString().padStart(2, '0')}:${utcMinutes.toString().padStart(2, '0')}`;
}

function convertTimeFromUTC(time: string) {
  const [hours, minutes, seconds] = time.split(':');
  const now = new Date();
  const utcDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      Number(hours),
      Number(minutes),
      Number(seconds)
    )
  );
  const localDate = new Date(utcDate);

  let localHours: number = localDate.getHours();
  const localMinutes = localDate.getMinutes().toString().padStart(2, '0');

  const period = localHours >= 12 ? 'PM' : 'AM';

  localHours = localHours % 12;
  localHours = localHours ? localHours : 12; // the hour '0' should be '12'

  return `${localHours}:${localMinutes} ${period}`;
}

export const getDateTimeInUTC = (
  date: string | null | undefined,
  time: string | null
): {date: string | null; time: string | null} => {
  const eventDate = date || '2024-01-01';
  const eventTime = time || '02:00 PM';
  const tempDate = new Date(
    moment(eventDate, 'YYYY-MM-DD').format('YYYY-MM-DD') +
      'T' +
      moment(eventTime, 'hh:mm A').format('HH:mm:ss')
  );
  const utcDate = moment.utc(tempDate).format();

  return {
    date: date ? moment.utc(utcDate).format('YYYY-MM-DD') : null,
    time: time ? convertTimeToUTC(time) : null,
  };
};

export const getDateTimeInEventFormat = (
  date: string | null | undefined,
  time: string | null
): {date: string | null; time: string | null} => {
  const eventDate = date
    ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
    : null;
  const eventTime = time ? moment(time, 'hh:mm A').format('HH:mm:ss') : null;
  return {
    date: eventDate,
    time: eventTime,
  };
};

export const fromUtcToUserTimezone = (
  date: string | null,
  time: string | null
): {date: string; time: string} => {
  const eventDate = date || '2024-01-01';
  const eventTime = time || '14:00:00';
  // Combine date and time, assuming input is in UTC
  const utcDateTime = moment.utc(
    `${eventDate} ${eventTime}`,
    'YYYY-MM-DD HH:mm:ss'
  );
  // Convert to user's timezone
  const userDateTime = utcDateTime.local();
  return {
    date: date ? userDateTime.format('YYYY-MM-DD') : '',
    // time: time ? userDateTime.format('hh:mm A') : '',
    time: time ? convertTimeFromUTC(time) : '',
  };
};

export function scrollToFirstError(
  errors: FieldErrors<EventFormFields>,
  errorElementRefs: ErrorElementRefs,
  isCustomPremiumDateErrorExist?: boolean
) {
  if (isCustomPremiumDateErrorExist && errorElementRefs.eventSummary) {
    errorElementRefs.eventSummary.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    return;
  }

  const sortedErrors = [
    'categoryId',
    'performers',
    'title',
    'eventDate',
    'eventTime',
    'domainIds',
    'venue',
    'seatmapId',
  ];

  for (const key of sortedErrors) {
    if (key in errors && key in errorElementRefs) {
      errorElementRefs[key].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
  }
}

export function splitTime(time: string) {
  const is24Format = !time.includes('M');
  if (is24Format) {
    return time.split(':').map(Number);
  }
  const [timePart, period] = time.split(' ');
  const [hours, minutes] = timePart.split(':').map(Number);
  if (period.toLowerCase() === 'am') {
    return [hours, minutes].map(Number);
  }
  return [Number(hours) + 12, minutes].map(Number);
}
