import cx from 'clsx';
import {Theme} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/styles';
import IconButton from '../../../../Common/IconButton';
import {EditIconWhite, TrashIconWhite} from '../../../../../constants/images';
import {DropzoneInputProps, DropzoneRootProps} from 'react-dropzone';
import {UseFormWatch} from 'react-hook-form';
import {SeatmapFormFields} from '../../types';
import {SeatmapSvgViewer} from '../../../components/SeatmapSvgViewer';
import {SeatmapImageViewTitle} from './SeatmapImageViewTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    minHeight: '10vh',
    '&:before': {
      content: '""',
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#282B3EB2',
      mixBlendMode: 'multiply',
      zIndex: 1,
    },
    '&:hover': {
      '&:before': {
        display: 'block',
      },
      '& .btn-toolbar': {
        display: 'flex',
      },
      '& .message': {
        display: 'block',
      },
    },
  },
  btnsWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 0,
    display: 'none',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingRight: 10,
    zIndex: 2,
    '& .MuiButtonBase-root': {
      padding: 10,
      margin: 0,
      boxShadow: 'none',
    },
  },
}));

export type SeatmapViewProps = {
  src: string;
  className?: string;
  onDelete?: () => void;
  getInputProps?: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps?: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  watch: UseFormWatch<SeatmapFormFields>;
};

export const SeatmapImageView = (props: SeatmapViewProps) => {
  const {src, className, onDelete, getInputProps, getRootProps, watch} = props;
  const classes = useStyles();
  const handleDelete = () => {
    onDelete && onDelete();
  };

  const isSVGWithSeatmapFormat = watch('isSVGWithSeatmapFormat');

  return (
    <Box className={cx(classes.root, className)}>
      {!isSVGWithSeatmapFormat && (
        <img className="img-fluid" src={src} alt="Seatmap" />
      )}
      {isSVGWithSeatmapFormat && <SeatmapSvgViewer src={src} watch={watch} />}
      <SeatmapImageViewTitle />
      <div className={cx('btn-toolbar', classes.btnsWrapper)}>
        {getRootProps && (
          <div {...getRootProps()}>
            <IconButton variant="action">
              {getInputProps && <input {...getInputProps()} />}
              <EditIconWhite style={{fontSize: 20}} />
            </IconButton>
          </div>
        )}
        <IconButton variant="action" onClick={handleDelete}>
          <TrashIconWhite style={{fontSize: 20}} />
        </IconButton>
      </div>
    </Box>
  );
};
