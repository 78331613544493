import {useIntl} from 'react-intl';
import {PlusIconDanger} from '../../../../../../constants/images';
import {memo} from 'react';
import Button from '../../../../../Common/Button';

interface AddBlockProps {
  disabled?: boolean;
  canAddBlock?: boolean;
  onAddBlock: () => void;
}

export const AddBlock = memo((props: AddBlockProps) => {
  const {disabled, canAddBlock, onAddBlock} = props;
  const intl = useIntl();

  if (!canAddBlock) return null;

  return (
    <Button
      data-testid={'seatmap-section-block-add-button'}
      disabled={disabled}
      link
      small
      danger
      style={{fontWeight: '600'}}
      icon={<PlusIconDanger />}
      title={intl.formatMessage({
        id: 'dashboard.seatmap.details.new_block',
      })}
      onMouseDown={onAddBlock}
    />
  );
});
