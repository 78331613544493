import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import {
  AutocompleteFilter,
  CheckboxFilter,
  CollapsableFilterItem,
  DateSelectFilter,
  ModularFilter,
  MultiSelectAutocompleteFilter,
  MultiSelectAutocompleteOption,
  RadioGroupFilter,
} from '../../../Widgets/ModularFilter';
import {
  EventFilterValues,
  useCategoriesDropdownQuery,
  useEventsDropdownQuery,
  usePerformersDropdownQuery,
  useVenueListQuery,
} from '../../../../services/eventsApi';
import {
  getActiveFilters,
  getOptionsList,
  prepareFiltersPayload,
} from './helpers';
import {Item} from '../../../Widgets/ActiveFilters';
import {FilterItems} from '../constants';
import {debounce} from 'lodash';

interface EventsFilterProps {
  onApply: (values: EventFilterValues, filters: Item[]) => void;
  onClear: () => void;
}

const date15DayAgo = new Date();
date15DayAgo.setDate(date15DayAgo.getDate() - 15);
const date15DayAgoString = date15DayAgo.toISOString().split('T')[0];

export function EventsFilter(props: Readonly<EventsFilterProps>) {
  const {onApply: onEventFilterApply, onClear} = props;
  const intl = useIntl();
  const [searchEventsText, setSearchEventsText] = useState('');
  const [searchPerformersText, setSearchPerformersText] = useState('');
  const [searchCategoriesText, setSearchCategoriesText] = useState('');
  const [searchVenuesText, setSearchVenuesText] = useState('');
  const [filtersPendingApply, setFiltersPendingApply] =
    useState<Record<string, any>>();

  const {
    data: eventDropDown,
    isLoading: isEventsDropDownLoading,
    isFetching: isEventsDropDownFetching,
  } = useEventsDropdownQuery({
    search: searchEventsText,
    fromDate: date15DayAgoString,
  });

  const {
    data: performerDropDown,
    isLoading: isPerformerDropDownloading,
    isFetching: isPerformerDropDownFetching,
  } = usePerformersDropdownQuery(searchPerformersText);

  const {
    data: categoriesDropDown,
    isLoading: isCategoriesDropDownloading,
    isFetching: isCategoriesDropDownFetching,
  } = useCategoriesDropdownQuery({search: searchCategoriesText});

  const {
    data: venuesDropDown,
    isLoading: isVenuesDropDownloading,
    isFetching: isVenuesDropDownFetching,
  } = useVenueListQuery({name: searchVenuesText});

  const onEventTitleFilterTypingDebounced = useMemo(
    () =>
      debounce((text: string) => {
        setSearchEventsText(text);
      }),
    []
  );

  const onEventTitleFilterTyping = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      onEventTitleFilterTypingDebounced(text);
    },
    [onEventTitleFilterTypingDebounced]
  );

  const onVenuesFilterChangeDebounced = useMemo(() => {
    return debounce((text: string) => {
      setSearchVenuesText(text);
    });
  }, []);

  const onVenuesFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      onVenuesFilterChangeDebounced(text);
    },
    [onVenuesFilterChangeDebounced]
  );

  const onApply = useCallback(
    (values: Record<string, unknown>) => {
      const payload = prepareFiltersPayload(values);
      if (!performerDropDown?.performers || !categoriesDropDown?.categories) {
        setFiltersPendingApply(values);
        return;
      } else {
        setFiltersPendingApply(undefined);
      }
      const activeFilters = getActiveFilters(
        values,
        intl,
        performerDropDown?.performers,
        categoriesDropDown?.categories
      );
      onEventFilterApply(payload, activeFilters);
    },
    [
      categoriesDropDown?.categories,
      intl,
      onEventFilterApply,
      performerDropDown?.performers,
    ]
  );

  useEffect(() => {
    if (
      filtersPendingApply &&
      performerDropDown?.performers &&
      categoriesDropDown?.categories
    ) {
      const payload = prepareFiltersPayload(filtersPendingApply);
      const activeFilters = getActiveFilters(
        filtersPendingApply,
        intl,
        performerDropDown?.performers,
        categoriesDropDown?.categories
      );
      onEventFilterApply(payload, activeFilters);
      setFiltersPendingApply(undefined);
    }
  }, [
    categoriesDropDown?.categories,
    filtersPendingApply,
    intl,
    onApply,
    onEventFilterApply,
    performerDropDown?.performers,
  ]);

  useEffect(() => {
    return () => {
      onVenuesFilterChangeDebounced.cancel();
      onEventTitleFilterTypingDebounced.cancel();
    };
  }, [onVenuesFilterChangeDebounced, onEventTitleFilterTypingDebounced]);

  const eventOptions = getOptionsList('title', 'title', eventDropDown?.events);
  const venueOptions = getOptionsList('name', 'name', venuesDropDown);
  const performerOptions = getOptionsList(
    'name',
    'id',
    performerDropDown?.performers
  );
  const categoryOptions = getOptionsList(
    'name',
    'id',
    categoriesDropDown?.categories
  );

  const yesLabel = intl.formatMessage({id: 'actions.yes'});
  const noLabel = intl.formatMessage({id: 'actions.no'});
  const searchLabel = intl.formatMessage({id: 'actions.search'});

  return (
    <ModularFilter onApply={onApply} onClear={onClear}>
      <CollapsableFilterItem
        titleFormattedMessageId="dashboard.events.list.filters.event_name"
        name="title"
        childrenNames={['title']}
      >
        <AutocompleteFilter
          options={eventOptions}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          name="title"
          loading={isEventsDropDownLoading || isEventsDropDownFetching}
          textFieldProps={{
            inputProps: {
              onChange: onEventTitleFilterTyping,
            },
          }}
        />
      </CollapsableFilterItem>
      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.status}
        name="status"
        childrenNames={['activeStatus', 'inactiveStatus']}
      >
        <CheckboxFilter label="Upcoming" name="activeStatus" />
        <CheckboxFilter label="Passed" name="inactiveStatus" />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.date}
        name="date"
        childrenNames={['date']}
      >
        <DateSelectFilter name="date" />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.toBeConfirmed}
        name="isDateConfirmedFilter"
        childrenNames={['isDateConfirmed']}
      >
        <RadioGroupFilter
          items={[
            {label: yesLabel, value: false},
            {label: noLabel, value: true},
          ]}
          name="isDateConfirmed"
        />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.creationDate}
        name="createdAt"
        childrenNames={['creationDate']}
      >
        <DateSelectFilter name="creationDate" />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.isPremium}
        name="isPremiumFilter"
        childrenNames={['isPremium']}
      >
        <RadioGroupFilter
          items={[
            {label: yesLabel, value: true},
            {label: noLabel, value: false},
          ]}
          name="isPremium"
        />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.performers}
        name="performer"
        childrenNames={['performers']}
      >
        <MultiSelectAutocompleteFilter
          loading={isPerformerDropDownloading || isPerformerDropDownFetching}
          options={performerOptions}
          getOptionValue={option => option.value}
          getSelectedLabel={option => option?.label ?? null}
          name="performers"
          onTyping={setSearchPerformersText}
          inputValue={searchPerformersText}
          isOptionEqualToValue={(option1, option2) =>
            option1.value === option2.value
          }
          placeholder={searchLabel}
          getOptionKey={option => option.value}
          renderOption={(htmlProps, option, state) => (
            <MultiSelectAutocompleteOption
              label={option.label}
              state={state}
              {...htmlProps}
            />
          )}
        />
      </CollapsableFilterItem>
      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.categories}
        name="category"
        childrenNames={['categories']}
      >
        <MultiSelectAutocompleteFilter
          loading={isCategoriesDropDownloading || isCategoriesDropDownFetching}
          options={categoryOptions}
          getOptionValue={option => option.value}
          getSelectedLabel={option => option?.label ?? null}
          name="categories"
          onTyping={setSearchCategoriesText}
          inputValue={searchCategoriesText}
          isOptionEqualToValue={(option1, option2) =>
            option1.value === option2.value
          }
          placeholder={searchLabel}
          getOptionKey={option => option.value}
          renderOption={(htmlProps, option, state) => (
            <MultiSelectAutocompleteOption
              label={option.label}
              state={state}
              {...htmlProps}
            />
          )}
        />
      </CollapsableFilterItem>
      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.venue}
        name="venueFilter"
        childrenNames={['venue']}
      >
        <AutocompleteFilter
          options={venueOptions}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          name="venue"
          loading={isVenuesDropDownloading || isVenuesDropDownFetching}
          textFieldProps={{
            inputProps: {
              onChange: onVenuesFilterChange,
            },
          }}
        />
      </CollapsableFilterItem>
      {/*<CollapsableFilterItem*/}
      {/*  titleFormattedMessageId={FilterItems.requiresUpdate}*/}
      {/*  name="requiresUpdateFilter"*/}
      {/*  childrenNames={['requiresUpdate']}*/}
      {/*>*/}
      {/*  <RadioGroupFilter*/}
      {/*    items={[*/}
      {/*      {label: yesLabel, value: true},*/}
      {/*      {label: noLabel, value: false},*/}
      {/*    ]}*/}
      {/*    name="requiresUpdate"*/}
      {/*  />*/}
      {/*</CollapsableFilterItem>*/}

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.isMissingSeatmapImage}
        name="isMissingSeatmapImageFilter"
        childrenNames={['isMissingSeatmapImage']}
      >
        <RadioGroupFilter
          items={[
            {label: yesLabel, value: true},
            {label: noLabel, value: false},
          ]}
          name="isMissingSeatmapImage"
        />
      </CollapsableFilterItem>

      <CollapsableFilterItem
        titleFormattedMessageId={FilterItems.source}
        name="source"
        childrenNames={['fanpassEventSource', 'tixstockEventSource']}
        style={{marginBottom: '5vh'}}
      >
        <CheckboxFilter label="Fanpass" name="fanpassEventSource" />
        <CheckboxFilter label="Tixstock" name="tixstockEventSource" />
      </CollapsableFilterItem>
    </ModularFilter>
  );
}
