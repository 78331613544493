import {Col, Row} from 'reactstrap';
import {useFormContext, useWatch} from 'react-hook-form';
import clsx from 'clsx';
import IconButton from '../../../../../Common/IconButton';
import {ColumnDelete} from '../../../../../../constants/images';
import React, {forwardRef, memo, useMemo} from 'react';
import {
  CountSectionChangesFunction,
  OnBlockAddedOrRemovedFunction,
  SeatmapFormFields,
} from '../../../types';
import {useIntl} from 'react-intl';
import {get} from 'lodash';
import {EnhancedInputField} from '../../../../../Form/Fields/EnhancedInputField/EnhancedInputField';
import {
  useAddSeatmapErrorElementRef,
  useIsMissingBlockCheck,
} from '../../../../../../store/slices/eventsManagement';
import {useCanDeleteBlock} from '../hooks/useCanDeleteBlock';

interface BlockItemProps {
  blockIndex: number;
  blockId?: number | string;
  sectionSlug: string;
  blockSlug?: string;
  sectionIndex: number;
  sectionId?: number | string;
  isSvgMissingSection: boolean;
  remove: (index: number) => void;
  countSectionChanges?: CountSectionChangesFunction;
  onBlockAddedOrRemoved?: OnBlockAddedOrRemovedFunction;
  disabled?: boolean;
}

export const BlockItem = memo(
  forwardRef<HTMLInputElement | null, BlockItemProps>(
    (props: Readonly<BlockItemProps>, forwardedRef) => {
      const {
        blockIndex,
        blockId,
        blockSlug,
        sectionIndex,
        sectionId,
        isSvgMissingSection,
        remove,
        countSectionChanges,
        onBlockAddedOrRemoved,
        sectionSlug,
        disabled,
      } = props;

      const intl = useIntl();
      const addErrorElementRef = useAddSeatmapErrorElementRef();

      const {
        control,
        formState: {errors},
        register,
        trigger,
        watch,
        getValues,
        setError,
      } = useFormContext<SeatmapFormFields>();

      const blockName = useWatch<
        SeatmapFormFields,
        `sections.${number}.blocks.${number}.name`
      >({
        control,
        name: `sections.${sectionIndex}.blocks.${blockIndex}.name`,
        exact: true,
      });

      const canDeleteBlock = useCanDeleteBlock(watch, isSvgMissingSection);

      const isMissingBlock = useIsMissingBlockCheck(sectionSlug, blockName);

      const blockErrors = get(
        errors,
        `sections[${sectionIndex}].blocks[${blockIndex}]`,
        {}
      ) as Record<string, any>;

      const hasBlockError =
        Object.keys(blockErrors).length > 0 || isMissingBlock;

      const blockFormName = useMemo(
        () =>
          `sections.${sectionIndex}.blocks.${blockIndex}.name` as `sections.${number}.blocks.${number}.name`,
        [sectionIndex, blockIndex]
      );
      const registerValue = useMemo(
        () => register(blockFormName),
        [register, blockFormName]
      );

      const validateNameUniqueness = () => {
        const blocks = getValues(`sections.${sectionIndex}.blocks`);
        if (!blocks || !blockName) return;
        const sameNameBlockIndices = blocks.reduce(
          (acc: number[], block, index) => {
            if (block.name === blockName) {
              acc.push(index);
            }
            return acc;
          },
          []
        );
        if (sameNameBlockIndices.length === 1) return;

        sameNameBlockIndices.forEach(index => {
          setError(
            `sections.${sectionIndex}.blocks.${index}`,
            {
              type: 'blockNameUniqueness',
              message: intl.formatMessage({
                id: 'validation.error.block_name_uniqueness',
              }),
            },
            {shouldFocus: false}
          );
        });
      };

      return (
        <Row className="align-items-center gx-2">
          <Col>
            <EnhancedInputField
              data-testid={`seatmap-section-block-name-${blockName?.toLowerCase().replace(' ', '-')}`}
              disabled={disabled}
              className={clsx({
                'bg-white': !isMissingBlock && !hasBlockError,
              })}
              {...registerValue}
              onBlur={e => {
                setTimeout(() => {
                  e.preventDefault();
                  void registerValue.onBlur(e);
                  // trigger(`sections.${sectionIndex}.blocks`).catch();
                  trigger(
                    `sections.${sectionIndex}.blocks.${blockIndex}`
                  ).catch();
                  validateNameUniqueness();
                }, 100);
              }}
              onChange={event => {
                void registerValue.onChange(event);
                setTimeout(() => {
                  const value = event.target.value;
                  countSectionChanges?.({
                    sectionId: String(sectionId),
                    blockId: blockId,
                    value: value,
                  });
                });

                if (blockErrors.message) {
                  trigger(`sections.${sectionIndex}.blocks`).catch();
                }
              }}
              ref={element => {
                if (forwardedRef) {
                  if (typeof forwardedRef === 'function') {
                    forwardedRef(element);
                  } else {
                    forwardedRef.current = element;
                  }
                }
                registerValue.ref(element);
                addErrorElementRef(blockFormName, element);
              }}
              error={isSvgMissingSection || isMissingBlock || hasBlockError}
              errorMessage={
                isMissingBlock
                  ? intl.formatMessage({
                      id: 'dashboard.seatmap.details.missing_block_description',
                    })
                  : blockErrors?.name?.message ?? blockErrors.message
              }
            />
          </Col>

          {(canDeleteBlock || isMissingBlock) && (
            <Col
              xs="auto"
              className={clsx({
                'mb-3': hasBlockError,
              })}
            >
              <IconButton
                data-testid={`seatmap-section-block-delete-${blockIndex}`}
                disabled={disabled}
                color="default"
                size="small"
                variant="action"
                className="m-0 bg-transparent shadow-none"
                onClick={() => {
                  remove(blockIndex);
                  setTimeout(() => {
                    trigger(`sections.${sectionIndex}`).catch();
                    onBlockAddedOrRemoved?.(
                      String(blockId),
                      String(sectionId),
                      'delete',
                      blockSlug,
                      sectionSlug
                    );
                  });
                }}
              >
                <ColumnDelete style={{fontSize: 20}} />
              </IconButton>
            </Col>
          )}
        </Row>
      );
    }
  )
);
