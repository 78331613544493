import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '../../Common/Button';
import {
  DeleteCategoryQueryParam,
  ICategory,
  useDeleteCategoryMutation,
} from '../../../services/eventsApi';
import {
  CalendarIcon,
  EventManualIcon,
  ImportedEventIcon,
  SyncedIcon,
  TicketIcon,
  UnsyncedIcon,
} from '../../../constants/images';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import PermissionContainer from '../../Common/PermissionContainer';
import {CategoryPermissions} from '../../../constants/permissions';
import {EVENTS_CATEGORY_PATH} from '../../../constants/urls';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import ConfirmationModal from '../../Common/ConfirmationModal';

export type EventDetailsHeaderProps = {
  category: ICategory;
};

const elementClassName = 'd-inline-flex align-items-center gap-2';

const CategoryHeader = (props: EventDetailsHeaderProps) => {
  const {category} = props;
  const intl = useIntl();
  const history = useHistory();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteCategory, {isSuccess}] = useDeleteCategoryMutation();

  const onDeleteConfirmed = useCallback(() => {
    if (!category) return;
    const categoryData: DeleteCategoryQueryParam = {
      id: category.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.CATEGORY_DELETED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    deleteCategory(categoryData);
    setIsConfirmationOpen(false);
  }, [category, deleteCategory, intl]);

  useEffect(() => {
    if (isSuccess) {
      history.replace(EVENTS_CATEGORY_PATH);
    }
  }, [isSuccess, history]);

  const isSynced = category.providers?.some(
    provider => provider.source === 'tixstock' && provider.entity.useForFeeds
  );

  const providers = category.providers;

  return (
    <div className="container-fluid top-block bg-white">
      <div className="details-header">
        <div className="row align-items-center header-row pb-lg-2 mb-1">
          <div className="col-12 col-lg text-center text-lg-start">
            {/* Header */}
            <Typography variant="h3" className="font-weight-bolder">
              {category.name}
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*@ts-ignore */}
            <PermissionContainer
              permissions={[CategoryPermissions.deleteCategoryDetails]}
            >
              <Button
                link
                title={intl.formatMessage({id: 'actions.delete'})}
                className="w-min-unset normal-font"
                onClick={() => {
                  setIsConfirmationOpen(true);
                }}
              />
            </PermissionContainer>
          </div>
        </div>
        <div className="row align-items-center justify-content-center justify-content-lg-start gx-3 gy-2 pb-fan-15 pb-lg-3">
          <div className="col-auto">
            <div className={elementClassName}>
              {!providers?.length ? (
                <>
                  <EventManualIcon style={{width: 15, height: 15}} />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'dashboard.events.add.source_manual',
                    })}
                  </Typography>
                </>
              ) : (
                <>
                  <ImportedEventIcon style={{width: 15, height: 15}} />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'dashboard.events.add.sourceImported',
                    })}
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div className="col-auto">
            <div className={elementClassName}>
              {isSynced ? (
                <>
                  <SyncedIcon style={{width: 13, height: 15}} />
                  <Typography variant="body2">
                    {intl.formatMessage({id: 'dashboard.events.add.synced'})}
                  </Typography>
                </>
              ) : (
                <>
                  <UnsyncedIcon style={{width: 13, height: 15}} />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'dashboard.events.add.unsynced',
                    })}
                  </Typography>
                </>
              )}
            </div>
          </div>
          {!!providers?.length && (
            <div className="col-8 col-lg-auto">
              <div className={elementClassName}>
                <TicketIcon style={{width: 17, height: 15}} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="text-center"
                >
                  {intl.formatMessage({id: 'dashboard.events.add.source'})}:{' '}
                  {intl.formatMessage({
                    id: 'dashboard.events.list.switcher.tixstock',
                  })}{' '}
                  |{' '}
                  {intl.formatMessage({
                    id: 'dashboard.events.add.external_id',
                  })}
                  :{' '}
                  {providers
                    .map(tixstockCategory => tixstockCategory.id)
                    .join(' | ')}
                </Typography>
              </div>
            </div>
          )}
          <div className="col-auto">
            <div className={elementClassName}>
              <CalendarIcon style={{width: 15, height: 15, color: '#989898'}} />
              {providers?.length ? (
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({id: 'dashboard.events.add.imported'})}{' '}
                  {moment(providers.at(-1)?.importDate).format(
                    'DD/MM/YYYY | h:mm A'
                  )}
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({id: 'dashboard.events.add.created'})}
                  {' ' +
                    moment(category.createdAt).format('DD/MM/YYYY | h:mm A')}
                </Typography>
              )}
            </div>
          </div>
        </div>
        {/*Action buttons*/}
        <div className="text-center d-lg-none pb-4">
          {/*@ts-ignore */}
          <PermissionContainer
            permissions={[CategoryPermissions.deleteCategoryDetails]}
          >
            <Button
              link
              title={intl.formatMessage({id: 'actions.delete'})}
              className="w-min-unset"
              onClick={() => {
                setIsConfirmationOpen(true);
              }}
            />
          </PermissionContainer>

          <ConfirmationModal
            opened={isConfirmationOpen}
            onClose={() => {
              setIsConfirmationOpen(false);
            }}
            onAccept={onDeleteConfirmed}
            description={intl.formatMessage({
              id: 'dashboard.confirm_continue_process',
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;
