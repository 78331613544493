import {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {EventFormFields} from '../../types';

interface PremiumDaysBeforeEventConditionsProps {
  disabled?: boolean;
  isEventPassed: boolean;
  isEventDayTooSoon: boolean;
  eventDate?: string | null;
  isDateToBeConfirmed: boolean;
  isCustom: boolean;
}

export function usePremiumDaysBeforeEventConditions(
  props: PremiumDaysBeforeEventConditionsProps
) {
  const {
    disabled,
    isEventPassed,
    isEventDayTooSoon,
    eventDate,
    isDateToBeConfirmed,
    isCustom,
  } = props;

  const {
    formState: {dirtyFields},
  } = useFormContext<EventFormFields>();

  const isDaysBeforeEventDateDisabled = useMemo(
    () => disabled || isEventPassed || isEventDayTooSoon,
    [disabled, isEventPassed, isEventDayTooSoon]
  );

  const isPremiumStateChanged = useMemo(
    () => dirtyFields.premiumDaysBeforeEvent || dirtyFields.isPremium,
    [dirtyFields.premiumDaysBeforeEvent, dirtyFields.isPremium]
  );

  const isDateOrTimeChanged = useMemo(() => {
    return dirtyFields.eventDate || dirtyFields.eventTime;
  }, [dirtyFields.eventDate, dirtyFields.eventTime]);

  const displayTooSoonError = useMemo(() => {
    if (isCustom && !isPremiumStateChanged && !isDateOrTimeChanged)
      return false;
    return isEventDayTooSoon || isEventPassed;
  }, [
    isCustom,
    isEventDayTooSoon,
    isDateOrTimeChanged,
    isEventPassed,
    isPremiumStateChanged,
  ]);

  const displayDateNotProvidedError = useMemo(
    () => !eventDate || isDateToBeConfirmed,
    [eventDate, isDateToBeConfirmed]
  );

  return {
    isDaysBeforeEventDateDisabled,
    displayTooSoonError,
    displayDateNotProvidedError,
    isPremiumStateChanged,
    isDateOrTimeChanged,
  };
}
