import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Container, Row} from 'reactstrap';
import Button from '../../Common/Button';
import {
  CalendarIcon,
  EventManualIcon,
  ImportedEventIcon,
  TicketIcon,
  UpdateIcon,
} from '../../../constants/images';
import {
  DeleteVenueQueryParam,
  IVenue,
  useDeleteVenueMutation,
} from '../../../services/eventsApi';
import moment from 'moment/moment';
import {useCallback, useEffect, useState} from 'react';
import {EVENTS_VENUES_PATH} from '../../../constants/urls';
import {useHistory} from 'react-router-dom';
import usePermission from '../../../Auth/usePermission';
import {VenuePermissions} from '../../../constants/permissions';
import ConfirmationModal from '../../Common/ConfirmationModal';
import {formatEventsApiErrorMessage} from '../../../utils/functions';

interface VenueHeaderProps {
  venue?: IVenue;
}

const VenueHeader = (props: VenueHeaderProps) => {
  const {venue} = props;
  const intl = useIntl();
  const history = useHistory();
  const {hasPermission} = usePermission();
  const [deleteVenue, {isSuccess}] = useDeleteVenueMutation();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      history.replace(EVENTS_VENUES_PATH);
    }
  }, [isSuccess, history]);

  const onDeleteConfirmed = useCallback(() => {
    if (!venue) return;
    const venueData: DeleteVenueQueryParam = {
      id: venue.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.VENUE_DELETED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    deleteVenue(venueData);
    setIsConfirmationOpen(false);
  }, [venue, deleteVenue, intl]);

  if (!venue) return null;

  const elementClassName = 'd-inline-flex align-items-center gap-2';

  const venueProvider = venue.providers?.[0];

  const updateTitle = venueProvider?.changes?.name;

  const isDeleteDisabled = !hasPermission(VenuePermissions.deleteVenueDetails);

  return (
    <Container fluid className="top-block bg-white">
      <div className="details-header event-details-header pt-1 pt-lg-fan-40">
        <Row className="title-block pb-4 pb-lg-3 gy-2">
          <Col>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start pb-2 pb-lg-fan-15">
              <Typography
                variant="h3"
                className="font-weight-bolder pe-lg-2"
                data-testid="header-venue-name"
              >
                {venue.name}
              </Typography>
            </div>
            <Row
              className="align-items-center justify-content-center justify-content-lg-start gx-3 gy-1 pb-2 pb-lg-2"
              data-testid="header-venue-source"
            >
              {venueProvider && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <ImportedEventIcon style={{width: 15, height: 15}} />
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'dashboard.events.add.sourceImported',
                      })}
                    </Typography>
                  </div>
                </Col>
              )}
              {!venueProvider && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <EventManualIcon style={{width: 15, height: 15}} />
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'dashboard.events.venue_details.manual_input',
                      })}
                    </Typography>
                  </div>
                </Col>
              )}

              {updateTitle && (
                <Col lg="auto" className="text-center">
                  <div className={elementClassName}>
                    <UpdateIcon style={{width: 15, height: 15}} />
                    <Typography
                      variant="body2"
                      className="text-truncate font-bold-italic text-blue"
                    >
                      {intl.formatMessage({
                        id: 'dashboard.events.list.venue_tab.venue_update',
                      })}
                    </Typography>
                  </div>
                </Col>
              )}

              {!!venueProvider && (
                <Col lg="auto" className="text-center">
                  <div className={elementClassName}>
                    <TicketIcon style={{width: 17, height: 15}} />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="text-center"
                    >
                      <FormattedMessage id="dashboard.events.add.source" /> :{' '}
                      <FormattedMessage id="dashboard.events.list.switcher.tixstock" />{' '}
                      |{' '}
                      <FormattedMessage id="dashboard.events.add.external_id" />
                      :{venueProvider.id}
                    </Typography>
                  </div>
                </Col>
              )}

              <Col lg="auto" className="text-center">
                <div className={elementClassName}>
                  <CalendarIcon
                    style={{width: 15, height: 15, color: '#989898'}}
                  />
                  {venueProvider && venueProvider.importDate ? (
                    <Typography variant="body2" color="textSecondary">
                      {intl.formatMessage({
                        id: 'dashboard.events.add.imported',
                      })}{' '}
                      {moment(venueProvider.importDate).format(
                        'DD/MM/YYYY | h:mm A'
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      data-testid="header-venue-creation-date"
                    >
                      {intl.formatMessage({id: 'dashboard.events.add.created'})}
                      {' ' +
                        moment(venue.createdAt).format('DD/MM/YYYY | h:mm A')}
                    </Typography>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="text-center">
            {!isDeleteDisabled && (
              <Button
                link
                title={intl.formatMessage({id: 'actions.delete'})}
                className="w-min-unset normal-font"
                onClick={() => {
                  setIsConfirmationOpen(true);
                }}
                data-testid="header-venue-delete-btn"
              />
            )}
          </Col>
        </Row>
        <ConfirmationModal
          opened={isConfirmationOpen}
          onClose={() => {
            setIsConfirmationOpen(false);
          }}
          onAccept={onDeleteConfirmed}
          description={intl.formatMessage({
            id: 'dashboard.confirm_continue_process',
          })}
        />
      </div>
    </Container>
  );
};

export default VenueHeader;
