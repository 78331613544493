import {TeamPermission} from './teamPermission';
import {
  MARKETPLACE_FEE_SETTINGS,
  PERMISSION_SETTINGS,
  SALES_DETAILS_PATH,
  SALES_LIST_PATH,
  SETTINGS_PATH,
  TEAM_MEMBERS_LIST,
  TRANSACTIONS_PATH,
  USERS_LIST_TAB_PATH,
} from './urls';

export const SALES_LIST_PERMISSIONS = ['admin_api_sales_list'];
export const PERMISSION_PERMISSIONS = [
  'admin_roles_index',
  TeamPermission.Index,
];
export const TEAM_MEMBERS_PERMISSIONS: TeamPermission[] = [
  TeamPermission.Index,
];
export const MARKETPLACE_FEE_PERMISSIONS = [];
export const TRANSACTIONS_LIST_PERMISSIONS = ['admin_transactions_list'];

export const SETTINGS_PERMISSIONS = [
  ...PERMISSION_PERMISSIONS,
  ...TEAM_MEMBERS_PERMISSIONS,
  ...MARKETPLACE_FEE_PERMISSIONS,
];
export const SALES_DETAILS_PERMISSIONS = ['admin_api_sales_details'];
export const BREAKDOWN_FEES_PERMISSIONS = ['admin_breakdown_fees'];
export const BUYER_IP_PERMISSIONS = ['admin_buyer_ip'];
export const ETICKET_MTICKET_FILES_PERMISSIONS = [
  'admin_eticket_mticket_files',
];
export const SALES_SUMMARY_PERMISSIONS = ['admin_sales_summary'];
export const LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS = [
  'admin_listing_user_details_hyperlinks',
];
export const USERS_LIST_PERMISSIONS = ['admin_filter_list_users'];
export const ADMIN_TRANSACTION_DETAILS_HYPERLINKS = [
  'admin_transaction_details_hyperlinks',
];
export const ADMIN_TRANSACTION_RESUME_PAUSE_PAYOUT = [
  'admin_resume_pause_payout',
];

export const ADMIN_CANCEL_SALES = ['admin_cancel_sales'];
export const ADMIN_SET_AS_CANCELLED_BOTH = ['admin_set_as_cancelled_both'];
export const ADMIN_REFUND_SALES = ['admin_refund_sales'];
export const ADMIN_SET_AS_DELIVERED = ['admin_set_as_delivered'];
export const ADMIN_PAUSE_RESUME_PAYOUT = ['admin_pause_resume_payout'];
export const ADMIN_ADD_PENALTY = ['admin_add_penalty'];
export const ADMIN_CREATE_PAYMENT = ['admin_create_payment'];
export const ADMIN_USER_ADDRESS_LIST = [
  'admin_user_address_list',
  'admin_add_edit_address',
];
export const ADMIN_EDIT_USER = ['admin_edit_user'];

// Here we define permissions associated with any url used
export const urlPermissions = [
  {
    path: SALES_LIST_PATH,
    permissions: SALES_LIST_PERMISSIONS,
  },
  {
    path: SALES_DETAILS_PATH,
    permissions: SALES_DETAILS_PERMISSIONS,
  },
  {
    path: SETTINGS_PATH,
    permissions: SETTINGS_PERMISSIONS,
  },
  {
    path: PERMISSION_SETTINGS,
    permissions: PERMISSION_PERMISSIONS,
  },
  {
    path: TEAM_MEMBERS_LIST,
    permissions: TEAM_MEMBERS_PERMISSIONS,
  },
  {
    path: MARKETPLACE_FEE_SETTINGS,
    permissions: MARKETPLACE_FEE_PERMISSIONS,
  },
  {
    path: TRANSACTIONS_PATH,
    permissions: TRANSACTIONS_LIST_PERMISSIONS,
  },
  {
    path: USERS_LIST_TAB_PATH,
    permissions: ADMIN_USER_ADDRESS_LIST,
  },
];

export const CategoryPermissions = {
  seeCategoryList: 'see_category_list',
  addCategory: 'add_category',
  seeCategoryDetails: 'see_category_details',
  editCategoryDetails: 'edit_category_details',
  updateDomainSetting: 'update_domain_setting_category',
  deleteCategoryDetails: 'delete_category_details',
  seeTixstockCategories: 'see_tixstock_categories',
  syncTixstockCategories: '(un)sync_tixstock_categories',
};

export const VenuePermissions = {
  seeVenueList: 'see_venue_list',
  addVenue: 'add_venue',
  seeVenueDetails: 'see_venue_details',
  editVenueDetails: 'edit_venue_details',
  deleteVenueDetails: 'delete_venue_details',
};

export const PerformerPermissions = {
  seePerformerList: 'see_performer_list',
  addPerformer: 'add_performer',
  seePerformerDetails: 'see_performer_details',
  editPerformerDetails: 'edit_performer_details',
  deletePerformerDetails: 'delete_performer_details',
};

export const SeatmapPermissions = {
  addSeatmap: 'add_seat_map',
  seeSeatmapList: 'see_seat_map_list',
  seeSeatmapDetails: 'see_seat_map_details',
  editSeatmapDetails: 'edit_seat_map_details',
  deleteSeatmapDetails: 'delete_seat_map_details',
};

export const EventPermissions = {
  addEvent: 'add_event',
  seeEventList: 'see_event_list',
  performEventBulkActions: 'perform_event_bulk_actions',
  seeEventDetails: 'see_event_details',
  updateDomainSetting: 'update_domain_setting_event',
  editEventDetails: 'edit_event_details',
  seeTixstockEventList: 'see_tixstock_event_list',
  seeTixstockEventDetails: 'see_tixstock_event_details',
  importTixstockEvents: 'import_tixstock_events',
  deleteEventDetails: 'delete_event_details',
  updateOrDeclineTixstockUpdates: 'update_or_decline_tixstock_updates',
};

export const EVENT_TAB_PERMISSIONS = [
  'see_event_list',
  'see_seat_map_list',
  'see_performer_list',
  'see_venue_list',
  'see_category_list',
  'see_tixstock_categories',
];
