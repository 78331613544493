import * as yup from 'yup';
import {ObjectSchema} from 'yup';
import {IntlShape} from 'react-intl';
import {EventFormFields} from './types';

export type EventFormSchema = ObjectSchema<EventFormFields>;

export function getEventValidationSchema(intl: IntlShape): EventFormSchema {
  const getRequiredFieldMessage = (fieldId: string) =>
    intl.formatMessage(
      {id: 'validation.error.required_field'},
      {fieldName: intl.formatMessage({id: fieldId})}
    );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return yup.object().shape({
    title: yup
      .string()
      .required(getRequiredFieldMessage('dashboard.events.event_title')),
    categoryId: yup
      .number()
      .required(getRequiredFieldMessage('dashboard.events.details.category')),
    eventDate: yup
      .string()
      .optional()
      .nullable()
      .when('isDateConfirmed', {
        is: (isDateConfirmed: boolean) => !isDateConfirmed,
        then: s =>
          s.required(
            getRequiredFieldMessage('dashboard.events.details.event_date')
          ),
        otherwise: s => s,
      }),
    eventTime: yup
      .string()
      .optional()
      .nullable()
      .when('isTimeConfirmed', {
        is: (isTimeConfirmed: boolean) => !isTimeConfirmed,
        then: s =>
          s.required(
            getRequiredFieldMessage('dashboard.events.details.event_time')
          ),
        otherwise: s => s,
      }),
    isDateConfirmed: yup.boolean(),
    isTimeConfirmed: yup.boolean(),
    domainIds: yup
      .array()
      .of(yup.number().required())
      .test(
        'categorySettingsOrDomainIds',
        intl.formatMessage({id: 'validation.error.required'}),
        // @ts-ignore
        (domainIds, context) => {
          const {useCategoryDomainSettings} = context.parent as EventFormFields;
          return useCategoryDomainSettings || domainIds?.length;
        }
      ),
    venue: yup
      .object()
      .shape({
        id: yup
          .number()
          .required(
            getRequiredFieldMessage('dashboard.events.venue_details.venue_name')
          ),
      })
      .required(
        getRequiredFieldMessage('dashboard.events.venue_details.venue_name')
      ),
    seatmapId: yup
      .number()
      .min(1, getRequiredFieldMessage('dashboard.events.details.seatmap'))
      .required(getRequiredFieldMessage('dashboard.events.details.seatmap')),
    // imageUrl: yup.string().optional(), //TODO
    performers: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            getRequiredFieldMessage('dashboard.events.details.performers')
          ),
      })
    ),
    isLive: yup.boolean(),
    isPremium: yup.boolean(),
    premiumDaysBeforeEvent: yup.number().when('isCustom', {
      is: (isCustom: boolean) => isCustom,
      then: schema =>
        schema
          .min(1, getRequiredFieldMessage('dashboard.events.days_before_date'))
          .required(
            getRequiredFieldMessage('dashboard.events.days_before_date')
          ),
      otherwise: s => s,
    }),
  });
}
