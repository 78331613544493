import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ISeatmapParsedResponse,
  ISeatmapParsedSection,
  ParseSeatmapQueryParam,
  useParseSeatmapSvgMutation,
} from '../../../../services/eventsApi';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import {useIntl} from 'react-intl';
import {DropzoneOptions, FileRejection, useDropzone} from 'react-dropzone';
import {SeatmapFormFields} from '../types';
import {UseFormSetValue, UseFormTrigger, UseFormWatch} from 'react-hook-form';
import {DEFAULT_SEATMAP_SECTION_COLOR} from '../constants';
import {slugify} from '../helpers';
import useSnackBar from '../../../../hooks/app/useSnackBar';
import {unionBy} from 'lodash';
import {
  useGetFormSvgSyncProps,
  useSvgFormActions,
} from '../../../../store/slices/eventsManagement';
import {showRejectedFilesMessages} from './rejectedDroppedFiles.helper';

interface UseSeatmapImageProps {
  setValue: UseFormSetValue<SeatmapFormFields>;
  getValues: UseFormWatch<SeatmapFormFields>;
  trigger: UseFormTrigger<SeatmapFormFields>;
  countAllSectionChanges?: () => void;
  onImageChange?: () => void;
}

export default function useSeatmapImage(props: UseSeatmapImageProps) {
  const {setValue, countAllSectionChanges, trigger, getValues, onImageChange} =
    props;
  const intl = useIntl();
  const {showMessage} = useSnackBar();

  const [isLoading, setIsLoading] = useState(false);

  const getFormSvgSyncProps = useGetFormSvgSyncProps();
  const {
    removeSvgMissingBlock,
    removeSvgMissingBlocks,
    removeSvgMissingSectionById,
    clearFormMissingSections,
    clearFormMissingBlocks,
    clearSvgMissingSections,
    clearSvgMissingBlocks,
    clearFormSvgSyncProps,
    onSvgParsed,
  } = useSvgFormActions();

  const [
    parseSeatmapSvg,
    {isError, reset: resetParsingRequest, isLoading: isParsing},
  ] = useParseSeatmapSvgMutation();

  const updateSeatmapFile = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = e => {
        setValue('seatmapFile', {
          file,
          url: e.target?.result as string,
        });
      };
      reader.readAsDataURL(file);
    },
    [setValue]
  );

  const updateMissingSections = useCallback(
    (parsedSections: ISeatmapParsedSection[]): void => {
      const formSections = getValues('sections');
      onSvgParsed(parsedSections, formSections);
    },
    [getValues, onSvgParsed]
  );

  const callParseSeatmapSvg = useCallback(
    async (formData: FormData) => {
      const payload: ParseSeatmapQueryParam = {
        body: formData,
        showProgressDialog: false,
        formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      };
      const result = (await parseSeatmapSvg(payload)) as {
        data: ISeatmapParsedResponse;
      };
      if (result.data.sections) {
        setValue('isSVGWithSeatmapFormat', true);
        updateMissingSections(result.data.sections);
      }
    },
    [intl, parseSeatmapSvg, setValue, updateMissingSections]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      showRejectedFilesMessages(rejectedFiles, intl, showMessage);

      if (acceptedFiles.length < 1) {
        return;
      }

      setIsLoading(true);
      setTimeout(() => {
        const file = acceptedFiles[0];
        const fileExtension = file.name.split('.').pop();

        if (fileExtension !== 'svg') {
          setIsLoading(false);
          setValue('isSVGWithSeatmapFormat', false);
          updateSeatmapFile(file);
          onImageChange?.();
          return;
        }

        const formData = new FormData();
        formData.append('file', file);
        callParseSeatmapSvg(formData).catch(() => {
          setIsLoading(false);
        });
        updateSeatmapFile(file);
        onImageChange?.();
        setIsLoading(false);
      });
    },
    [
      callParseSeatmapSvg,
      updateSeatmapFile,
      onImageChange,
      showMessage,
      intl,
      setValue,
    ]
  );

  const useDropzoneParams = useMemo(
    (): DropzoneOptions => ({
      accept: ['.svg', '.png', '.jpg', '.jpeg'],
      maxFiles: 1,
      maxSize: 20 * 1024 * 1024,
      multiple: false,
      onDrop,
    }),
    [onDrop]
  );

  const {getRootProps, getInputProps} = useDropzone(useDropzoneParams);

  const createFormMissingSections = useCallback(() => {
    const formSections = getValues('sections');
    const {formMissingBlocks, formMissingSections} = getFormSvgSyncProps();
    setValue('sections', [
      ...formSections,
      ...formMissingSections.map(section => ({
        ...section,
        color: DEFAULT_SEATMAP_SECTION_COLOR,
        isGeneralAdmission: !section.blocks.length,
      })),
    ]);

    Object.entries(formMissingBlocks).forEach(([sectionSlug, blocks]) => {
      const sectionIndex = formSections.findIndex(
        section => section.slug === sectionSlug
      );

      if (sectionIndex < 0) return;
      const section = formSections[sectionIndex];

      const newSectionBlocks = section.blocks
        ? unionBy(section.blocks, blocks, 'name').map(slugify)
        : blocks.map(slugify);

      setValue(`sections.${sectionIndex}.isGeneralAdmission`, false);
      setValue(`sections.${sectionIndex}.blocks`, newSectionBlocks);
    });

    void trigger('sections');
    clearFormMissingSections();
    clearFormMissingBlocks();
    countAllSectionChanges?.();
  }, [
    getValues,
    getFormSvgSyncProps,
    setValue,
    trigger,
    clearFormMissingSections,
    clearFormMissingBlocks,
    countAllSectionChanges,
  ]);

  const removeSvgUnrelatedSections = useCallback(() => {
    const formSections = getValues('sections');
    const {svgMissingSections, svgMissingBlocks} = getFormSvgSyncProps();

    const svgMissingSectionsMap = new Map(
      svgMissingSections.map(section => [section.slug, section])
    );

    const newFormSections = formSections
      .filter(
        formSection => !svgMissingSectionsMap.has(slugify(formSection).slug)
      )
      .map(formSection => {
        const formSectionSlug = slugify(formSection).slug;
        const svgMissingSectionBlocks = svgMissingBlocks[formSectionSlug];

        if (!svgMissingSectionBlocks) {
          return formSection;
        }

        const newBlocks = formSection.blocks?.filter(
          block =>
            !svgMissingSectionBlocks.some(
              svgBlock => slugify(svgBlock).slug === slugify(block).slug
            )
        );

        return {
          ...formSection,
          blocks: newBlocks,
        };
      });

    setValue('sections', newFormSections);
    void trigger('sections');
    clearSvgMissingSections();
    clearSvgMissingBlocks();
    countAllSectionChanges?.();
  }, [
    trigger,
    clearSvgMissingSections,
    clearSvgMissingBlocks,
    countAllSectionChanges,
    getFormSvgSyncProps,
    getValues,
    setValue,
  ]);

  const reset = useCallback(() => {
    resetParsingRequest();
    clearFormSvgSyncProps();
  }, [resetParsingRequest, clearFormSvgSyncProps]);

  const deleteSvgMissingBlock = useCallback(
    (sectionSlug: string, blockSlug: string) => {
      removeSvgMissingBlock(sectionSlug, blockSlug);
    },
    [removeSvgMissingBlock]
  );

  const deleteSvgMissingSection = useCallback(
    (sectionId: string, slug?: string) => {
      removeSvgMissingSectionById(sectionId);
      if (slug) {
        removeSvgMissingBlocks(slug);
      }
    },
    [removeSvgMissingBlocks, removeSvgMissingSectionById]
  );

  useEffect(() => {
    if (isError) {
      setValue('seatmapFile', null);
      setValue('isSVGWithSeatmapFormat', false);
    }
  }, [isError, setValue]);

  return {
    deleteSvgMissingBlock,
    deleteSvgMissingSection,
    getRootProps,
    getInputProps,
    createFormMissingSections,
    removeSvgUnrelatedSections,
    reset,
    isLoading: isParsing || isLoading,
  };
}
