import {makeStyles} from '@material-ui/core';

/* Data Table styles */
const useDataTableStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    '& .MuiTableContainer-root': {
      // overflow: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingTop: 2,
    },

    '&.product-list': {
      '& .pagination-row': {
        marginTop: 34,
      },
    },
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    background: 'none',
  },
  emptyWrapper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  table: {
    borderCollapse: 'unset',
    maxWidth: '100%',
  },
  tableRow: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  progressBarWrapper: {
    borderBottom: 'none',
    '& .MuiLinearProgress-root': {
      marginTop: -3,
      marginLeft: -5,
      marginRight: -5,
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiLinearProgress-root': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  collapsableTableCell: {
    borderBottom: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      paddingTop: '0 !important',
    },
  },
  headCell: {
    fontFamily: 'Averta Semibold',
    fontSize: 14,
    borderBottom: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    padding: 15,
    lineHeight: 1.2,
    [theme.breakpoints.down('lg')]: {
      width: '1%',
    },
    '&.MuiTableCell-paddingCheckbox': {
      paddingLeft: 5,
      [theme.breakpoints.down('md')]: {
        width: '1%',
      },
    },
    '&.MuiTableCell-paddingNone': {
      paddingLeft: '0 !important',
      [theme.breakpoints.down('md')]: {
        width: 'inherit',
      },
    },
    '&.action-wrapper': {
      paddingRight: 0,
      width: 50,
      [theme.breakpoints.down('md')]: {
        width: '1%',
      },
    },
    '& .MuiTableSortLabel-icon': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: '15px !important',
      marginLeft: '8px !important',
      marginRight: '8px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: 'normal',
      height: 56,
      '& > .MuiTableSortLabel-root': {
        '&.color-disabled': {
          color: theme.palette.text.disabled,
        },
      },
    },
  },
  wrappedRow: {
    '&.additional': {
      '& td': {
        border: 'none',
        position: 'relative',
        padding: 0,
        '&:before': {
          content: "''",
          pointerEvents: 'none',
          position: 'absolute',
          left: 20,
          top: -91,
          bottom: 15,
          right: -33,
          backgroundColor: theme.palette.background.additional,
          borderRadius: 8,
        },
      },
    },
  },
  tableCell: {
    borderCollapse: 'unset',
    borderColor: theme.palette.background.default,
    borderWidth: 0,
    backgroundColor: theme.palette.background.paper,
    verticalAlign: 'top',
    padding: 15,
    '&.MuiTableCell-paddingCheckbox': {
      paddingLeft: 5,
      [theme.breakpoints.down('md')]: {
        width: '1%',
      },
    },
    '&.with-wrapped-row': {
      '&.expanded': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:not(.expanded)': {},
    },
    '&.wrapped-row-cell': {
      paddingTop: 5,
      paddingBottom: 5,
      position: 'relative',
      '&:first-child': {
        paddingLeft: 30,
        borderLeft: '20px solid #fff',
      },
      '&:last-child': {
        borderRight: '20px solid #fff',
      },
      '&.headers': {
        borderRadius: 0,
        paddingBottom: 0,
        paddingTop: 15,
      },
      '&.cells': {
        borderRadius: 0,
        paddingBottom: 15,
        borderBottom: '15px solid #fff',
      },
    },
    '&.bulk-actions': {
      paddingTop: 21,
      paddingBottom: 21,

      '&:last-child': {
        paddingRight: 15,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      [theme.breakpoints.down('lg')]: {
        // paddingBottom: 12,
        '&:first-child': {
          paddingLeft: 16,
        },
      },
    },
    '&:first-child': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:nth-child(2)': {
      cursor: 'pointer',
    },
    '&:nth-last-child(2)': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      paddingRight: 20,
    },
    '&.MuiTableCell-paddingNone': {
      paddingLeft: '0 !important',
    },
    [theme.breakpoints.down('lg')]: {
      '&:nth-last-child(2)': {
        paddingRight: 6,
      },
    },
    [theme.breakpoints.down('md')]: {
      borderWidth: 0,
      '&:first-child': {
        borderRadius: 0,
      },
      '&:nth-last-child(2)': {
        borderRadius: 0,
        paddingRight: 0,
        width: '1%',
      },
      '&:last-child': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        paddingRight: '10px !important',
      },
      '&.bold': {
        '& > p': {
          fontWeight: 'bold',
        },
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tablePagination: {
    marginTop: 32,
    marginBottom: 5,
  },
  collapseWrapper: {
    padding: '0 20px 16px 0',
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: theme.shadows[2],
    '&.bulk': {
      paddingLeft: 16,
      paddingBottom: 24,
    },
  },
  collapsableRow: {
    display: 'flex',
    paddingLeft: 45,
  },
  collapsableRowItem: {
    flexBasis: '50%',
    overflowX: 'hidden',
    fontSize: 11,
    '& h6': {
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 11,
      paddingTop: 0,
    },
  },
  expandIcon: {
    color: theme.palette.text.primary,
    fontSize: 23,
  },
  tableContainer: {
    position: 'relative',
  },
  tableLoadingWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useDataTableStyles;
