import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {SearchForm} from '../../../Form/NonReduxSearchForm';
import {
  PlusRoundedDangerIcon,
  RefreshSearchResultsIcon,
} from '../../../../constants/images';
import * as urls from '../../../../constants/urls';
import DataTable from '../../../Widgets/DataTable';
import {useHistory} from 'react-router-dom';
import {useEventsBulkActions} from './useEventsBulkActions';
import {useSearch} from '../../../../hooks/common/useSearch';
import {EventsFilter} from './EventsFilter';
import {
  EventFilterValues,
  IAPIListOptions,
  IEvent,
  IPaginatedResponse,
  useLazyEventListQuery,
} from '../../../../services/eventsApi';
import {ArrayElementType} from '../../../../utils/types';
import {useHeaderCells} from './ListCells';
import usePermission from '../../../../Auth/usePermission';
import {EventPermissions} from '../../../../constants/permissions';
import {IconButton} from '@mui/material';
import ActiveFilters, {Item} from '../../../Widgets/ActiveFilters';
import {KEYWORD_FIELD} from '../../../Form/KeywordSearchForm';
import {getActiveFilters, getFilterPayloadKey} from './helpers';
import {DataTableSortKeysOverrides} from '../constants';
import PermissionContainer from '../../../Common/PermissionContainer';
import useTableSort from '../../hooks/useTableSort';
import {getPaginationURLParams} from '../../../../utils/filters';
import {useDispatch} from 'react-redux';
import {setTableSelectionState} from '../../../../store/slices/tableSelectionSlice';

interface EventListProps {
  onAddClick: () => void;
  scrollToTop: () => void;
}

export type EventsListOptions = IAPIListOptions & EventFilterValues;

export const EventList = (props: EventListProps) => {
  const {onAddClick, scrollToTop} = props;

  const dispatch = useDispatch();

  const intl = useIntl();
  // const [activeTab, setActiveTab] = useState('database');
  const {searchText, setSearchText} = useSearch();
  const [loader, setLoader] = useState(false);
  const [listOptions, setListOptions] = useState<EventsListOptions>(
    getPaginationURLParams()
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [paginatedEvents, setPaginatedEvents] =
    useState<IPaginatedResponse<IEvent> | null>();
  const [activeFilters, setActiveFilters] = useState<Item[]>(
    getActiveFilters({}, intl)
  );
  const history = useHistory();
  const listCells = useHeaderCells();
  const {hasPermission} = usePermission();
  const {handleSortChange} = useTableSort();

  useEffect(() => {
    scrollToTop();
  }, [paginatedEvents?.meta.page, scrollToTop]);

  const [
    getEventsList,
    {data: eventListData, isSuccess, isLoading, isFetching},
  ] = useLazyEventListQuery();

  const {
    bulkActions,
    selectedItems,
    addSelectedItemsIds,
    onSelectAllListItems,
    setSelectedItemsAll,
    onClearSelectedItems,
    successCount,
    ignoredEvents,
    isBulkActionSuccess,
  } = useEventsBulkActions({
    listCount: paginatedEvents?.meta.itemCount ?? 0,
    options: listOptions,
  });

  useEffect(() => {
    if (isBulkActionSuccess) {
      getEventsList(listOptions);
    }
  }, [getEventsList, isBulkActionSuccess, listOptions]);

  // const toggleActiveTab = (tab: string) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const onDataTablePaginationChange = useCallback(
    (pagination: {page: number; rowsPerPage: number}) => {
      const search = new URLSearchParams(
        history.location.search.replace('?', '')
      );
      if (
        search.get('page') === pagination.page.toString() &&
        search.get('rowsPerPage') === pagination.rowsPerPage.toString()
      ) {
        return;
      }
      const oldPage = search.get('page');
      setLoader(true);
      search.set('page', pagination.page.toString());
      search.set('rowsPerPage', pagination.rowsPerPage.toString());
      if (oldPage) {
        history.push({
          search: search.toString(),
        });
      } else {
        history.replace({
          search: search.toString(),
        });
      }
      setTimeout(() => {
        setLoader(false);
      }, 500);
    },
    [history]
  );

  const onFilterApply = useCallback(
    (values: EventFilterValues, filters: Item[]) => {
      setListOptions(prevState => ({
        page: 1,
        perPage: prevState.perPage,
        rowsPerPage: prevState.rowsPerPage,
        sort: prevState.sort,
        direction: prevState.direction,
        q: prevState.q,
        ...values,
      }));
      onClearSelectedItems();
      setActiveFilters(filters);
    },
    [onClearSelectedItems]
  );

  const onFilterClear = useCallback(() => {
    setLoader(true);
    setListOptions(prevState => {
      return {
        page: 1,
        perPage: prevState.perPage,
        rowsPerPage: prevState.rowsPerPage,
        sort: prevState.sort,
        direction: prevState.direction,
        q: prevState.q,
      };
    });
    onClearSelectedItems();
    setActiveFilters([]);
    setTimeout(() => {
      setLoader(false);
    }, 200);
  }, [onClearSelectedItems]);

  const handleRowClick = (itemSlug: string) => {
    if (!hasPermission(EventPermissions.seeEventDetails)) return;
    history.push(urls.EVENT_DETAILS_PATH.replace(':slug', itemSlug));
  };

  const onRefresh = async () => {
    setIsRefreshing(true);
    await getEventsList(listOptions);
    setIsRefreshing(false);
  };

  const onRemoveSingleFilter = useCallback(
    (filterKey: string) => {
      const filterPayloadKeys = getFilterPayloadKey(filterKey) as Array<
        keyof EventsListOptions | 'Fanpass' | 'Tixstock' | 'Upcoming' | 'Passed'
      > | null;

      if (!filterPayloadKeys) return;
      const listOptionsCopy = {...listOptions};
      filterPayloadKeys.forEach(key => {
        if (key === 'Fanpass') {
          listOptionsCopy.source = listOptionsCopy.source?.filter(
            item => item !== 'FANPASS'
          );
        } else if (key === 'Tixstock') {
          listOptionsCopy.source = listOptionsCopy.source?.filter(
            item => item !== 'TIXSTOCK'
          );
        } else if (key === 'Upcoming') {
          listOptionsCopy.status = listOptionsCopy.status?.filter(
            item => item !== 'UPCOMING'
          );
        } else if (key === 'Passed') {
          listOptionsCopy.status = listOptionsCopy.status?.filter(
            item => item !== 'PASSED'
          );
        } else {
          delete listOptionsCopy[key];
        }
      });

      setLoader(true);
      setListOptions(listOptionsCopy);
      onClearSelectedItems();
      setActiveFilters(activeFilters.filter(item => item.id !== filterKey));
      setTimeout(() => {
        setLoader(false);
      }, 200);
    },
    [activeFilters, listOptions, onClearSelectedItems]
  );

  useEffect(() => {
    if (isSuccess && eventListData) {
      setPaginatedEvents(eventListData);
    }
  }, [eventListData, isSuccess]);

  useEffect(() => {
    getEventsList(listOptions);
  }, [listOptions, getEventsList]);

  const liveString = intl.formatMessage({id: 'dashboard.status.live'});
  const offString = intl.formatMessage({id: 'dashboard.status.off'});

  const events = paginatedEvents?.data || [];
  const eventRows = events.map(
    ({performers, seatmap, venue, category, ...event}) => {
      return {
        id: event.id,
        slug: event.slug,
        title: {
          ...event,
          seatmap,
        },
        venue,
        category,
        date: {
          value: event?.date,
          dateUpdates: event.provider?.changes?.date,
          timeValue: event?.time,
          isDateToBeConfirmed: !event.isDateConfirmed,
          isTimeToBeConfirmed: !event.isTimeConfirmed,
        },
        status: {
          status: event.isLive ? 'active' : 'inactive',
          label: event.isLive ? liveString : offString,
        },
      };
    }
  );

  const isSelectOff = !hasPermission(EventPermissions.performEventBulkActions);

  return (
    <div className="dashboard-content">
      <PermissionContainer
        permissions={[EventPermissions.seeEventList as never]}
        url={undefined}
      >
        <div className="row gy-3 gx-2 gx-lg-3 align-items-center justify-content-between search-row pb-1">
          <div className="col-12 col-lg-auto me-auto">
            {/* The following Nav is commented till we implement Tixstock integration */}
            {/*<Nav pills fill className="d-flex mb-0">*/}
            {/*  <NavItem>*/}
            {/*    <NavLink*/}
            {/*      active={activeTab === 'database'}*/}
            {/*      onClick={() => toggleActiveTab('database')}*/}
            {/*    >*/}
            {/*      <FormattedMessage id="dashboard.events.list.switcher.database" />*/}
            {/*    </NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink*/}
            {/*      active={activeTab === 'tixstock'}*/}
            {/*      onClick={() => toggleActiveTab('tixstock')}*/}
            {/*    >*/}
            {/*      <FormattedMessage id="dashboard.events.list.switcher.tixstock" />*/}
            {/*    </NavLink>*/}
            {/*  </NavItem>*/}
            {/*</Nav>*/}
          </div>
          <div className="col col-lg-4">
            <SearchForm
              loading={false}
              search={searchText}
              onChange={(value: string) => {
                setSearchText(value);
              }}
              onSubmit={() => {
                setListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: searchText,
                }));
              }}
              onClear={() => {
                setListOptions(prevState => ({
                  ...prevState,
                  page: 1,
                  q: '',
                }));
              }}
              SearchProps={{
                buttonProps: {
                  onClick: () => {},
                },
              }}
            />
          </div>
          <div className="col-auto">
            <IconButton
              variant="filled"
              // @ts-ignore
              size="xs"
              onClick={onRefresh}
              style={{
                transform: isRefreshing ? 'rotate(360deg)' : 'none',
                transition: 'transform 0.5s',
              }}
            >
              <RefreshSearchResultsIcon style={{width: 18, height: 14}} />
            </IconButton>
          </div>
          <div className="col-auto">
            <EventsFilter onApply={onFilterApply} onClear={onFilterClear} />
          </div>
        </div>
        <div className="row">
          <ActiveFilters
            items={activeFilters}
            onFilterRemoved={onRemoveSingleFilter}
            clearAdditionalParams={{[KEYWORD_FIELD]: '', page: '1'}}
            onClearAll={onFilterClear}
          />
        </div>
        <div className="row">
          <div className="col">
            <DataTable
              className="events-table"
              canSelect={!isSelectOff}
              loading={isLoading || isRefreshing || loader || isFetching}
              empty={eventRows.length === 0}
              clickableRow={hasPermission(EventPermissions.seeEventDetails)}
              onCustomRowClick={({slug}: ArrayElementType<typeof eventRows>) =>
                handleRowClick(slug)
              }
              headCells={listCells}
              actionButton={{
                variant: 'filled',
                size: 'sm',
                children: <PlusRoundedDangerIcon />,
                onClick: onAddClick,
              }}
              rows={eventRows}
              onChange={(payload: any) => {
                const sortBy =
                  payload.sort.by in DataTableSortKeysOverrides
                    ? DataTableSortKeysOverrides[
                        payload.sort.by as 'event' | 'status'
                      ]
                    : payload.sort.by;
                setListOptions(prevState => ({
                  ...prevState,
                  ...(payload.page && {page: payload.page}),
                  ...(payload.rowsPerPage && {perPage: payload.rowsPerPage}),
                  ...(sortBy && {sort: sortBy}),
                  ...(payload.sort.direction && {
                    direction: payload.sort.direction,
                  }),
                }));
              }}
              total={paginatedEvents?.meta.itemCount ?? 0}
              currentPage={Number(listOptions?.page)}
              defaultRowPerPage={listOptions.rowsPerPage}
              bulkActions
              selectedItems={selectedItems}
              selectedCount={selectedItems.length}
              onSelect={(row: ArrayElementType<typeof eventRows>) => {
                dispatch(setTableSelectionState('selected'));
                addSelectedItemsIds(row.id);
              }}
              onSelectAll={(rows: typeof eventRows) => {
                dispatch(setTableSelectionState('selected'));
                setSelectedItemsAll(rows.map(row => row.id));
              }}
              onClearSelect={onClearSelectedItems}
              onSelectAllList={onSelectAllListItems}
              bulkProps={{
                actionList: bulkActions,
                errorDetails: ignoredEvents,
                successCount,
                ignoredCount: ignoredEvents.length,
                entitiesName: 'events',
              }}
              onPaginationChange={onDataTablePaginationChange}
              handleParameterChange={handleSortChange}
              defaultOrder={listOptions.direction}
              defaultOrderBy={listOptions.sort}
            />
          </div>
        </div>
      </PermissionContainer>
    </div>
  );
};
