import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {PlusIconDanger} from '../../../../constants/images';
import Button from '../../../Common/Button';
import {FieldErrors, useFieldArray, useFormContext} from 'react-hook-form';
import PerformerItem from './PerformerItem';
import {EmptyPerformer} from '../constants';
import {Box, FormHelperText} from '@material-ui/core';
import {IPerformer} from '../../../../services/eventsApi';
import React, {useEffect} from 'react';
import {EventFormFields} from '../types';
import {useAddEventsErrorElementRef} from '../../../../store/slices/eventsManagement';

interface PerformersBlockProps {
  errors?: FieldErrors<EventFormFields>;
  disabled?: boolean;
}

const PerformersBlock = ({errors, disabled}: PerformersBlockProps) => {
  const intl = useIntl();
  const addErrorElementRef = useAddEventsErrorElementRef();

  const {control, watch, setValue, getValues, clearErrors} = useFormContext();

  const {fields, append} = useFieldArray({
    name: 'performers',
    keyName: 'formId',
    control,
  });
  const categoryId = watch('categoryId');

  useEffect(() => {
    const defaultPerformers = getValues('defaultPerformers');
    const currentPerformers = getValues('performers');
    const hasUpdatedPerformer = currentPerformers.find((p: IPerformer) => p.id);
    if (categoryId > 0 && (!hasUpdatedPerformer || !currentPerformers.length)) {
      if (defaultPerformers === 2) {
        setValue('performers', [EmptyPerformer, EmptyPerformer]);
      } else if (defaultPerformers === 1) {
        setValue('performers', [EmptyPerformer]);
      } else if (defaultPerformers === 0) {
        setValue('performers', []);
      }
    }
  }, [categoryId, setValue, getValues]);

  return (
    <div
      ref={ref => {
        addErrorElementRef('performers', ref);
      }}
    >
      {!fields.length && (
        <>
          <Typography variant="body2" className="mb-1">
            <FormattedMessage id="dashboard.events.details.no_performers" />
          </Typography>
          <Button
            style={{fontWeight: '600'}}
            link
            small
            danger
            icon={<PlusIconDanger />}
            title={intl.formatMessage({
              id: 'dashboard.events.details.new_performer',
            })}
            onClick={() => {
              append(EmptyPerformer);
              clearErrors('performers');
            }}
            disabled={disabled}
          />
        </>
      )}
      {!!fields.length && (
        <>
          <Typography variant="subtitle2" className="text-placeholder mb-2">
            <FormattedMessage id="dashboard.events.details.performers" />
          </Typography>
          <Box
            px={1}
            py={1}
            display="flex"
            flexDirection="column"
            bgcolor="background.default"
            borderRadius={8}
            gridGap={10}
          >
            {fields.map((item, index) => (
              <PerformerItem
                key={item.formId}
                index={index}
                remove={() => {
                  const newPerformers = getValues('performers').concat([]);
                  newPerformers.splice(index, 1);
                  setValue('performers', newPerformers);
                }}
                disabled={disabled}
              />
            ))}
            <Button
              style={{fontWeight: '600'}}
              disabled={disabled}
              link
              small
              danger
              icon={<PlusIconDanger />}
              title={intl.formatMessage({
                id: 'dashboard.events.details.new_performer',
              })}
              onClick={() => {
                append({});
              }}
            />
          </Box>
        </>
      )}
      {errors && (
        <FormHelperText error>{errors.performers?.message} </FormHelperText>
      )}
    </div>
  );
};

export default PerformersBlock;
