import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Typography from '@mui/material/Typography';
import CustomCheckbox from '../../Common/Checkbox';
import {FilterInputTypesEnum, FilterItem} from './types';
import {useEffect, useState} from 'react';
import {useModularFilterUrlValue} from './hooks';
import {
  eventBusEvents,
  useEventBusListener,
} from '../../../store/slices/eventBus';
import {nanoid} from 'nanoid';

export interface CheckboxFilterProps extends FilterItem {
  label: string;
}

export function CheckboxFilter(props: Readonly<CheckboxFilterProps>) {
  const {label, name, onFilterChange, onFilterRemove} = props;
  const [value, setValue] = useState<boolean>();
  const {on, off} = useEventBusListener();
  const urlValue = useModularFilterUrlValue(name);

  useEffect(() => {
    if (urlValue) {
      setValue(urlValue);
    } else {
      onFilterRemove?.(name);
      setValue(undefined);
    }
  }, [urlValue, onFilterRemove, name]);

  useEffect(() => {
    if (typeof value === 'undefined') {
      return;
    }
    if (value) {
      onFilterChange?.({value, type: FilterInputTypesEnum.CHECKBOX, name});
    } else {
      onFilterRemove?.(name);
    }
  }, [onFilterChange, onFilterRemove, value, name]);

  useEffect(() => {
    const callbackId = nanoid(5);
    on(
      eventBusEvents.ModularFilterEvents.CLEAR_FILTER,
      callbackId,
      (clearedFilterNames: string[]) => {
        if (clearedFilterNames.includes(name)) {
          setValue(undefined);
        }
      }
    );
    return () => {
      off(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, callbackId);
    };
  }, [on, off, name]);

  return (
    <FormControlLabel
      className="checkbox-filter-row"
      label={<Typography variant="body2">{label}</Typography>}
      // @ts-ignore
      control={
        <CustomCheckbox
          key={label}
          value={!!value}
          checked={!!value}
          onChange={(event, checked) => {
            setValue(checked);
          }}
        />
      }
    />
  );
}
