import cx from 'clsx';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import Select, {SelectProps} from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem, {MenuItemProps} from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {CustomEditIcon} from '../../../constants/images';
import BaseInput from '../../Common/BaseInput';
import {ReactNode} from 'react';

export type ColorSelectProps = SelectProps & {
  selectClassName?: string;
  ignoreDispatch?: boolean;
  outlined?: boolean;
  externalLabel?: boolean;
};

type ColorValue =
  | '#3c40c6'
  | '#ef5777'
  | '#7efff5'
  | '#eba9c1'
  | '#9893c1'
  | '#ee7d79'
  | '#93cbdb'
  | '#c1e89d'
  | '#b2bec3'
  | '#f5cd7c';

const Colors: {[key: string]: ColorValue} = {
  COLOR_1: '#3c40c6',
  COLOR_2: '#ef5777',
  COLOR_3: '#7efff5',
  COLOR_4: '#eba9c1',
  COLOR_5: '#9893c1',
  COLOR_6: '#ee7d79',
  COLOR_7: '#93cbdb',
  COLOR_8: '#c1e89d',
  COLOR_9: '#f5cd7c',
  COLOR_10: '#b2bec3',
};

/* Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  select: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.border}`,
    borderRadius: 4,

    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      boxShadow: theme.shadows[0],
      padding: theme.spacing(0.65, 1),
      '&.MuiSelect-outlined': {
        backgroundColor: 'inherit',
      },
    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
      top: 10,
      right: 10,
    },
  },
  menu: {
    marginTop: theme.spacing(0.5),
  },
  paper: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0.5, 0),
    maxHeight: 321,
    '& > ul': {
      padding: 0,
    },
  },
  menuItem: {
    padding: theme.spacing(0.5, 1),
    zIndex: 1001,
  },
}));

const ColorSelectMenuItem = (props: MenuItemProps) => {
  const {children, ...rest} = props;
  return (
    <MenuItem {...rest}>
      <ListItemIcon className="w-min-unset me-2">
        <Box
          width={15}
          height={15}
          borderRadius={2}
          style={{backgroundColor: String(children)}}
        />
      </ListItemIcon>
      <Typography variant="body2">{children}</Typography>
    </MenuItem>
  );
};

const ColorSelect = ({
  selectClassName,
  ignoreDispatch = false,
  outlined = false,
  externalLabel = false,
  ...props
}: ColorSelectProps) => {
  const classes = useStyles();

  return (
    <Select
      {...props}
      variant="outlined"
      className={cx(classes.select, selectClassName)}
      input={<BaseInput />}
      IconComponent={() => (
        <CustomEditIcon className="MuiSvgIcon-root MuiSelect-icon" />
      )}
      MenuProps={{
        elevation: 0,
        anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
        getContentAnchorEl: null,
        autoFocus: false,
        className: classes.menu,
        classes: {
          paper: classes.paper,
        },
      }}
      renderValue={(value: unknown) =>
        (
          <Box display="flex" alignItems="center" gridGap={10}>
            <Box
              width={15}
              height={15}
              borderRadius={2}
              style={{backgroundColor: value as string}}
            />
            <Typography variant="body2">{value}</Typography>
          </Box>
        ) as ReactNode
      }
      externalLabel={externalLabel}
      outlined={outlined}
      ignoreDispatch={ignoreDispatch}
    >
      {Object.entries(Colors).map(([colorKey, colorValue]) => (
        <ColorSelectMenuItem
          key={colorKey}
          value={colorValue}
          className={classes.menuItem}
        >
          {colorValue}
        </ColorSelectMenuItem>
      ))}
    </Select>
  );
};

export default ColorSelect;
