import Modal from '../../Widgets/Modal';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {useIntl} from 'react-intl';

interface DeleteConfirmationModalProps {
  opened: boolean;
  onAccept: () => void;
  onClose: () => void;
}

const DeleteConfirmationModal = ({
  opened,
  onAccept,
  onClose,
}: DeleteConfirmationModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      opened={opened}
      width="328px"
      saveTitle={intl.formatMessage({id: 'actions.yes'})}
      cancelTitle={intl.formatMessage({id: 'actions.no'})}
      title={intl.formatMessage({id: 'actions.confirmation_popup.areYouSure'})}
      onOk={onAccept}
      handleClose={onClose}
    >
      <Typography variant="body2">
        {intl.formatMessage({id: 'actions.confirmation_popup.description'})}
      </Typography>
    </Modal>
  );
};

export default DeleteConfirmationModal;
