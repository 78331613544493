import {FormattedMessage, useIntl} from 'react-intl';
import {Prompt, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '../../Common/Button';
import * as urls from '../../../constants/urls';
import {EVENTS_VENUES_PATH, VENUE_DETAILS_PATH} from '../../../constants/urls';
import VenueForm from './VenueForm';
import {useForm} from 'react-hook-form';
import {VenueFormFields} from './types';
import {
  getVenueValidationSchema,
  VenueFormDefaultValues,
} from './helpers/validations';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  AddVenueQueryParam,
  useAddVenueMutation,
} from '../../../services/eventsApi';
import {useEffect, useState} from 'react';
import {useVenueUpdatedFields} from './hooks/useVenueUpdatedFields';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import usePermission from '../../../Auth/usePermission';
import {VenuePermissions} from '../../../constants/permissions';

const AddVenue = () => {
  const history = useHistory();
  const intl = useIntl();
  const [addVenue, {data: addedVenue}] = useAddVenueMutation();

  const {hasPermission} = usePermission();

  const form = useForm<VenueFormFields>({
    defaultValues: VenueFormDefaultValues,
    resolver: yupResolver(getVenueValidationSchema(intl)) as any,
    mode: 'onBlur',
  });

  const formChanges = form.formState.isDirty;

  const onCancelClick = () =>
    history.push(urls.EVENTS_LIST_TAB_PATH.replace(':tab', 'venue'));

  const onSubmit = (data: VenueFormFields) => {
    const city = form.getValues('city');
    if (!city) {
      form.setError('addressLine1', {
        type: 'manual',
        message: intl.formatMessage({
          id: 'validation.error.select_from_suggestions',
        }),
      });
      return;
    }
    const venueData: AddVenueQueryParam = {
      body: {
        ...data,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.VENUE_CREATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    addVenue(venueData);
  };

  useEffect(() => {
    if (addedVenue) {
      hasPermission(VenuePermissions.seeVenueDetails)
        ? history.replace(
            VENUE_DETAILS_PATH.replace(':id', addedVenue.id.toString()),
            {
              venue: addedVenue,
            }
          )
        : history.replace(EVENTS_VENUES_PATH);
    }
  }, [addedVenue, hasPermission, history]);

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid mx-lg-0">
        <div className="row align-items-center header-row pb-4 mb-1">
          <div className="col text-center text-lg-start">
            {/* Header */}
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.events.venue_details.add_title" />
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*Cancel/Add buttons*/}
            <div className="row gx-3 align-items-center">
              <div className="col text-center">
                <Button
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                  onClick={onCancelClick}
                />
              </div>
              <div className="col text-center">
                <Button
                  primary
                  className="w-100"
                  title={intl.formatMessage({id: 'actions.add'})}
                  onClick={form.handleSubmit(onSubmit)}
                  data-testid="add-venue-button"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content">
          <VenueForm form={form} onSubmit={onSubmit} />
        </div>
        {/*Cancel/Add buttons*/}
        <div className="row gx-3 align-items-center d-lg-none mt-4">
          <div className="col text-center">
            <Button
              link
              title={intl.formatMessage({id: 'actions.cancel'})}
              onClick={onCancelClick}
            />
          </div>
          <div className="col text-center">
            <Button
              primary
              className="w-100"
              title={intl.formatMessage({id: 'actions.add'})}
              onClick={console.error}
            />
          </div>
        </div>
      </div>
      <Prompt
        when={formChanges && !addedVenue}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />
    </div>
  );
};

export default AddVenue;
