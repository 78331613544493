import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Container, Row} from 'reactstrap';
import Button from '../../../Common/Button';
import {
  CalendarIcon,
  EventManualIcon,
  ImportedEventIcon,
  TicketIcon,
  UpdateIcon,
} from '../../../../constants/images';
import {
  DeleteVenueQueryParam,
  IPerformer,
  useDeletePerformerMutation,
} from '../../../../services/eventsApi';
import moment from 'moment';
import ConfirmationModal from '../../../Common/ConfirmationModal';
import {useCallback, useEffect, useState} from 'react';
import {formatEventsApiErrorMessage} from '../../../../utils/functions';
import {EVENTS_PERFORMER_PATH} from '../../../../constants/urls';
import {useHistory} from 'react-router-dom';
import {PerformerPermissions} from '../../../../constants/permissions';
import PermissionContainer from '../../../Common/PermissionContainer';
import useSnackBar from '../../../../hooks/app/useSnackBar';

interface PerformerHeaderProps {
  performer?: IPerformer;
}

const elementClassName = 'd-inline-flex align-items-center gap-2';

const PerformerHeader = (props: PerformerHeaderProps) => {
  const {performer} = props;
  const intl = useIntl();
  const history = useHistory();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deletePerformer, {isSuccess}] = useDeletePerformerMutation();
  const {showMessage} = useSnackBar();

  useEffect(() => {
    if (isSuccess) {
      showMessage(
        intl.formatMessage({
          id: 'messages.PERFORMER_DELETED_SUCCESSFULLY',
        }),
        {variant: 'success'}
      );
      history.replace(EVENTS_PERFORMER_PATH);
    }
  }, [isSuccess, history, showMessage, intl]);

  const onDeleteConfirmed = useCallback(() => {
    if (!performer) return;
    const venueData: DeleteVenueQueryParam = {
      id: performer.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatEventsApiErrorMessage(error, intl);
      },
    };
    deletePerformer(venueData);
    setIsConfirmationOpen(false);
  }, [performer, deletePerformer, intl]);

  if (!performer) {
    return null;
  }

  const provider = performer.providers?.at(0);
  const importDate = provider?.importDate
    ? new Date(provider.importDate)
    : null;
  const updatesExist = !!Object.keys(provider?.changes ?? {}).length;

  return (
    <Container fluid className="top-block bg-white">
      <div className="details-header event-details-header pt-1 pt-lg-fan-40">
        <Row className="title-block pb-4 pb-lg-3 gy-2">
          <Col>
            <div className="text-center text-lg-start pb-2 pb-lg-fan-15">
              <Typography
                variant="h3"
                className="font-weight-bolder pe-lg-2"
                data-testid="header-performer-name"
              >
                {performer.name}
              </Typography>
            </div>
            <Row className="align-items-center justify-content-center justify-content-lg-start gx-3 gy-1 pb-2 pb-lg-2">
              <Col xs="auto">
                <div className={elementClassName}>
                  {provider && (
                    <>
                      <ImportedEventIcon style={{width: 15, height: 15}} />
                      <Typography
                        variant="body2"
                        data-testid="header-performer-source"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.events.add.sourceImported',
                        })}
                      </Typography>
                    </>
                  )}
                  {!provider && (
                    <>
                      <EventManualIcon style={{width: 15, height: 15}} />
                      <Typography
                        variant="body2"
                        data-testid="header-performer-source"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.events.add.source_manual',
                        })}
                      </Typography>
                    </>
                  )}
                </div>
              </Col>
              {updatesExist && (
                <Col xs="auto">
                  <div className={elementClassName}>
                    <UpdateIcon
                      style={{width: 11, height: 11, marginBottom: 2}}
                    />
                    <Typography
                      variant="body2"
                      className="text-blue font-bold-italic"
                    >
                      <FormattedMessage id="dashboard.performer.performer_update" />
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="align-items-center justify-content-center justify-content-lg-start gx-3 gy-1">
              {provider && (
                <Col xs="auto" className="text-center">
                  <div className={elementClassName}>
                    <TicketIcon style={{width: 17, height: 15}} />
                    <Typography variant="body2" color="textSecondary">
                      {intl.formatMessage({id: 'dashboard.events.add.source'})}:{' '}
                      {intl.formatMessage({
                        id: 'dashboard.events.list.switcher.tixstock',
                      })}{' '}
                      |{' '}
                      {intl.formatMessage({
                        id: 'dashboard.events.add.external_id',
                      })}
                      : {provider.id}
                    </Typography>
                  </div>
                </Col>
              )}
              <Col lg="auto" className="text-center">
                <div className={elementClassName}>
                  <CalendarIcon
                    style={{width: 15, height: 15, color: '#989898'}}
                  />
                  {importDate && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      data-testid="header-performer-creation-date"
                    >
                      {intl.formatMessage({
                        id: 'dashboard.events.add.imported',
                      })}{' '}
                      {moment(importDate).format('DD/MM/YYYY | h:mm A')}
                    </Typography>
                  )}

                  {!provider && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      data-testid="header-performer-creation-date"
                    >
                      {intl.formatMessage({
                        id: 'dashboard.events.add.created',
                      })}{' '}
                      {moment(performer.createdAt).format(
                        'DD/MM/YYYY | h:mm A'
                      )}
                    </Typography>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="auto" className="text-center">
            <PermissionContainer
              // @ts-ignore
              permissions={[PerformerPermissions.deletePerformerDetails]}
            >
              <Button
                link
                title={intl.formatMessage({id: 'actions.delete'})}
                className="w-min-unset normal-font"
                onClick={() => {
                  setIsConfirmationOpen(true);
                }}
                data-testid="header-performer-delete-btn"
              />
            </PermissionContainer>
          </Col>
        </Row>
        <ConfirmationModal
          opened={isConfirmationOpen}
          onClose={() => {
            setIsConfirmationOpen(false);
          }}
          onAccept={onDeleteConfirmed}
          description={intl.formatMessage({
            id: 'dashboard.confirm_continue_process',
          })}
        />
      </div>
    </Container>
  );
};

export default PerformerHeader;
