/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReactNode} from 'react';
import {
  ToastMessage,
  ToastMessagePayload,
} from '../../components/Common/feedback/ToastMessages';
import {RootState} from '../../../src';

export interface AppState {
  showProgressDialogCount: number;
  messages: ToastMessage[];
  gmapsLoaded: boolean;
}

const initialState: AppState = {
  showProgressDialogCount: 0,
  messages: [],
  gmapsLoaded: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showProgressDialog: state => {
      state.showProgressDialogCount += 1;
    },
    hideProgressDialog: state => {
      state.showProgressDialogCount = Math.max(
        0,
        state.showProgressDialogCount - 1
      );
    },
    enqueueMessage: (state, action: PayloadAction<ToastMessagePayload>) => {
      // Prevent duplicated id
      const existsMessage = state.messages.find(
        message => message.id === action.payload.id
      );
      if (!existsMessage) {
        state.messages = [
          ...state.messages,
          {
            ...action.payload,
            id: action.payload.id ?? Math.round(Math.random() * 1000000),
          },
        ];
      }
    },
    enqueueErrorMessage: (state, action: PayloadAction<string | ReactNode>) => {
      state.messages = [
        // @ts-ignore
        ...state.messages,
        {
          body: action.payload,
          id: Math.round(Math.random() * 1000000),
          // @ts-ignore
          variant: 'danger',
        },
      ];
    },
    removeMessage: (state, action: PayloadAction<ToastMessage>) => {
      state.messages = state.messages.filter(
        item => item.id !== action.payload.id
      );
    },
    setGmapsLoaded: (state, action: PayloadAction<boolean>) => {
      state.gmapsLoaded = action.payload;
    },
  },
});

export const {
  showProgressDialog,
  hideProgressDialog,
  enqueueMessage,
  enqueueErrorMessage,
  removeMessage,
  setGmapsLoaded,
} = appSlice.actions;

export const selectShowProgressDialogCount = (state: RootState) =>
  state.app.showProgressDialogCount;

export const selectMessages = (state: RootState) => state.app.messages;

export const selectGmapsLoaded = (state: RootState) => state.app.gmapsLoaded;

export default appSlice.reducer;
