import {useIntl} from 'react-intl';
import {
  EditEventQueryParam,
  IEvent,
  useEditEventMutation,
  useGetEventQuery,
} from '../../../services/eventsApi';
import {
  countDifferences,
  formatEventsApiErrorMessage,
} from '../../../utils/functions';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {EventFormFields, IPerformer} from './types';
import {EventFormDefaultValues} from './constants';
import {getEventValidationSchema} from './validations';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import {SaveFeature} from '../../Common/SaveFeature';
import {EventPermissions} from '../../../constants/permissions';
import EventDetailsForm from './Forms/EventDetailsForm';
import EventDetailsHeader from './Forms/EventDetailsHeader';
import usePermission from '../../../Auth/usePermission';
import {getDateTimeInEventFormat, scrollToFirstError} from './helpers';
import * as urls from '../../../constants/urls';
import {useGetEventErrorElementsRef} from '../../../store/slices/eventsManagement';

const EditEvent = () => {
  const intl = useIntl();
  const [notFound, setNotFound] = useState(false);
  const [disablePrompt, setDisablePrompt] = useState(false);
  const {slug} = useParams<{slug: string}>();
  const getErrorElementRefs = useGetEventErrorElementsRef();

  const {data: event, refetch} = useGetEventQuery({
    slug: slug ?? '',
    formatErrorMessage: error => {
      if (error.status === 404) {
        setNotFound(true);
      }
      return formatEventsApiErrorMessage(error, intl);
    },
    showProgressDialog: true,
  });
  const history = useHistory();
  const [countUpdatedFields, setCountUpdatedFields] = useState(0);

  if (notFound) {
    history.replace(urls.EVENTS_LIST_PATH);
  }

  const [editEvent, {isSuccess: isUpdateSuccess, data: updateData}] =
    useEditEventMutation();

  const {hasPermission} = usePermission();
  const isFormDisabled = !hasPermission(EventPermissions.editEventDetails);
  const isUpdateDomainDisabled = !hasPermission(
    EventPermissions.updateDomainSetting
  );

  const getEventValues = (event?: IEvent): EventFormFields | undefined => {
    if (!event) {
      return;
    }

    const dateFormatted = event.date
      ? moment.utc(event.date).format('YYYY-MM-DD')
      : '';

    const timeFormatted = event.time
      ? moment(event.time, 'HH:mm:ss').format('hh:mm A')
      : '';

    let isPremium = event.isPremium;
    if (!event.isPremium && event.premiumDaysBeforeEvent > 0) {
      isPremium = true;
    }

    const performers = event.performersOrder
      .map(id => event.performers.find(p => p.id === id))
      .filter(Boolean);

    if (performers.length < event.performers.length) {
      performers.push(
        ...event.performers
          .filter(p => !event.performersOrder.includes(p.id))
          .filter(Boolean)
      );
    }

    return {
      ...event,
      performers: performers?.map(performer => ({
        id: (performer as IPerformer).id,
        name: (performer as IPerformer).name,
      })),
      imageUrl: event.imageUrl ?? '',
      publicUrl: `${process.env.REACT_APP_API_BASE_URL}/tickets-${event.slug}`,
      eventDate: dateFormatted,
      eventTime: timeFormatted,
      domainIds: event.domains?.map(d => d.id).sort() ?? [],
      defaultPerformers: 0,
      isCustom: event.premiumDaysBeforeEvent > 0 && isPremium,
      isDateConfirmed: !event.isDateConfirmed,
      isTimeConfirmed: !event.isTimeConfirmed,
      isPremium,
    };
  };

  const form = useForm<EventFormFields>({
    defaultValues: EventFormDefaultValues,
    resolver: yupResolver(getEventValidationSchema(intl)),
    values: getEventValues(event),
    mode: 'onBlur',
  });

  const {watch} = form;

  const formValuesWatch = watch();

  const onSubmit = useCallback(
    (data: EventFormFields) => {
      if (!event) {
        return;
      }
      const {date: formDate, time: formTime} = getDateTimeInEventFormat(
        data.eventDate,
        data.eventTime
      );

      const premiumDaysBeforeEventNumber = Number(data.premiumDaysBeforeEvent);

      const premiumDaysBeforeEvent =
        data.isPremium && !Number.isNaN(premiumDaysBeforeEventNumber)
          ? premiumDaysBeforeEventNumber
          : 0;

      const payload: EditEventQueryParam = {
        id: event?.id,
        slug: event?.slug,
        body: {
          title: data.title,
          imageUrl: null,
          premiumDaysBeforeEvent,
          isPremium: data.isPremium,
          isLive: data.isLive,
          isDateConfirmed: !data.isDateConfirmed,
          isTimeConfirmed: !data.isTimeConfirmed,
          date: formDate,
          time: formTime,
          categoryId: data.categoryId,
          venueId: data.venue.id,
          performerIds: data.performers.map(p => p.id),
          seatmapId: data.seatmapId,
          domainIds: data.domainIds,
          isPostponed: data.isPostponed,
          useCategoryDomainSettings: data.useCategoryDomainSettings,
        },
        showProgressDialog: true,
        formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
        formatSuccessMessage: () =>
          intl.formatMessage({
            id: 'messages.EVENT_UPDATED_SUCCESSFULLY',
          }),
      };
      editEvent(payload);
    },
    [editEvent, event, intl]
  );

  const submitIfValid = useCallback(async () => {
    const isValid = await form.trigger();
    const errorElementRefs = getErrorElementRefs();
    const payload = form.getValues();

    if (!isValid || payload.isCustomPremiumDateErrorExist) {
      scrollToFirstError(
        form.formState.errors,
        errorElementRefs,
        payload.isCustomPremiumDateErrorExist
      );
      return;
    }
    return onSubmit(form.getValues());
  }, [getErrorElementRefs, form, onSubmit]);

  useEffect(() => {
    if (formValuesWatch) {
      setCountUpdatedFields(
        countDifferences(formValuesWatch, getEventValues(event), {
          ignorePaths: [
            'isCustomPremiumDateErrorExist',
            'performersOrder',
            'isCustom',
          ],
          arrayItemComparator: {
            performers: (a?: IPerformer, b?: IPerformer, index?: number) => {
              if (a === undefined || b === undefined) {
                return false;
              }

              return a?.id === b?.id;
            },
          },
        })
      );
    }
  }, [formValuesWatch, event]);

  useEffect(() => {
    if (isUpdateSuccess && updateData) {
      if (updateData.data.slug !== slug) {
        setDisablePrompt(true);
        setTimeout(() => {
          history.replace(
            urls.EVENT_DETAILS_PATH.replace(':slug', updateData.data.slug)
          );
        }, 50);
      } else {
        refetch();
      }
      setCountUpdatedFields(0);
    }
  }, [isUpdateSuccess, updateData, slug, history, refetch]);

  return (
    <div className="details-page event">
      {event && (
        <>
          <EventDetailsHeader event={event} />
          <div className="container ms-lg-0">
            <div className="content-block">
              <EventDetailsForm
                isNew={false}
                domainDisabled={isUpdateDomainDisabled}
                form={form}
                event={event}
                disabled={isFormDisabled}
              />
            </div>
          </div>
          <Prompt
            when={countUpdatedFields > 0 && !disablePrompt}
            message={intl.formatMessage({
              id: 'dashboard.confirm_not_saved_message',
            })}
          />
          {(!isFormDisabled || !isUpdateDomainDisabled) && (
            <SaveFeature
              data-testid="save-feature-content-wrapper"
              onSave={submitIfValid}
              num={countUpdatedFields}
              onClose={() => {
                form.reset(getEventValues(event));
              }}
              open={countUpdatedFields > 0}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditEvent;
