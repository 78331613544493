import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import {InputBaseProps} from '@material-ui/core/InputBase/InputBase';
import {PaperProps} from '@material-ui/core/Paper/Paper';
import {IconButtonProps} from '@material-ui/core/IconButton/IconButton';

//SearchIcon
const searchIcon = (
  <SvgIcon
    viewBox="0 0 14 14"
    style={{
      fontSize: 14,
    }}
  >
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-626.000000, -187.000000)"
      fill="#B8B8B8"
      fillRule="nonzero"
    >
      <g id="Group-11" transform="translate(290.000000, 174.000000)">
        <g id="search" transform="translate(336.000000, 13.000000)">
          <path
            d="M6.16530469,0 C2.76584766,0 0,2.76584766 0,6.16530469 C0,9.56495313 2.76584766,12.3306094 6.16530469,12.3306094 C9.56495313,12.3306094 12.3306094,9.56495313 12.3306094,6.16530469 C12.3306094,2.76584766 9.56495313,0 6.16530469,0 Z M6.16530469,11.1924258 C3.39338672,11.1924258 1.13821094,8.93725 1.13821094,6.16533203 C1.13821094,3.39341406 3.39338672,1.13821094 6.16530469,1.13821094 C8.93722266,1.13821094 11.1923984,3.39338672 11.1923984,6.16530469 C11.1923984,8.93722266 8.93722266,11.1924258 6.16530469,11.1924258 Z"
            id="Shape"
          ></path>
          <path
            d="M13.8332578,13.0285312 L10.5703828,9.76565625 C10.3480508,9.54332422 9.98798828,9.54332422 9.76565625,9.76565625 C9.54332422,9.98779687 9.54332422,10.3482422 9.76565625,10.5703828 L13.0285312,13.8332578 C13.1396836,13.9444102 13.285207,14 13.4308945,14 C13.5763906,14 13.7220781,13.9444102 13.8332578,13.8332578 C14.0555898,13.6111172 14.0555898,13.2506719 13.8332578,13.0285312 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

//ClearIcon
const clearIcon = (
  <SvgIcon viewBox="0 0 23 23">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="SW---UI-style-guideline_icons"
        transform="translate(-625.000000, -314.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g
          id="Path-3"
          transform="translate(636.500000, 325.500000) rotate(-315.000000) translate(-636.500000, -325.500000) translate(629.000000, 318.000000)"
        >
          <polygon
            id="Path"
            points="8.43585237 6.56414763 8.43585237 0 6.56414763 0 6.56414763 6.56414763 0 6.56414763 0 8.43585237 6.56414763 8.43585237 6.56414763 15 8.43585237 15 8.43585237 8.43585237 15 8.43585237 15 6.56414763"
          ></polygon>
        </g>
      </g>
    </g>
  </SvgIcon>
);

const useStyles = makeStyles(theme => ({
  root: {
    padding: '7px 20px',
    height: 40,
    display: 'flex',
    flex: '1 1',
    alignItems: 'center',
    borderRadius: 40,

    '&.bgGray': {
      backgroundColor: theme.palette.background.default,
    },

    '&.sharpedSmall': {
      height: 35,
      padding: '6px 12px 6px 10px',
      borderRadius: 0,
    },

    [theme.breakpoints.down('md')]: {
      height: 42,
      boxShadow: theme.shadows[2],
      padding: '7px 17px 7px 14px',
    },
  },
  input: {
    flex: 1,
    fontSize: 14,
  },
  icon: {
    padding: '0 0 0 12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export interface SearchTextFieldProps extends InputBaseProps {
  grayBackground?: boolean;
  sharpedSmall?: boolean;
  onSearchChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearable?: boolean;
  containerProps?: PaperProps;
  buttonProps?: IconButtonProps & {onClear?: () => void; onClick?: () => void};
}

const SearchTextField = (props: SearchTextFieldProps) => {
  const {
    grayBackground,
    sharpedSmall,
    buttonProps: {onClear, onClick, ...buttonProps} = {},
    containerProps,
    onSearchChanged,
    clearable,
    ...others
  } = props;
  const classes = useStyles();

  const showClear = clearable && others.value;
  return (
    <Paper
      className={clsx(classes.root, grayBackground && 'bgGray', {sharpedSmall})}
      elevation={0}
      {...containerProps}
    >
      <InputBase
        className={classes.input}
        onChange={onSearchChanged}
        {...others}
      />
      <IconButton
        className={classes.icon}
        type="button"
        onClick={showClear ? onClear : onClick}
        {...buttonProps}
      >
        {showClear ? clearIcon : searchIcon}
      </IconButton>
    </Paper>
  );
};

export default SearchTextField;
