import {useLocation} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {FilterChangePayload} from '../types';
import {fromStringToPrimitiveType} from './helpers';

export function useModularFilterUrlInitialValues() {
  const {search} = useLocation();
  const isFirstCheckRef = useRef(true);
  const [values, setValues] = useState<Record<string, FilterChangePayload>>();

  useEffect(() => {
    if (!isFirstCheckRef.current) {
      return;
    }
    isFirstCheckRef.current = false;
    const searchParams = new URLSearchParams(search);
    const filterValues = Array.from(searchParams.entries()).reduce(
      (acc, [key, value]) => {
        if (key.startsWith('filters_')) {
          return {...acc, [key]: value};
        }
        return acc;
      },
      {} as Record<string, string>
    );
    if (!Object.keys(filterValues).length) {
      return;
    }

    const filterKeys = Object.keys(filterValues);
    const shouldBeSkippedFilters: string[] = [];
    const filterValuesObject = Object.entries(filterValues).reduce(
      (acc, [key, value]) => {
        if (shouldBeSkippedFilters.includes(key)) {
          return acc;
        }
        const filterFullPath = key.replace('filters_', '');
        const filterName = filterFullPath.split('.')[0];
        if (filterName === filterFullPath) {
          return {...acc, [filterName]: fromStringToPrimitiveType(value)};
        }

        const relatedFilterKeys = filterKeys.filter(k =>
          k.startsWith(`filters_${filterName}.`)
        );
        shouldBeSkippedFilters.push(...relatedFilterKeys);
        const filterObject = relatedFilterKeys.reduce((filterAcc, k) => {
          const filterKey = k.replace(`filters_${filterName}.`, '');
          return {
            ...filterAcc,
            [filterKey]: fromStringToPrimitiveType(filterValues[k]),
          };
        }, {});

        return {...acc, [filterName]: filterObject};
      },
      {}
    );

    setValues(filterValuesObject);
  }, [search]);

  return values;
}
