import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';

export const EmptySectionsView = () => {
  return (
    <Box pb={1}>
      <Typography variant="body2">
        <FormattedMessage id="dashboard.seatmap.details.no_section_message" />
      </Typography>
    </Box>
  );
};
