import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ListenerMiddlewareInstance,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {get} from 'lodash';
import {
  enqueueSnackbar,
  enqueueSnackbarError,
} from '../../actions/snackbarActions';
import {teamMemberApi} from '../../services/teamMemberApi';
import {authApi} from '../../services/authApi';
import {saleApi} from '../../services/saleApi';
import {logout} from '../slices/authSlice';
import {hideProgressDialog, showProgressDialog} from '../slices/appSlice';
import {
  categoryApi,
  eventsApi,
  eventsManagementApi,
  performerApi,
  seatmapApi,
  tixstockCategoryApi,
  venueApi,
} from '../../services/eventsApi';
import {noteApi} from '../../features/Note/noteApi';

/**
 * Check if the error was an invalid token error
 */
export const isTokenError = (action: any): boolean => {
  const endpoint = get(action, 'meta.arg.endpointName');
  const code = get(action, 'payload.data.code');
  const errorCode = get(action, 'payload.error.response.data.code');
  const url = get(action, 'error.config.url');

  return (
    (code === 401 || errorCode === 401) &&
    !['login'].includes(endpoint) &&
    !/api\/admin_login_check$/.test(url)
  );
};

/**
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 * RTK Listener middleware, used to replace redux-saga
 */
const listenerMiddlewares: ListenerMiddlewareInstance[] = [];

export function showOrHideDialogIfPresent(action: any, show: boolean) {
  const progressDialog = get(
    action,
    'meta.arg.originalArgs.showProgressDialog',
    null
  );
  const aborted = get(action, 'meta.aborted', null);
  const condition = get(action, 'meta.condition', null);
  const shouldShow = !condition || (condition && aborted);
  if (progressDialog && shouldShow) {
    if (show) {
      return showProgressDialog();
    } else {
      return hideProgressDialog();
    }
  }

  return null;
}

const showProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, true);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const hideProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, false);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const showSuccessMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const messages: string[] = [];
  const successMessage = get(
    action,
    'meta.arg.originalArgs.successMessage',
    null
  );
  const formatSuccessMessage = get(
    action,
    'meta.arg.originalArgs.formatSuccessMessage'
  );

  if (successMessage) {
    messages.push(successMessage);
  }

  if (formatSuccessMessage) {
    try {
      const errorData = get(action, 'payload', get(action, 'error', null));
      const formattedError = formatSuccessMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Unable to format success message', e);
    }
  }

  messages.forEach(msg => {
    listenerApi.dispatch(
      enqueueSnackbar({
        message: msg,
        variant: 'success',
      })
    );
  });
};

const showErrorMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const errorMessage = get(action, 'meta.arg.originalArgs.errorMessage');
  const showErrorMessage = get(
    action,
    'meta.arg.originalArgs.showErrorMessage'
  );
  const formatErrorMessage = get(
    action,
    'meta.arg.originalArgs.formatErrorMessage'
  );
  const messages: string[] = [];
  if (errorMessage) {
    messages.push(errorMessage);
  }

  if (formatErrorMessage) {
    try {
      const errorData = get(action, 'payload', get(action, 'error', null));
      const formattedError = formatErrorMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Unable to format error message', e);
    }
  }

  if (!errorMessage && !formatErrorMessage && showErrorMessage) {
    const msg = get(
      action,
      'payload.data.message',
      get(
        action,
        'payload.data["hydra:description"]',
        get(
          action,
          'payload.error',
          get(action, 'error.message', get(action, 'error', null))
        )
      )
    );
    if (typeof msg === 'string') {
      messages.push(msg);
    } else {
      console.warn(
        'Error message was not added because it is not a string',
        msg
      );
    }
  }

  messages.forEach(msg =>
    listenerApi.dispatch(enqueueSnackbarError({message: msg}))
  );
};

// Endpoints for progress dialog and snackbar
const endpoints = [
  teamMemberApi.endpoints.getMembers,
  teamMemberApi.endpoints.addMember,
  teamMemberApi.endpoints.editMember,
  teamMemberApi.endpoints.updateRoles,
  teamMemberApi.endpoints.deleteMember,
  teamMemberApi.endpoints.suspendMember,
  teamMemberApi.endpoints.unsuspendMember,
  teamMemberApi.endpoints.toggle2FA,
  teamMemberApi.endpoints.reset2FA,
  authApi.endpoints.verify2FaCode,
  saleApi.endpoints.getSales,
  noteApi.endpoints.updateNote,
  noteApi.endpoints.createSaleNote,
  categoryApi.endpoints.getCategories,
  categoryApi.endpoints.addCategory,
  categoryApi.endpoints.editCategory,
  categoryApi.endpoints.getCategory,
  categoryApi.endpoints.deleteCategory,
  venueApi.endpoints.addVenue,
  venueApi.endpoints.editVenue,
  venueApi.endpoints.deleteVenue,
  venueApi.endpoints.getVenue,
  tixstockCategoryApi.endpoints.getTixstockCategories,
  tixstockCategoryApi.endpoints.updateTixstockCategories,
  performerApi.endpoints.addPerformer,
  performerApi.endpoints.getPerformers,
  performerApi.endpoints.getPerformer,
  performerApi.endpoints.deletePerformer,
  performerApi.endpoints.editPerformer,
  seatmapApi.endpoints.getSeatmap,
  seatmapApi.endpoints.editSeatmap,
  seatmapApi.endpoints.deleteSeatmap,
  seatmapApi.endpoints.parseSeatmapSvg,
  seatmapApi.endpoints.createSeatmap,
  eventsManagementApi.endpoints.generatePresignedUrl,
  eventsManagementApi.endpoints.getDomains,
  eventsManagementApi.endpoints.getPictures,
  eventsApi.endpoints.deleteEvent,
  eventsApi.endpoints.bulkUpdateEventsLiveState,
  eventsApi.endpoints.bulkUpdateEventsPremiumState,
  eventsApi.endpoints.createEvent,
  eventsApi.endpoints.editEvent,
  eventsApi.endpoints.getEvent,
];

endpoints
  .map(ep => ep.matchPending)
  .forEach(matcher => {
    const listenerMiddleware = createListenerMiddleware();
    listenerMiddleware.startListening({
      matcher,
      effect: showProgressEffect,
    });
    listenerMiddlewares.push(listenerMiddleware);
  });

const fulfilledMatchers = endpoints.map(ep => ep.matchFulfilled);

const rejectedMatchers = endpoints.map(ep => ep.matchRejected);

[...fulfilledMatchers, ...rejectedMatchers].forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: hideProgressEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

fulfilledMatchers.forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showSuccessMessageEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

rejectedMatchers.forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    // effect: showErrorMessageEffect,
    effect: async (
      action: AnyAction,
      listenerApi: ListenerEffectAPI<
        unknown,
        ThunkDispatch<unknown, unknown, AnyAction>,
        unknown
      >
    ) => {
      if (!isTokenError(action)) {
        await showErrorMessageEffect(action, listenerApi);
      }
    },
  });
  listenerMiddlewares.push(listenerMiddleware);
});

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  effect: (
    action,
    listenerApi: ListenerEffectAPI<
      unknown,
      ThunkDispatch<unknown, unknown, AnyAction>,
      unknown
    >
  ) => {
    const message = get(
      action,
      'payload.data.message',
      get(action, 'payload.error.response.data.message')
    );
    // show error message
    listenerApi.dispatch(enqueueSnackbarError({message}));
    // logout and redirect to /login
    listenerApi.dispatch(logout());
  },
  predicate: action => isTokenError(action),
});

listenerMiddlewares.push(listenerMiddleware);

export default listenerMiddlewares;
