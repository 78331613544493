import {createSelector} from '@reduxjs/toolkit';
import {ISeatmapState} from './seatmap.state';

export const selectFormMissingBlocks = createSelector(
  (rootState: {seatmap: ISeatmapState}) => rootState.seatmap.formMissingBlocks,
  formMissingBlocks => formMissingBlocks
);

export const selectFormMissingSections = createSelector(
  (rootState: {seatmap: ISeatmapState}) =>
    rootState.seatmap.formMissingSections,
  formMissingSections => formMissingSections
);

export const selectSvgMissingBlocks = createSelector(
  (rootState: {seatmap: ISeatmapState}) => rootState.seatmap.svgMissingBlocks,
  svgMissingBlocks => svgMissingBlocks
);

export const selectSvgMissingSections = createSelector(
  (rootState: {seatmap: ISeatmapState}) => rootState.seatmap.svgMissingSections,
  svgMissingSections => svgMissingSections
);

export const selectIsSeatmapProcessingAction = createSelector(
  (rootState: {seatmap: ISeatmapState}) => rootState.seatmap.isProcessingAction,
  svgMissingSections => svgMissingSections
);
