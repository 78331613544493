import clsx from 'clsx';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import {Collapse} from 'reactstrap';
import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FilterChangePayload,
  FilterInputTypesEnum,
  FilterItem,
  OnFilterChangeFunction,
  OnFilterRemoveFunction,
} from './types';
import {FormattedMessage} from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import {eventBusEvents, useEmitEvent} from '../../../store/slices/eventBus';

interface CollapsableFilterItemProps {
  titleFormattedMessageId: string;
  inputType?: FilterInputTypesEnum;
  children: ReactElement<FilterItem> | ReactElement<FilterItem>[];
  onFilterChange?: OnFilterChangeFunction;
  onFilterRemove?: OnFilterRemoveFunction;
  openedFilterName?: string;
  openFilter?: (name: string) => void;
  name?: string;
  childrenNames: string[];
  style?: React.CSSProperties;
}

export function CollapsableFilterItem(
  props: Readonly<CollapsableFilterItemProps>
) {
  const {
    titleFormattedMessageId,
    inputType,
    onFilterChange,
    onFilterRemove,
    children,
    name,
    openFilter,
    openedFilterName,
    childrenNames,
    style,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [changedFilterNames, setChangedFilterNames] = useState<Set<string>>(
    new Set()
  );
  const emit = useEmitEvent();

  const toggle = () => {
    setIsOpen(!isOpen);
    openFilter?.(name ?? '');
  };

  const onCollapsableFilterItemFilterChange = useCallback(
    (payload: FilterChangePayload) => {
      setChangedFilterNames(prev => {
        const newSet = new Set(prev);
        newSet.add(payload.name);
        return newSet;
      });
      onFilterChange?.(payload);
    },
    [onFilterChange]
  );

  const onCollapsableFilterItemFilterRemove = useCallback(
    (name: string) => {
      setChangedFilterNames(prev => {
        const newSet = new Set(prev);
        newSet.delete(name);
        return newSet;
      });
      onFilterRemove?.(name);
    },
    [onFilterRemove]
  );

  const clearChildrenValues = useCallback(() => {
    childrenNames.forEach(name => {
      onFilterRemove?.(name);
    });
    setChangedFilterNames(new Set());
    emit(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, childrenNames);
  }, [childrenNames, emit, onFilterRemove]);

  useEffect(() => {
    if (openedFilterName && name && openedFilterName !== name) {
      setIsOpen(false);
    }
  }, [openedFilterName, name]);

  const modifiedChildren = useMemo(
    () =>
      React.Children.map(
        Array.isArray(children) ? children : [children],
        child =>
          cloneElement(child, {
            onFilterChange: onCollapsableFilterItemFilterChange,
            onFilterRemove: onCollapsableFilterItemFilterRemove,
          })
      ).filter(React.isValidElement),
    [
      children,
      onCollapsableFilterItemFilterChange,
      onCollapsableFilterItemFilterRemove,
    ]
  );

  return (
    <div
      className={clsx(
        'card collapsable-block',
        isOpen && 'expanded',
        inputType
      )}
      style={style}
    >
      <div className="card-header" onClick={toggle}>
        <h6
          className={clsx('card-title', {
            'font-weight-bold': isOpen,
            'text-app-primary': changedFilterNames.size > 0,
          })}
        >
          <FormattedMessage id={titleFormattedMessageId} />
        </h6>
        <div>
          {changedFilterNames.size > 0 && (
            <CloseIcon
              style={{fontSize: 17, marginRight: '0.2vw', color: '#21B59B'}}
              onClick={event => {
                event.stopPropagation();
                clearChildrenValues();
              }}
            />
          )}
          {isOpen ? (
            <RemoveIcon style={{fontSize: 17}} />
          ) : (
            <AddIcon style={{fontSize: 17}} />
          )}
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="card-body">
          <>{modifiedChildren}</>
        </div>
      </Collapse>
    </div>
  );
}
