import Box from '@material-ui/core/Box';
import {InfoPrimaryRedIcon} from '../../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {
  useAddSeatmapErrorElementRef,
  useSvgMissingBlocks,
  useSvgMissingSections,
} from '../../../../../store/slices/eventsManagement';

export function SectionsFormSyncError() {
  const addErrorElementRef = useAddSeatmapErrorElementRef();
  const missingBlocks = useSvgMissingBlocks();
  const missingSections = useSvgMissingSections();

  if (!missingSections.length && !Object.keys(missingBlocks).length) {
    return null;
  }

  return (
    <>
      <div
        ref={ref => {
          addErrorElementRef('sectionsSvgSync', ref);
        }}
      />
      <Box display="flex" gridGap={5}>
        <InfoPrimaryRedIcon style={{width: 11, height: 11, marginTop: 2}} />
        <Typography
          variant="body2"
          color="primary"
          className="font-bold-italic"
        >
          <FormattedMessage id="dashboard.seatmap.details.sections_description" />
        </Typography>
      </Box>
    </>
  );
}
