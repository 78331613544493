import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {
  useFormMissingBlocks,
  useFormMissingSections,
} from '../../../../../store/slices/eventsManagement';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
    color: theme.palette.text.primary,
    textAlign: 'center',
    '& > p': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

export function SeatmapImageViewTitle() {
  const classes = useStyles();
  const missingBlocks = useFormMissingBlocks();
  const missingSections = useFormMissingSections();

  const isFormMissingElements =
    !!Object.keys(missingBlocks).length || !!missingSections.length;

  if (!isFormMissingElements) return null;

  return (
    <div className={cx('message', classes.message)}>
      <Typography variant="h3" component="p">
        <FormattedMessage id="dashboard.seatmap.details.seat_map_need_be_changed" />
      </Typography>
    </div>
  );
}
