import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody} from 'reactstrap';
import ImageField from '../../../../Form/Fields/ImageField';
import Switch from '../../../../Common/Switch';
import Button from '../../../../Common/Button';
import TextLine from '../../../../Common/TextLine';
import {useFormContext, useWatch} from 'react-hook-form';
import moment from 'moment';
import React, {useMemo} from 'react';
import RadioGroup from '../../../../Common/RadioGroup';
import {PremiumDaysBeforeEvent} from './PremiumDaysBeforeEvent';
import {EventFormFields} from '../../types';
import {splitTime} from '../../helpers';
import {useAddEventsErrorElementRef} from '../../../../../store/slices/eventsManagement';

interface EventSummaryProps {
  isNew?: boolean;
  disabled?: boolean;
}

export const EventSummary = (props: EventSummaryProps) => {
  const {isNew, disabled} = props;
  const intl = useIntl();
  const {setValue, control, trigger} = useFormContext<EventFormFields>();
  const addErrorElementRef = useAddEventsErrorElementRef();

  const isLive = useWatch<EventFormFields, 'isLive'>({
    control,
    name: 'isLive',
    exact: true,
  });
  const isPremium = useWatch<EventFormFields, 'isPremium'>({
    control,
    name: 'isPremium',
    exact: true,
  });
  const isDateToBeConfirmed = useWatch<EventFormFields, 'isDateConfirmed'>({
    control,
    name: 'isDateConfirmed',
    exact: true,
  });
  const eventDate = useWatch<EventFormFields, 'eventDate'>({
    control,
    name: 'eventDate',
    exact: true,
  });
  const eventTime = useWatch<EventFormFields, 'eventTime'>({
    control,
    name: 'eventTime',
    exact: true,
  });
  const isCustom = useWatch<EventFormFields, 'isCustom'>({
    control,
    name: 'isCustom',
    exact: true,
  });

  const momentEventDate = useMemo(() => {
    if (!eventDate) return null;
    const momentDate = moment(eventDate);
    if (eventTime) {
      const [hours, minutes] = splitTime(eventTime);
      momentDate.set({hours: Number(hours), minutes: Number(minutes)});
    }
    return momentDate;
  }, [eventDate, eventTime]);

  const isEventDayTooSoon = useMemo(() => {
    if (!momentEventDate) return false;
    const diff = momentEventDate.diff(moment(), 'day');
    return diff === 0;
  }, [momentEventDate]);

  const isEventPassed = useMemo(
    () =>
      !isNew &&
      !!momentEventDate &&
      moment(momentEventDate).isSameOrBefore(moment()),
    [momentEventDate, isNew]
  );

  const radioItems = useMemo(() => {
    const isEventPassedAndNotCustom = isEventPassed && !isCustom;
    const isCustomDaysOptionDisabled =
      isEventDayTooSoon ||
      !eventDate ||
      isDateToBeConfirmed ||
      isEventPassedAndNotCustom;

    return [
      {
        label: intl.formatMessage({
          id: 'dashboard.events.apply_immediately',
        }),
        value: 0,
        disabled: false,
      },
      {
        label: intl.formatMessage({
          id: 'dashboard.events.custom_date',
        }),
        value: 1,
        disabled: isCustomDaysOptionDisabled,
        ...(isCustomDaysOptionDisabled && {
          tooltipLabel: intl.formatMessage({
            id:
              !eventDate || isDateToBeConfirmed
                ? 'dashboard.events.details.info_errors.no_date_provided'
                : 'dashboard.events.details.info_errors.date_too_soon',
          }),
        }),
      },
    ];
  }, [
    eventDate,
    intl,
    isCustom,
    isEventDayTooSoon,
    isEventPassed,
    isDateToBeConfirmed,
  ]);

  return (
    <Card className="details-card mb-0">
      <CardBody>
        {isNew && (
          <>
            <Typography variant="subtitle2" className="text-placeholder mb-2">
              {intl.formatMessage({id: 'dashboard.events.add.image'})}*
            </Typography>
            <Button
              secondary
              fullWidth
              mobileFullWidth
              small
              title={intl.formatMessage({
                id: 'dashboard.events.details.select_category',
              })}
              onClick={undefined}
              data-testid="selectImageBtn"
              disabled={disabled}
            />
            <TextLine className="py-2 py-lg-fan-15">
              <FormattedMessage id="actions.or" />
            </TextLine>
          </>
        )}

        {/*Event image*/}
        <div className="form-group d-flex justify-content-between mb-3">
          <ImageField
            input={{
              name: 'categoryImage',
              onChange: (image: unknown) => {
                console.log('TODO Event Image');
              },
            }}
            onDelete={() => {
              console.log('TODO Event Image');
            }}
            wrapperClassName="form-group"
          />
        </div>

        {/*Event live*/}
        <div className="form-group d-flex justify-content-between mb-3">
          <Typography variant="body2" component="span">
            {intl.formatMessage({id: 'dashboard.events.event_live'})}
          </Typography>
          <Switch
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={disabled}
            checked={isLive}
            onChange={(): void => {
              setValue('isLive', !isLive, {shouldDirty: true});
            }}
            data-testid="event-isLive-switch"
          />
        </div>

        {/*Postpone event*/}
        <div className="form-group d-flex justify-content-between mb-3">
          <Typography variant="body2" component="span">
            {intl.formatMessage({id: 'dashboard.events.date_to_be_confirmed'})}
          </Typography>
          <Switch
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={disabled}
            checked={isDateToBeConfirmed}
            onChange={(): void => {
              setValue('isDateConfirmed', !isDateToBeConfirmed, {
                shouldDirty: true,
              });
              void trigger('eventDate');
            }}
            data-testid="event-isDateConfirmed-switch"
          />
        </div>

        {/*Premium event*/}
        <div className="form-group d-flex justify-content-between mb-1">
          <Typography variant="body2" component="span">
            {intl.formatMessage({id: 'dashboard.events.premium_event'})}
          </Typography>
          <Switch
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={disabled}
            checked={isPremium}
            onChange={(): void => {
              setValue('isPremium', !isPremium, {shouldDirty: true});
              setValue('premiumDaysBeforeEvent', 0, {
                shouldDirty: true,
              });
              if (isPremium) {
                setValue('isCustom', false);
              }
            }}
            data-testid="event-isPremium-switch"
          />
        </div>

        <div
          className="form-group d-flex flex-column mb-0 mx-2"
          ref={ref => {
            addErrorElementRef('eventSummary', ref);
          }}
        >
          {isPremium && (
            <>
              <RadioGroup
                name="apply"
                items={radioItems}
                disabled={disabled}
                radioGroupProps={{
                  onChange: e => {
                    const value = Number(e.target.value);
                    if (value === 0) {
                      setValue('premiumDaysBeforeEvent', 0, {
                        shouldDirty: true,
                      });
                      setValue('isCustom', false);
                    } else if (!Number.isNaN(value)) {
                      if (isEventDayTooSoon) {
                        return;
                      }
                      setValue('premiumDaysBeforeEvent', 0, {
                        shouldDirty: true,
                      });
                      setValue('isCustom', true);
                    }
                  },
                  value: isCustom ? '1' : '0',
                }}
                radioProps={{
                  className: 'form-field-row',
                }}
              />

              <PremiumDaysBeforeEvent
                disabled={disabled}
                isCustom={isCustom}
                eventDate={momentEventDate?.toDate().toString()}
                isEventDayTooSoon={isEventDayTooSoon}
                isEventPassed={isEventPassed}
                isDateToBeConfirmed={isDateToBeConfirmed}
              />
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
