import {Col, Row} from 'reactstrap';
import Box from '@material-ui/core/Box';
import {InfoPrimaryRedIcon} from '../../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from '../../../../Common/Button';
import {
  useAddSeatmapErrorElementRef,
  useFormMissingBlocks,
  useFormMissingSections,
  useSvgMissingBlocks,
  useSvgMissingSections,
} from '../../../../../store/slices/eventsManagement';
import {createStyles, makeStyles} from '@material-ui/styles';
import {ITheme} from '../../../../../constants/theme';
import {useIsWithDown} from '../../../../../hooks/UI';
import {BREAKPOINTS} from '../../../../../constants/appConstants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    removeUnrelatedSectionsBtn: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      marginRight: '0.5vw',
      '&:hover': {
        background: theme.palette.background.additional,
        color: theme.palette.primary.main,
      },
    },
  })
);

interface SeatmapSvgFormSyncControlsProps {
  createFormMissingSections: () => void;
  removeSvgUnrelatedSections: () => void;
}

export function SeatmapSvgFormSyncControls(
  props: Readonly<SeatmapSvgFormSyncControlsProps>
) {
  const {createFormMissingSections, removeSvgUnrelatedSections} = props;

  const intl = useIntl();
  const classes = useStyles();
  const addErrorElementRef = useAddSeatmapErrorElementRef();
  const missingBlocks = useFormMissingBlocks();
  const missingSections = useFormMissingSections();
  const svgMissingBlocks = useSvgMissingBlocks();
  const svgMissingSections = useSvgMissingSections();
  const isScreenWidthLessThanLarge = useIsWithDown(BREAKPOINTS.LG);

  const isFormMissingElements =
    !!Object.keys(missingBlocks).length || !!missingSections.length;

  const isSvgMissingElements =
    !!Object.keys(svgMissingBlocks).length || !!svgMissingSections.length;

  const isMissingElements = isFormMissingElements || isSvgMissingElements;

  if (!isMissingElements) return null;

  return (
    <div
      className="mb-fan-15"
      ref={ref => {
        addErrorElementRef('svgFormSync', ref);
      }}
    >
      <Box display="flex" gridGap={5}>
        <InfoPrimaryRedIcon style={{width: 11, height: 11, marginTop: 2}} />
        <div>
          <Typography
            variant="body2"
            color="primary"
            className="font-weight-bold mb-2"
          >
            <FormattedMessage id="dashboard.seatmap.details.vaidation_message" />
          </Typography>
          <div className="mb-3">
            {!!missingSections.length && (
              <Typography variant="body2" color="primary" className="mb-1">
                <FormattedMessage id="dashboard.seatmap.details.section_s" />
                {`: ${missingSections.map(section => section.name).join(', ')}`}
              </Typography>
            )}
            {!!Object.keys(missingBlocks).length && (
              <Typography variant="body2" color="primary">
                <FormattedMessage id="dashboard.seatmap.details.block_s" />
                {`: ${Object.entries(missingBlocks)
                  .map(
                    ([sectionName, blocks]) =>
                      `${sectionName} (${blocks.map(block => block.name).join(', ')})`
                  )
                  .join(', ')}`}
              </Typography>
            )}
          </div>
          <Row className="gx-4 gy-2 align-items-lg-center mb-2">
            {isSvgMissingElements && (
              <Col
                xs="auto"
                className={isScreenWidthLessThanLarge ? 'mx-auto' : undefined}
                style={{marginBottom: isScreenWidthLessThanLarge ? '15px' : 0}}
              >
                <Button
                  data-testid="create_missing_sections"
                  className={classes.removeUnrelatedSectionsBtn}
                  title={intl.formatMessage({
                    id: 'actions.remove_unrelated_sections',
                  })}
                  onClick={removeSvgUnrelatedSections}
                />
              </Col>
            )}
            {isFormMissingElements && (
              <Col lg={6}>
                <Button
                  data-testid="seatmap-missing-create-button"
                  primary
                  fullWidth
                  mobileFullWidth
                  title={intl.formatMessage({
                    id: 'actions.create_missing_sections',
                  })}
                  onClick={createFormMissingSections}
                />
              </Col>
            )}
          </Row>
        </div>
      </Box>
    </div>
  );
}
