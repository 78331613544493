import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import PerformerForm from './Details/PerformerForm';
import NewActionsToolbar from '../../Widgets/NewActionsToolbar';
import {Prompt, useHistory} from 'react-router-dom';
import {
  AddPerformerQueryParam,
  useAddPerformerMutation,
} from '../../../services/eventsApi';
import {PerformerFormFields} from './types';
import {
  getPerformerValidationSchema,
  PerformerFormDefaultValues,
} from './validations';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {formatEventsApiErrorMessage} from '../../../utils/functions';
import {useEffect} from 'react';
import {
  EVENTS_PERFORMER_PATH,
  PERFORMER_EDIT_PATH,
} from '../../../constants/urls';
import usePermission from '../../../Auth/usePermission';
import {PerformerPermissions} from '../../../constants/permissions';

const AddPerformer = () => {
  const history = useHistory();
  const intl = useIntl();

  const [addPerformer, {data: createdPerformer}] = useAddPerformerMutation();
  const {hasPermission} = usePermission();

  const form = useForm<PerformerFormFields>({
    defaultValues: PerformerFormDefaultValues,
    resolver: yupResolver(getPerformerValidationSchema(intl)) as never,
    mode: 'onBlur',
  });

  const onSubmit = (data: PerformerFormFields) => {
    const performerData: AddPerformerQueryParam = {
      body: {
        ...data,
        landingPageUrl: data.landingPageUrl ? data.landingPageUrl : null,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.PERFORMER_CREATED_SUCCESSFULLY',
          },
          {}
        );
      },
    };
    addPerformer(performerData);
  };

  const formChanges = form.formState.isDirty;

  useEffect(() => {
    if (createdPerformer) {
      hasPermission(PerformerPermissions.seePerformerDetails)
        ? history.replace(
            PERFORMER_EDIT_PATH.replace(':id', createdPerformer.id.toString()),
            {
              performer: createdPerformer,
            }
          )
        : history.replace(EVENTS_PERFORMER_PATH);
    }
  }, [createdPerformer, hasPermission, history]);

  return (
    <div className="dashboard-wrapper">
      <Container fluid className="mx-lg-0">
        <div className="details-header performer-details-header pb-fg">
          <Row className="title-block pb-2 justify-content-center">
            <Col xs="auto" lg>
              <Typography variant="h3" className="font-weight-bolder">
                <FormattedMessage id="dashboard.performer.add_performer" />
              </Typography>
            </Col>
            <Col lg="auto">
              <NewActionsToolbar onAddClick={form.handleSubmit(onSubmit)} />
            </Col>
          </Row>
        </div>
      </Container>
      <Prompt
        when={!!formChanges && !createdPerformer}
        message={intl.formatMessage({
          id: 'dashboard.confirm_not_saved_message',
        })}
      />

      <Container className="mx-lg-0">
        <div className="dashboard-content">
          <PerformerForm isNew form={form} />
        </div>
        <div className="pt-3">
          <NewActionsToolbar
            isMobile
            onAddClick={form.handleSubmit(onSubmit)}
          />
        </div>
      </Container>
    </div>
  );
};

export default AddPerformer;
