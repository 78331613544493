import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {CheckboxProps} from '@material-ui/core/Checkbox/Checkbox';

//Styles wrapper
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5),
    '&.filter': {
      padding: theme.spacing(0.3),
      marginLeft: 3,
    },
    '&.Mui-disabled': {
      filter: 'grayscale(1) opacity(0.5)',
    }
  },
  checked: {},
  icon: {
    borderRadius: 0,
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0.5),
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#EDEDED',
      margin: theme.spacing(0.5),
    },
    '&.small': {
      border: 'none',
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 62 (101010) - https://sketch.com --%3E%3Ctitle%3E66913449-D980-46F1-B573-93626E9A54F2%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW_Admin_User_Recipient-View-Profile_Bottom-pop-up' transform='translate(-319.000000, -388.000000)' fill='%23FFFFFF' stroke='%23B8B8B8'%3E%3Cg id='Check/off-Copy-7' transform='translate(320.000000, 389.000000)'%3E%3Crect id='Rectangle-Copy-14' x='0' y='0' width='10' height='10' rx='1'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      margin: 0,
    },
  },
  checkedIcon: {
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'none',
    margin: theme.spacing(0.5),
    '&:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Crect width='15' height='15' rx='1' fill='%23FF7575'/%3E%3Cpath d='M3 7.41216L6.082 10.5L12 4.58788L11.1504 3.75L6.082 8.8125L3.83786 6.56838L3 7.41216Z' fill='white'/%3E%3C/svg%3E\")",
      content: '""',
    },
    '&.small': {
      margin: 0,
      '&:before': {
        width: 12,
        height: 12,
      },
    },
  },
  indeterminateIcon: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    '&:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Crect width='15' height='15' rx='1' fill='%23FF7575'/%3E%3Cpath d='M3 7.41216L6.082 10.5L12 4.58788L11.1504 3.75L6.082 8.8125L3.83786 6.56838L3 7.41216Z' fill='white'/%3E%3Crect width='15' height='15' rx='1' fill='%23FF7575'/%3E%3Crect x='3' y='6.5' width='9' height='2' fill='white'/%3E%3Crect width='15' height='15' rx='1' fill='%23FF7575'/%3E%3Cpath d='M3 7.41216L6.082 10.5L12 4.58788L11.1504 3.75L6.082 8.8125L3.83786 6.56838L3 7.41216Z' fill='white'/%3E%3Crect x='0.5' y='0.5' width='14' height='14' rx='0.5' fill='white' stroke='%23B8B8B8'/%3E%3Crect width='15' height='15' rx='1' fill='%23FF7575'/%3E%3Crect x='3' y='6.5' width='9' height='2' fill='white'/%3E%3C/svg%3E\")",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      content: '""',
    },
  },
}));

interface CustomCheckboxProps extends Omit<CheckboxProps, 'size'> {
  size?: 'default' | 'small';
}

/* Checkbox Component */
const CustomCheckbox = ({size = 'default', ...other}: CustomCheckboxProps) => {
  const classes = useStyles();

  //is small
  const small = size === 'small';

  return (
    <Checkbox
      classes={{
        root: classes.root,
      }}
      icon={<span className={clsx(classes.icon, small && 'small')} />}
      checkedIcon={
        <span className={clsx(classes.checkedIcon, small && 'small')} />
      }
      indeterminateIcon={<span className={clsx(classes.indeterminateIcon)} />}
      {...other}
    />
  );
};

export default CustomCheckbox;
