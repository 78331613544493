import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {IMG_BASE_URL} from '../../../../constants/urls';
import {
  EventManualIcon,
  ImportedEventIcon,
  InfoPrimaryRedIcon,
  UpdateIcon,
} from '../../../../constants/images';
import {useTheme} from '@material-ui/styles';
import EventMark from '../../components/EventMark';
import {
  IAPIListOptions,
  useGetSeatmapsQuery,
} from '../../../../services/eventsApi';
import {useMemo} from 'react';
import {formatDate} from '../../../../utils/functions';
import {ITheme} from '../../../../constants/theme';

type SeatmapRowProps = {
  id: number;
  title: string;
  imageUrl: string;
  isManual: boolean;
  seatmapMissing: boolean;
  needUpdate: boolean;
  intl: IntlShape;
};

export default function useSeatmapList(options: IAPIListOptions = {}) {
  const intl = useIntl();

  const {
    data: seatmapResult,
    isLoading,
    isFetching,
  } = useGetSeatmapsQuery(options);

  const seatmaps = useMemo(() => {
    if (!seatmapResult?.data) {
      return [];
    }
    return seatmapResult.data.map(seatmap => ({
      id: seatmap.id,
      name: {
        id: seatmap.id,
        title: seatmap.name,
        imageUrl: seatmap.imageUrl,
        isManual: !seatmap.providers?.length,
        seatmapMissing: !seatmap.imageUrl,
        needUpdate: false, //TODO
      },
      venue: `${seatmap.venue.name}, ${seatmap.venue.city}, ${seatmap.venue.country}`,
      createdAt: formatDate(seatmap.createdAt, 'DD MMM YYYY'),
      updatedAt: formatDate(seatmap.updatedAt, 'DD MMM YYYY'),
    }));
  }, [seatmapResult?.data]);

  const HeaderCells = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'dashboard.seatmap.seat_map',
      }),
      collapse: false,
      renderValue: (seatmapRowProps: Omit<SeatmapRowProps, 'intl'>) => (
        <SeatmapRow {...seatmapRowProps} intl={intl} />
      ),
      width: '25%',
    },
    {
      name: 'venue',
      label: intl.formatMessage({id: 'dashboard.seatmap.venue'}),
      collapse: true,
    },
    {
      name: 'updatedAt',
      label: intl.formatMessage({id: 'dashboard.seatmap.last_update'}),
      collapse: true,
    },
    {
      name: 'createdAt',
      label: intl.formatMessage({id: 'dashboard.seatmap.creation_date'}),
      collapse: false,
    },
  ];

  return {
    HeaderCells,
    rows: seatmaps,
    isLoading: isLoading || isFetching,
    total: seatmapResult?.meta.itemCount ?? 0,
    pageCount: seatmapResult?.meta.pageCount ?? 0,
    currentPage: options.page ?? seatmapResult?.meta.page ?? 1,
  };
}

function SeatmapRow({
  title,
  seatmapMissing,
  needUpdate,
  isManual,
  imageUrl,
  id,
}: SeatmapRowProps) {
  const theme: ITheme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap={10}
      className="ps-1 ps-lg-1"
    >
      <picture className="event-picture">
        <img
          data-testid={`seatmapsvg-${id}`}
          className="img-fluid rounded-sm"
          src={imageUrl ? imageUrl : `${IMG_BASE_URL}event-placeholder.jpg`}
          alt=""
        />
      </picture>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row gap-1">
          <EventMark
            icon={isManual ? <EventManualIcon /> : <ImportedEventIcon />}
            formattedMessageId={
              'dashboard.events.list.' + (isManual ? 'manual_input' : 'import')
            }
            iconSize="11"
          />
          <span>{title}</span>
        </div>
        {seatmapMissing && (
          <div className="d-flex align-items-center mt-1">
            <InfoPrimaryRedIcon style={{width: 11, height: 11}} />
            <Typography
              color="primary"
              className="ps-1 font-bold-italic text-truncate"
              variant="body2"
            >
              <FormattedMessage id="dashboard.events.list.seat_map_missing" />
            </Typography>
          </div>
        )}
        {needUpdate && (
          <div className="d-inline-flex align-items-center w-100 mt-1">
            <div className="d-inline-flex">
              <UpdateIcon style={{width: 11, height: 11, marginBottom: 2}} />
            </div>
            <Typography
              style={{color: theme.palette?.blue?.main}}
              variant="body2"
              className="ps-1 font-bold-italic text-truncate"
            >
              <FormattedMessage id="dashboard.seatmap.seat_map_update" />
            </Typography>
          </div>
        )}
      </div>
    </Box>
  );
}
