import clsx from 'clsx';
import {EventMark} from '../../../components';
import {
  EventManualIcon,
  ImportedEventIcon,
  InfoPrimaryRedIcon,
  PremiumIcon,
} from '../../../../../constants/images';
import {IMG_BASE_URL} from '../../../../../constants/urls';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Updates} from '../../components';
import React from 'react';
import {IEvent} from '../../../../../services/eventsApi';
import usePermission from '../../../../../Auth/usePermission';
import {EventPermissions} from '../../../../../constants/permissions';

export type EventHeaderCellProps = Omit<IEvent, 'category' | 'venue'>;

export function EventCell(props: Readonly<EventHeaderCellProps>) {
  const {
    title,
    imageUrl,
    isPremium,
    seatmap,
    provider,
    premiumDaysBeforeEvent,
  } = props;

  const isSeatmapImageMissing = !seatmap.imageUrl;
  const isImported = !!provider;
  const titleUpdate = provider?.changes?.title;
  const {hasPermission} = usePermission();

  const intl = useIntl();
  const isSelectOff = !hasPermission(EventPermissions.performEventBulkActions);

  return (
    <div
      className={clsx('d-flex overflow-hidden align-items-center', {
        'ps-2 ps-lg-3': isSelectOff,
      })}
    >
      <picture className={clsx('event-picture me-2', {premium: true})}>
        {(isPremium || !!premiumDaysBeforeEvent) && (
          <div className="event-mark">
            <EventMark
              icon={<PremiumIcon />}
              iconSize="15px"
              formattedMessageId={intl.formatMessage({
                id: 'dashboard.events.list.premium',
              })}
            />
          </div>
        )}
        <img
          className="img-fluid rounded-sm"
          src={!imageUrl ? `${IMG_BASE_URL}event-placeholder.jpg` : imageUrl}
          alt=""
        />
      </picture>
      <div className="overflow-hidden">
        <div className="d-flex align-items-center">
          <div className="d-inline-flex pe-1">
            <EventMark
              icon={isImported ? <ImportedEventIcon /> : <EventManualIcon />}
              formattedMessageId={
                isImported
                  ? 'dashboard.events.list.import'
                  : 'dashboard.events.list.manual_input'
              }
              iconSize="11"
            />
          </div>
          <div className="d-flex flex-column">
            <span>{title}</span>
          </div>
        </div>
        {isSeatmapImageMissing && (
          <div className="d-flex align-items-center mt-1">
            <InfoPrimaryRedIcon style={{width: 11, height: 11}} />
            <Typography
              color="primary"
              className="ps-1 font-bold-italic text-truncate"
              variant="body2"
            >
              <FormattedMessage id="dashboard.events.list.seat_map_missing" />
            </Typography>
          </div>
        )}
        <Updates
          updatedValueLabel={intl.formatMessage({
            id: 'dashboard.events.list.event_update',
          })}
          updatedValue={titleUpdate?.value}
        />
      </div>
    </div>
  );
}
