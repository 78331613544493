import {IPermission, IRole} from '../../../services/eventsApi';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../Common/IconButton';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useState} from 'react';
import Checkbox from '../../Common/Checkbox';
import {Permission} from '../types';
import {uniqBy} from 'lodash';

interface PermissionGroupListProps {
  roles: IRole[];
  title: string;
  permissions: IPermission[] | Permission[];
  isEventPermission?: boolean;
  setRolePermissionsMap: React.Dispatch<
    React.SetStateAction<Map<string, IPermission[] | Permission[]>>
  >;
  rolePermissionsMap: Map<string, IPermission[] | Permission[]>;
  onPermissionAssigned: (
    role: IRole,
    permissions: Array<Permission | IPermission>,
    isEventPermission?: boolean
  ) => Promise<boolean>;
}

export function PermissionGroupList(props: Readonly<PermissionGroupListProps>) {
  const {
    roles,
    title,
    permissions,
    onPermissionAssigned,
    isEventPermission,
    setRolePermissionsMap,
    rolePermissionsMap,
  } = props;
  const [collapsed, setCollapsed] = useState(false);

  const onPermissionCheckboxChange = async (
    role: IRole,
    permission: IPermission | Permission,
    isChecked: boolean
  ) => {
    const newPermissionsArray = rolePermissionsMap.get(role.slug)?.concat([]);
    if (!newPermissionsArray) {
      return;
    }

    if (isChecked) {
      newPermissionsArray.push(permission);
    } else {
      const index = newPermissionsArray.findIndex(
        rolePermission => rolePermission.id === permission.id
      );
      if (index !== -1) {
        newPermissionsArray.splice(index, 1);
      }
    }
    const newMap = new Map(rolePermissionsMap);
    newMap.set(role.slug, newPermissionsArray);
    const result = await onPermissionAssigned(
      role,
      newPermissionsArray,
      isEventPermission
    );
    if (result) {
      setRolePermissionsMap(newMap);
    }
  };

  const onPermissionGroupCheckboxChange = async (
    role: IRole,
    isChecked: boolean
  ) => {
    const newPermissionsArray: Array<IPermission | Permission> = [];
    const groupPermissions = permissions.filter(permission => {
      if ('permissionGroup' in permission) {
        return permission.permissionGroup === title;
      }
      return permission.permission_group === title;
    });

    if (isChecked) {
      newPermissionsArray.push(
        ...uniqBy(
          groupPermissions.concat(rolePermissionsMap.get(role.slug) ?? []),
          'id'
        )
      );
    } else {
      const rolePermissions = rolePermissionsMap.get(role.slug) ?? [];
      newPermissionsArray.push(
        ...rolePermissions.filter(
          rolePermission =>
            !groupPermissions.some(
              permission => permission.id === rolePermission.id
            )
        )
      );
    }

    const newMap = new Map(rolePermissionsMap);
    newMap.set(role.slug, newPermissionsArray);
    const result = await onPermissionAssigned(
      role,
      newPermissionsArray,
      isEventPermission
    );
    if (result) {
      setRolePermissionsMap(newMap);
    }
  };

  const columnWidth = 100 / roles.length;

  return (
    <>
      <div className="row gx-0">
        <div className="col-lg-4">
          <div
            className="right-cell main text-truncate"
            onClick={() => setCollapsed(prev => !prev)}
          >
            <Typography variant="body2" className="text-truncate">
              <b>{title}</b>{' '}
              <Typography
                variant="body2"
                component="b"
                style={{color: '#21B59B'}}
              >{`(${permissions.length})`}</Typography>
            </Typography>
            {/*// @ts-ignore*/}
            <IconButton size="xs" variant="action" onClick={() => {}}>
              {collapsed ? (
                <ExpandMore style={{fontSize: 20}} />
              ) : (
                <ExpandLess style={{fontSize: 20}} />
              )}
            </IconButton>
          </div>
        </div>
        <div className="role-list">
          {roles.map((role, index) => {
            const rolePermissions =
              rolePermissionsMap.get(role.slug)?.filter(permission => {
                if ('permissionGroup' in permission) {
                  return permission.permissionGroup === title;
                }
                return permission.permission_group === title;
              }) ?? [];

            const isChecked = rolePermissions.length === permissions.length;
            const isIndeterminate = !isChecked && !!rolePermissions.length;
            return (
              <div
                key={`${role.slug}_${index}`}
                className="role-cell main"
                style={{width: `${columnWidth}%`}}
              >
                <Checkbox
                  checked={rolePermissions.length === permissions.length}
                  indeterminate={isIndeterminate}
                  onChange={event => {
                    const isEventChecked = event.target.checked;
                    void onPermissionGroupCheckboxChange(role, isEventChecked);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {!collapsed &&
        permissions.map((permission, index) => {
          return (
            <div className="row gx-0 row" key={permission.id}>
              <div className="col-lg-4">
                <div className="right-cell">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="text-truncate"
                  >
                    {permission.name}
                  </Typography>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="role-list">
                  {roles.map((role, index) => {
                    return (
                      <div
                        key={`${role.slug}_${index}`}
                        className="role-cell"
                        style={{width: `${columnWidth}%`}}
                      >
                        <Checkbox
                          checked={
                            !!rolePermissionsMap
                              .get(role.slug)
                              ?.find(
                                rolePermission =>
                                  rolePermission.id === permission.id
                              )
                          }
                          onChange={event => {
                            const isChecked = event.target.checked;
                            void onPermissionCheckboxChange(
                              role,
                              permission,
                              isChecked
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
