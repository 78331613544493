import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {
  AutocompleteValue,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import {FilterInputTypesEnum, FilterItem} from '../types';
import {SearchIcon} from '../../../../constants/images';
import Autocomplete from '@mui/material/Autocomplete';
import {FormattedMessage} from 'react-intl';
import {
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete/Autocomplete';
import {useMultiSelectAutocompleteFilterStyles} from './styles';
import {Box, ClickAwayListener} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useModularFilterUrlValue} from '../hooks';
import {
  eventBusEvents,
  useEventBusListener,
} from '../../../../store/slices/eventBus';
import {nanoid} from 'nanoid';
import Chip from '../../../Common/Chip';
import CloseIcon from '@material-ui/icons/Close';

type FilterAutocompleteValue<T> = AutocompleteValue<T, true, false, false>;

export interface MultiSelectAutocompleteFilterProps<T>
  extends FilterItem,
    Omit<
      AutocompleteProps<T, true, false, false>,
      'onChange' | 'value' | 'renderInput' | 'classes'
    > {
  getSelectedLabel: (option?: T) => string | null;
  getOptionValue: (option: T) => string | number;
  onTyping?: (text: string) => void;
}

export function MultiSelectAutocompleteFilter<T>(
  props: Readonly<MultiSelectAutocompleteFilterProps<T>>
) {
  const {
    getSelectedLabel,
    getOptionValue,
    name,
    options,
    placeholder,
    inputValue,
    onTyping,
    loading,
    onFilterChange,
    onFilterRemove,
    ...restProps
  } = props;

  const classes = useMultiSelectAutocompleteFilterStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
  const urlValues = useModularFilterUrlValue(name);
  const {on, off} = useEventBusListener();

  const removeValue = (option: T) => {
    const newOptions = selectedOptions.filter(
      selectedOption =>
        getOptionValue(selectedOption) !== getOptionValue(option)
    );
    if (newOptions.length) {
      onFilterChange?.({
        value: newOptions.map(getOptionValue),
        type: FilterInputTypesEnum.MULTISELECT_AUTOCOMPLETE,
        name,
      });
    } else {
      onFilterRemove?.(name);
    }
    setSelectedOptions(newOptions);
  };

  const handleChange = (
    event: SyntheticEvent,
    newOptions: FilterAutocompleteValue<T>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'removeOption' && event.type !== 'click') return;
    setSelectedOptions(newOptions);
    if (newOptions.length) {
      onFilterChange?.({
        value: newOptions.map(getOptionValue),
        type: FilterInputTypesEnum.MULTISELECT_AUTOCOMPLETE,
        name,
      });
    } else {
      onFilterRemove?.(name);
    }
    onTyping?.('');
  };

  useEffect(() => {
    if (urlValues) {
      setSelectedOptions(
        options.filter(option => urlValues.includes(getOptionValue(option)))
      );
      onFilterChange?.({
        value: urlValues,
        type: FilterInputTypesEnum.MULTISELECT_AUTOCOMPLETE,
        name,
      });
    } else {
      setSelectedOptions([]);
      onFilterRemove?.(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlValues]);

  useEffect(() => {
    const callbackId = nanoid(5);
    on(
      eventBusEvents.ModularFilterEvents.CLEAR_FILTER,
      callbackId,
      (clearedFilterNames: string[]) => {
        if (clearedFilterNames.includes(name)) {
          setSelectedOptions([]);
        }
      }
    );
    return () => {
      off(eventBusEvents.ModularFilterEvents.CLEAR_FILTER, callbackId);
    };
  }, [on, off, name]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className="search-filter-row">
        <Autocomplete
          {...restProps}
          value={selectedOptions}
          multiple
          classes={{
            paper: classes.paper,
            listbox: classes.listBox,
            option: classes.option,
          }}
          componentsProps={{
            popper: {sx: {width: '18vw !important'}},
          }}
          disableCloseOnSelect
          disablePortal
          open={isOpen}
          renderTags={selectedOptions => null}
          options={options}
          renderInput={params => {
            return (
              <div className={classes.selectedOptionsWrapper}>
                {selectedOptions.map(selectedOption => {
                  const value = getOptionValue(selectedOption);
                  const label = getSelectedLabel(selectedOption);
                  if (!label) return null;
                  return (
                    <Chip
                      key={value}
                      label={label}
                      className={classes.chip}
                      onDelete={() => removeValue(selectedOption)}
                      // @ts-ignore
                      deleteIcon={
                        <CloseIcon
                          style={{fontSize: '16px'}}
                          onClick={() => removeValue(selectedOption)}
                        />
                      }
                    />
                  );
                })}
                <Input
                  placeholder={placeholder}
                  ref={params.InputProps.ref}
                  onClick={() => setIsOpen(true)}
                  disableUnderline
                  style={{border: 0, padding: 0}}
                  inputProps={{
                    ...params.inputProps,
                    value: inputValue,
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      params.inputProps?.onChange?.(event);
                      onTyping?.(event.target.value);
                    },
                  }}
                  autoFocus
                  className={classes.inputBase}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon style={{fontSize: '16px'}} />
                    </InputAdornment>
                  }
                />
              </div>
            );
          }}
          onChange={handleChange}
          noOptionsText={
            loading ? (
              <div className={classes.noOptionsWrapper}>
                <Box sx={{position: 'relative'}}>
                  <CircularProgress
                    variant="determinate"
                    className={classes.circularProgressBack}
                    size={20}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.circularProgress}
                    size={20}
                    thickness={4}
                  />
                </Box>
                <Typography className={classes.loadingText}>
                  <FormattedMessage id="actions.autocomplete_loading" />
                </Typography>
              </div>
            ) : (
              <Typography className={classes.noOptionText}>
                <FormattedMessage id="dashboard.no_results" />
              </Typography>
            )
          }
        />
      </div>
    </ClickAwayListener>
  );
}
