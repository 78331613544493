import {
  ErrorElementRefs,
  FormMissingBlock,
  FormMissingSection,
  SvgMissingBlock,
  SvgMissingSection,
} from '../types';

export interface ISeatmapState {
  errorElementRefsMap: ErrorElementRefs;
  formMissingBlocks: {[key: string]: FormMissingBlock[]};
  formMissingSections: FormMissingSection[];
  svgMissingBlocks: {[key: string]: SvgMissingBlock[]};
  svgMissingSections: SvgMissingSection[];
  isProcessingAction: boolean;
}

export const InitialSeatmapState: ISeatmapState = {
  errorElementRefsMap: {},
  formMissingBlocks: {},
  formMissingSections: [],
  svgMissingBlocks: {},
  svgMissingSections: [],
  isProcessingAction: false,
};
