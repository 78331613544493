import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import NewActionsToolbar from '../../Widgets/NewActionsToolbar';
import SeatmapForm from './Form/SeatmapForm';
import {useForm} from 'react-hook-form';
import {SeatmapFormFields} from './types';
import {getSeatmapValidationSchema} from './validations';
import {yupResolver} from '@hookform/resolvers/yup';
import {useUploadFileToS3} from '../../../services/hooks/useUploadFileToS3';
import {OverlaySpinner} from '../../Widgets/Spinner';
import {
  CreateSeatmapQueryParam,
  useCreateSeatmapMutation,
} from '../../../services/eventsApi';
import {SeatmapFormDefaultValues} from './constants';
import {
  countDirtyFields,
  formatEventsApiErrorMessage,
} from '../../../utils/functions';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  EVENTS_SEATMAP_PATH,
  SEATMAP_DETAILS_PATH,
} from '../../../constants/urls';
import {Prompt, useHistory} from 'react-router-dom';
import PermissionContainer from '../../Common/PermissionContainer';
import {SeatmapPermissions} from '../../../constants/permissions';
import useSeatmapImage from './hooks/useSeatmapImage';
import ConfirmationModal from '../../Common/ConfirmationModal';
import {
  useGetFormSvgSyncProps,
  useGetSeatmapErrorElementsRef,
  useSeatmapStoreReset,
} from '../../../store/slices/eventsManagement';
import {scrollToFirstError, slugify} from './helpers';
import usePermission from '../../../Auth/usePermission';

export const AddSeatmap = () => {
  const intl = useIntl();
  const history = useHistory();
  const {upload: uploadSeatmapImage, isLoading: isUploadingSeatmapFile} =
    useUploadFileToS3({
      entity: 'Seatmap',
    });
  const [createSeatmap, {data: createdSeatmap, isLoading: isCreatingSeatmap}] =
    useCreateSeatmapMutation();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [pendingConfirmationFormData, setPendingConfirmationFormData] =
    useState<SeatmapFormFields>();

  const getErrorElementRefs = useGetSeatmapErrorElementsRef();
  const getFormSvgSyncProps = useGetFormSvgSyncProps();
  const resetSeatmapStore = useSeatmapStoreReset();

  const {hasPermission} = usePermission();

  const form = useForm<SeatmapFormFields>({
    defaultValues: SeatmapFormDefaultValues,
    resolver: yupResolver(getSeatmapValidationSchema(intl)),
    mode: 'onSubmit',
  });

  const {setValue, trigger, getValues, watch} = form;

  const useSeatmapImageProps = useMemo(
    () => ({
      setValue,
      trigger,
      getValues,
      watch,
    }),
    [setValue, trigger, getValues, watch]
  );

  const {
    getRootProps,
    getInputProps,
    createFormMissingSections,
    removeSvgUnrelatedSections,
    reset,
    isLoading: isImageLoading,
    deleteSvgMissingBlock,
  } = useSeatmapImage(useSeatmapImageProps);

  const getImageUrl = useCallback(async (): Promise<string | undefined> => {
    const seatmapFile = form.getValues('seatmapFile');
    if (seatmapFile) {
      return uploadSeatmapImage(seatmapFile);
    }
  }, [form, uploadSeatmapImage]);

  const updateSeatmap = useCallback(
    async (data?: SeatmapFormFields) => {
      setIsConfirmationOpen(false);
      const payloadData = data || pendingConfirmationFormData;
      if (!payloadData) return;

      const imageUrl = await getImageUrl();
      const payload: CreateSeatmapQueryParam = {
        body: {
          name: payloadData.name,
          sections: payloadData.sections.map(section => ({
            ...slugify(section),
            blocks: section.blocks?.map(block => slugify(block)),
          })),
          isSVGWithSeatmapFormat: payloadData.isSVGWithSeatmapFormat,
          venueId: payloadData.venue.id,
          imageUrl,
        },
        showProgressDialog: false,
        formatErrorMessage: error => formatEventsApiErrorMessage(error, intl),
        formatSuccessMessage: () =>
          intl.formatMessage({
            id: 'messages.SEATMAP_CREATED_SUCCESSFULLY',
          }),
      };
      createSeatmap(payload);
    },
    [createSeatmap, getImageUrl, intl, pendingConfirmationFormData]
  );

  const onSubmit = useCallback(
    async (data: SeatmapFormFields) => {
      const colors = new Set(data.sections.map(section => section.color));
      if (colors.size === data.sections.length) {
        return updateSeatmap(data);
      } else {
        setPendingConfirmationFormData(data);
        setIsConfirmationOpen(true);
      }
    },
    [updateSeatmap]
  );

  const submitIfValid = useCallback(async () => {
    const isValid = await form.trigger();
    const {
      formMissingSections,
      formMissingBlocks,
      svgMissingBlocks,
      svgMissingSections,
    } = getFormSvgSyncProps();

    const isListContainErrors =
      Object.keys(svgMissingBlocks).length > 0 || svgMissingSections.length > 0;

    const isFormMissingElements =
      Object.keys(formMissingBlocks).length > 0 ||
      formMissingSections.length > 0;

    if (isValid && !isListContainErrors && !isFormMissingElements) {
      return onSubmit(form.getValues());
    }

    const errorElementRefs = getErrorElementRefs();

    scrollToFirstError(
      form.formState.errors,
      errorElementRefs,
      isListContainErrors,
      isFormMissingElements
    );
  }, [getErrorElementRefs, form, getFormSvgSyncProps, onSubmit]);

  const onColorsConfirmed = useCallback(() => {
    updateSeatmap().catch();
  }, [updateSeatmap]);

  const onImageDelete = useCallback(() => {
    reset();
    setValue('seatmapFile', null);
    setValue('imageUrl', null);
    setValue('isSVGWithSeatmapFormat', false);
  }, [reset, setValue]);

  const onBlockAddedOrRemoved = useCallback(
    (
      blockId: number | string,
      sectionId: number | string,
      action: 'add' | 'delete',
      blockSlug?: string,
      sectionSlug?: string
    ) => {
      if (sectionSlug && blockSlug) {
        deleteSvgMissingBlock(sectionSlug, blockSlug);
      }
    },
    [deleteSvgMissingBlock]
  );

  useEffect(() => {
    if (createdSeatmap) {
      hasPermission(SeatmapPermissions.seeSeatmapDetails)
        ? history.replace(
            SEATMAP_DETAILS_PATH.replace(':id', createdSeatmap.id.toString()),
            {
              seatmap: createdSeatmap,
            }
          )
        : history.replace(EVENTS_SEATMAP_PATH);
    }
  }, [createdSeatmap, hasPermission, history]);

  useEffect(() => {
    return () => {
      resetSeatmapStore();
    };
  }, [resetSeatmapStore]);

  const isLoading =
    isUploadingSeatmapFile || isCreatingSeatmap || isImageLoading;

  return (
    <div className="dashboard-wrapper">
      <OverlaySpinner isLoading={isLoading} />
      <Container fluid className="mx-lg-0">
        <div className="details-header sale-details-header pb-fg">
          <Row className="title-block pb-2 justify-content-center">
            <Col xs="auto" lg>
              <Typography variant="h3" className="font-weight-bolder">
                <FormattedMessage id="dashboard.seatmap.add.title" />
              </Typography>
            </Col>
            <Col lg="auto">
              <PermissionContainer
                // @ts-ignore
                permissions={[SeatmapPermissions.addSeatmap]}
              >
                <NewActionsToolbar onAddClick={submitIfValid} />
              </PermissionContainer>
            </Col>
          </Row>
        </div>
        <div className="dashboard-content">
          <Row>
            <Col lg={8}>
              <Card className="details-card">
                <CardBody>
                  <Typography variant="h4" className="mb-2 mb-lg-3">
                    <FormattedMessage id="dashboard.seatmap.details.seat_map_details" />
                  </Typography>
                  <SeatmapForm
                    isNew
                    form={form}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    createFormMissingSections={createFormMissingSections}
                    removeSvgUnrelatedSections={removeSvgUnrelatedSections}
                    onImageDelete={onImageDelete}
                    onBlockAddedOrRemoved={onBlockAddedOrRemoved}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="pt-3">
            <NewActionsToolbar isMobile onAddClick={submitIfValid} />
          </div>
        </div>
      </Container>
      {!createdSeatmap && (
        <Prompt
          when={!!countDirtyFields(form.formState.dirtyFields)}
          message={intl.formatMessage({
            id: 'dashboard.confirm_not_saved_message',
          })}
        />
      )}
      <ConfirmationModal
        opened={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onAccept={onColorsConfirmed}
        description={intl.formatMessage({
          id: 'dashboard.confirm_colors_duplication',
        })}
      />
    </div>
  );
};
